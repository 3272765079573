import { Redirect, useHistory } from 'react-router-dom';
import { useSearchAtArchiveEventTabQuery } from '../../generated/graphql';
import { LoadingBox } from '../atoms/LoadingBox';
import AtSearchEventListVertical from '../molecules/AtSearchEventListVertical';
import style from './AtSearchUpcomingEventList.module.scss';
import { AtSearchNoResults } from '../atoms/AtSearchNoResults';
import { tabsInfo } from '../pages/AtSearch';

interface AtSearchArchiveEventListProps {
  keyword: string;
  page: number;
  size: number;
}

export const AtSearchArchiveEventList = ({ keyword, page, size }: AtSearchArchiveEventListProps) => {

  const {
    loading, error, data, refetch,
  } = useSearchAtArchiveEventTabQuery({
    variables: { page, size, keyword },
  });

  const history = useHistory();

  const onPageChanged = (currentPage: number) => {

    history.push(`/search/${tabsInfo.archiveEvent.url}?k=${keyword}&p=${currentPage}&s=${size}`);

  };

  const onSizeChanged = (currentSize: number) => {

    history.push(`/search/${tabsInfo.archiveEvent.url}?k=${keyword}&p=1&s=${currentSize}`);

  };

  if (loading) {

    return <LoadingBox />;

  }

  if (error || !data?.searchOldTvEvents) {

    return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  }

  const searchResults = data?.searchOldTvEvents;

  return (
    <div className={` ${style.atSearchEventList} `}>
      {searchResults.content && searchResults.content.length > 0 && (
        <AtSearchEventListVertical
          refetch={refetch}
          page={page}
          size={size}
          tvEventPages={searchResults}
          onPageChanged={onPageChanged}
          onSizeChanged={onSizeChanged}
        />
      )}
      {(!searchResults.content || searchResults.content.length === 0) && <AtSearchNoResults />}
    </div>
  );

};
