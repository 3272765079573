import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { AtPagingRouteParams, AtTabName } from '../../App';
import { TvWatchLaterStatus } from '../../generated/graphql';
import { useTrackEvent } from '../../hooks/tracking/useTrackEvent';
import { getMoment } from '../../util/dateUtil';
import { getWatchStatus } from '../../util/urlUtil';
import AtPagination from '../atoms/AtPagination';
import paginationStyle from '../atoms/AtPagination.module.scss';
import { LoadingBox } from '../atoms/LoadingBox';
import TitleAndMeta from '../atoms/TitleAndMeta';
import AtMovieListHorizontal from '../molecules/AtMovieListHorizontal';
import AtMovieTab from '../molecules/AtMovieTab';
import './AtMypageWatchlist.scss';

const tvWatchLaterQuery = gql`
  query tvWatchLaters($tvWatchLaterStatus: TvWatchLaterStatus, $page: Int!, $size: Int!) {
    tvWatchLaters(tvWatchLaterStatus: $tvWatchLaterStatus, page: $page, size: $size) {
      content {
        tvWatchLaterId
        tvWebinarId
        content {
          tvWebinarId
          companyContractId
          tvWebinarPublishStartDatetime
          tvWebinarPublishEndDatetime
          tvWebinarTitle
          tvWebinarSubtitle
          tvWebinarDescription
          tvWebinarSpeakerName
          tvWebinarSpeakerDepartment
          tvWebinarMainImgFilePath
          tvWebinarSpeakerImgFilePath
          tvWebinarMoviePlayTime
          tvWebinarOndemandFlag
          tvWebinarStreamingStartDatetime
          tvWebinarStreamingEndDatetime
          tvEventPages {
            tvEventId
            tvEventTitle
            tvEventSubtitle
            tvEventDescription
            tvEventStartDatetime
            tvEventEndDatetime
            tvEventPublishFlag
            tvEventPublishStartDatetime
            tvEventPublishEndDatetime
            tvEventType
          }
        }
      }
      page
      size
      totalElements
      totalPages
    }
  }
`;

const getTitle = (atTabName: AtTabName | undefined, atPageNo: string | undefined) => {

  const pageTitlePrefix = atPageNo && atPageNo !== '1' ? `${atPageNo}ページ目:` : '';

  switch (atTabName) {
    case 'upcoming':
      return `${pageTitlePrefix}マイページ 配信予定 | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`;
    case 'live':
      return `${pageTitlePrefix}マイページ 配信中 | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`;
    case 'ondemand':
      return `${pageTitlePrefix}マイページ オンデマンド | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`;
    default:
      return `${pageTitlePrefix}マイページ 視聴リスト | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`;
  }

};

const getDescription = (atTabName: AtTabName | undefined) => {

  switch (atTabName) {
    case 'upcoming':
    case 'live':
    case 'ondemand':
    default:
      return 'Apérza TV（アペルザTV、アペルザテレビ）は、ものづくり産業向けに特化した動画サイトです。最新の製品や技術、トレンドやノウハウなど、ものづくりや研究開発に携わる技術者の役に立つ動画コンテンツをいつでも無料で視聴できます。';
  }

};

interface ViewMypageTrackEventProps {
  filterType?: AtTabName;
  pageNo?: number;
  pageSize?: number;
  totalCount?: number;
}

const ViewMypageTrackEvent = ({
  filterType, pageNo, pageSize, totalCount,
}: ViewMypageTrackEventProps) => {

  const { trackEvent } = useTrackEvent();

  useEffect(() => {

    if (totalCount === 0) return;

    const defaultPropeties = {
      page_no:     pageNo || undefined,
      page_size:   pageSize || undefined,
      total_count: totalCount || undefined,
    };

    switch (filterType) {
      case 'upcoming':
      case 'ondemand':
        trackEvent('View Mypage Watchlist', {
          filter_type: filterType,
          ...defaultPropeties,
        });

        break;

      case undefined:
        trackEvent('View Mypage Watchlist', {
          filter_type: 'all',
          ...defaultPropeties,
        });

        break;

      default:
        break;
    }

  }, [filterType, pageNo, pageSize, totalCount, trackEvent]);

  return null;

};

export const AtMypageWatchlist = () => {

  const [webinars, setWebinars] = useState<any[]>([]);
  const { pageNo, tabName } = useParams<AtPagingRouteParams>();
  const currentPage = pageNo ? parseInt(pageNo, 10) : 1;
  const currentTab = getWatchStatus<TvWatchLaterStatus>(tabName) || TvWatchLaterStatus.All;
  const displayPageSize = 10;
  const isTabNameInvalid = tabName !== undefined && currentTab === null;
  const { loading, error, data } = useQuery(tvWatchLaterQuery, {
    variables: {
      tvWatchLaterStatus: currentTab,
      page:               currentPage,
      size:               displayPageSize,
    },
    fetchPolicy: 'cache-and-network',
    skip:        isTabNameInvalid,
    onCompleted: () => {

      const _webinars = [...data.tvWatchLaters.content];
      if (TvWatchLaterStatus.BeforeStreaming === currentTab || TvWatchLaterStatus.Ondemand === currentTab) {

        _webinars.sort((a: any, b: any) =>
          (getMoment(a.content.tvWebinarStreamingStartDatetime).isBefore(
            getMoment(b.content.tvWebinarStreamingStartDatetime),
          )
            ? -1
            : 1));

      }
      setWebinars(_webinars);

    },
  });

  if (loading) return <LoadingBox />;

  if (error) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  if (isTabNameInvalid) {

    return <Redirect to={{ pathname: '/404', state: { invalidURL: window.location.pathname } }} />;

  }

  const trimWebinars = (webinarId: number) => {

    const tmpWebinars = webinars.filter((webinar) => webinar.content.tvWebinarId !== webinarId);
    setWebinars([...tmpWebinars]);

  };

  const atMovieList = webinars.map((webinar: any) => (
    <AtMovieListHorizontal
      key={webinar.tvWatchLaterId}
      watchLaterId={webinar.tvWatchLaterId}
      webinarId={webinar.content.tvWebinarId}
      thumbnailImage={webinar.content.tvWebinarMainImgFilePath}
      companyContractId={webinar.content.companyContractId}
      ondemandFlag={webinar.content.tvWebinarOndemandFlag}
      streamingStartDatetime={webinar.content.tvWebinarStreamingStartDatetime}
      streamingEndDatetime={webinar.content.tvWebinarStreamingEndDatetime}
      moviePlayTime={webinar.content.tvWebinarMoviePlayTime}
      title={webinar.content.tvWebinarTitle}
      subtitle={webinar.content.tvWebinarSubtitle}
      speakerName={webinar.content.tvWebinarSpeakerName}
      speakerImage={webinar.content.tvWebinarSpeakerImgFilePath}
      speakerPost={webinar.content.tvWebinarSpeakerDepartment}
      optionalFuncWhenCanceling={trimWebinars}
      tvEventPage={webinar.content.tvEventPages.length ? webinar.content.tvEventPages[0] : undefined}
    />
  ));

  return (
    <>
      <TitleAndMeta title={getTitle(tabName, pageNo)} description={getDescription(tabName)} noIndex />
      <div className="at-mypage-watchlist">
        <AtMovieTab watchTabPrefix="mypage/watchlist" selectedTab={currentTab} />
        {data.tvWatchLaters.totalElements > 0 && (
          <p className={`${paginationStyle.atPaginationHeader}`}>
            {data.tvWatchLaters.totalElements}件中{data.tvWatchLaters.content.length}件を表示
          </p>
        )}
        <div className="movielist-area">
          {(atMovieList.length === 0 && (
            <div className="at-movie-list-horizontal">
              <p>条件に一致する視聴リストへの登録がありません</p>
            </div>
          )) ||
            (atMovieList.length >= 1 && (
              <>
                {atMovieList}
                <ViewMypageTrackEvent
                  filterType={tabName}
                  pageNo={currentPage}
                  pageSize={displayPageSize}
                  totalCount={data.tvWatchLaters.totalElements}
                />
              </>
            ))}
        </div>
        {webinars.length > 0 && (
          <AtPagination currentPage={currentPage} totalPages={data.tvWatchLaters.totalPages} />
        )}
      </div>
    </>
  );

};

export default AtMypageWatchlist;
