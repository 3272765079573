import React from 'react';
import { render } from 'react-dom';
import { init as amplitudeInit } from '@amplitude/analytics-browser';
import App from './App';
import { intializeAnalytics } from './util/analyticsUtil';
import { getAmplitudeApiKey } from './util/envUtil';
// eslint-disable-next-line
import { add__UBID } from './util/rd-ubid';

amplitudeInit(getAmplitudeApiKey(), undefined, {
  minIdLength: 1,
});

const element: HTMLElement | null = document.getElementById('aperza-app-tv');

add__UBID();
render(
  <React.StrictMode>
    <App element={element} />
  </React.StrictMode>,

  document.getElementById('aperza-app-tv'),
);
intializeAnalytics();
