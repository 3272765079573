import {
  SyntheticEvent, useCallback, useRef, VFC,
} from 'react';
import style from './AtRecommendedWebinarAreaWebinar.module.scss';
import { AtCard } from './AtCard';
import { AtLabel } from '../atoms/AtLabel';
import { RelatedWebinar } from '../../generated/graphql';
import { AtPlayIcon } from '../atoms/AtPlayIcon';
import useClickRecommendedWebinarTrackEvent from '../../hooks/tracking/useClickRecommendedWebinarTrackEvent';

export interface RecommendedWebinarProps {
  webinar: RelatedWebinar;
  lazy?: boolean;
}

export const AtRecommendedWebinarAreaWebinar: VFC<RecommendedWebinarProps> = (props) => {

  const { webinar, lazy } = props;

  const menuLinkRef = useRef<HTMLAnchorElement>(null);
  const { clickRecommendedWebinarTrackEvent } = useClickRecommendedWebinarTrackEvent();

  const handleClick = (e: SyntheticEvent) => {

    e.stopPropagation();

    clickRecommendedWebinarTrackEvent(window.location, webinar);

  };

  return (
    <AtCard className={style.card} onClick={() => menuLinkRef.current?.click()}>
      <div className={style.container}>
        <div className={style.thumbnailArea}>
          <img loading={lazy ? 'lazy' : undefined} src={webinar?.tvWebinarMainImgFilePath} className={` ${style.img} `} alt="movieThumbnail" />
          <AtLabel className={style.label} labelKind={webinar?.tvWebinarOndemandFlag} />
          <AtPlayIcon />
        </div>
        <a href={`/watch/${webinar?.id}`} ref={menuLinkRef} onClick={handleClick} target="_blank">
          <div className={style.title}>{webinar?.tvWebinarTitle}</div>
          <div className={style.subtitle}>{webinar?.tvWebinarSubtitle}</div>
        </a>
      </div>
    </AtCard>
  );

};
