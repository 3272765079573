import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import './AtEventIndexFrame.scss';
import { Carousel } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { LoadingBox } from '../atoms/LoadingBox';
import AtEventIndexSliderItem from './AtEventIndexSliderItem';

const mainVisualQuery = gql`
  query {
    tvTopMainVisuals(page: 1, size: 1) {
      content {
        tvTopMainVisualId
        tvTopMainVisualTvEvents {
          tvEventId
          tvEventTitle
          tvEventSubtitle
          tvEventDescription
          tvEventMainImgFilePath
          tvEventOgpImgFilePath
          tvEventStartDatetime
          tvEventEndDatetime
          tvEventPublishFlag
          tvEventPublishStartDatetime
          tvEventPublishEndDatetime
          tvEventType
          eventApplyFlag
          relTvEventCompanyContracts {
            tvEventId
            companyContractId
            companyContract {
              companyContractId
              companyLang {
                companyLangId
                companyName
                companyLogoUrl
                companyNameFull
              }
            }
            displayOrder
          }
          tvExhibitionReports {
            id
            companyContractId
            companyNameFull
            loggedInUserWatchStatus {
              tvWebinarId
              tvWatchLaterId
            }
            tvWebinarPublishFlag
            tvWebinarPublishStartDatetime
            tvWebinarPublishEndDatetime
            tvWebinarTitle
            tvWebinarSubtitle
            tvWebinarStreamingStartDatetime
            tvWebinarStreamingEndDatetime
            tvWebinarMoviePlayTime
            tvWebinarMainImgFilePath
            tvWebinarOndemandFlag
            tvWebinarSpeakerName
            tvWebinarSpeakerImgFilePath
            tvWebinarSpeakerDepartment
            createTimestamp
            updateTimestamp
          }
        }
        tvTopMainVisualPublishStartDatetime
        tvTopMainVisualPublishEndDatetime
      }
      page
      size
      totalElements
      totalPages
    }
  }
`;

export const AtEventIndexSlider = () => {

  const [tabIndex, setTabIndex] = useState(0);
  const {
    loading, error, data, refetch,
  } = useQuery(mainVisualQuery, {});

  if (loading) return <LoadingBox />;
  if (error) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  const eventList: any[] = data.tvTopMainVisuals?.content[0]?.tvTopMainVisualTvEvents;
  if (eventList && eventList.length > 0) {

    return (
      <div className="topics-frame">
        <Carousel
          activeIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
          controls={false}
          indicators={false}
        >
          {eventList.map((event) => (
            <Carousel.Item key={`slideritem-${event.tvEventId}`}>
              <AtEventIndexSliderItem refetch={refetch} key={`slider-${event.tvEventId}`} event={event} displayButtonFlag />
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="topic-frame-pages d-flex align-items-center justify-content-center">
          {eventList.map((event, index) => (
            <div
              key={`sliderpage-${event.tvEventId}`}
              className={`topic-frame-item ${index === tabIndex ? 'active' : ''}`}
              onClick={() => setTabIndex(index)}
            />
          ))}
        </div>
      </div>
    );

  }
  // Return empty when tvTopMainVisuals are no setting
  return <></>;

};
