import ReactTooltip from 'react-tooltip';
import '../atoms/AtButton.scss';
import '../atoms/AtLabel.scss';
import '../atoms/AtLogo.scss';
import { useIsMobile } from '../templates/MobileReady';
import './AtDetail.scss';
import style from './AtDetailRecommendText.module.scss';

interface AtDetailRecommendTextProps {
  recommendTexts: Array<string | undefined>;
}

export const AtDetailRecommendText = ({ recommendTexts }: AtDetailRecommendTextProps) => {

  const isMobile = useIsMobile();

  const recommendValue = () => (
    <ul className={style.recommendList}>
      {recommendTexts.map((recommendText, index) => {

        if (recommendText) {

          return (
            <li key={`${recommendText}-${index}`} className={style.recommendText}>
              {recommendText}
            </li>
          );

        }

        return undefined;

      })}
    </ul>
  );

  const DesktopVersion = () => (
    <div className="at-recommend-text" data-tip data-for="recommend-data-tip">
      <i className="far fa-comment-check" />
      <div className="tool-tip-text">こんな方にオススメ</div>
      <i className="far fa-chevron-down" />
      <ReactTooltip
        className="custom-tool-tip"
        id="recommend-data-tip"
        type="light"
        border
        place="bottom"
        effect="solid"
        multiline
        offset={{ right: 66 }}
      >
        {recommendValue()}
      </ReactTooltip>
    </div>
  );

  const MobileVersion = () => {

    if (recommendTexts.length === 0) {

      return null;

    }

    return (
      <div>
        <div className={style.recommendTextTitle}>
          <i className={`far fa-comment-check ${style.commentIcon}`} />
          <div className={style.recommendationText}>こんな方にオススメ</div>
        </div>

        <div className={style.recommendTextDetails}>{recommendValue()}</div>
      </div>
    );

  };

  return isMobile ? <MobileVersion /> : <DesktopVersion />;

};
