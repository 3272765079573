import { TvWebinarType, WebinarType } from '../constant/tvConstant';

const isChatShow = (webinarType: WebinarType) =>
  webinarType === TvWebinarType.OnTime || webinarType === TvWebinarType.WaitTime;

const isVideoShow = (webinarType: WebinarType) =>
  webinarType === TvWebinarType.StandBy ||
  webinarType === TvWebinarType.OnTime ||
  webinarType === TvWebinarType.Ondemand;

const isDocumentAreaShow = (webinarType: WebinarType) =>
  webinarType === TvWebinarType.OnTime ||
  webinarType === TvWebinarType.WaitTime ||
  webinarType === TvWebinarType.Ondemand;

export { isChatShow, isVideoShow, isDocumentAreaShow };
