import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AperzaTVTreasure } from './treasureExtension';
import { useAuth } from '../contexts/AuthContext';

export const AtPageViewTracking = (): null => {

  const history = useHistory();
  const { currentUser } = useAuth();
  const excludedPathPatterns = [/\/watch\/\d/, /\/company\/\d/];

  const trackPageview = () => {

    // These paths have moved the tracking process within their components.
    if (excludedPathPatterns.some((path) => path.test(window.location.href))) {

      return;

    }

    AperzaTVTreasure.pageView(window.location.href, currentUser?.userId);

  };

  useEffect(() => {

    trackPageview();
    const unlisten = history.listen(trackPageview); // Track all subsequent pageviews

    return () => unlisten();

  }, [history]);

  return null;

};
