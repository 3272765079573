import { useEffect, useState, VFC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import {
  FormItemAnswerRequired,
  FormItemType,
  TvWebinarDocumentType,
  useGetTvWebinarQuestionnaireQuery,
} from '../../generated/graphql';
import { getTvDomain } from '../../util/envUtil';
import { removeEndSlash } from '../../util/pathUtil';
import { FormSectionType } from '../atoms/AtFormAreaOneLine';
import { LoadingBox } from '../atoms/LoadingBox';
import TitleAndMeta from '../atoms/TitleAndMeta';
import { FormSection } from '../molecules/AtForm';
import { AtBizCardConfirmContainer } from '../organisms/AtBizCardConfirmContainer';
import { AtBizCardFormContainer } from '../organisms/AtBizCardFormContainer';
import { AtBizCardPreviewContainer } from '../organisms/AtBizCardPreviewContainer';
import style from './AtBizCard.module.scss';

export interface AtBizcardProps {
  tvWebinarId: number;
  documentType: string;
}

const typeMapReverse = {
  MULTI_SELECT:           'checkbox',
  SINGLE_SELECT:          'radio',
  DROPDOWN_SINGLE_SELECT: 'select',
  COMMENTBOX:             'textarea',
  PREDEFINED_ITEM:        'text',
  SINGLE_TEXTBOX:         'text',
};

const typeMapDocumentTypeReverse = {
  bizcard:    TvWebinarDocumentType.AperzaTvBusinesscard,
  projection: TvWebinarDocumentType.AperzaTvProjectionDocument,
};

type Screen = 'input' | 'confirm';

export const AtBizCard: VFC<AtBizcardProps> = (props) => {

  const [formSections, setFormSections] = useState<FormSection[]>([]);
  const [screen, setScreen] = useState<Screen>('input');
  const { data, loading, error } = useGetTvWebinarQuestionnaireQuery({
    variables: {
      tvWebinarId:  props.tvWebinarId,
      documentType: typeMapDocumentTypeReverse[props.documentType as keyof typeof typeMapDocumentTypeReverse],
    },
  });

  const methods = useForm();
  const { setValue } = methods;

  useEffect(() => {

    if (!loading && data && data.tvWebinarQuestionnaire) {

      const insertFormSections: FormSection[] = data.tvWebinarQuestionnaire.form
        ? data.tvWebinarQuestionnaire.form.formItems!.map((formItem) => ({
          id:              `formItems.${formItem.formItemId}`,
          name:            formItem.formItemTitle,
          // eslint-disable-next-line max-len
          type:            typeMapReverse[formItem.formItemType as keyof typeof typeMapReverse] as FormSectionType,
          required:        formItem.formItemAnswerRequired === FormItemAnswerRequired.Required,
          registerOptions: {
            required: {
              value:   formItem.formItemAnswerRequired === FormItemAnswerRequired.Required,
              message: `「${formItem.formItemTitle}」を選択してください`,
            },
          },
          placeholder: formItem.formItemPlaceHolder,
          options:     formItem.formItemAnswers
            ? formItem.formItemAnswers.map((formItemAnswer) => ({
              code: formItemAnswer.formItemAnswerId,
              name: formItemAnswer.formItemAnswerTitle,
            }))
            : [],
          withOptionOtherComment:     formItem.withOptionOtherComment,
          requiredOptionOtherComment: formItem.requiredOptionOtherComment,
        }))
        : [];

      const onBusinessTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

        if (event.target.value) {

          const jobCategoryTypes = data.businessTypes
            .filter((businessType) => businessType.code === event.target.value)
            .map((businessType) => businessType.jobCategories);

          if (jobCategoryTypes) {

            const index = insertFormSections.findIndex((formSection) => formSection.id === 'jobCategoryType');

            if (index >= 0) {

              const changeSelectionForm = insertFormSections[index];
              // eslint-disable-next-line prefer-destructuring
              changeSelectionForm.options = jobCategoryTypes[0];
              changeSelectionForm.disabled = false;
              insertFormSections.splice(index, 1, changeSelectionForm);
              setFormSections([...insertFormSections]);
              if (setValue) {

                setValue('jobCategoryType', undefined);

              }

            }

          }

        }

      };

      const jobCategoryTypes = data.businessTypes
        .filter((businessType) => businessType.code === data.tvWebinarQuestionnaire!.businessType)
        .map((businessType) => businessType.jobCategories);

      insertFormSections.push(
        {
          id:                         'name',
          name:                       'お名前',
          type:                       'name',
          required:                   true,
          withOptionOtherComment:     false,
          requiredOptionOtherComment: false,
        },
        {
          id:              'tel',
          name:            '電話番号',
          type:            'text',
          required:        true,
          registerOptions: {
            required: {
              value:   true,
              message: '「電話番号」をご入力ください',
            },
            pattern: {
              value:   /^0\d{1,4}-\d{1,4}-\d{3,4}$/,
              message: '「電話番号」の形式が違います',
            },
            maxLength: {
              value:   20,
              message: '「電話番号」は20文字以内でご入力ください',
            },
          },
          placeholder:                '000-1234-5678',
          withOptionOtherComment:     false,
          requiredOptionOtherComment: false,
        },
        {
          id:              'companyName',
          name:            '会社名',
          type:            'text',
          required:        true,
          registerOptions: {
            required: {
              value:   true,
              message: '「会社名」をご入力ください',
            },
            maxLength: {
              value:   255,
              message: '「会社名」は255文字以内でご入力ください',
            },
          },
          placeholder:                'ご所属の会社名',
          withOptionOtherComment:     false,
          requiredOptionOtherComment: false,
        },
        {
          id:              'officeName',
          name:            '事業所名',
          type:            'text',
          required:        false,
          registerOptions: {
            maxLength: {
              value:   255,
              message: '「事業所名」は255文字以内でご入力ください',
            },
          },
          placeholder:                'ご所属の事業所名',
          withOptionOtherComment:     false,
          requiredOptionOtherComment: false,
        },
        {
          id:              'departmentName',
          name:            '部署名',
          type:            'text',
          required:        true,
          registerOptions: {
            required: {
              value:   true,
              message: '「部署名」をご入力ください',
            },
            maxLength: {
              value:   255,
              message: '「部署名」は255文字以内でご入力ください',
            },
          },
          placeholder:                'ご所属の部署名',
          withOptionOtherComment:     false,
          requiredOptionOtherComment: false,
        },
        {
          id:                         'businessType',
          name:                       '業態',
          type:                       'select',
          required:                   true,
          registerOptions:            { required: { value: true, message: '「業態」を選択してください' } },
          options:                    data.businessTypes,
          onSelectChange:             onBusinessTypeChange,
          withOptionOtherComment:     false,
          requiredOptionOtherComment: false,
        },
        {
          id:                         'jobCategoryType',
          name:                       '職務区分',
          type:                       'select',
          required:                   true,
          registerOptions:            { required: { value: true, message: '「職務区分」を選択してください' } },
          options:                    jobCategoryTypes[0],
          withOptionOtherComment:     false,
          requiredOptionOtherComment: false,
          disabled:                   data.tvWebinarQuestionnaire.businessType == null,
          disabledComment:            '業態を選択してください',
        },
        {
          id:              'postClass',
          name:            '役職クラス',
          type:            'select',
          required:        true,
          registerOptions: {
            required: { value: true, message: '「役職クラス」を選択してください' },
          },
          options:                    data.postClasses,
          withOptionOtherComment:     false,
          requiredOptionOtherComment: false,
        },
        {
          id:              'postName',
          name:            '役職名',
          type:            'text',
          required:        false,
          registerOptions: {
            maxLength: {
              value:   255,
              message: '「役職名」は255文字以内でご入力ください',
            },
          },
          placeholder:                'ご自身の役職名',
          withOptionOtherComment:     false,
          requiredOptionOtherComment: false,
        },
        {
          id:                         'subdivisionCode',
          name:                       '所在地',
          type:                       'select',
          required:                   true,
          registerOptions:            { required: { value: true, message: '「所在地」を選択してください' } },
          options:                    data.prefectures,
          withOptionOtherComment:     false,
          requiredOptionOtherComment: false,
        },
      );
      setFormSections(insertFormSections);
      if (data.tvWebinarQuestionnaire.form) {

        data.tvWebinarQuestionnaire.form.formItems!.forEach((formItem) => {

          if (formItem.formItemType === FormItemType.MultiSelect) {

            methods.setValue(`formItems.${formItem.formItemId}`, []);

          } else {

            methods.setValue(`formItems.${formItem.formItemId}`, '');

          }

        });

      }

      methods.setValue('name.lastName', data.tvWebinarQuestionnaire.lastName);
      methods.setValue('name.firstName', data.tvWebinarQuestionnaire.firstName);
      methods.setValue('companyName', data.tvWebinarQuestionnaire.companyName);
      methods.setValue('officeName', data.tvWebinarQuestionnaire.officeName);
      methods.setValue('departmentName', data.tvWebinarQuestionnaire.departmentName);
      methods.setValue('businessType', data.tvWebinarQuestionnaire.businessType);
      methods.setValue('jobCategoryType', data.tvWebinarQuestionnaire.jobCategoryType);
      methods.setValue('tel', data.tvWebinarQuestionnaire.tel);
      methods.setValue('postClass', data.tvWebinarQuestionnaire.postClass);
      methods.setValue('postName', data.tvWebinarQuestionnaire.postName);
      methods.setValue('subdivisionCode', data.tvWebinarQuestionnaire.subdivisionCode);

    }

  }, [data]);

  if (loading) {

    return <LoadingBox />;

  }
  if (error) {

    return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  }

  return (
    <section>
      <TitleAndMeta canonicalHref={getTvDomain().slice(0, -1) + removeEndSlash(window.location.pathname)} />
      <div className={`container-fluid p-0 ${style.pageContainer}`}>
        <div className="row justify-content-center no-gutters">
          <div className="col-12 col-xl-8">
            <FormProvider {...{ ...methods, setScreen }}>
              {screen === 'input' ? (
                <>
                  <AtBizCardPreviewContainer
                    webinarTitle={data?.tvWebinarQuestionnaire?.tvWebinarTitle!}
                    catalogName={data?.tvWebinarQuestionnaire?.questionnaireTitle!}
                    catalogSubName={data?.tvWebinarQuestionnaire?.questionnaireSubTitle!}
                    catalogImageUrl={data?.tvWebinarQuestionnaire?.questionnaireImageUrl!}
                    documentType={data?.tvWebinarQuestionnaire?.documentType!}
                    companyNameFull={
                      data?.tvWebinarQuestionnaire?.companyContract?.companyLang?.companyNameFull!
                    }
                  />
                  <AtBizCardFormContainer
                    documentType={data?.tvWebinarQuestionnaire?.documentType!}
                    formSections={formSections}
                  />
                </>
              ) : (
                <AtBizCardConfirmContainer
                  tvWebinarId={data?.tvWebinarQuestionnaire?.tvWebinarId!}
                  documentType={data?.tvWebinarQuestionnaire?.documentType!}
                  formSections={formSections}
                />
              )}
            </FormProvider>
          </div>
        </div>
      </div>
    </section>
  );

};
