import { Dispatch, FC, SetStateAction } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { UserType } from '../../../generated/graphql';
import style from './ConfirmConsent.module.scss';

type ConfirmConsentProps = {
  isChecked?: boolean;
  onChecked?: Dispatch<SetStateAction<boolean>>;
};

export const ConfirmConsent: FC<ConfirmConsentProps> = ({ isChecked = false, onChecked }) => {

  const { currentUser } = useAuth();

  return (
    <div className={style.container}>
      <h3 className={style.title}>アペルザTVにおける個人情報の取扱について</h3>
      <div className={style.content}>
        <p>
          イベント参加時に、アペルザIDにご登録のプロフィール情報及び視聴した動画に関する情報（視聴した動画、視聴時間等）が
          掲載企業（商品・サービスの提供者の他、当該商品・サービスの販売代理店等の商品・サービスの取扱事業者、当該イベントまたは動画の協力・協賛事業者を含みます。）へ提供されます。
          掲載企業は同情報に基づきお客様へ情報提供やお問合せへのフォローを行う場合があります。上記にご同意の上、アペルザTVをご利用ください。
        </p>
        {currentUser.userType !== UserType.Member && (
          <>
            <p>
              また、アペルザIDの登録には
              <a href="https://www.aperza.com/corp/term/ja/index.html" target="_blank">
                サービス利用規約
              </a>
              及び
              <a href="https://www.aperza.com/corp/term/ja/privacy.html" target="_blank">
                プライバシーポリシー
              </a>
              への同意が必要となります。
            </p>
            <p>加えてアペルザからのメールによるお知らせを受け取ることに同意する必要があります。</p>
          </>
        )}
      </div>
      <div className={style.checkboxContainer}>
        <input
          className={style.checkbox}
          id="confirm"
          type="checkbox"
          checked={isChecked}
          onChange={(e) => {

            onChecked && onChecked(e.target.checked);

          }}
        />
        <label className={style.label} htmlFor="confirm">
          同意する
        </label>
        <div className="at-label-small-required">必須</div>
      </div>
    </div>
  );

};
