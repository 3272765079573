import { VFC } from 'react';
import style from './AtEventLpOutline.module.scss';
import { AtEventLpEvent } from '../atoms/AtEventLpEvent';
import { TvEventOverview } from '../../generated/graphql';

export interface AtEventLpOutlineProps {
  eventTitle?: string;
  description?: string;
  eventList?: TvEventOverview[];
  sectionTitle: string;
  backgroundColor: string;
}

export const AtEventLpOutline: VFC<AtEventLpOutlineProps> = (props) => {

  const {
    eventTitle, description, eventList, sectionTitle, backgroundColor,
  } = props;

  return (
    <div
      style={{
        background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 50%), ${backgroundColor}`,
      }}
      id={sectionTitle}
      className={style.container}
    >
      <div className={` ${style.title} `}>{eventTitle}</div>
      <div className={` ${style.description} `}>{description}</div>
      {eventList && eventList.length > 0 && (
        <div className={` ${style.eventContainer} `}>
          {eventList
            ?.slice()
            ?.sort((a, b) => a.displayOrder - b.displayOrder)
            .map((event, index) => (
              <AtEventLpEvent event={event} key={index} />
            ))}
        </div>
      )}
    </div>
  );

};
