import { VFC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { AtHeaderAndFooter } from '../templates/AtHeaderAndFooter';
import { AtButtonNormalBack } from '../atoms/AtButton';
import style from './At404Error.module.scss';
import { AtTechBackground } from '../atoms/AtTechBackground';

export const At404Error: VFC = () => {

  const location = useLocation<{ invalidURL?: string }>();

  if (location.state?.invalidURL) {

    window.history.replaceState({}, '', location.state.invalidURL);

  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <AtHeaderAndFooter>
        <div className={`${style.pageContainer}`}>
          <AtTechBackground contents="404" />
          <p className={` ${style.message} text-center`}>
            ご指定のURLでページが見つかりませんでした。
            <br />
            トップページに戻って操作するか、再度アクセスしてください。
          </p>
          <AtButtonNormalBack message={'トップページに戻る'} href={'/'} />
        </div>
      </AtHeaderAndFooter>
    </>
  );

};
