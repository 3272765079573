import { useParams } from 'react-router-dom';
import { AtPagingRouteParams } from '../../App';
import TitleAndMeta from '../atoms/TitleAndMeta';
import { AtUpcomingFrame } from '../organisms/AtUpcomingFrame';
import './AtUpcoming.scss';

export const AtUpcoming = () => {

  const { pageNo } = useParams<AtPagingRouteParams>();
  const getTitle = () => {

    const pageTitlePrefix = pageNo && pageNo !== '1' ? `${pageNo}ページ目:` : '';
    return `${pageTitlePrefix}配信予定一覧 | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`;

  };

  const getDescription = () =>
    'Apérza TV（アペルザTV、アペルザテレビ）では、ものづくりの最新トレンドや基礎知識、最新事例などを対談形式の動画で分かりやすくお届けしています。直近で開催予定のイベントは本ページよりご確認ください。';

  return (
    <div className="at-upcoming">
      <TitleAndMeta title={getTitle()} description={getDescription()} />
      <AtUpcomingFrame />
    </div>
  );

};

export default AtUpcoming;
