import { VFC } from 'react';
import style from './AtModalHeader.module.scss';

export interface AtModalHeaderProps {
  title?: string;
  closeEventModal: () => void;
}

export const AtModalHeader: VFC<AtModalHeaderProps> = (props) => {

  const { title, closeEventModal } = props;

  return (
    <div className={`${style.container}`}>
      <p className={`${style.title}`}>{title}</p>
      <i className={` ${style.closeButton} fas fa-times`} onClick={closeEventModal} />
    </div>
  );

};
