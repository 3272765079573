import { useCallback } from 'react';
import {
  NotificationSettingFlag,
  NotificationSettingType,
  TvNotificationSetting,
} from '../../generated/graphql';
import { TvNotificationSettingsUserProperties } from '../../util/amplitude/userProperty';
import { useTrackEvent } from './useTrackEvent';

const useTvNotificationSettingsTrackEvent = () => {

  const { trackEvent, setUserProperties } = useTrackEvent();

  const toUserProperty = useCallback(
    (settings: TvNotificationSetting[]): TvNotificationSettingsUserProperties => {

      const isNotice = (flag: NotificationSettingFlag) => flag === NotificationSettingFlag.Notice;
      const values: TvNotificationSettingsUserProperties = { tv_notification_settings: {} };

      settings.forEach((v) => {

        switch (v.notificationSettingType) {
          case NotificationSettingType.RemindNotice:
            values.tv_notification_settings.remind_notice = isNotice(v.notificationSettingFlag);
            break;

          case NotificationSettingType.MissWatchNotice:
            values.tv_notification_settings.miss_watch_notice = isNotice(v.notificationSettingFlag);
            break;

          case NotificationSettingType.OndemandNotice:
            values.tv_notification_settings.ondemand_notice = isNotice(v.notificationSettingFlag);
            break;

          default:
            break;
        }

      });

      return values;

    },
    [],
  );

  const currentTvNotificationSettingsTrackEvent = useCallback(
    (settings: TvNotificationSetting[]) => {

      setUserProperties(toUserProperty(settings));
      trackEvent('Current TV Notification Settings');

    },
    [toUserProperty, setUserProperties, trackEvent],
  );

  const updateTvNotificationSettingsTrackEvent = useCallback(
    (settings: TvNotificationSetting[], updateSetting: TvNotificationSetting) => {

      setUserProperties(toUserProperty(settings));

      // refer to: onChangeNotificationSetting
      const isNotification = updateSetting.notificationSettingFlag === NotificationSettingFlag.NotNotice;

      trackEvent('Update TV Notification Settings', {
        notification_name: updateSetting.notificationSettingName,
        notification_type: updateSetting.notificationSettingType,
        is_notification:   isNotification,
      });

    },
    [toUserProperty, setUserProperties, trackEvent],
  );

  return {
    currentTvNotificationSettingsTrackEvent,
    updateTvNotificationSettingsTrackEvent,
  };

};

export default useTvNotificationSettingsTrackEvent;
