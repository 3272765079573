import { VFC } from 'react';
import style from './AtTimetableEvent.module.scss';
import { AtCard } from './AtCard';
import { dividePartDate, nowIsBetweenTimes, timeIsPast } from '../../util/dateUtil';
import { TvEventPageQuery } from '../../generated/graphql';
import { AtTimetableModal } from '../organisms/AtTimetableModal';
import { useTimetableModal } from '../../hooks/useTimetableModal';
import { AtCompanyImage } from '../atoms/AtCompanyImage';

export interface AtTimetableEventProps {
  webinar: N<N<TvEventPageQuery['tvEventPage']>['eventTimetable']>[number]['webinars'][number];
  setShowCTAModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AtTimetableEvent: VFC<AtTimetableEventProps> = (props) => {

  const { webinar, setShowCTAModal } = props;

  const { jstTime: startTime } = dividePartDate(webinar.tvWebinarStreamingStartDatetime);
  const { jstTime: endTime } = dividePartDate(webinar.tvWebinarStreamingEndDatetime);

  const { showTimetableModal, openEventTimetableModal, closeEventTimetableModal } = useTimetableModal(
    webinar.tvWebinarId,
    webinar.tvWebinarStreamingStartDatetime,
  );

  const isExhibitingWebinar = nowIsBetweenTimes(
    webinar.tvWebinarPublishStartDatetime,
    webinar.tvWebinarPublishEndDatetime,
  );

  const isPastWebinar = timeIsPast(webinar.tvWebinarStreamingEndDatetime);

  return (
    <>
      <AtCard onClick={openEventTimetableModal} disabled={!isExhibitingWebinar}>
        <div id={`webinar-${webinar.tvWebinarId}`} className={style.container}>
          {isPastWebinar && webinar.tvWebinarOndemandFlag === 'ONDEMAND' ? (
            <div className={` ${style.dateSection} `}>
              <span className={style.missedDelivery}>見逃し配信中</span>
            </div>
          ) : (
            <div className={` ${style.dateSection} `}>
              {startTime}
              <span className={` ${style.tilde} `}>～</span>
              {endTime}
            </div>
          )}

          <AtCompanyImage
            className={` ${style.companyLogo} `}
            width={138}
            src={webinar.companyContract.companyLang?.companyLogoUrl}
            alt={webinar.companyContract.companyLang?.companyNameFull}
            lazy
          />
          <div className={` ${style.mainSection} `}>
            {/* {(webinar.category1 || webinar.category2) && (
            <div className={` ${style.categorySection} `}>
              <AtLabel labelKind={webinar.category1} />
              <AtLabel labelKind={webinar.category2} />
            </div>
            // category feature not released yet
          )} */}
            <div className={` ${style.title} `}>{webinar.tvWebinarTitle}</div>
            <div className={` ${style.subtitle} `}>{webinar.tvWebinarSubtitle}</div>
            <div className={` ${style.speakerSection} `}>
              <img
                className={` ${style.speakerImg} `}
                src={webinar.tvWebinarSpeakerImgFilePath}
                alt="speaker"
                loading="lazy"
              />
              <div className={` ${style.speakerTextSection} `}>
                <div className={` ${style.speakerCompany} `}>
                  {webinar.companyContract.companyLang?.companyNameFull}
                </div>
                <div className={` ${style.speakerTitle} `}>{webinar.tvWebinarSpeakerDepartment}</div>
                <div className={` ${style.speakerName} `}>
                  {webinar.tvWebinarSpeakerName ? `${webinar.tvWebinarSpeakerName} 氏` : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AtCard>
      {showTimetableModal && (
        <AtTimetableModal
          setShowCTAModal={setShowCTAModal}
          webinar={webinar}
          closeModal={closeEventTimetableModal}
        />
      )}
    </>
  );

};
