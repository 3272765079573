import { useEffect, useState } from 'react';
import { useImageOrientation } from '../../hooks/useImageOrientation';

type CatalogImageProps = {
  className?: string;
  src: string;
  alt?: string;
  defaultWidth: string;
  defaultHeight: string;
  loading?: 'lazy' | 'eager' | undefined;
};

type CatalogImageDisplaySize = {
  width: string | number;
  height: string | number;
};

export const CatalogImage = ({
  className,
  src,
  alt,
  defaultWidth,
  defaultHeight,
  loading,
}: CatalogImageProps) => {

  const { orientation, setCanOrientation } = useImageOrientation(src);

  /**
   * NOTE: https://aperza.atlassian.net/browse/AB-20372
   * どちらも0を指定しているのは、初回読み込み時に縦に長い画像だと表示が崩れてしまい、
   * 縦と横のどちらが長いかを判定できてから画像の表示を行うため。
   */
  const [catalogImageDisplaySize, setCatalogImageDisplaySize] = useState<CatalogImageDisplaySize>({
    width:  0,
    height: 0,
  });

  useEffect(() => {

    if (orientation === 'landscape') {

      setCatalogImageDisplaySize({ width: defaultWidth, height: 'auto' });

    }

    if (orientation === 'portrait') {

      setCatalogImageDisplaySize({ width: 'auto', height: defaultHeight });

    }

  }, [orientation, defaultWidth, defaultHeight]);

  return (
    <img
      className={className}
      src={src}
      alt={alt}
      width={catalogImageDisplaySize.width}
      height={catalogImageDisplaySize.height}
      loading={loading}
      onLoad={() => {

        setCanOrientation(true);

      }}
    />
  );

};
