import React, {
  FC, useState, useEffect, useRef,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import style from './CatalogCluezDownloadModal.module.scss';

const modalGroupIdChecked = '';
let catalogDlCount = 0;
let catalogDlIntervalId: any;

// TODO aperza-app-searchからほぼそのまま持ってきたため、運用が続く場合は全体的にリファクタリングが必要
const catalogDl = (cid: any) => {

  if (catalogDlCount === 1) {

    return;

  }
  if (catalogDlCount === 0) {

    catalogDlCount += 1;

  }
  catalogDlIntervalId = setInterval(() => {

    try {

      const dlIframe = document.querySelector<HTMLIFrameElement>('#dliframe');

      if (dlIframe == null) {

        return;

      }

      dlIframe.style.width = '100%';

      if (dlIframe.contentWindow?.document == null) {

        return;

      }

      if (
        dlIframe.contentWindow.document.querySelector('#userInputModal')?.classList.contains('in') === false
      ) {

        const catalogDlScript = dlIframe.contentWindow.document.querySelector<HTMLDivElement>(
          '#catalog_dl_script',
        );

        catalogDlScript?.setAttribute('onclick', `dl('${cid}')`);

        catalogDlScript?.click();

        const userInputModal = dlIframe.contentWindow.document.querySelector<HTMLDivElement>(
          '#userInputModal',
        );

        const modalLg = userInputModal?.querySelector<HTMLDivElement>('.modal-dialog.modal-lg');

        modalLg!.style.margin = '0';
        modalLg!.style.padding = '0';
        modalLg!.style.width = '100%';

        const modalContent = userInputModal?.querySelector<HTMLDivElement>('.modal-content');

        modalContent!.style.border = 'none';
        modalContent!.style.borderRadius = '0';
        modalContent!.style.boxShadow = 'none';
        modalContent!.style.backgroundColor = 'transparent';

      }

      if (dlIframe.contentWindow !== undefined) {

        const modalHtml = dlIframe.contentWindow.document.querySelector<HTMLDivElement>('.modal-dialog');
        if (modalHtml!.clientHeight.toString() !== dlIframe.style.height.replace(/[^0-9]/g, '')) {

          dlIframe.style.height = `${modalHtml!.clientHeight.toString()}px`;

        }

      }

      if (dlIframe.contentWindow.document.querySelector('.target-1')?.classList.contains('jsc-dn')) {

        document.querySelector('#downloadStart')?.remove();

      }

      if (
        dlIframe.contentWindow.document.querySelector('body')?.classList.contains('jsc-loading') &&
        !document.querySelector('#downloadStart')
      ) {

        const downloadStart = document.createElement('div');

        downloadStart.setAttribute('id', 'downloadStart');

        downloadStart.style.zIndex = '9999';
        downloadStart.style.position = 'absolute';
        downloadStart.style.top = '0';
        downloadStart.style.left = '0';
        downloadStart.style.right = '0';
        downloadStart.style.bottom = '0';
        downloadStart.style.height = `${document.querySelector('html')?.scrollHeight}px`;

        document.querySelector('body')?.appendChild(downloadStart);

      }

    } catch (e) {

      // モーダルを閉じるタイミングでエラーが出る場合がある
      console.log('error on catalogDl()', e);

    }

  }, 400);

};

export interface CatalogCluezDownloadModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  catalogId?: string;
  companyId?: number;
  requestId?: string;
}

export const CatalogCluezDownloadModal: FC<CatalogCluezDownloadModalProps> = (props) => {

  const {
    showModal, setShowModal, catalogId, companyId, requestId,
  } = props;

  const catalogDlMain = async (): Promise<string | undefined> => {

    let domain = ''; // 環境設定
    if (document.domain.indexOf('test') > -1 || document.domain.indexOf('local') > -1) {

      domain = 'www.test.aperza.com';

    } else if (document.domain.indexOf('stg') > -1) {

      domain = 'www.stg.aperza.com';

    } else {

      domain = 'www.aperza.com';

    }

    try {

      const catalogModalContent = await fetch(`https://${domain}/download/catalog-dl`, {
        method:      'POST',
        body:        JSON.stringify({ modalDlGroupNameSet: modalGroupIdChecked }),
        credentials: 'include',
      });

      const responseBody = catalogModalContent.body;

      const reader = responseBody?.getReader();
      const readableStream = new ReadableStream({
        start(controller) {

          const push = () => {

            reader?.read().then(({ done, value }) => {

              if (done) {

                controller.close();
                return;

              }
              controller.enqueue(value);
              push();

            });

          };

          push();

        },
      });

      const result = await new Response(readableStream, {
        headers: { 'Content-Type': 'text/html' },
      }).text();

      return result;

    } catch (e) {

      console.error(e);

      // TODO エラー時の画面表示を決める。
      return undefined;

    }

  };

  useEffect(() => {

    if (showModal) {

      (async () => {

        const catalogModalHTML = await catalogDlMain();

        const iframe: any = document.getElementById('dliframe');
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        iframeDoc.write(catalogModalHTML);
        iframeDoc.close();
        catalogDl(`${companyId}_${catalogId}`);

      })();

    }

  }, [showModal]);

  const onModalClose = () => {

    clearInterval(catalogDlIntervalId);
    setShowModal(false);
    const dlIframe = document.querySelector<HTMLDivElement>('#dliframe');
    dlIframe!.remove();
    catalogDlCount = 0;

  };

  return (
    <Modal
      show={showModal}
      onHide={onModalClose}
      onClick={onModalClose}
      size={'lg'}
      dialogClassName={style.modal}
      id="catalogDownloadModal"
    >
      <Modal.Body className="p-0">
        <div id="modal-catalog-dl">
          <iframe
            id={'dliframe'}
            className={'dliframe'}
            style={{
              width:  '100%',
              height: '0px',
              border: 0,
            }}
            title={'title'}
          />
        </div>
      </Modal.Body>
    </Modal>
  );

};

export default CatalogCluezDownloadModal;
