import { ApolloQueryResult } from '@apollo/client';
import { useRef, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { InView, useInView } from 'react-intersection-observer';
import { Redirect } from 'react-router-dom';
import scrollIntoView from 'scroll-into-view-if-needed';
import { TvEventPageQuery, useTvEventPageQuery } from '../../generated/graphql';
import { dividePartJstDate, timeIsFuture } from '../../util/dateUtil';
import { getTvDomain } from '../../util/envUtil';
import { removeEndSlash } from '../../util/pathUtil';
import type { Breadcrumb } from '../atoms/AtBreadcrumbs';
import { AtBreadcrumbs } from '../atoms/AtBreadcrumbs';
import { AtButtonAddEvent } from '../atoms/AtButton';
import { AtLabel } from '../atoms/AtLabel';
import { AtScheduleTab as AtDateTab } from '../atoms/AtTab';
import { LoadingBox } from '../atoms/LoadingBox';
import TitleAndMeta from '../atoms/TitleAndMeta';
import AtBreadcrumbsJsonLd from '../molecules/AtBreadcrumbsJsonLd';
import { AtCTAModal } from '../organisms/AtCTAModal';
import { AtEventBanner } from '../organisms/AtEventBanner';
import { AtEventCardWide, AtEventCardWideProps } from '../organisms/AtEventCardWide';
import { AtEventScheduleMovies } from '../organisms/AtEventScheduleMovies';
import { AtEventStickyRow } from '../organisms/AtEventStickyRow';
import { useIsMobile } from '../templates/MobileReady';
import './AtFeatureDetail.scss';

interface AtFeatureDetailProps {
  eventId: string;
  data?: TvEventPageQuery;
  refetch: () => Promise<ApolloQueryResult<any>>;
}

interface EventTitleProps extends AtEventCardWideProps {
  setEventTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const EventTitle = ({ eventId, setEventTitle }: EventTitleProps) => {

  const { loading, error, data } = useTvEventPageQuery({
    variables: {
      eventId,
    },
    onCompleted: (_data) => {

      setEventTitle?.(_data?.tvEventPage?.tvEventTitle);

    },
  });

  if (loading) return <LoadingBox />;
  if (error) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  if (!data?.tvEventPage?.tvEventTitle) {

    return <Redirect to={{ pathname: '/404', state: { invalidURL: window.location.pathname } }} />;

  }
  const title = `${
    data?.tvEventPage?.tvEventTitle ?? ''
  } | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`;
  return (
    <TitleAndMeta
      title={title}
      canonicalHref={getTvDomain().slice(0, -1) + removeEndSlash(window.location.pathname)}
    />
  );

};

export const AtFeatureDetail = ({ eventId, data, refetch }: AtFeatureDetailProps) => {

  const [showEventModal, setShowEventModal] = useState(false);
  const [eventTitle, setEventTitle] = useState<string | undefined>('');
  const [inViewTabs, setInViewTabs] = useState<{ [key: number]: boolean }>({ 0: true });
  const [eventType, setEventType] = useState<string | undefined>(undefined);
  const isMobile = useIsMobile();
  const dateRowRef = useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView({
    threshold: 0.19,
  });

  if (!data?.tvEventPage?.tvEventSchedules?.content || !data?.tvEventPage) {

    return <Redirect to={{ pathname: '/404', state: { invalidURL: window.location.pathname } }} />;

  }

  const eventSchedules = data?.tvEventPage?.tvEventSchedules?.content;
  const eventDateTabs: React.ReactNode[] = [];
  const eventScheduleMoviesValue: React.ReactNode[] = [];
  eventSchedules?.forEach((eventSchedule, index) => {

    const scrollHref = `#scroll${index}`;

    if (eventSchedule.tvEventScheduleDate) {

      const { jstMonth: month, jstDay: day, jstWeekDay: weekDay } = dividePartJstDate(
        `${eventSchedule.tvEventScheduleDate}T00:00:00`,
      );
      const tabDate = `${month}/${day}(${weekDay})`;

      eventDateTabs.push(
        <AnchorLink key={eventSchedule.tvEventScheduleId} href={scrollHref} offset={isMobile ? '54' : '170'}>
          <AtDateTab date={tabDate} activeFlag={inViewTabs[index]} />
        </AnchorLink>,
      );

    }

    eventScheduleMoviesValue.push(
      <InView
        onChange={(eventScheduleMoviesInView) => {

          setInViewTabs({ ...inViewTabs, [index]: eventScheduleMoviesInView });

          const myTarget = document.querySelector(`a[href^="${scrollHref}"]`);

          if (eventScheduleMoviesInView && myTarget) {

            scrollIntoView(myTarget, {
              behavior: 'smooth',
              block:    'nearest',
              boundary: dateRowRef.current,
            });

          }

        }}
        rootMargin={'-50% 0px -50% 0px'}
        key={eventSchedule.tvEventScheduleId}
      >
        <div id={`scroll${index}`}>
          <AtEventScheduleMovies eventType={eventType} eventSchedule={eventSchedule} />
        </div>
      </InView>,
    );

  });
  const breadcrumbs: Breadcrumb[] = [
    {
      text: 'トップ',
      url:  '/',
    },
    {
      text: 'イベント・特集一覧',
      url:  '/event',
    },
    {
      text: `${eventTitle}`,
    },
  ];

  if (isMobile) {

    return (
      <>
        <EventTitle eventId={Number(eventId)} setEventTitle={setEventTitle} />
        <AtEventBanner eventId={eventId} setEventType={setEventType} />
        {eventType && eventType !== 'FEATURE' && (
          <AtEventStickyRow
            dateRowRef={dateRowRef}
            eventType={eventType}
            eventDateTabs={eventDateTabs}
            displayButtonFlag={timeIsFuture(data?.tvEventPage?.tvEventEndDatetime)}
            setShowEventModal={setShowEventModal}
          />
        )}
        {showEventModal && (
          <AtCTAModal
            webinarDays={eventSchedules}
            refetch={refetch}
            eventId={String(eventId)}
            title={eventTitle}
            closeModal={() => setShowEventModal(false)}
          />
        )}
        <div className="movieListContainer">{eventScheduleMoviesValue}</div>
      </>
    );

  }

  return (
    <section className="at-feature-detail">
      <EventTitle eventId={Number(eventId)} setEventTitle={setEventTitle} />
      <AtBreadcrumbsJsonLd breadcrumbs={breadcrumbs} baseUrl={getTvDomain().slice(0, -1)} />
      <div className="at-feature-detail-breadcrumbs">
        <AtBreadcrumbs breadcrumbs={breadcrumbs} />
      </div>
      <div className="at-feature-detail-banner">
        <div className="at-feature-detail-main" ref={ref}>
          <AtEventCardWide eventId={Number(eventId)} setEventType={setEventType} />
        </div>
        {!inView && (
          <div className="at-event-info">
            <AtLabel labelKind={String(eventType)} />
            <p className="at-feature-detail-event-title">{eventTitle}</p>
          </div>
        )}
        {eventType && eventType !== 'FEATURE' && (
          <div className="at-link-button-area">
            {eventDateTabs}
            {timeIsFuture(data?.tvEventPage?.tvEventEndDatetime) && (
              <div style={{ marginLeft: 'auto' }}>
                <AtButtonAddEvent openEventModal={() => setShowEventModal(true)} />
              </div>
            )}
          </div>
        )}
      </div>
      {eventScheduleMoviesValue}
      {showEventModal && (
        <AtCTAModal
          eventId={String(eventId)}
          title={eventTitle}
          closeModal={() => setShowEventModal(false)}
          webinarDays={data.tvEventPage.tvEventSchedules.content}
          refetch={refetch}
        />
      )}
    </section>
  );

};
