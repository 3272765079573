import { useQuery } from '@apollo/client';
import './AtMovieListHorizontalSimple.scss';
import { companyContractQuery, CompanyContractProps } from './AtMovieListDetail';
import { AtCompanyImage } from '../atoms/AtCompanyImage';

interface AtMovieListHorizontalSimpleProps {
  rankingNo?: number;
  tvWebinar: any;
}

const CompanyName = (props: any) => {

  const CompanyContract = ({ companyContractId }: CompanyContractProps) => {

    const { loading: loadingC, error: errorC, data: dataC } = useQuery(companyContractQuery, {
      variables: {
        companyContractId,
      },
    });

    if (loadingC) return <p>loading</p>;
    if (errorC) return <p>{errorC.stack}</p>;

    const companyName = dataC.companyContract.companyLang.companyNameFull ?? '';
    const companyImageUrl = dataC.companyContract.companyLang.companyLogoUrl;

    return (
      <a href={`/watch/${props.tvWebinarId}`}>
        <div className="at-movie-list-horizontal-simple">
          {props.no}
          <div className="at-logo-box">
            <AtCompanyImage width={48} src={companyImageUrl} alt={companyName} />
          </div>
          <p className="at-title">
            {props.title}
            <br />
            <span className="at-presenter">
              {companyName}
              <br />
              {props.speakerDepartment}&nbsp;
              {props.speakerDepartment.length > 44 && <br />}
              {props.speakerName ? `${props.speakerName} 氏` : ''}
            </span>
          </p>
          <span className="at-read-more">
            <i className="fas fa-chevron-right fa-fw" />
          </span>
        </div>
      </a>
    );

  };

  return <CompanyContract companyContractId={props.companyContractId} />;

};

export const AtMovieListHorizontalSimple = ({ rankingNo, tvWebinar }: AtMovieListHorizontalSimpleProps) => {

  const companyContractId = tvWebinar.companyContractId as string;
  const title = tvWebinar.tvWebinarTitle;
  const moviePlayTime = tvWebinar.tvWebinarMoviePlayTime;
  const speakerName = tvWebinar.tvWebinarSpeakerName;
  const speakerDepartment = tvWebinar.tvWebinarSpeakerDepartment;

  const no = rankingNo ? <p className="at-no">{rankingNo}</p> : <></>;

  return (
    <CompanyName
      companyContractId={companyContractId}
      title={title}
      moviePlayTime={moviePlayTime}
      speakerName={speakerName}
      speakerDepartment={speakerDepartment}
      no={no}
      tvWebinarId={tvWebinar?.tvWebinarId}
    />
  );

};

export default AtMovieListHorizontalSimple;
