import { Redirect, useHistory } from 'react-router-dom';
import { useSearchAtWebinarTabQuery } from '../../generated/graphql';
import AtMovieAttention from '../atoms/AtMovieAttention';
import AtPagination from '../atoms/AtPagination';
import { AtSearchNoResults } from '../atoms/AtSearchNoResults';
import { LoadingBox } from '../atoms/LoadingBox';
import AtSearchHeader from '../molecules/AtSearchHeader';
import AtMovieListForWebinars from './AtMovieListForWebinars';
import style from './AtSearchWebinarList.module.scss';

interface AtSearchWebinarListProps {
  keyword: string;
  page: number;
  size: number;
}

export const AtSearchWebinarList = ({ keyword, page, size }: AtSearchWebinarListProps) => {

  const { loading, error, data } = useSearchAtWebinarTabQuery({
    variables: { page, size, keyword },
  });

  const history = useHistory();

  const onPageChanged = (currentPage: number) => {

    history.push(`/search/webinar?k=${keyword}&p=${currentPage}&s=${size}`);

  };

  const onSizeChanged = (currentSize: number) => {

    history.push(`/search/webinar?k=${keyword}&p=1&s=${currentSize}`);

  };

  if (loading) {

    return <LoadingBox />;

  }

  if (error) {

    return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  }

  if (!data?.searchTvWebinars) {

    return <Redirect to={{ pathname: '/404', state: { invalidURL: window.location.pathname } }} />;

  }

  const searchResults = data?.searchTvWebinars;
  const webinarValue = searchResults.content?.map(
    (tvWebinar) =>
      tvWebinar && (
        <div key={tvWebinar.tvWebinarId} className={` ${style.atMovielistContent} `}>
          <AtMovieListForWebinars
            webinarId={tvWebinar.tvWebinarId}
            thumbnailImage={tvWebinar.tvWebinarMainImgFilePath}
            companyContract={tvWebinar?.companyContract}
            ondemandFlag={tvWebinar.tvWebinarOndemandFlag}
            streamingStartDatetime={tvWebinar.tvWebinarStreamingStartDatetime?.toString()}
            streamingEndDatetime={tvWebinar.tvWebinarStreamingEndDatetime?.toString()}
            title={tvWebinar.tvWebinarTitle}
            subtitle={tvWebinar.tvWebinarSubtitle}
            speakerName={tvWebinar.tvWebinarSpeakerName}
            speakerImage={tvWebinar.tvWebinarSpeakerImgFilePath}
            speakerPost={tvWebinar.tvWebinarSpeakerDepartment}
            watchLaterId={tvWebinar.loggedInUserWatchStatus?.tvWatchLaterId}
            doesWatchLater={!!tvWebinar.loggedInUserWatchStatus?.tvWatchLaterId}
            recommendTexts={[
              tvWebinar.tvWebinarRecommendSentence1,
              tvWebinar.tvWebinarRecommendSentence2,
              tvWebinar.tvWebinarRecommendSentence3,
            ]}
          />
        </div>
      ),
  );

  return (
    <div className={` ${style.atSearchWebinarList} `}>
      {searchResults.content && searchResults.content.length > 0 && (
        <>
          <AtMovieAttention />
          <div className={` ${style.atMovieResultArea} `}>
            <AtSearchHeader
              totalElements={searchResults.totalElements}
              contentLength={searchResults.content.length}
              size={size}
              onSizeChanged={onSizeChanged}
            />
            <div className={` ${style.atMovielistArea} `}>{webinarValue}</div>
            <AtPagination
              currentPage={page}
              onPageChanged={onPageChanged}
              totalPages={searchResults.totalPages}
            />
          </div>
        </>
      )}
      {(!searchResults.content || searchResults.content.length === 0) && <AtSearchNoResults />}
    </div>
  );

};

export default AtSearchWebinarList;
