import React, { Fragment, VFC } from 'react';
import style from './AtBreadcrumbs.module.scss';

export type Breadcrumb = {
  text: string;
  url?: string;
};

interface Props {
  breadcrumbs: Breadcrumb[];
}

export const AtBreadcrumbs: VFC<Props> = (props) => {

  const { breadcrumbs } = props;
  const breadcrumbsCopy = [...breadcrumbs];
  const lastBreadcrumb = breadcrumbsCopy.pop();

  const lastBreadcrumbLink = (
    <span className={` font-weight-normal ${style.lastBreadcrumb} `}>{lastBreadcrumb!.text}</span>
  );

  const breadcrumbsLinks = breadcrumbsCopy.map((breadcrumb) => (
    <Fragment key={breadcrumb.text}>
      {breadcrumb.url && (
        <a className={` ${style.link}  font-weight-normal  `} href={breadcrumb.url}>
          {breadcrumb.text}
        </a>
      )}
      {!breadcrumb.url && (
        <span className={` ${style.notLink}  font-weight-normal  `}>{breadcrumb.text}</span>
      )}
      <i className={`far px-2 fa-chevron-right ${style.chevron}`} />
    </Fragment>
  ));

  return (
    <>
      {breadcrumbsLinks}
      {lastBreadcrumbLink}
    </>
  );

};
