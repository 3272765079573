import { VFC } from 'react';
import { TvEventPageQuery } from '../../generated/graphql';
import { AtEventLpTitle } from '../molecules/AtEventLpTitle';
import style from './AtPartnerCompanies.module.scss';

export interface AtPartnerCompaniesProps {
  partnerCompanyNames?: Array<string>;
  sectionTitle: string;
}

export const AtPartnerCompanies: VFC<AtPartnerCompaniesProps> = (props) => {

  const { sectionTitle, partnerCompanyNames } = props;

  if (!partnerCompanyNames || partnerCompanyNames.length === 0) {

    return null;

  }

  return (
    <div id={sectionTitle} className={style.container}>
      <AtEventLpTitle title={sectionTitle} />
      <div className={style.partnerCompanyNameContainer}>
        {partnerCompanyNames.map((partnerCompanyName, index) => (
          <div key={index} className={style.partnerCompanyName}>{partnerCompanyName}</div>
        ))}
      </div>
    </div>
  );

};
