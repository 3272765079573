import { useLocation } from 'react-router-dom';
import { AtSearchUpcomingEventList } from '../organisms/AtSearchUpcomingEventList';
import { AtSearchArchiveEventList } from '../organisms/AtSearchArchiveEventList';
import AtSearchFeatureList from '../organisms/AtSearchFeatureList';
import AtSearchWebinarList from '../organisms/AtSearchWebinarList';
import { getPageFromParam, getSizeFromParam } from '../../util/searchUtil';
import { searchTypes, tabsInfo } from './AtSearch';

export interface AtSearchResultListProps {
  listType: searchTypes['searchType'];
}

export const AtSearchResultList = ({ listType }: AtSearchResultListProps) => {

  const queryParam = new URLSearchParams(useLocation().search);
  const keyword = queryParam.get('k') || '';

  window.scrollTo(0, 0);

  const page = getPageFromParam(queryParam.get('p'));
  const size = getSizeFromParam(queryParam.get('s'));
  // Display search result list
  if (listType === tabsInfo.upcomingEvent.url) {

    return <AtSearchUpcomingEventList keyword={keyword} page={page} size={size} />;

  }
  if (listType === tabsInfo.webinar.url) {

    return <AtSearchWebinarList keyword={keyword} page={page} size={size} />;

  }
  if (listType === tabsInfo.archiveEvent.url) {

    return <AtSearchArchiveEventList keyword={keyword} page={page} size={size} />;

  }
  if (listType === tabsInfo.feature.url) {

    return <AtSearchFeatureList keyword={keyword} page={page} size={size} />;

  }
  return null;

};
