import { useRef, VFC } from 'react';
import { AtButtonAddEvent } from '../atoms/AtButton';
import { TableScrollButtons } from '../atoms/TableScrollButtons';
import style from './AtEventStickyRow.module.scss';

export interface AtEventStickyRowProps {
  eventType?: string;
  eventDateTabs: React.ReactNode[];
  displayButtonFlag?: boolean;
  setShowEventModal: React.Dispatch<React.SetStateAction<boolean>>;
  dateRowRef?: React.RefObject<HTMLDivElement>;
}

export const AtEventStickyRow: VFC<AtEventStickyRowProps> = (props) => {

  const {
    eventType, eventDateTabs, displayButtonFlag, setShowEventModal, dateRowRef,
  } = props;

  const scrollElement = useRef<HTMLDivElement>(null);

  return (
    <>
      {eventType && eventType !== 'FEATURE' && (
        <div className={` ${style.container} `}>
          {displayButtonFlag && (
            <div className={` ${style.buttonWrapper} `}>
              <AtButtonAddEvent openEventModal={() => setShowEventModal(true)} />
            </div>
          )}
          <div className={` ${style.dateContainer} `}>
            <TableScrollButtons
              className={` ${style.scrollButtons} ${style.leftScroll}`}
              left
              tableElement={dateRowRef ?? scrollElement}
            />
            <div ref={dateRowRef ?? scrollElement} className={` ${style.date_tab_area} `}>
              {eventDateTabs}
            </div>
            <TableScrollButtons
              className={` ${style.scrollButtons} ${style.rightScroll} `}
              right
              tableElement={dateRowRef ?? scrollElement}
            />
          </div>
        </div>
      )}
    </>
  );

};
