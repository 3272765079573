import { VFC } from 'react';
import {
  FieldValues, SubmitErrorHandler, SubmitHandler, useFormContext,
} from 'react-hook-form';
import { TvWebinarDocumentType } from '../../generated/graphql';
import { AtForm, FormSection } from '../molecules/AtForm';
import style from './AtBizCardFormContainer.module.scss';

export interface AtBizCardFormContainerProps {
  documentType: TvWebinarDocumentType;
  formSections: FormSection[];
}

export const AtBizCardFormContainer: VFC<AtBizCardFormContainerProps> = (props) => {

  // @ts-ignore
  const { setScreen } = useFormContext();
  const onSuccessSubmit: SubmitHandler<FieldValues> = (data) => {

    window.scrollTo(0, 0);
    setScreen('confirm');

  };
  const onErrorSubmit: SubmitErrorHandler<FieldValues> = (data) => {

    window.scrollTo(0, 0);

  };
  return (
    <div className={` ${style.bizCardFormContainer} `}>
      <div className={` ${style.titleSection} font-weight-bold`}>
        {TvWebinarDocumentType.AperzaTvBusinesscard === props.documentType && <>名刺</>}
        {TvWebinarDocumentType.AperzaTvProjectionDocument === props.documentType && <>投影資料</>}
        をダウンロードする
      </div>
      <div className={` ${style.mainSection} `}>
        <p>
          アンケートとお客様情報を入力後、「入力内容を確認する」へお進みください。
          <br />
          ※任意項目以外はすべてご入力ください。
        </p>
        <AtForm
          formSections={props.formSections}
          onSuccessSubmit={onSuccessSubmit}
          onErrorSubmit={onErrorSubmit}
        />
        <p className={` ${style.formFooterText} `}>
          資料をダウンロードいただいたお客様の情報（企業名、部署、ご氏名、メールアドレス、電話番号な ど）は、
          弊社のプライバシーポリシーに則って取り扱われるほか、資料を掲載した企業（商品・サービスの提供者の他、
          当該商品・サービスの販売代理店等の商品・サービスの取扱事業者を含みます。）にも提供されます。
          また、資料掲載企業におきましては同情報に基づきお客様に メールの送信を行う場合があります。
          上記につきご同意の上ダウンロードをお願いいたします。
        </p>
      </div>
    </div>
  );

};
