import React, { VFC } from 'react';
import { CompanyPageDetail } from '../../generated/graphql';
import { formatZipcode } from '../../util/AddressUtil';
import { AtCompanySidebarSection } from '../atoms/AtCompanySidebarSection';
import style from './AtCompanySidebar.module.scss';

export interface AtCompanySidebarProps {
  companyDetail: CompanyPageDetail;
}

export const AtCompanySidebar: VFC<AtCompanySidebarProps> = (props) => {

  const { companyDetail } = props;

  const industryTypes = companyDetail.industryType.map((industryType: string) => (
    <React.Fragment key={industryType}>
      {industryType}
      <br />
    </React.Fragment>
  ));

  const zipCodeFormatted = formatZipcode(companyDetail.zipcode, companyDetail.countryCode);
  const fullAddress = `${zipCodeFormatted}${companyDetail.subdivisionName ?? ''}${companyDetail.cityName ?? ''}${companyDetail.address ?? ''} ${companyDetail.building ?? ''}`;

  return (
    <div className={style.sidebarContainer}>
      <div className={style.sidebarCorpInfoTitle}>企業情報</div>
      <div>
        <AtCompanySidebarSection title="本社所在地" content={fullAddress} />
        <AtCompanySidebarSection title="代表電話番号" content={companyDetail.tel} />
        <AtCompanySidebarSection title="代表FAX番号" content={companyDetail.fax} />

        {industryTypes && <AtCompanySidebarSection title="業種" content={industryTypes} />}
        <AtCompanySidebarSection title="資本金" content={companyDetail.capitalDescription} />
        <AtCompanySidebarSection title="設立" content={companyDetail.foundationDescription} />
        <AtCompanySidebarSection
          title="事業内容"
          content={companyDetail.businessDescription}
          maxHeightInPx={180}
        />

        <AtCompanySidebarSection
          title="主要取引先"
          content={companyDetail.mainCustomerDescription}
          maxHeightInPx={180}
        />

        <AtCompanySidebarSection title="従業員数" content={companyDetail.employeeDescription} />
        <AtCompanySidebarSection title="売上高" content={companyDetail.salesDescription} />
        <AtCompanySidebarSection
          title="ホームページURL"
          content={companyDetail.websiteUrl}
          linkUrl={companyDetail.websiteUrl}
          openLinkInSeparateTab
        />
      </div>
    </div>
  );

};
