import { useState, VFC } from 'react';
import InView from 'react-intersection-observer';
import { useTrackEvent } from '../../hooks/tracking/useTrackEvent';
import { dividePartDate } from '../../util/dateUtil';
import { getStaticFileDomain } from '../../util/envUtil';
import { AtLabel } from '../atoms/AtLabel';
import { ScrollToTop } from '../atoms/ScrollToTop';
import { useIsMobile } from '../templates/MobileReady';
import style from './AtEventLpMainVisual.module.scss';
import { EventApplyButton } from './EventApplyButton';

export interface AtEventLpMainVisualProps {
  eventId: string;
  imgSrc?: string;
  eventType?: string;
  title?: string;
  startDate?: string;
  endDate?: string;
  venueType: string;
  buttonBackgroundColor?: string;
  buttonTextColor?: string;
  backgroundColor: string;
  setShowCTAModal: React.Dispatch<React.SetStateAction<boolean>>;
  eventApplyFlag?: boolean;
}

export const AtEventLpMainVisual: VFC<AtEventLpMainVisualProps> = (props) => {

  const {
    imgSrc = `${getStaticFileDomain()}tv/images/video-cover-image.png`,
    eventType,
    title,
    startDate,
    endDate,
    venueType,
    buttonBackgroundColor = '#60d000',
    buttonTextColor = '#ffffff',
    backgroundColor,
    setShowCTAModal,
    eventApplyFlag,
    eventId,
  } = props;
  const [CTAButtonInView, setCTAButtonInView] = useState<boolean>(true);
  const isMobile = useIsMobile();
  const { trackEvent } = useTrackEvent();

  const {
    jstYear: startYear,
    jstMonth: startMonth,
    jstDay: startDay,
    jstWeekDay: startWeekDay,
  } = dividePartDate(startDate);

  const { jstMonth: endMonth, jstDay: endDay, jstWeekDay: endWeekDay } = dividePartDate(endDate);

  const displayEndDateFlag = startMonth !== endMonth || startDay !== endDay;

  const backgroundGradient = `linear-gradient(to top, ${backgroundColor} 0%, ${backgroundColor}00 50%)`;
  const backgroundImage = `url(${imgSrc})`;

  return (
    <>
      <div
        className={style.container}
        style={{
          backgroundImage: `${backgroundGradient},${backgroundImage}`,
        }}
      >
        <div className={` ${style.textSection} `}>
          <AtLabel className={` ${style.label} `} labelKind={eventType} />
          <div className={` ${style.title} `}>
            <h1>{title}</h1>
          </div>
          <div className={` ${style.bottomPart} `}>
            <div className={` ${style.dateArea} `}>
              <div className={` ${style.dateHeader} `}>開催日程：</div>
              <div>
                <span className={` ${style.date} `}>{`${startYear}/${startMonth}/${startDay}`}</span>
                <span className={` ${style.day} `}>{`(${startWeekDay})`}</span>
                {displayEndDateFlag && (
                  <>
                    <span className={` ${style.date} px-1`}>~</span>
                    <span className={` ${style.date} `}>{`${endMonth}/${endDay}`}</span>
                    <span className={` ${style.day} `}>{`(${endWeekDay})`}</span>
                  </>
                )}
              </div>
            </div>
            <div className={` ${style.venueArea} `}>
              <div className={` ${style.dateHeader} `}>開催方式：</div>
              <div className={` ${style.venueType} `}>{venueType}</div>
            </div>
            <InView
              className={` ${style.buttonArea} `}
              rootMargin={isMobile ? undefined : '-80px 0px 0px 0px'}
              onChange={setCTAButtonInView}
            >
              <EventApplyButton
                eventId={eventId}
                buttonBackgroundColor={buttonBackgroundColor}
                buttonTextColor={buttonTextColor}
                endDate={endDate}
                setShowCTAModal={setShowCTAModal}
                eventApplyFlag={eventApplyFlag}
                amplitudeTrackEvent={(isLoggedIn) => {

                  trackEvent('Click Event Apply Button', {
                    event_id:        Number(eventId),
                    button_position: 'main visual',
                    is_logged_in:    isLoggedIn,
                  });

                }}
              />
            </InView>
          </div>
        </div>
      </div>
      <div className={` ${style.fixedButtons} ${!CTAButtonInView && style.fixedButtonsShow}`}>
        <EventApplyButton
          eventId={eventId}
          buttonBackgroundColor={buttonBackgroundColor}
          buttonTextColor={buttonTextColor}
          endDate={endDate}
          setShowCTAModal={setShowCTAModal}
          eventApplyFlag={eventApplyFlag}
          amplitudeTrackEvent={(isLoggedIn) => {

            trackEvent('Click Event Apply Button', {
              event_id:        Number(eventId),
              button_position: 'sticky',
              is_logged_in:    isLoggedIn,
            });

          }}
        />
        <ScrollToTop />
      </div>
    </>
  );

};
