import { Redirect, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { AtPagingRouteParams } from '../../App';
import { CompanyNoImageLogoType, TvWebinarDeliveryStatus, useTvWebinarsQuery } from '../../generated/graphql';
import { getTvDomain } from '../../util/envUtil';
import { getWatchStatus } from '../../util/urlUtil';
import type { Breadcrumb } from '../atoms/AtBreadcrumbs';
import { AtBreadcrumbs } from '../atoms/AtBreadcrumbs';
import { AtHitCounter } from '../atoms/AtHitCounter';
import AtMovieAttention from '../atoms/AtMovieAttention';
import AtPagination from '../atoms/AtPagination';
import paginationStyle from '../atoms/AtPagination.module.scss';
import { LoadingBox } from '../atoms/LoadingBox';
import TitleAndMeta from '../atoms/TitleAndMeta';
import AtBreadcrumbsJsonLd from '../molecules/AtBreadcrumbsJsonLd';
import AtMovieListForWebinars from '../organisms/AtMovieListForWebinars';
import style from './AtMovieIndex.module.scss';

export const AtMovieIndex = () => {

  const { pageNo, tabName } = useParams<AtPagingRouteParams>();
  const currentTab = getWatchStatus<TvWebinarDeliveryStatus>(tabName) || TvWebinarDeliveryStatus.All;
  const currentPage = pageNo ? parseInt(pageNo, 10) : 1;

  const isTabNameInvalid = tabName !== undefined && currentTab === null;

  const { loading, error, data } = useTvWebinarsQuery({
    variables: {
      tvWebinarDeliveryStatus: currentTab,
      page:                    currentPage,
      size:                    10,
      isTopPage:               false,
      withCompanysNoLogo:      false,
      companyNoImageLogoType:  CompanyNoImageLogoType.Small,
    },
    skip: isTabNameInvalid,
  });

  useEffect(() => {

    window.scrollTo({
      top:      0,
      behavior: 'smooth',
    });

  }, [currentPage]);

  if (loading) return <LoadingBox />;
  if (error) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  const tvWebinars = data?.tvWebinars.content;
  const hasContent = tvWebinars && tvWebinars.length > 0;
  const isInvalidPage = currentPage > (data?.tvWebinars.totalPages || 0);

  if (isTabNameInvalid) {

    return <Redirect to={{ pathname: '/404', state: { invalidURL: window.location.pathname } }} />;

  }

  const webinarValue = tvWebinars?.map(
    (tvWebinar) =>
      tvWebinar && (
        <div key={tvWebinar.tvWebinarId} className={`${style.atMovielistContent}`}>
          <AtMovieListForWebinars
            webinarId={tvWebinar.tvWebinarId}
            thumbnailImage={tvWebinar.tvWebinarMainImgFilePath}
            companyContract={tvWebinar.companyContract}
            ondemandFlag={tvWebinar.tvWebinarOndemandFlag}
            streamingStartDatetime={tvWebinar.tvWebinarStreamingStartDatetime?.toString()}
            streamingEndDatetime={tvWebinar.tvWebinarStreamingEndDatetime?.toString()}
            title={tvWebinar.tvWebinarTitle}
            subtitle={tvWebinar.tvWebinarSubtitle}
            speakerName={tvWebinar.tvWebinarSpeakerName}
            speakerImage={tvWebinar.tvWebinarSpeakerImgFilePath}
            speakerPost={tvWebinar.tvWebinarSpeakerDepartment}
            watchLaterId={tvWebinar.loggedInUserWatchStatus?.tvWatchLaterId}
            doesWatchLater={!!tvWebinar.loggedInUserWatchStatus?.tvWatchLaterId}
            recommendTexts={[
              tvWebinar.tvWebinarRecommendSentence1,
              tvWebinar.tvWebinarRecommendSentence2,
              tvWebinar.tvWebinarRecommendSentence3,
            ]}
            displayCtaButton={false}
          />
        </div>
      ),
  );

  const getTitle = () => {

    const pageTitlePrefix = pageNo && pageNo !== '1' ? `${pageNo}ページ目:` : '';

    switch (tabName) {
      case 'upcoming':
        return `${pageTitlePrefix}配信予定の動画一覧 | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`;
      case 'live':
        return `${pageTitlePrefix}配信中の動画一覧 | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`;
      case 'ondemand':
        return `${pageTitlePrefix}オンデマンドの動画一覧 | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`;
      default:
        return `${pageTitlePrefix}動画一覧 | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`;
    }

  };

  const getDescription = () => {

    switch (tabName) {
      case 'upcoming':
        return 'Apérza TV（アペルザTV、アペルザテレビ）は、ものづくり産業向けに特化した動画サイトです。最新の製品や技術、トレンドやノウハウなど、ものづくりや研究開発に携わる技術者の役に立つ動画コンテンツをいつでも無料で視聴できます。';
      case 'live':
        return 'Apérza TV（アペルザTV、アペルザテレビ）は、ものづくり産業向けに特化した動画サイトです。最新の製品や技術、トレンドやノウハウなど、ものづくりや研究開発に携わる技術者の役に立つ動画コンテンツをいつでも無料で視聴できます。';
      case 'ondemand':
        return 'Apérza TV（アペルザTV、アペルザテレビ）は、ものづくり産業向けに特化した動画サイトです。最新の製品や技術、トレンドやノウハウなど、ものづくりや研究開発に携わる技術者の役に立つ動画コンテンツをいつでも無料で視聴できます。';
      default:
        return 'Apérza TV（アペルザTV、アペルザテレビ）は、ものづくり産業向けに特化した動画サイトです。最新の製品や技術、トレンドやノウハウなど、ものづくりや研究開発に携わる技術者の役に立つ動画コンテンツをいつでも無料で視聴できます。';
    }

  };
  const breadcrumbs: Breadcrumb[] = [
    {
      text: 'トップ',
      url:  '/',
    },
    {
      text: '動画一覧',
    },
  ];

  return (
    <div className={`${style.atMovieIndex}`}>
      <TitleAndMeta title={getTitle()} description={getDescription()} noIndex={isInvalidPage} />
      <AtBreadcrumbsJsonLd breadcrumbs={breadcrumbs} baseUrl={getTvDomain().slice(0, -1)} />
      <div className={`${style.atMovieIndexFrame}`}>
        <div className={`${style.atMovieIndexBreadcrumbs}`}>
          <AtBreadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <h1 className={`${style.atMovieIndexTitle}`}>動画一覧</h1>
          {hasContent && (
            <AtHitCounter
              totalHitCount={data?.tvWebinars.totalElements}
              displayCount={webinarValue?.length}
            />
          )}
        </div>
        <div className={`${paginationStyle.atPaginationHeader}`} />

        <div className={`${style.atMovielistArea}`}>
          {(webinarValue?.length === 0 && (
            <div className="at-movie-list-horizontal">
              <p>該当する動画がありません</p>
            </div>
          )) ||
            (webinarValue && webinarValue.length >= 0 && webinarValue)}
        </div>
        {hasContent && (
          <AtPagination currentPage={currentPage} totalPages={data?.tvWebinars.totalPages || 0} />
        )}
      </div>
    </div>
  );

};

export default AtMovieIndex;
