import { SetStateAction, useRef, useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { clickCard } from '../../util/clickUtil';
import { dividePartDate, timeIsFuture, timeIsPast } from '../../util/dateUtil';
import { RelatedWebinar } from '../../generated/graphql';
import { AtButtonAddEvent } from '../atoms/AtButton';
import { AtLabelLarge } from '../atoms/AtLabel';
import { AtCTAModal } from './AtCTAModal';

import './AtEventCardWide.scss';
import { EventApplyButtonSmall } from '../molecules/EventApplyButtonSmall';

export interface AtEventIndexSliderItemProps {
  event: any;
  setEventTitle?: React.Dispatch<React.SetStateAction<string>>;
  displayButtonFlag?: boolean;
  setEventType?: (eventTypeInput: string) => void;
  refetch: () => Promise<ApolloQueryResult<any>>;
}

export const AtEventIndexSliderItem = ({
  event,
  displayButtonFlag,
  setEventType,
  refetch,
}: AtEventIndexSliderItemProps) => {

  const [showEventModal, setShowEventModal] = useState<boolean>(false);
  const eventLinkRef = useRef<HTMLAnchorElement>(null);

  const {
    jstYear: year, jstMonth: startMonth, jstDay: startDay, jstWeekDay: startWeekDay,
  } = dividePartDate(
    event.tvEventStartDatetime,
    true,
  );
  const startSlash = event.tvEventStartDatetime ? '/' : '';
  const startTilda = event.tvEventStartDatetime ? '～' : '';
  const { jstMonth: endMonth, jstDay: endDay, jstWeekDay: endWeekDay } = dividePartDate(
    event.tvEventEndDatetime,
    true,
  );
  const endSlash = event.tvEventEndDatetime ? '/' : '';

  const eventType = event.tvEventType;
  const eventTitle = event.tvEventTitle;
  const eventSubtitle = event.tvEventSubtitle;
  const eventDescription = event.tvEventDescription;
  const eventImg = event.tvEventMainImgFilePath;
  const displayEndDateFlag = startMonth !== endMonth || startDay !== endDay;

  let description = '';
  if (eventDescription.length < 200) {

    description = eventDescription;

  } else {

    description = `${eventDescription.substring(0, 200)}...`;

  }

  let eventTypeVertical = 'Exhibition';
  switch (eventType) {
    case 'EXHIBITION':
      eventTypeVertical = 'Exhibition';
      break;
    case 'EVENT':
    case 'EVENT_LP':
      eventTypeVertical = 'Event';
      break;
    case 'FEATURE':
      eventTypeVertical = 'Feature';
      break;
    default:
  }
  if (setEventType) setEventType(eventType);

  const upComingEventFilter = (relatedWebinar: RelatedWebinar) =>
    timeIsFuture(relatedWebinar.tvWebinarStreamingStartDatetime) &&
    timeIsPast(relatedWebinar.tvWebinarPublishStartDatetime);

  let upComingExhibitionReports: RelatedWebinar[] = [];

  if (event?.tvExhibitionReports && event?.tvExhibitionReports.length > 0) {

    upComingExhibitionReports = event?.tvExhibitionReports.filter(upComingEventFilter) ?? [];

  }

  return (
    <>
      <div className="at-event-index-wide">
        <div onClick={() => clickCard(eventLinkRef)}>
          <img className="at-event-index-wide-image" src={eventImg} alt={eventTitle} />
          <p className="at-top-gradation" />
          <p className="at-bottom-gradation" />
          {eventType !== 'FEATURE' && (
            <>
              <div className="at-event-index-wide-year">{year}</div>
              <div className="at-event-index-wide-date-term">
                <div className="at-event-index-wide-date-frame">
                  <div className="at-event-index-wide-month">
                    <span>{startMonth}</span>
                    {startSlash}
                  </div>
                  <div className="at-event-index-wide-date">
                    <div className="at-event-index-wide-week-day">{startWeekDay}</div>
                    <div className="at-event-index-wide-day">{startDay}</div>
                  </div>
                </div>
                {displayEndDateFlag && (
                  <>
                    <div className="at-event-index-wide-term">{startTilda}</div>
                    <div className="at-event-index-wide-date-frame">
                      <div className="at-event-index-wide-month">
                        <span>{endMonth}</span>
                        {endSlash}
                      </div>
                      <div className="at-event-index-wide-date">
                        <div className="at-event-index-wide-week-day">{endWeekDay}</div>
                        <div className="at-event-index-wide-day">{endDay}</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          <div className="at-event-type-vertical">{eventTypeVertical}</div>
          <div className="at-event-index-wide-content">
            <AtLabelLarge labelKind={eventType} />
            <a className="at-event-index-wide-title" href={`/event/${event.tvEventId}`} ref={eventLinkRef}>
              <h1>{eventTitle}</h1>
            </a>
            <p className="at-event-index-wide-subtitle">{eventSubtitle}</p>
            <div className="description-area" />
            <p className="at-event-index-wide-description">{description}</p>
          </div>
        </div>
        {displayButtonFlag && eventType !== 'FEATURE' && timeIsFuture(event.tvEventEndDatetime) && (
          <EventApplyButtonSmall
            eventId={event.tvEventId.toString()}
            setShowCTAModal={() => setShowEventModal(true)}
            eventApplyFlag={event.eventApplyFlag}
          />
        )}
      </div>
      {showEventModal && (
        <AtCTAModal
          refetch={refetch}
          eventId={String(event.tvEventId)}
          eventType={eventType}
          title={eventTitle}
          upComingEvents={upComingExhibitionReports}
          closeModal={() => setShowEventModal(false)}
        />
      )}
    </>
  );

};

export default AtEventIndexSliderItem;
