import { VFC } from 'react';
import style from './AtEventLpLinks.module.scss';
import { AtEventLpLink } from '../atoms/AtEventLpLink';
import { useIsMobile } from '../templates/MobileReady';

export interface AtEventLpLinksProps {
  sectionTitles: { [sectionTitle: string]: string };
  backgroundColor: string;
}

export const AtEventLpLinks: VFC<AtEventLpLinksProps> = (props) => {

  const { sectionTitles, backgroundColor } = props;

  const isMobile = useIsMobile();

  if (isMobile) {

    return null;

  }

  return (
    <div style={{ backgroundColor }} className={style.container}>
      <div className={` ${style.content} `}>
        {Object.entries(sectionTitles).map(([shortTitle, longTitle]) => (
          <AtEventLpLink key={shortTitle} longTitle={longTitle} sectionTitle={shortTitle} />
        ))}
      </div>
    </div>
  );

};
