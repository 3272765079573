import { FC } from 'react';
import { TvEventCatalog } from '../../../generated/graphql';
import { ApplyCompleteExplanation } from '../../molecules/static-event-apply/ApplyCompleteExplanation';
import { CatalogDownloadPageType, CatalogList } from './CatalogList';

type AtStaticEventApplyProps = {
  isGuest: boolean;
  catalogs?: TvEventCatalog[];
  tvEventId: number;
  tvEventStartDatetime?: string;
  tvEventEndDatetime?: string;
  accessPage: CatalogDownloadPageType;
};

export const AtStaticEventApplyComplete: FC<AtStaticEventApplyProps> = ({
  isGuest,
  catalogs,
  tvEventId,
  tvEventStartDatetime,
  tvEventEndDatetime,
  accessPage,
}) => (
  <div>
    <ApplyCompleteExplanation isGuest={isGuest} tvEventId={tvEventId} />
    {catalogs && catalogs.length !== 0 && (
      <CatalogList
        catalogs={catalogs}
        sectionTitle={'イベント出展カタログ'}
        tvEventId={tvEventId}
        tvEventStartDatetime={tvEventStartDatetime}
        tvEventEndDatetime={tvEventEndDatetime}
        accessPage={accessPage}
      />
    )}
  </div>
);
