import style from './AtMovieAttention.module.scss';

export const AtMovieAttention = () => (
  <div className={`${style.atAttention}`}>
    ご視聴されたお客様の情報（お客様がアペルザIDにご登録された企業名、名前、メールアドレス、住所、電話番号などのプロフィール情報）は、弊社プライバシーポリシーに則り掲載企業へ提供されます。
    <br />
    あらかじめ同意の上、ご視聴してください。
    <br />
    なお、お客様の情報の提供を受けた掲載企業は、お問合せへのご回答や営業活動（メールなどによる広告宣伝を含む）等において、それぞれ掲載企業が定めるプライバシーポリシー等の方針に沿って取り扱われます。
    詳しくは掲載企業のウェブサイトなどをご確認いただくか、掲載企業に直接お問い合わせください。
  </div>
);

export default AtMovieAttention;
