import { useShowMore } from '@aperza/ac-contents-ui';
import { useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTvEventPageQuery } from '../../generated/graphql';
import { clickCard } from '../../util/clickUtil';
import { dividePartDate } from '../../util/dateUtil';
import { AtButtonAddEvent } from '../atoms/AtButton';
import { AtLabel, AtLabelLarge } from '../atoms/AtLabel';
import { LoadingBox } from '../atoms/LoadingBox';
import { useIsMobile } from '../templates/MobileReady';
import style from './AtEventCardWide.module.scss';
import './AtEventCardWide.scss';
import { AtCTAModal } from './AtCTAModal';

export interface AtEventCardWideProps {
  eventId: number;
  displayButtonFlag?: boolean;
  setEventType?: (eventTypeInput?: string) => void;
}

export const AtEventCardWide = ({ eventId, displayButtonFlag, setEventType }: AtEventCardWideProps) => {

  const {
    contentRef, displayShowMoreLink, showAllContent, setShowAllContent,
  } = useShowMore(68);

  const [showEventModal, setShowEventModal] = useState(false);
  const isMobile = useIsMobile();

  const {
    loading, error, data, refetch,
  } = useTvEventPageQuery({
    variables: {
      eventId,
    },
    onCompleted: (_data) => {

      setEventType?.(_data.tvEventPage?.tvEventType);

    },
  });
  const eventLinkRef = useRef<HTMLAnchorElement>(null);

  if (loading) return <LoadingBox />;
  if (error) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;
  // どうするか確認必要です。
  if (!data?.tvEventPage) {

    return <></>;

  }

  const {
    jstYear: year, jstMonth: startMonth, jstDay: startDay, jstWeekDay: startWeekDay,
  } = dividePartDate(
    data.tvEventPage.tvEventStartDatetime,
    true,
  );
  const startSlash = data.tvEventPage.tvEventStartDatetime ? '/' : '';
  const startTilde = data.tvEventPage.tvEventStartDatetime ? '～' : '';
  const { jstMonth: endMonth, jstDay: endDay, jstWeekDay: endWeekDay } = dividePartDate(
    data.tvEventPage.tvEventEndDatetime,
    true,
  );

  const endSlash = data.tvEventPage.tvEventEndDatetime ? '/' : '';

  const eventType = data.tvEventPage.tvEventType;
  const eventTitle = data.tvEventPage.tvEventTitle;
  const eventSubtitle = data.tvEventPage.tvEventSubtitle;
  const eventDescription = data.tvEventPage.tvEventDescription;
  const eventImg = data.tvEventPage.tvEventMainImgFilePath;
  const displayEndDateFlag = startMonth !== endMonth || startDay !== endDay;

  const description =
    (eventDescription?.length ?? 0) < 200 ? eventDescription : `${eventDescription?.substring(0, 200)}...`;

  let eventTypeVertical = 'Exhibition';
  switch (eventType) {
    case 'EXHIBITION':
      eventTypeVertical = 'Exhibition';
      break;
    case 'EVENT':
    case 'EVENT_LP':
      eventTypeVertical = 'Event';
      break;
    case 'FEATURE':
      eventTypeVertical = 'Feature';
      break;
    default:
  }

  if (isMobile) {

    return (
      <>
        {/* card area */}
        <div className={` ${style.card} `} onClick={() => clickCard(eventLinkRef)}>
          {/* image part */}
          <img className={` ${style.card_image} `} src={eventImg} alt={eventTitle} />
          <div className={` ${style.top_gradation} `} />
          <div className={` ${style.bottom_gradation} `} />
          {/* -image part */}
          {/* date part */}
          {eventType !== 'FEATURE' && (
            <>
              <div className={` ${style.date_area} `}>
                {/* date atom */}
                <div className={` ${style.date_bunch} `}>
                  <div className={`${style.date_left}`}>
                    <span className={`${style.month}`}>{startMonth}</span>
                    <span className={` ${style.slash} `}>{startSlash}</span>
                  </div>
                  <div className={`${style.date_right}`}>
                    <span className={`${style.week_day}`}>{startWeekDay}</span>
                    <div className={`${style.day}`}>{startDay}</div>
                  </div>
                </div>
                {/* -date atom */}
                {displayEndDateFlag && (
                  <>
                    <div className={`${style.tilde}`}>{startTilde}</div>
                    {/* date atom */}
                    <div className={` ${style.date_bunch} `}>
                      <div className={`${style.date_left}`}>
                        <span className={`${style.month}`}>{endMonth}</span>
                        <span className={` ${style.slash} `}>{endSlash}</span>
                      </div>
                      <div className={`${style.date_right}`}>
                        <span className={`${style.week_day}`}>{endWeekDay}</span>
                        <div className={`${style.day}`}>{endDay}</div>
                      </div>
                    </div>
                    {/* -date atom */}
                  </>
                )}
              </div>
            </>
          )}
          {/* -date part */}
          {/* vertical type text */}
          <div className={` ${style.eventTypeVertical} `}>{eventTypeVertical}</div>
          {/* -vertical type text */}
          {/* title area */}
          <div className={`${style.banner_content}`}>
            <AtLabel labelKind={eventType} />
            <a className={`${style.title}`} href={`/event/${eventId}`} ref={eventLinkRef}>
              {eventTitle}
            </a>
            {/* subtitle */}
            {/* <p className={`${style.at_event_index_wide_subtitle}`}>{eventSubtitle}</p> */}
          </div>
          {/* -title area */}
        </div>
        {/* -card area */}
        {/* description */}
        <div ref={contentRef} className={`${style.description} ${showAllContent ? '' : style.hideLines}`}>
          {description}
        </div>
        {displayShowMoreLink && (
          <div className={` ${style.showMoreButton} `} onClick={() => setShowAllContent(!showAllContent)}>
            {showAllContent ? '折りたたむ' : 'すべて見る'}
          </div>
        )}
        {/* -description */}
      </>
    );

  }

  return (
    <>
      <div className="at-event-index-wide">
        <div onClick={() => clickCard(eventLinkRef)}>
          <img className="at-event-index-wide-image" src={eventImg} alt={eventTitle} />
          <p className="at-top-gradation" />
          <p className="at-bottom-gradation" />
          {eventType !== 'FEATURE' && (
            <>
              <div className="at-event-index-wide-year">{year}</div>
              <div className="at-event-index-wide-date-term">
                <div className="at-event-index-wide-date-frame">
                  <div className="at-event-index-wide-month">
                    <span>{startMonth}</span>
                    {startSlash}
                  </div>
                  <div className="at-event-index-wide-date">
                    <div className="at-event-index-wide-week-day">{startWeekDay}</div>
                    <div className="at-event-index-wide-day">{startDay}</div>
                  </div>
                </div>
                {displayEndDateFlag && (
                  <>
                    <div className="at-event-index-wide-term">{startTilde}</div>
                    <div className="at-event-index-wide-date-frame">
                      <div className="at-event-index-wide-month">
                        <span>{endMonth}</span>
                        {endSlash}
                      </div>
                      <div className="at-event-index-wide-date">
                        <div className="at-event-index-wide-week-day">{endWeekDay}</div>
                        <div className="at-event-index-wide-day">{endDay}</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          <div className="at-event-type-vertical">{eventTypeVertical}</div>
          <div className="at-event-index-wide-content">
            <AtLabelLarge labelKind={eventType} />
            <a className="at-event-index-wide-title" href={`/event/${eventId}`} ref={eventLinkRef}>
              <h1>{eventTitle}</h1>
            </a>
            <p className="at-event-index-wide-subtitle">{eventSubtitle}</p>
            <div className="description-area" />
            <p className="at-event-index-wide-description">{description}</p>
          </div>
        </div>
        {displayButtonFlag && eventType !== 'FEATURE' && (
          <AtButtonAddEvent openEventModal={() => setShowEventModal(true)} />
        )}
      </div>
      {showEventModal && (
        <AtCTAModal
          webinarDays={data.tvEventPage.tvEventSchedules?.content}
          refetch={refetch}
          eventId={`${eventId}`}
          title={eventTitle}
          closeModal={() => setShowEventModal(false)}
        />
      )}
    </>
  );

};
