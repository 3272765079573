import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt, Redirect } from 'react-router-dom';
import { FormSection } from '../molecules/AtForm';
import { AtStaticEventApplyComplete } from '../organisms/static-event/Complete';
import { AtStaticEventApplyConfirmContainer } from '../organisms/static-event/Input';
import { AtStaticEventApplyFormContainer } from '../organisms/static-event/Confirm';
import { LoadingBox } from '../atoms/LoadingBox';
import style from './AtStaticEventApply.module.scss';
import { useGetStaticApplyPageQuery, UserType } from '../../generated/graphql';
import { useAuth } from '../../contexts/AuthContext';
import { EventDescription } from '../molecules/static-event-apply/EventDescription';
import { AtHeaderAndFooter } from '../templates/AtHeaderAndFooter';
import TitleAndMeta from '../atoms/TitleAndMeta';
import { dividePartJstDate } from '../../util/dateUtil';
import { useTrackEvent } from '../../hooks/tracking/useTrackEvent';

type Screen = 'input' | 'confirm' | 'complete';

type AtStaticEventApplyProps = {
  tvEventId: number;
};

interface StartEventApplyTrackEventProps {
  eventId: number;
  isMember: boolean;
}

const StartEventApplyTrackEvent = ({ eventId, isMember }: StartEventApplyTrackEventProps) => {

  const { trackEvent } = useTrackEvent();

  useEffect(() => {

    // TODO: 戻ってきたときには発生しないようにしたい
    trackEvent('Start Event Apply', {
      event_id:  eventId,
      is_member: isMember,
    });

  }, [eventId, isMember, trackEvent]);

  return null;

};

interface CompleteEventApplyTrackEventProps {
  eventId: number;
}

const CompleteEventApplyTrackEvent = ({ eventId }: CompleteEventApplyTrackEventProps) => {

  const { trackEvent } = useTrackEvent();

  useEffect(() => {

    trackEvent('Complete Event Apply', {
      event_id: eventId,
    });

  }, [eventId, trackEvent]);

  return null;

};

// 静的なイベントページの登録申し込み用
export const AtStaticEventApply: FC<AtStaticEventApplyProps> = ({ tvEventId }) => {

  const { currentUser } = useAuth();

  const [formUserType, setFormUserType] = useState<UserType>(UserType.Guest);

  const [formSections, setFormSections] = useState<FormSection[]>([]);
  const insertFormSections: FormSection[] = [];

  const [screen, setScreen] = useState<Screen>('input');

  const [confirmed, setConfirmed] = useState<boolean>(false);

  const { data, loading, error } = useGetStaticApplyPageQuery({
    variables: {
      tvEventId,
    },
  });

  const {
    jstYear: startYear,
    jstMonth: startMonth,
    jstDay: startDay,
    jstWeekDay: startWeekDay,
  } = dividePartJstDate(data?.tvEventPage?.tvEventStartDatetime);

  const { jstMonth: endMonth, jstDay: endDay, jstWeekDay: endWeekDay } = dividePartJstDate(
    data?.tvEventPage?.tvEventEndDatetime,
  );

  const startDate = data?.tvEventPage?.tvEventStartDatetime
    ? `${startMonth}/${startDay}(${startWeekDay})`
    : '';
  const endDate = data?.tvEventPage?.tvEventEndDatetime ? `${endMonth}/${endDay}(${endWeekDay})` : '';

  const eventTitle = data?.tvEventPage?.tvEventTitle ?? '';
  const eventCatchCopy = data?.tvEventPage?.tvEventSubtitle ?? '';
  const eventSchedule =
    startDate !== endDate ? `${startYear}/${startDate}〜${endDate}` : `${startYear}/${startDate}`;

  const methods = useForm();

  const { setValue } = methods;

  useEffect(() => {

    if (data) {

      const onBusinessTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

        if (event.target.value) {

          const jobCategoryTypes = data.businessTypes
            .filter((businessType) => businessType.code === event.target.value)
            .map((businessType) => businessType.jobCategories);

          if (jobCategoryTypes) {

            const index = insertFormSections.findIndex((formSection) => formSection.id === 'jobCategoryType');

            if (index >= 0) {

              const changeSelectionForm = insertFormSections[index];
              // eslint-disable-next-line prefer-destructuring
              changeSelectionForm.options = jobCategoryTypes[0];
              changeSelectionForm.disabled = false;
              insertFormSections.splice(index, 1, changeSelectionForm);
              setFormSections([...insertFormSections]);
              if (setValue) {

                setValue('jobCategoryType', undefined);

              }

            }

          }

        }

      };

      const jobCategoryTypes = data.businessTypes.map((businessType) => businessType.jobCategories);

      const nameForm: FormSection = {
        id:                         'name',
        name:                       '氏名',
        type:                       'name',
        required:                   true,
        withOptionOtherComment:     false,
        requiredOptionOtherComment: false,
      };

      const mailAddressForm: FormSection = {
        id:              'mailAddress',
        name:            'メールアドレス',
        type:            'text',
        required:        true,
        registerOptions: {
          required: {
            value:   true,
            message: '「メールアドレス」をご入力ください',
          },
          pattern: {
            value:   /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
            message: '「メールアドレス」の形式が違います',
          },
        },
        placeholder:                'aperza@example.com',
        withOptionOtherComment:     false,
        requiredOptionOtherComment: false,
      };

      const telForm: FormSection = {
        id:              'tel',
        name:            '電話番号',
        type:            'text',
        required:        true,
        registerOptions: {
          required: {
            value:   true,
            message: '「電話番号」をご入力ください',
          },
          pattern: {
            value:   /^0\d{1,4}-\d{1,4}-\d{3,4}$/,
            message: '「電話番号」の形式が違います',
          },
          maxLength: {
            value:   20,
            message: '「電話番号」は20文字以内でご入力ください',
          },
        },
        placeholder:                '000-1234-5678',
        withOptionOtherComment:     false,
        requiredOptionOtherComment: false,
        formInfo:                   '※ ハイフン含む',
      };

      const companyNameForm: FormSection = {
        id:              'companyName',
        name:            '会社名',
        type:            'text',
        required:        true,
        registerOptions: {
          required: {
            value:   true,
            message: '「会社名」をご入力ください',
          },
          maxLength: {
            value:   255,
            message: '「会社名」は255文字以内でご入力ください',
          },
        },
        placeholder:                'ご所属の会社名',
        withOptionOtherComment:     false,
        requiredOptionOtherComment: false,
      };

      const departmentNameForm: FormSection = {
        id:              'departmentName',
        name:            '部署名',
        type:            'text',
        required:        true,
        registerOptions: {
          required: {
            value:   true,
            message: '「部署名」をご入力ください',
          },
          maxLength: {
            value:   255,
            message: '「部署名」は255文字以内でご入力ください',
          },
        },
        placeholder:                'ご所属の部署名',
        withOptionOtherComment:     false,
        requiredOptionOtherComment: false,
      };

      const subdivisionCodeForm: FormSection = {
        id:                         'subdivisionCode',
        name:                       '所在地',
        type:                       'select',
        required:                   true,
        registerOptions:            { required: { value: true, message: '「所在地」を選択してください' } },
        options:                    data.prefectures,
        withOptionOtherComment:     false,
        requiredOptionOtherComment: false,
      };

      const passwordForm: FormSection = {
        id:              'passowrd',
        name:            'パスワード',
        type:            'password',
        required:        true,
        registerOptions: {
          required:  { value: true, message: 'パスワードを入力してください' },
          maxLength: { value: 30, message: '「パスワード」は30文字以内でご入力ください' },
          minLength: { value: 8, message: '「パスワード」は8文字以上でご入力ください' },
        },
        withOptionOtherComment:     false,
        requiredOptionOtherComment: false,
        placeholder:                'パスワードをご入力ください',
        formInfo:                   '※ 半角数字をご入力ください',
      };

      if (currentUser.userType === UserType.Member) {

        setFormUserType(UserType.Member);

        insertFormSections.push(nameForm, companyNameForm, departmentNameForm, subdivisionCodeForm, telForm);

        methods.setValue('name.lastName', currentUser.lastName);
        methods.setValue('name.firstName', currentUser.firstName);
        methods.setValue('companyName', currentUser.companyName);
        methods.setValue('departmentName', currentUser.departmentName);
        methods.setValue('tel', currentUser.tel);
        methods.setValue('subdivisionCode', currentUser.subdivisionCode);

      } else {

        insertFormSections.push(
          nameForm,
          companyNameForm,
          departmentNameForm,
          subdivisionCodeForm,
          telForm,
          mailAddressForm,
          passwordForm,
        );

      }

      setFormSections(insertFormSections);

    }

  }, [data]);

  // Warning leave browser without saving data
  // eslint-disable-next-line consistent-return
  useEffect(() => {

    if (screen !== 'complete') {

      window.onbeforeunload = (e: BeforeUnloadEvent) => {

        // Cancel the event as stated by the standard.
        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = '';

      };

      return () => {

        window.onbeforeunload = (e: BeforeUnloadEvent) => {

          delete e.returnValue;

        };

      };

    }

  }, [screen]);

  if (loading) return <LoadingBox />;
  if (Number.isNaN(tvEventId)) return <Redirect to={{ pathname: '/404', state: { invalidURL: window.location.pathname } }} />;
  if (error) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  if (!data?.tvEventPage) return <Redirect to={{ pathname: '/404', state: { invalidURL: window.location.pathname } }} />;

  const display = () => {

    switch (screen) {
      case 'input':
        return (
          <div className={`container-fluid p-0 ${style.pageContainer}`}>
            <div className="row justify-content-center no-gutters">
              <div className="col-12 col-xl-8">
                <div className={style.titleContainer}>
                  <h1 className={style.title}>参加登録</h1>
                  <p className={style.info}>
                    下記フォームに必要事項を入力の上、「入力内容を確認」ボタンを押してください。
                  </p>
                </div>
                <EventDescription
                  eventTitle={eventTitle}
                  catchCopy={eventCatchCopy}
                  schedule={eventSchedule}
                />
                <AtStaticEventApplyFormContainer
                  formSections={formSections}
                  tvEventId={tvEventId}
                  confirmed={confirmed}
                  setConfirmed={setConfirmed}
                />
                <StartEventApplyTrackEvent
                  eventId={tvEventId}
                  isMember={currentUser.userType === UserType.Member}
                />
                <Prompt
                  // TODO フォームに変更があった時のみアラートを出したい。
                  // when={formChanged}
                  message={'このサイトを離れますか？\n\n行った変更が保存されない可能性があります。'}
                />
              </div>
            </div>
          </div>
        );
      case 'confirm':
        return (
          <div className={`container-fluid p-0 ${style.pageContainer}`}>
            <div className="row justify-content-center no-gutters">
              <div className="col-12 col-xl-8">
                <div className={style.titleContainer}>
                  <h1 className={style.title}>入力内容の確認</h1>
                  <p className={style.info}>以下の内容でよろしければ「登録」へお進みください。</p>
                </div>
                <EventDescription
                  eventTitle={eventTitle}
                  catchCopy={eventCatchCopy}
                  schedule={eventSchedule}
                />
                <AtStaticEventApplyConfirmContainer formSections={formSections} tvEventId={tvEventId} />
                <Prompt
                  // TODO フォームに変更があった時のみアラートを出したい。
                  // when={formChanged}
                  message={
                    'このサイトを離れますか？\n\n行った変更が保存されない可能性があります。入力フォームに戻る場合は、戻るボタンを押してください。'
                  }
                />
              </div>
            </div>
          </div>
        );
      case 'complete':
        return (
          <>
            <AtStaticEventApplyComplete
              isGuest={formUserType === UserType.Guest}
              catalogs={data.tvEventPage?.catalogs}
              tvEventId={tvEventId}
              tvEventStartDatetime={data?.tvEventPage?.tvEventStartDatetime}
              tvEventEndDatetime={data?.tvEventPage?.tvEventEndDatetime}
              accessPage="complete"
            />
            <CompleteEventApplyTrackEvent eventId={tvEventId} />
          </>
        );
      default:
        return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;
    }

  };

  return (
    <div>
      <TitleAndMeta noIndex />
      <AtHeaderAndFooter>
        <FormProvider {...{ ...methods, setScreen }}>{display()}</FormProvider>
      </AtHeaderAndFooter>
    </div>
  );

};
