import {
  Dispatch, RefObject, SetStateAction, useEffect, useRef, useState,
} from 'react';
import style from './AtCompanySidebarSection.module.scss';
import AtExternalLink from './AtExternalLink';

interface AtCompanySidebarSectionProps {
  title: string;
  content?: React.ReactNode;
  /**
   * Displays show more if exceeds this height
   */
  maxHeightInPx?: number;
  linkUrl?: string;
  openLinkInSeparateTab?: boolean;
}

export const useShowMore = (
  contentRef: RefObject<any>,
  setDisplayShowMoreLink: Dispatch<SetStateAction<boolean>>,
  maxHeightInPx?: number,
) => {

  useEffect(() => {

    if (maxHeightInPx && contentRef.current && contentRef.current.scrollHeight > maxHeightInPx) {

      setDisplayShowMoreLink(true);

    }

  }, [contentRef]);

};

export const AtCompanySidebarSection = ({
  title,
  content,
  maxHeightInPx,
  linkUrl,
  openLinkInSeparateTab,
}: AtCompanySidebarSectionProps) => {

  const [showAllContent, setShowAllContent] = useState(false);
  const [displayShowMoreLink, setDisplayShowMoreLink] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  useShowMore(contentRef, setDisplayShowMoreLink, maxHeightInPx);

  if (!content || (Array.isArray(content) && content.length === 0)) {

    return null;

  }

  const renderLink = () => {

    if (!linkUrl) {

      return null;

    }

    if (openLinkInSeparateTab && typeof content === 'string') {

      return <AtExternalLink href={linkUrl} name={content} />;

    }

    return <a href={linkUrl}>{content}</a>;

  };

  return (
    <>
      <div className={style.title}>{title}</div>
      <div
        className={`${style.content}  ${
          displayShowMoreLink && !showAllContent ? 'overflow-hidden' : ''
        } wrap`}
        style={displayShowMoreLink && !showAllContent ? { height: maxHeightInPx } : {}}
        ref={contentRef}
      >
        {linkUrl ? renderLink() : <>{content}</>}
      </div>

      {displayShowMoreLink && (
        <a
          className={style.showMoreLink}
          onClick={() => {

            setShowAllContent(!showAllContent);

          }}
        >
          {showAllContent ? '折りたたむ' : 'すべて見る'}
        </a>
      )}
    </>
  );

};
