import { useCallback } from 'react';
import { addAnalyticsDataLayer } from '../../util/analyticsUtil';

const useClickDownloadTrackEvent = () => {

  const clickDocumentDownloadOnWebinarTrackEvent = useCallback((tvWebinarId: number) => {

    addAnalyticsDataLayer({
      event:    'tv_dl_document',
      video_id: tvWebinarId,
    });

  }, []);

  const clickBizcardDownloadOnWebinarTrackEvent = useCallback((tvWebinarId: number) => {

    addAnalyticsDataLayer({
      event:    'tv_dl_bizcard',
      video_id: tvWebinarId,
    });

  }, []);

  return {
    clickDocumentDownloadOnWebinarTrackEvent,
    clickBizcardDownloadOnWebinarTrackEvent,
  };

};

export default useClickDownloadTrackEvent;
