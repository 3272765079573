import { VFC } from 'react';
import style from './AtEventLpLink.module.scss';

export interface AtEventLpLinkProps {
  sectionTitle: string;
  longTitle: string;
}

export const AtEventLpLink: VFC<AtEventLpLinkProps> = (props) => {

  const { sectionTitle, longTitle } = props;

  return (
    <a className={style.atEventLink} href={`#${longTitle.replace(/\r\n/, '')}`}>
      {sectionTitle}
      <i className={`fas fa-chevron-down ${style.chevron}`} />
    </a>
  );

};
