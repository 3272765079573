import { PlaybackRateEvent } from '@u-wave/react-vimeo';
import Player from '@vimeo/player';
import { useCallback } from 'react';
import { useTrackEvent } from './tracking/useTrackEvent';

// ここにVideo.tsxのロジックを徐々に移したい。
export const useVideo = (webinarId: number) => {

  const { trackEvent } = useTrackEvent();

  const onPlaybackRateChange = useCallback((e: PlaybackRateEvent): void => {

    if (e.playbackRate) {

      localStorage.setItem('playbackRate', e.playbackRate.toString());

      trackEvent('Change Playback Rate', { webinar_id: webinarId, playback_rate: e.playbackRate });

    }

  }, []);

  const setStoredPlaybackRate = useCallback((player: Player): void => {

    const playbackRate = Number(localStorage.getItem('playbackRate'));

    const isValidPlaybackRate = !Number.isNaN(playbackRate) && playbackRate >= 0.5 && playbackRate <= 2;

    if (isValidPlaybackRate) {

      player.setPlaybackRate(playbackRate);

    }

  }, []);

  return { onPlaybackRateChange, setStoredPlaybackRate };

};
