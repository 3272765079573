import { gql, useQuery } from '@apollo/client';
import { SyntheticEvent, useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { dividePartDate, timeIsFuture, timeIsPast } from '../../util/dateUtil';
import { getOmittionSentence } from '../../util/displayUtil';
import { AtLabel } from '../atoms/AtLabel';
import { LoadingBox } from '../atoms/LoadingBox';
import { AtPlayIcon } from '../atoms/AtPlayIcon';
import './AtMovieListHorizontal.scss';
import { AtMovieListHorizontalPart } from './AtMovieListHorizontalPart';
import useClickWebinarOnWatchlistTrackEvent from '../../hooks/tracking/useClickWebinarOnWatchlistTrackEvent';

export type MovieStatus = 'opentime' | 'ontime' | 'ondemand' | 'closed' | '';

const companyContractQuery = gql`
  query companyContract($companyContractId: String!) {
    companyContract(companyContractId: $companyContractId) {
      companyContractId
      companyLang {
        companyNameFull
        companyLogoUrl
      }
    }
  }
`;

export const AtMovieListHorizontal = (props: any) => {

  const {
    companyContractId,
    streamingStartDatetime,
    streamingEndDatetime,
    ondemandFlag,
    speakerPost,
    webinarId,
    optionalFuncWhenCanceling,
    thumbnailImage,
    tvEventPage,
    title,
    subtitle,
    speakerImage,
    speakerName,
    watchLaterId,
  } = props;

  const [showCassette, setShowCassette] = useState(true);
  const { clickWebinarOnWatchlistTrackEvent } = useClickWebinarOnWatchlistTrackEvent();

  const { loading, error, data } = useQuery(companyContractQuery, {
    variables: {
      companyContractId,
    },
  });

  if (loading) return <LoadingBox />;
  if (error) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  let value = <></>;
  let movieStatus: MovieStatus = '';
  if (timeIsFuture(streamingStartDatetime)) {

    movieStatus = 'opentime';
    value = <div className="movie-label at-label-normal-will-stream">配信予定</div>;

  } else if (timeIsPast(streamingStartDatetime) && timeIsFuture(streamingEndDatetime)) {

    movieStatus = 'ontime';
    value = <div className="movie-label at-label-normal-in-stream">配信中</div>;

  } else if (ondemandFlag === 'ONDEMAND' && timeIsPast(streamingEndDatetime)) {

    movieStatus = 'ondemand';
    value = <div className="movie-label at-label-normal-ondemand">オンデマンド</div>;

  } else if (ondemandFlag !== 'ONDEMAND' && timeIsPast(streamingEndDatetime)) {

    movieStatus = 'closed';
    value = <div className="movie-label at-label-normal-close-stream">公開終了</div>;

  }

  const companyName = data.companyContract.companyLang.companyNameFull
    ? data.companyContract.companyLang.companyNameFull
    : '';
  const omittedSpeakerPost = getOmittionSentence(speakerPost, 20);

  const hiddenCassette = () => {

    if (webinarId) {

      optionalFuncWhenCanceling(webinarId);

    }
    setShowCassette(false);

  };

  const {
    jstYear: year,
    jstMonth: month,
    jstDay: day,
    jstWeekDay: weekDay,
    jstTime: startTime,
  } = dividePartDate(streamingStartDatetime);
  const { jstTime: endTime } = dividePartDate(streamingEndDatetime);

  const handleClick = (e: SyntheticEvent) => {

    e.stopPropagation();

    clickWebinarOnWatchlistTrackEvent(webinarId, movieStatus);

  };

  return (
    (showCassette && (
      <div className="at-movie-list-horizontal">
        <a href={`/watch/${webinarId}`} onClick={handleClick}>
          <div className="thumbnail-area">
            <img src={thumbnailImage} alt="" />
            {value}
            {(movieStatus === 'ondemand' || movieStatus === 'ontime') && <AtPlayIcon />}
          </div>
        </a>

        <div className="text-area">
          <a href={`/watch/${webinarId}`} onClick={handleClick}>
            <div className="movie-detail-area">
              <div className="movie-title-area">
                <div className="movie-event-type">
                  <AtLabel labelKind={tvEventPage?.tvEventType} />
                </div>
                <p className="movie-event-title">{tvEventPage?.tvEventTitle}</p>
                <p className="movie-title">{title}</p>
                <p className="movie-subtitle">{subtitle}</p>
              </div>
              <i className="fas fa-chevron-right" />
            </div>
          </a>
          <div className="movie-company-other-area">
            <a href={`/watch/${webinarId}`} onClick={handleClick}>
              <div className="company-area">
                <p className="company-name">{companyName}</p>
                <div className="speaker-area">
                  <img className="speaker-image" src={speakerImage} alt="speaker" />
                  <div className="speaker-text">
                    <p className="speaker-post">{omittedSpeakerPost}</p>
                    <p className="speaker-name">{speakerName ? `${speakerName} 氏` : ''}</p>
                  </div>
                </div>
              </div>
            </a>
            <div className="other-area">
              <a href={`/watch/${webinarId}`} onClick={handleClick}>
                {movieStatus !== 'ondemand' && (
                  <p className="movie-term">
                    {/* JSTに変換して取得 */}
                    {year}年{month}月{day}日 &nbsp;
                    {startTime}～{endTime}
                  </p>
                )}
              </a>
              <AtMovieListHorizontalPart
                webinarId={webinarId}
                watchLaterId={watchLaterId}
                optionalFuncWhenCanceling={hiddenCassette}
                movieStatus={movieStatus}
              />
            </div>
          </div>
        </div>
      </div>
    )) || <></>
  );

};

export default AtMovieListHorizontal;
