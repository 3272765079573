import { EnvUtil, UUIDUtil } from '@aperza/util';
import Vimeo, {
  EndEvent,
  PauseEvent,
  PlayEvent,
  ProgressEvent,
  SeekedEvent,
  TimeUpdateEvent,
} from '@u-wave/react-vimeo';
import { Player } from '@vimeo/player';
import axios from 'axios';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useTrackEvent } from '../../hooks/tracking/useTrackEvent';
import { useVideo } from '../../hooks/useVideo';
import { judgeWebinarStreamingAccessTiming } from '../../util/amplitudeWrapper';
import { getCurrentMoment } from '../../util/dateUtil';
import { getStaticFileDomain } from '../../util/envUtil';
import { useIsMobile } from '../templates/MobileReady';
import useWatchWebinarTrackEvent from '../../hooks/tracking/useWatchWebinarTrackEvent';

const Cookies = require('js-cookie');

interface VideoProps {
  webinarId: number;
  movieUrl: string;
  chatUrl?: string;
  webinarOndemandFlag: boolean;
  chatShowFlag: boolean;
  videoShowFlag: boolean;
  disableVideoImageUrl: string;
  disableVideoImageAlt: string;
  startVideoTime?: number;
  ubidForEmbed?: string;
  webinarStreamingStartDatetime?: string;
  webinarStreamingEndDatetime?: string;
  webinarMoviePlayTime?: string;
  onPlaybackTimeUpdate?: (time: TimeUpdateEvent, player: Player) => void;
  onPlayerSeeked?: (time: SeekedEvent, player: Player) => void;
}

export function Video({
  webinarId,
  movieUrl,
  chatUrl,
  webinarOndemandFlag,
  chatShowFlag,
  videoShowFlag,
  disableVideoImageUrl,
  disableVideoImageAlt,
  startVideoTime = 0,
  ubidForEmbed,
  webinarStreamingStartDatetime,
  webinarStreamingEndDatetime,
  webinarMoviePlayTime,
  onPlaybackTimeUpdate,
  onPlayerSeeked,
}: VideoProps) {

  const { currentUser } = useAuth();
  const isMobile = useIsMobile();
  const env: string = EnvUtil.getAppEnv();
  const [error, setError] = useState(false);
  const [player, setPlayer] = useState<Player>();
  const { onPlaybackRateChange, setStoredPlaybackRate } = useVideo(webinarId);
  const { trackEvent } = useTrackEvent();
  const { watchLiveTrackEvent, watchOndemandTrackEvent } = useWatchWebinarTrackEvent();

  let apiEndPoint: string = '';
  let aperzaApiKey: string = '';

  if (env === EnvUtil.ENV_STG) {

    apiEndPoint = 'https://tr.api.stg.aperza.com/v1/tv/e';
    aperzaApiKey = '9d2eb583-1eb3-4fb7-b125-e7657da7db63';

  } else if (env === EnvUtil.ENV_PROD) {

    apiEndPoint = 'https://tr.api.aperza.com/v1/tv/e';
    aperzaApiKey = 'c9f72e6e-2d01-4263-bcb2-1e9bf428c79b';

  } else {

    apiEndPoint = 'https://tr.api.test.aperza.com/v1/tv/e';
    aperzaApiKey = '0e5643ce-eb44-4bcb-a0b0-52010f93374b';

  }

  let timeCodeBuffer: number = 0;
  let basedDateTime: Dayjs = getCurrentMoment();
  let viewingID: string = UUIDUtil.uuid();
  let playbackID: string = UUIDUtil.uuid();
  const ubid = Cookies.get('__ubid') ?? ubidForEmbed;

  type EventLogOptions = {
    /* eslint-disable camelcase */
    event_datetime: string;
    tv_webinar_id: number;
    event_type: number;
    rd_ubid: string;
    user_id: number | undefined;
    mail_subscriber_code: string;
    viewing_id: string | null;
    playback_id: string | null;
    playback_speed: number | null;
    time_code: number | null;
    /* eslint-enable */
  };
  const logAnalyticsEvent = (eventName: string, eventLog: EventLogOptions) => {

    axios
      .post(apiEndPoint, eventLog, {
        headers: {
          'aperza-api-key': aperzaApiKey,
        },
      })
      .then((res) => {})
      .catch((err) => {});

  };

  const onReady = (event: Player) => {

    setPlayer(event);
    logAnalyticsEvent('onReady', {
      event_datetime:       getCurrentMoment().format('YYYY-MM-DD HH:mm:ss'),
      tv_webinar_id:        webinarId,
      event_type:           1, // 動画の再生準備が完了した ≒ 動画ページが表示された
      rd_ubid:              ubid,
      user_id:              currentUser?.userId,
      mail_subscriber_code: Cookies.get('__tr_mscd'),
      viewing_id:           viewingID,
      playback_id:          playbackID,
      playback_speed:       0, // 0: 不明
      time_code:            0,
    });
    trackEvent('Ready Webinar', {
      webinar_id:              webinarId,
      webinar_type:            'ondemand',
      webinar_movie_play_time: webinarMoviePlayTime,
    });
    setStoredPlaybackRate(event);

  };

  const onLiveReady = (event: Player) => {

    setPlayer(event);
    logAnalyticsEvent('onLiveReady', {
      event_datetime:       getCurrentMoment().format('YYYY-MM-DD HH:mm:ss'),
      tv_webinar_id:        webinarId,
      event_type:           1, // 動画の再生準備が完了した ≒ 動画ページが表示された
      rd_ubid:              ubid,
      user_id:              currentUser?.userId,
      mail_subscriber_code: Cookies.get('__tr_mscd'),
      viewing_id:           null,
      playback_id:          null,
      playback_speed:       null,
      time_code:            null,
    });

    const accessTiming = judgeWebinarStreamingAccessTiming(
      webinarStreamingStartDatetime,
      webinarMoviePlayTime,
    );
    trackEvent('Ready Streaming', {
      webinar_id:                      webinarId,
      webinar_type:                    'streaming',
      webinar_streaming_start_at:      webinarStreamingStartDatetime,
      webinar_streaming_end_at:        webinarStreamingEndDatetime,
      webinar_streaming_access_timing: accessTiming,
      webinar_movie_play_time:         webinarMoviePlayTime,
    });

  };

  let onLiveTimeUpdateInitFlag: boolean = true;
  const onLiveTimeUpdate = (event: any) => {

    if (onLiveTimeUpdateInitFlag) {

      const accessTiming = judgeWebinarStreamingAccessTiming(
        webinarStreamingStartDatetime,
        webinarMoviePlayTime,
      );
      trackEvent('Play Streaming', {
        webinar_id:                      webinarId,
        webinar_type:                    'streaming',
        webinar_streaming_start_at:      webinarStreamingStartDatetime,
        webinar_streaming_end_at:        webinarStreamingEndDatetime,
        webinar_streaming_access_timing: accessTiming,
        webinar_movie_play_time:         webinarMoviePlayTime,
      });
      watchLiveTrackEvent(webinarId, currentUser.userId);

    }

    const currentTimeCode: number = Math.floor(event.seconds);
    if (
      onLiveTimeUpdateInitFlag ||
      (currentTimeCode > timeCodeBuffer && currentTimeCode - timeCodeBuffer >= 10)
    ) {

      onLiveTimeUpdateInitFlag = false;
      logAnalyticsEvent('onLiveTimeUpdate', {
        event_datetime:       getCurrentMoment().format('YYYY-MM-DD HH:mm:ss'),
        tv_webinar_id:        webinarId,
        event_type:           2, // Live配信閲覧中
        rd_ubid:              ubid,
        user_id:              currentUser?.userId,
        mail_subscriber_code: Cookies.get('__tr_mscd'),
        viewing_id:           null,
        playback_id:          null,
        playback_speed:       null,
        time_code:            null,
      });
      timeCodeBuffer = currentTimeCode;

    }

  };

  const onPlay = (event: PlayEvent) => {

    playbackID = UUIDUtil.uuid();
    timeCodeBuffer = Math.floor(event.seconds);

    const currentMoment = getCurrentMoment();
    const diff: any = currentMoment.diff(basedDateTime, 'hours');
    if (diff >= 12) {

      basedDateTime = getCurrentMoment();
      viewingID = UUIDUtil.uuid();

    }

    logAnalyticsEvent('onPlay', {
      event_datetime:       getCurrentMoment().format('YYYY-MM-DD HH:mm:ss'),
      tv_webinar_id:        webinarId,
      event_type:           3, // オンデマンド動画-再生開始
      rd_ubid:              ubid,
      user_id:              currentUser?.userId,
      mail_subscriber_code: Cookies.get('__tr_mscd'),
      viewing_id:           viewingID,
      playback_id:          playbackID,
      playback_speed:       0, // 0: 不明
      time_code:            Math.floor(event.seconds),
    });
    trackEvent('Play Webinar', {
      webinar_id:              webinarId,
      webinar_type:            'ondemand',
      webinar_movie_play_time: webinarMoviePlayTime,
    });
    watchOndemandTrackEvent(webinarId, currentUser.userId);

  };

  const onPause = (event: PauseEvent) => {

    logAnalyticsEvent('onPause', {
      event_datetime:       getCurrentMoment().format('YYYY-MM-DD HH:mm:ss'),
      tv_webinar_id:        webinarId,
      event_type:           4, // オンデマンド動画-動画再生一時停止
      rd_ubid:              ubid,
      user_id:              currentUser?.userId,
      mail_subscriber_code: Cookies.get('__tr_mscd'),
      viewing_id:           viewingID,
      playback_id:          playbackID,
      playback_speed:       0, // 0: 不明
      time_code:            Math.floor(event.seconds),
    });
    trackEvent('Pause Webinar', {
      webinar_id:              webinarId,
      webinar_type:            'ondemand',
      webinar_movie_play_time: webinarMoviePlayTime,
    });

  };

  let onTimeUpdateFlag: boolean = true;
  const onTimeUpdate = (event: TimeUpdateEvent) => {

    const currentTimeCode: number = Math.floor(event.seconds);

    if (onTimeUpdateFlag || (currentTimeCode > timeCodeBuffer && currentTimeCode - timeCodeBuffer >= 10)) {

      onTimeUpdateFlag = false;
      logAnalyticsEvent('onTimeUpdate', {
        event_datetime:       getCurrentMoment().format('YYYY-MM-DD HH:mm:ss'),
        tv_webinar_id:        webinarId,
        event_type:           5, // オンデマンド動画-再生中
        rd_ubid:              ubid,
        user_id:              currentUser?.userId,
        mail_subscriber_code: Cookies.get('__tr_mscd'),
        viewing_id:           viewingID,
        playback_id:          playbackID,
        playback_speed:       0, // 0: 不明
        time_code:            Math.floor(event.seconds),
      });
      timeCodeBuffer = currentTimeCode;

    }

    // player will always be initialized when it reaches this.
    onPlaybackTimeUpdate && onPlaybackTimeUpdate(event, player!);

  };

  const onProgress = (event: ProgressEvent) => {};

  const onSeeked = (event: SeekedEvent) => {

    timeCodeBuffer = Math.floor(event.seconds);

    const userAgentLowerCase = navigator.userAgent.toLowerCase();
    // For Safari on Mac OS only
    if (
      userAgentLowerCase.indexOf('mac os') > -1 &&
      userAgentLowerCase.indexOf('safari') > -1 &&
      userAgentLowerCase.indexOf('chrome') === -1 &&
      userAgentLowerCase.indexOf('iphone') === -1
    ) {

      // player will always be initialized when it reaches this.
      onPlayerSeeked && onPlayerSeeked(event, player!);

    }

  };

  const onEnd = (event: EndEvent) => {

    logAnalyticsEvent('onEnd', {
      event_datetime:       getCurrentMoment().format('YYYY-MM-DD HH:mm:ss'),
      tv_webinar_id:        webinarId,
      event_type:           7, // オンデマンド動画-動画再生終了
      rd_ubid:              ubid,
      user_id:              currentUser?.userId,
      mail_subscriber_code: Cookies.get('__tr_mscd'),
      viewing_id:           viewingID,
      playback_id:          playbackID,
      playback_speed:       0, // 0: 不明
      time_code:            Math.floor(event.seconds),
    });
    trackEvent('End Webinar', {
      webinar_id:              webinarId,
      webinar_type:            'ondemand',
      webinar_movie_play_time: webinarMoviePlayTime,
    });

  };

  const onError = (err: Object) => setError(true);

  const renderError = (videoImageAlt: string, videoImageUrl?: string) => (
    <div id="at-video-area" className="at-video-area-cover">
      <div id="video-container">
        <img
          src={videoImageUrl ?? `${getStaticFileDomain()}tv/images/video-cover_no_image.png`}
          alt={videoImageAlt}
        />
        <div className="video-error-message">
          <p className="video-error-message-title">エラーが発生しました。</p>
          ブラウザのリロード、または再起動を行ってください。
          <br />
          それでも表示されない場合は動画が準備中の可能性があります。
          <br />
          時間をおいてアクセスしてください。
        </div>
      </div>
    </div>
  );

  if (error) return renderError(disableVideoImageAlt, disableVideoImageUrl);

  const result = webinarOndemandFlag ? (
    <div id="video-container">
      <Vimeo
        video={movieUrl}
        onReady={onReady}
        onPlay={onPlay}
        onTimeUpdate={onTimeUpdate}
        onPause={onPause}
        onProgress={onProgress}
        onSeeked={onSeeked}
        onEnd={onEnd}
        onError={onError}
        onPlaybackRateChange={onPlaybackRateChange}
        speed
        start={startVideoTime}
      />
    </div>
  ) : (
    <>
      {videoShowFlag ? (
        <div id="video-container">
          <Vimeo
            video={movieUrl}
            onReady={onLiveReady}
            onTimeUpdate={onLiveTimeUpdate}
            onError={onError}
            start={startVideoTime}
          />
        </div>
      ) : (
        <div className="at-video-area-cover">
          <img
            src={disableVideoImageUrl ?? `${getStaticFileDomain()}tv/images/video-cover_no_image.png`}
            alt={disableVideoImageAlt}
          />
          <div className="video-message">この動画のライブ配信は終了しました。</div>
        </div>
      )}
    </>
  );
  return result;

}
