import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useEventApplicantQuery, UserType } from '../../generated/graphql';
import { timeIsPast } from '../../util/dateUtil';
import { AtBasicButton } from '../atoms/AtButton';
import EventApplyModal from './EventApplyModal';
import style from './EventApplyButton.module.scss';

type EventApplyButtonProps = {
  eventId: string;
  setShowCTAModal: React.Dispatch<React.SetStateAction<boolean>>;
  eventApplyFlag?: boolean;
  endDate?: string;
  buttonTextColor?: string;
  buttonBackgroundColor?: string;
  amplitudeTrackEvent?: (isLoggedIn: boolean) => void;
};

export const EventApplyButton: FC<EventApplyButtonProps> = ({
  eventId,
  setShowCTAModal,
  eventApplyFlag = false,
  endDate,
  buttonTextColor = '#ffffff',
  buttonBackgroundColor = '#60d000',
  amplitudeTrackEvent,
}) => {

  const { currentUser } = useAuth();
  const isMember = currentUser.userType === UserType.Member;
  const eventPast = timeIsPast(endDate);

  // TODO ボタンのレンダリングごとにクエリ実行されるので改修したい
  const { data } = useEventApplicantQuery({
    variables: {
      tvEventId: +eventId,
    },
    skip: !eventApplyFlag,
  });

  const [open, setOpen] = useState(false);
  const history = useHistory();

  if (eventApplyFlag) {

    if (!data) return null;

    if (isMember) {

      return (
        <AtBasicButton
          className={` ${style.button} ${eventPast && style.hideButton}`}
          onSubmit={() => {

            history.push(`/event/${eventId}/apply`);
            amplitudeTrackEvent?.(true);

          }}
          style={{
            backgroundColor: `${data?.eventApplicant ? '#C8C8C8' : buttonBackgroundColor}`,
            color:           `${buttonTextColor}`,
            pointerEvents:   `${data?.eventApplicant && 'none'}`,
          }}
        >
          {data?.eventApplicant ? 'イベント登録済み' : '参加登録はこちら（無料）'}
        </AtBasicButton>
      );

    }

    return (
      <>
        <AtBasicButton
          className={` ${style.button} ${eventPast && style.hideButton}`}
          onSubmit={() => {

            setOpen(true);
            amplitudeTrackEvent?.(false);

          }}
          style={{
            backgroundColor: `${buttonBackgroundColor}`,
            color:           `${buttonTextColor}`,
          }}
        >
          参加登録はこちら（無料）
        </AtBasicButton>
        <EventApplyModal
          eventId={eventId}
          show={open}
          onClose={() => {

            setOpen(false);

          }}
          title="参加登録するにはログインが必要です"
          loginTitle={<>アペルザIDへログインの上、先にお進みください。</>}
          signupTitle={
            <>
              アペルザIDへご登録がお済みでない方は
              <br />
              会員登録へお進みください。（登録無料）
            </>
          }
          signupButtonName="無料会員登録をして参加登録する"
        />
      </>
    );

  }

  return (
    <AtBasicButton
      className={` ${style.button} ${eventPast && style.hideButton}`}
      onSubmit={() => {

        setShowCTAModal(true);

      }}
      style={{
        backgroundColor: `${buttonBackgroundColor}`,
        color:           `${buttonTextColor}`,
      }}
    >
      {'参加登録はこちら（無料）'}
    </AtBasicButton>
  );

};
