import React, {
  Dispatch, SetStateAction, useState, VFC,
} from 'react';
import AtShowMoreButton from '../atoms/AtShowMoreButton';
import style from './AtShowMoreSection.module.scss';

interface AtShowMoreSectionProps {
  title: string;
  /**
   * FontAwesome icon (see test for example
   */
  titleIcon: string;
  children: React.ReactNode;
  onShowMore: (setLoading: Dispatch<SetStateAction<boolean>>) => void;
  hideShowMore?: boolean;
}

export const AtShowMoreSection: VFC<AtShowMoreSectionProps> = ({
  titleIcon,
  title,
  children,
  onShowMore,
  hideShowMore,
}: AtShowMoreSectionProps) => {

  const [loading, setLoading] = useState(false);
  const showMoreWrapper = () => {

    onShowMore(setLoading);

  };

  return (
    <>
      <div className={style.titleAndIconContainer}>
        <i className={`${style.titleIcon} ${titleIcon}`} />
        <h3 className={style.title}>{title}</h3>
      </div>
      <div>{children}</div>
      <AtShowMoreButton onClick={showMoreWrapper} hide={hideShowMore} loading={loading} />
    </>
  );

};

export default AtShowMoreSection;
