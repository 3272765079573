import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { AtBasicButton } from '../../atoms/AtButton';
import style from './ApplyCompleteExplanation.module.scss';

type ApplyCompleteExplanationProps = {
  isGuest: boolean;
  tvEventId: number;
};

export const ApplyCompleteExplanation: FC<ApplyCompleteExplanationProps> = ({ isGuest, tvEventId }) => {

  const history = useHistory();

  return (
    <div className={style.completePage}>
      <h1 className={style.title}>イベント登録完了</h1>
      <div className={style.flowContainer}>
        <h2 className={style.flowTitle}>イベントまでの今後の流れ</h2>
        <div className={style.flow}>
          <div className={style.flowDetail}>
            <div className={style.iconArea}>
              <i className={`fas fa-envelope fa-3x ${style.mail}`} />
            </div>
            <div className={style.infoArea}>
              <p>登録完了メールをお送りしています。マイページのURLを送っていますので確認してください。</p>
              {isGuest && (
                <p>
                  また、アペルザIDより会員登録用メールをご案内しています。メール内のURLをクリックして認証してください。
                </p>
              )}
            </div>
          </div>
          <div className={style.arrow}>
            <i className={`far fa-chevron-right fa-lg ${style.arrowIcon}`} />
          </div>
          <div className={style.flowDetail}>
            <div className={style.iconArea}>
              <i className={`fas fa-clipboard-list fa-3x ${style.board}`} />
              <i className={`far fa-check-circle fa-2x ${style.check}`} />
            </div>
            <div className={style.infoArea}>
              <p>
                タイムテーブルが決まり、視聴予約が出来るようになりましたらメールでご連絡します。
                イベントページより登録してください。
              </p>
            </div>
          </div>
          <div className={style.arrow}>
            <i className={`far fa-chevron-right fa-lg ${style.arrowIcon}`} />
          </div>
          <div className={style.flowDetail}>
            <div className={style.iconArea}>
              <i className={`fas fa-tv fa-3x ${style.tv}`} />
              <i className={`fas fa-user fa-2x ${style.user}`} />
            </div>
            <div className={style.infoArea}>
              <p>
                イベント前日と当日に視聴予約したウェビナーの視聴用URLをお送りします。イベントへの参加お待ちしています。
              </p>
            </div>
          </div>
        </div>
      </div>
      <AtBasicButton
        className={` ${style.backbutton} `}
        onSubmit={() => {

          history.push(`/event/${tvEventId}?reload=1`);

        }}
      >
        イベントページに戻る
      </AtBasicButton>
    </div>
  );

};
