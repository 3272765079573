import { useParams } from 'react-router-dom';
import { AtPagingRouteParams } from '../../App';
import TitleAndMeta from '../atoms/TitleAndMeta';
import AtFeatureIndexFrame from '../organisms/AtFeatureIndexFrame';
import './AtFeatureIndex.scss';

export const AtFeatureIndex = () => {

  const { pageNo, tabName } = useParams<AtPagingRouteParams>();

  const getTitle = () => {

    const pageTitlePrefix = pageNo && pageNo !== '1' ? `${pageNo}ページ目:` : '';
    switch (tabName) {
      case 'event':
        return `${pageTitlePrefix}展示会・イベント動画一覧 | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`;
      case 'feature':
        return `${pageTitlePrefix}特集動画一覧 | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`;
      default:
        return `${pageTitlePrefix}イベント一覧 | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`;
    }

  };

  const getDescription = () => {

    switch (tabName) {
      case 'event':
        return 'Apérza TV（アペルザTV、アペルザテレビ）は、ものづくり産業向けに特化した動画サイトです。最新の製品や技術、トレンドやノウハウなど、ものづくりや研究開発に携わる技術者の役に立つ動画コンテンツをいつでも無料で視聴できます。';
      case 'feature':
        return 'Apérza TV（アペルザTV、アペルザテレビ）は、ものづくり産業向けに特化した動画サイトです。最新の製品や技術、トレンドやノウハウなど、ものづくりや研究開発に携わる技術者の役に立つ動画コンテンツをいつでも無料で視聴できます。';
      default:
        return 'Apérza TV（アペルザTV、アペルザテレビ）では、ものづくりの最新トレンドや基礎知識、最新事例などをまとめてご覧いただけます。展示会やイベントでの動画は本ページよりご覧ください。';
    }

  };

  return (
    <div className="at-feature-index">
      <TitleAndMeta title={getTitle()} description={getDescription()} />
      <AtFeatureIndexFrame />
    </div>
  );

};

export default AtFeatureIndex;
