import { setTabField, Tabs, TabType } from '@aperza/ac-contents-ui';
import { useEffect, useState, VFC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSearchTvWebinarsQuery } from '../../generated/graphql';
import { keywordTooManyWords } from '../../util/searchUtil';
import { AtSearchNoResults } from '../atoms/AtSearchNoResults';
import TitleAndMeta from '../atoms/TitleAndMeta';
import { AtSearchSections } from '../organisms/AtSearchSections';
import { AtHeaderAndFooter } from '../templates/AtHeaderAndFooter';
import style from './AtSearch.module.scss';
import { AtSearchResultList } from './AtSearchResultList';

export const tabsInfo = {
  all: {
    id:    'all',
    label: 'すべて',
    url:   '' as const,
  },
  upcomingEvent: {
    id:    'upcomingevent',
    label: '開催予定のイベント',
    url:   'upcomingevent' as const,
  },
  webinar: {
    id:    'webinar',
    label: '動画',
    url:   'webinar' as const,
  },
  archiveEvent: {
    id:    'archiveevent',
    label: '過去のイベント',
    url:   'archiveevent' as const,
  },
  feature: {
    id:    'feature',
    label: '特集',
    url:   'feature' as const,
  },
};

export type searchTypes = { searchType?: typeof tabsInfo[keyof typeof tabsInfo]['url'] };

export const AtSearch: VFC = () => {

  const history = useHistory();
  const queryParam = new URLSearchParams(window.location.search); // window.location is updated earlier than react router's useLocation
  const keyword = queryParam.get('k') || '';
  let skipQuery = false;

  const { searchType } = useParams<searchTypes>();

  if (!keyword.trim() || keywordTooManyWords(keyword) || keyword.length > 100) {

    skipQuery = true;

  }

  const {
    data, loading, error, refetch,
  } = useSearchTvWebinarsQuery({
    variables: {
      keyword,
      page:             1,
      size:             5,
      withTvEventsFlag: true,
    },
    skip: skipQuery,
  });

  const onClickTab = (tabId: typeof tabsInfo[keyof typeof tabsInfo]['id']) => {

    const encodedSearchWord = encodeURIComponent(new URLSearchParams(window.location.search).get('k') || '');

    if (tabId === tabsInfo.all.id) {

      history.push(`/search?k=${encodedSearchWord}`);

    }

    if (tabId === tabsInfo.upcomingEvent.id) {

      history.push(`/search/${tabsInfo.upcomingEvent.url}?k=${encodedSearchWord}`);

    }

    if (tabId === tabsInfo.webinar.id) {

      history.push(`/search/${tabsInfo.webinar.url}?k=${encodedSearchWord}`);

    }

    if (tabId === tabsInfo.archiveEvent.id) {

      history.push(`/search/${tabsInfo.archiveEvent.url}?k=${encodedSearchWord}`);

    }

    if (tabId === tabsInfo.feature.id) {

      history.push(`/search/${tabsInfo.feature.url}?k=${encodedSearchWord}`);

    }

  };
  const [tabsState, setTabsState] = useState<TabType[]>([
    {
      label:   tabsInfo.all.label,
      id:      tabsInfo.all.id,
      onClick: () => onClickTab(tabsInfo.all.id),
    },
    {
      label:   tabsInfo.upcomingEvent.label,
      id:      tabsInfo.upcomingEvent.id,
      onClick: () => onClickTab(tabsInfo.upcomingEvent.id),
    },
    {
      label:   tabsInfo.webinar.label,
      id:      tabsInfo.webinar.id,
      onClick: () => onClickTab(tabsInfo.webinar.id),
    },
    {
      label:   tabsInfo.archiveEvent.label,
      id:      tabsInfo.archiveEvent.id,
      onClick: () => onClickTab(tabsInfo.archiveEvent.id),
    },
    {
      label:   tabsInfo.feature.label,
      id:      tabsInfo.feature.id,
      onClick: () => onClickTab(tabsInfo.feature.id),
    },
  ]);

  const upcomingEventTotal = data?.searchTvWebinars.futureTvEventPages?.length ?? 0;
  const webinarTotal = data?.searchTvWebinars.totalElements ?? 0;
  const archiveEventTotal = data?.searchTvWebinars.oldTvEventPages?.length ?? 0;
  const featureTotal = data?.searchTvWebinars.tvFeaturePages?.length ?? 0;
  const total = upcomingEventTotal + webinarTotal + archiveEventTotal + featureTotal;

  // set active tab based on URL
  useEffect(() => {

    setTabsState(
      (prevTabsState) => prevTabsState.map((tab) => ({ ...tab, active: false })), // reset active on all tabs
    );
    setTabField(searchType || tabsInfo.all.id, 'active', true, setTabsState); // This isn't exactly right.  Id is what is checked later, but this is setting the URL.  So, the URL and id must match.

  }, [searchType]);

  useEffect(() => {

    setTabField(tabsInfo.all.id, 'label', `${tabsInfo.all.label} (${total})`, setTabsState);
    setTabField(
      tabsInfo.upcomingEvent.id,
      'label',
      `${tabsInfo.upcomingEvent.label} (${upcomingEventTotal})`,
      setTabsState,
    );
    setTabField(tabsInfo.webinar.id, 'label', `${tabsInfo.webinar.label} (${webinarTotal})`, setTabsState);
    setTabField(
      tabsInfo.archiveEvent.id,
      'label',
      `${tabsInfo.archiveEvent.label} (${archiveEventTotal})`,
      setTabsState,
    );
    setTabField(tabsInfo.feature.id, 'label', `${tabsInfo.feature.label} (${featureTotal})`, setTabsState);
    window.scrollTo(0, 0);

  }, [data]);

  const renderResults = () => {

    if (skipQuery) {

      return <AtSearchNoResults />;

    }

    if (searchType) {

      return <AtSearchResultList listType={searchType} />;

    }
    return (
      <AtSearchSections
        refetch={refetch}
        data={data}
        loading={loading}
        error={error}
        onClickTab={onClickTab}
      />
    );

  };

  return (
    <>
      <TitleAndMeta
        title={`"${keyword}" 検索結果 | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`}
        noIndex
      />
      <AtHeaderAndFooter showSidebar hideFooter>
        {webinarTotal > 0 && (
          <>
            <div className={` pl-3 `}>
              <Tabs
                tabsState={tabsState}
                setTabsState={setTabsState}
                activeClassName={style.active}
                notActiveClassName={style.notActive}
              />
            </div>
            <div className={` ${style.tabsBoxShadow} `} />
          </>
        )}
        <div className={`${style.pageContainer}`}>{renderResults()}</div>
      </AtHeaderAndFooter>
    </>
  );

};
