import { useIsMobile } from '../templates/MobileReady';
import { dividePartJstDate } from '../../util/dateUtil';
import { AtMovieListVertical } from '../molecules/AtMovieListVertical';
import { TvEventPageQuery } from '../../generated/graphql';
import './AtEventScheduleMovies.scss';
import style from './AtEventScheduleMovies.module.scss';

interface AtEventScheduleMoviesProps {
  eventSchedule: N<N<N<TvEventPageQuery['tvEventPage']>['tvEventSchedules']>['content']>[number];
  eventType?: string;
}

export const AtEventScheduleMovies = ({ eventSchedule, eventType }: AtEventScheduleMoviesProps) => {

  const movies = eventSchedule.content;

  const isMobile = useIsMobile();

  const movieList: React.ReactNode[] = [];
  movies?.forEach((movie, index) => {

    movieList.push(<AtMovieListVertical key={index} movie={movie} eventType={eventType} />);

  });

  const {
    jstMonth: month, jstDay: day, jstWeekDay: weekDay, jstTime: startTime,
  } = dividePartJstDate(
    `${eventSchedule.tvEventScheduleDate}T${eventSchedule.tvEventScheduleStartTime}`,
  );

  const { jstTime: endTime } = dividePartJstDate(
    `${eventSchedule.tvEventScheduleDate}T${eventSchedule.tvEventScheduleEndTime}`,
  );

  const slash = eventSchedule.tvEventScheduleDate ? '/' : '';
  let timeTermValue = <></>;
  if (eventSchedule.tvEventScheduleStartTime && eventSchedule.tvEventScheduleEndTime) {

    timeTermValue = (
      <div className="at-event-schedule-movies-time-frame">
        {startTime}
        <br />～{endTime}
      </div>
    );

  }

  let movieListClassName = 'at-movies-area';
  if (eventType === 'FEATURE') {

    movieListClassName += ' at-movies-area-feature';

  }

  if (isMobile) {

    let timeString = '';

    if (eventSchedule.tvEventScheduleStartTime && eventSchedule.tvEventScheduleEndTime) {

      timeString = `${startTime}～ ${endTime}`;

    }

    return (
      <div className={` ${style.container} `}>
        {/* date part */}
        {eventType !== 'FEATURE' && (
          <div className={` ${style.time} `}>{`${month}/${day}（${weekDay}）　${timeString}`}</div>
        )}
        {/* -date part */}
        {/* content part */}
        <div
          className={`${style.at_movies_area} ${
            /*
            eventType === 'FEATURE' ? style.at_movies_area_feature ://implement when feature page is added */ ''
          } `}
        >
          {movieList}
        </div>
        {/* -content part */}
      </div>
    );

  }

  return (
    <div className="at-event-schedule-movies">
      {eventType !== 'FEATURE' && (
        <div className="d-flex flex-column align-items-start at-event-schedule-movies-datetime-frame">
          <div>
            <div className="at-event-schedule-movies-date-frame">
              <div className="at-event-schedule-movies-month">
                {month}
                {slash}
              </div>
              <div className="at-event-schedule-movies-date">
                <div className="at-event-schedule-movies-weekday">{weekDay}</div>
                <div className="at-event-schedule-movies-day">{day}</div>
              </div>
            </div>
            {timeTermValue}
          </div>
        </div>
      )}
      <div className={movieListClassName}>{movieList}</div>
    </div>
  );

};
