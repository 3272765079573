import style from './AtExternalLink.module.scss';

export interface AtExternalLinkProps {
  name: string;
  href: string;

  bold?: boolean;
  fontSize?: `${number}px` | `${number}em` | `${number}rem` | `${number}%`;
  iconSize?: 'xs' | '2x' | '1x';
}

export const AtExternalLink = ({
  name, href, fontSize, bold, iconSize,
}: AtExternalLinkProps) => (
  <div className={`${style.linkContainer} ${bold ? 'font-weight-bold' : ''}`}>
    <a
      href={href}
      // eslint-disable-next-line react/jsx-no-target-blank
      target="_blank"
      rel="noopener"
      style={{ fontSize }}
    >{name}
    </a>
    <i data-testid="icon-external-link" className={`ml-1 fa fa-external-link ${iconSize ? `fa-${iconSize}` : 'fa-xs'}`} aria-hidden="true" />
  </div>
);

export default AtExternalLink;
