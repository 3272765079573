import { VFC } from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { AtFormSubmit } from '../molecules/AtFormSubmit';
import style from './AtBizCardFormContainer.module.scss';
import { FormSection } from '../molecules/AtForm';
import { TvWebinarDocumentType, useRegisterTvWebinarQuestionnaireMutation } from '../../generated/graphql';
import useClickDownloadTrackEvent from '../../hooks/tracking/useClickDownloadTrackEvent';

export interface AtBizCardConfirmContainerProps {
  tvWebinarId: number;
  documentType: TvWebinarDocumentType;
  formSections: FormSection[];
}

export const AtBizCardConfirmContainer: VFC<AtBizCardConfirmContainerProps> = (props) => {

  const [
    registerTvWebinarQuestionnaireMutation,
    { data, loading, error },
  ] = useRegisterTvWebinarQuestionnaireMutation();

  const {
    clickBizcardDownloadOnWebinarTrackEvent,
    clickDocumentDownloadOnWebinarTrackEvent,
  } = useClickDownloadTrackEvent();

  const onSuccessSubmit: SubmitHandler<FieldValues> = async (_data) => {

    window.scrollTo(0, 0);
    const answers: { formItemId: number; formItemAnswerId: number; answerText?: string }[] = [];
    if (Array.isArray(_data.formItems)) {

      (Object.keys(_data.formItems) as Array<string>).forEach((key) => {

        const index = props.formSections.findIndex(
          (checkFormItem) => checkFormItem.id === `formItems.${key}`,
        );
        if (index < 0) {

          return;

        }
        const formItem = props.formSections[index];

        const formItemAnswers = _data.formItems[key];
        if (Array.isArray(formItemAnswers)) {

          if (formItem.type === 'checkbox') {

            formItemAnswers.forEach((formItemAnswer) => {

              let answerText;
              if (+formItemAnswer === 2) {

                answerText = _data.formItems[`${key}-other`];

              }
              answers.push({
                formItemId:       +key,
                formItemAnswerId: +formItemAnswer,
                answerText,
              });

            });

          }

        } else {

          switch (formItem.type) {
            case 'text':
              if (formItemAnswers) {

                answers.push({
                  formItemId:       +key,
                  formItemAnswerId: 3,
                  answerText:       formItemAnswers,
                });

              } else {

                answers.push({
                  formItemId:       +key,
                  formItemAnswerId: 0,
                  answerText:       undefined,
                });

              }
              break;
            case 'textarea':
              if (formItemAnswers) {

                answers.push({
                  formItemId:       +key,
                  formItemAnswerId: 4,
                  answerText:       formItemAnswers,
                });

              } else {

                answers.push({
                  formItemId:       +key,
                  formItemAnswerId: 0,
                  answerText:       undefined,
                });

              }
              break;
            case 'radio':
            case 'select':
              if (formItemAnswers) {

                let answerText;
                if (+formItemAnswers === 2) {

                  answerText = _data.formItems[`${key}-other`];

                }
                answers.push({
                  formItemId:       +key,
                  formItemAnswerId: +formItemAnswers,
                  answerText,
                });

              } else {

                answers.push({
                  formItemId:       +key,
                  formItemAnswerId: 0,
                  answerText:       undefined,
                });

              }
              break;
            default:
          }

        }

      });

    }

    const result = await registerTvWebinarQuestionnaireMutation({
      variables: {
        tvWebinarQuestionnaireAnswer: {
          tvWebinarId:     props.tvWebinarId,
          documentType:    props.documentType,
          answers,
          lastName:        _data.name.lastName,
          firstName:       _data.name.firstName,
          companyName:     _data.companyName,
          officeName:      _data.officeName,
          departmentName:  _data.departmentName,
          tel:             _data.tel,
          businessType:    _data.businessType,
          jobCategoryType: _data.jobCategoryType,
          postClass:       _data.postClass,
          postName:        _data.postName,
          subdivisionCode: _data.subdivisionCode,
        },
      },
    });
    if (result && result.data && result.data?.registerTvWebinarQuestionnaire) {

      switch (props.documentType) {
        case TvWebinarDocumentType.AperzaTvBusinesscard:
          clickBizcardDownloadOnWebinarTrackEvent(props.tvWebinarId);
          break;
        case TvWebinarDocumentType.AperzaTvProjectionDocument:
          clickDocumentDownloadOnWebinarTrackEvent(props.tvWebinarId);
          break;
        default:
          break;
      }

      window.location.assign(result.data?.registerTvWebinarQuestionnaire);

    }

  };

  return (
    <div className={` ${style.bizCardFormContainer} `}>
      <div className={` ${style.titleSection} font-weight-bold`}>
        以下の内容でよろしければ「ダウンロード」へお進みください。
      </div>
      <div className={` ${style.mainSection} `}>
        <AtFormSubmit
          formSections={props.formSections}
          onCompleteSubmit={onSuccessSubmit}
          submitButtonValue={'ダウンロード'}
        />
      </div>
    </div>
  );

};
