import { VFC, Children } from 'react';
import style from './AtSearchSection.module.scss';
import { AtSearchSectionTitle } from './AtSearchSectionTitle';
import { AtSearchTabButton } from '../atoms/AtButton';

export interface AtSearchSectionProps {
  icon: string;
  title: string;
  tabId: string;
  totalCount: number;
  displayCount: number;
  onClickLink: (tabId: string) => void;
  children?: React.ReactNode;
}

export const AtSearchSection: VFC<AtSearchSectionProps> = (props) => {

  const {
    icon, title, tabId, totalCount, displayCount, onClickLink, children,
  } = props;

  if (Children.count(children) === 0) {

    return null;

  }

  return (
    <div className={` ${style.sectionWrapper} `}>
      <AtSearchSectionTitle icon={icon} title={title} />
      <div className={` ${style.itemWrapper} `}>{children}</div>
      {displayCount < totalCount && (
        <div className={` ${style.searchTabButton} `}>
          <AtSearchTabButton onClick={() => onClickLink(tabId)}>
            {`${totalCount}件の${title}をすべて表示`}
          </AtSearchTabButton>
        </div>
      )}
    </div>
  );

};
