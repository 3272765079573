import './AtLabel.scss';

export interface AtLabelProps {
  labelKind?: string;
  className?: string;
}

export const AtLabel = ({ labelKind, className }: AtLabelProps) => {

  let value = <></>;
  switch (labelKind) {
    case 'EVENT_LP':
    case 'EVENT':
      value = <p className={`at-label-normal-event at-exhibition-label ${className}`}>イベント</p>;
      break;
    case 'EXHIBITION':
      value = <p className={`at-label-normal-exhibition at-exhibition-label ${className}`}>展示会</p>;
      break;
    case 'FEATURE':
      value = <p className={`at-label-normal-topic at-exhibition-label ${className}`}>特集</p>;
      break;
    case 'ONDEMAND':
      value = <p className={`at-label-normal-ondemand at-exhibition-label ${className}`}>オンデマンド</p>;
      break;
    default:
      break;
  }

  return value;

};

export const AtLabelLarge = ({ labelKind }: AtLabelProps) => {

  let value = <></>;
  switch (labelKind) {
    case 'EVENT':
    case 'EVENT_LP':
      value = <p className="at-label-large-event at-exhibition-large-label">イベント</p>;
      break;
    case 'EXHIBITION':
      value = <p className="at-label-large-exhibition at-exhibition-large-label">展示会</p>;
      break;
    case 'FEATURE':
      value = <p className="at-label-large-topic at-exhibition-large-label">特集</p>;
      break;
    case 'ONDEMAND':
      value = <p className="at-label-large-ondemand at-exhibition-large-label">オンデマンド</p>;
      break;
    default:
      break;
  }

  return value;

};
