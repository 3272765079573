import {
  track, setUserId, Identify, identify,
} from '@amplitude/analytics-browser';
import { EnvUtil } from '@aperza/util';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { User } from '../generated/graphql';
import { EventPageAccessTiming, WebinarStreamingAccessTiming } from './amplitude/eventProperty';
import { getCurrentMoment, getMoment } from './dateUtil';
import { timeStringToSeconds } from './videoTimeUtil';

dayjs.extend(isSameOrAfter);

export const isTrackingTarget = (user?: User): boolean => {

  // NOTES: https://aperza.atlassian.net/browse/AB-19837
  const hasNonTrackingBrowser = (userAgent: string): boolean => {

    const lowerCaseUserAgent = userAgent.toLowerCase();
    const extractChromeVersion = lowerCaseUserAgent.match(/(chrome\/([0-9.]+))/);
    if (!extractChromeVersion) return false;

    const excludeChromeVersions = ['88.0.4298.0'];
    // https://docs.microsoft.com/ja-jp/microsoft-edge/web-platform/user-agent-guidance
    if (!/edge|edg/.test(lowerCaseUserAgent)) {

      return !!excludeChromeVersions.find((version) => extractChromeVersion[2] === version);

    }

    return false;

  };

  // NOTES: https://aperza.atlassian.net/browse/AB-19779
  const hasNonTrackingMailaddress = (_user?: User): boolean =>
    !!(_user?.mailAddress?.startsWith('e2e-') || _user?.mailAddress?.endsWith('@aperza.biz'));

  // FIXME: https://aperza.atlassian.net/browse/AB-19840
  // Prod以外は無条件でトラッキング対象とする。
  if (!EnvUtil.isProd) return true;

  if (hasNonTrackingBrowser(navigator.userAgent)) return false;
  if (hasNonTrackingMailaddress(user)) return false;

  return true;

};

export const identifyUser = (user: User) => {

  if (!isTrackingTarget(user)) return;

  const userId = user.userId?.toString();
  setUserId(userId);

};

export type SearchStringObject = Record<string, string[]> & Object;

export const toSearchStringObject = (href: URL): SearchStringObject | undefined => {

  const { searchParams } = href;
  const searchStringObj: SearchStringObject = {};

  searchParams.forEach((value, key) => {

    if (!value) return;

    if (key in searchStringObj) {

      const currentValue = searchStringObj[key];
      searchStringObj[key] = [...currentValue, value];

    } else {

      searchStringObj[key] = [value];

    }

  });

  return Object.keys(searchStringObj).length === 0 ? undefined : searchStringObj;

};

const toOriginPathname = (referer?: URL): string | undefined => {

  const refererOrigin = referer?.origin || '';
  const refererPathname = referer?.pathname || '';

  return refererOrigin + refererPathname || undefined;

};

export const trackPageView = (user?: User) => {

  if (!isTrackingTarget(user)) return;

  const {
    href, pathname, search, hash,
  } = window?.location;

  const urlHref = new URL(href);

  const { referrer } = window?.document;
  const referer = referrer ? new URL(referrer) : undefined;

  track('Page View', {
    href,
    pathname,
    search:              decodeURIComponent(search) || undefined,
    hash:                decodeURIComponent(hash) || undefined,
    referer:             referer?.href,
    referer_origin:      referer?.origin,
    referer_origin_path: toOriginPathname(referer),
    search_splited:      toSearchStringObject(urlHref),
  });

};

/**
 * どの時点から視聴開始したのかを、本編動画の開始時刻と動画の長さを使って割り出す。
 */
export const judgeWebinarStreamingAccessTiming = (
  webinarStreamingStartDatetime?: string,
  webinarMoviePlayTime?: string,
): WebinarStreamingAccessTiming => {

  if (!(webinarStreamingStartDatetime && webinarMoviePlayTime)) return 'unknown';

  // NOTES: moment.js などでExceptionが発生してしまっても他の処理が止まらないように
  try {

    const movieSecondsLong = timeStringToSeconds(webinarMoviePlayTime);
    const current = getCurrentMoment();
    const startAt = getMoment(webinarStreamingStartDatetime);
    const mainMovieEndAt = getMoment(webinarStreamingStartDatetime).add(movieSecondsLong, 'seconds');

    if (current.isBefore(startAt)) return 'before';

    // timeStringToSeconds() の default の返り値が 0 のため
    if (movieSecondsLong === 0) return 'unknown';

    if (current.isSameOrAfter(startAt) && current.isBefore(mainMovieEndAt)) return 'on stream';

    if (current.isSameOrAfter(mainMovieEndAt)) return 'after';

    return 'unknown';

  } catch (err) {

    return 'unknown';

  }

};

/**
 * どの時点でイベントページにアクセスしたのかを、指定された時刻をもとにして割り出す。
 */
export const judgeEventPageAccessTiming = (
  currentDatetime: Dayjs,
  tvEventStartDatetimeString?: string,
  tvEventEndDatetimeString?: string,
): EventPageAccessTiming => {

  if (!(tvEventStartDatetimeString && tvEventEndDatetimeString)) return 'unknown';

  // NOTES: moment.js などでExceptionが発生してしまっても他の処理が止まらないように
  try {

    const startAt = getMoment(tvEventStartDatetimeString);
    const endAt = getMoment(tvEventEndDatetimeString);

    if (currentDatetime.isBefore(startAt)) return 'before';

    if (currentDatetime.isSameOrAfter(startAt) && currentDatetime.isBefore(endAt)) return 'on time';

    if (currentDatetime.isSameOrAfter(endAt)) return 'after';

    return 'unknown';

  } catch (error) {

    return 'unknown';

  }

};
