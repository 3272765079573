import { getTvDomain } from '../../util/envUtil';
import TitleAndMeta from '../atoms/TitleAndMeta';
import { AtEventIndexSlider } from '../organisms/AtEventIndexSlider';
import AtEventScheduleFrame from '../organisms/AtEventScheduleFrame';
import AtMovieListDetailFrame from '../organisms/AtMovieListDetailFrame';
import AtMovieScheduleFrame from '../organisms/AtMovieScheduleFrame';
import AtRankingFrame from '../organisms/AtRankingFrame';
import './AtHome.scss';

export const AtHome = () => (
  <div className="at-home">
    <TitleAndMeta
      title="Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト"
      canonicalHref={`${getTvDomain().slice(0, -1)}`}
      description="Apérza TV（アペルザTV、アペルザテレビ）では、ものづくりの最新トレンドや基礎知識、最新事例などを対談形式の動画で分かりやすくお届けしています。直近で開催予定のイベントは本ページよりご確認ください。"
    />

    <AtEventIndexSlider />
    <AtMovieScheduleFrame />
    <AtEventScheduleFrame displayButton />
    <AtMovieListDetailFrame />
    <AtRankingFrame />
  </div>
);

export default AtHome;
