import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isDaysOfBeforeTheLiveStreaming } from '../util/dateUtil';

export const useTimetableModal = (webinarId: number, webinarStreamingStartDateTime?: string) => {

  const [showTimetableModal, setShowTimetableModal] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();

  const openWebinar = useMemo(() => new URLSearchParams(location.search).get('webinar'), [location]);

  const openEventTimetableModal = useCallback(() => {

    if (isDaysOfBeforeTheLiveStreaming(webinarStreamingStartDateTime)) {

      setShowTimetableModal(true);

      const queryParams = new URLSearchParams(location.search);

      queryParams.append('webinar', `${webinarId}`);

      history.replace({
        search: queryParams.toString(),
      });

    } else {

      window.open(`/watch/${webinarId}`, '_blank')?.focus();

    }

  }, [history, location.search, webinarId, webinarStreamingStartDateTime]);

  const closeEventTimetableModal = useCallback(() => {

    setShowTimetableModal(false);

    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('webinar')) {

      queryParams.delete('webinar');

      history.replace({
        search: queryParams.toString(),
      });

    }

  }, [history, location.search]);

  useEffect(() => {

    if (openWebinar != null && Number(openWebinar) === webinarId) {

      if (isDaysOfBeforeTheLiveStreaming(webinarStreamingStartDateTime)) {

        setShowTimetableModal(true);

      } else {

        history.replace(`/watch/${webinarId}`);

      }

    }

  }, [history, openWebinar, webinarId, webinarStreamingStartDateTime]);

  return {
    showTimetableModal,
    setShowTimetableModal,
    openEventTimetableModal,
    closeEventTimetableModal,
  };

};
