import { gql, useMutation } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { CompanyContract, TvWebinarOndemandFlag, UserType } from '../../generated/graphql';
import { clickCard } from '../../util/clickUtil';
import { dividePartDate, timeIsFuture, timeIsPast } from '../../util/dateUtil';
import { getOmittionSentence } from '../../util/displayUtil';
import { AtButtonDeleteForWebinarList } from '../atoms/AtButtonDeleteForWebinarList';
import { AtButtonInsertForWebinarList } from '../atoms/AtButtonInsertForWebinarList';
import { AtAuthModalForWatchLater } from '../molecules/AtAuthModalForWatchLater';
import { AtRecommendInfo } from '../molecules/AtRecommendInfo';
import { AtPlayIcon } from '../atoms/AtPlayIcon';
import style from './AtMovieListForWebinars.module.scss';
import useTvWatchLaterTrackEvent from '../../hooks/tracking/useTvWatchLaterTrackEvent';

const addWatchMutation = gql`
  mutation createTvWebinarWatchLater($tvWebinarIds: [BigInteger!]!) {
    createTvWebinarWatchLater(tvWebinarIds: $tvWebinarIds) {
      tvWatchLaterResults {
        tvWebinarId
        tvWatchLaterId
      }
      tvWatchLaterRegistrationStatus
    }
  }
`;

const cancelWatchMutation = gql`
  mutation updateTvWatchLater($tvWatchLaterIds: [BigInteger!]!) {
    updateTvWatchLater(tvWatchLaterIds: $tvWatchLaterIds) {
      tvWebinarId
      tvWatchLaterId
    }
  }
`;

interface AtMovieListForWebinarsProps {
  webinarId: number;
  thumbnailImage?: string;
  companyContract?: CompanyContract;
  title?: string;
  subtitle?: string;
  speakerImage?: string;
  speakerPost?: string;
  speakerName?: string;
  ondemandFlag?: TvWebinarOndemandFlag;
  streamingStartDatetime?: string;
  streamingEndDatetime?: string;
  doesWatchLater: boolean;
  watchLaterId?: number;
  recommendTexts: Array<string | undefined>;
  displayCtaButton?: boolean;
}

export const AtMovieListForWebinars = ({
  webinarId,
  thumbnailImage,
  companyContract,
  title = '',
  subtitle = '',
  speakerImage,
  speakerPost = '',
  speakerName,
  ondemandFlag,
  streamingStartDatetime,
  streamingEndDatetime,
  doesWatchLater,
  watchLaterId,
  recommendTexts,
  displayCtaButton = true,
}: AtMovieListForWebinarsProps) => {

  const [watchLaterStatus, setWatchLaterStatus] = useState(doesWatchLater);
  const [currentTvWatchLaterId, setCurrentTvWatchLaterId] = useState(watchLaterId);
  const { currentUser } = useAuth();
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const webinarLinkRef = useRef<HTMLAnchorElement>(null);
  const { tvWatchLaterTrackEvent } = useTvWatchLaterTrackEvent();

  const [AddWebinar, { loading: AddWebinarLoading }] = useMutation(addWatchMutation, {
    variables: {
      tvWebinarIds: [webinarId],
    },
    onCompleted: (data) => {

      setWatchLaterStatus(true);
      setCurrentTvWatchLaterId(data.createTvWebinarWatchLater.tvWatchLaterResults[0].tvWatchLaterId);
      tvWatchLaterTrackEvent(
        data.createTvWebinarWatchLater.tvWatchLaterResults.map((result: any) => result?.tvWebinarId),
      );

    },
  });

  const [CancelWebinar, { loading: CancelWebinarLoading }] = useMutation(cancelWatchMutation, {
    variables: {
      tvWatchLaterIds: [currentTvWatchLaterId],
    },
    onCompleted: (data) => {

      setWatchLaterStatus(false);

    },
  });

  const loading = AddWebinarLoading || CancelWebinarLoading;

  const openAuthModalForWatchLater = () => {

    setShowAuthModal(true);

  };

  const getWatchLaterButton = () => {

    if (watchLaterStatus) {

      return [
        <AtButtonDeleteForWebinarList
          key={webinarId}
          label={'視聴リストから削除'}
          iconStyle={'fas fa-times'}
          onClickFunction={CancelWebinar}
          loading={loading}
        />,
      ];

    }
    return [
      <AtButtonInsertForWebinarList
        key={webinarId}
        label={'視聴リストに追加'}
        iconStyle={'fas fa-plus'}
        onClickFunction={currentUser.userType === UserType.Member ? AddWebinar : openAuthModalForWatchLater}
        loading={loading}
      />,
    ];

  };

  let buttonValue: any = getWatchLaterButton();
  useEffect(() => {

    buttonValue = getWatchLaterButton();

  }, [watchLaterStatus]);

  let value = <></>;
  let movieStatus: string = '';

  if (timeIsFuture(streamingStartDatetime)) {

    movieStatus = 'opentime';
    value = <div className={`${style.movieLabel} at-label-normal-will-stream`}>配信予定</div>;

  } else if (timeIsPast(streamingStartDatetime) && timeIsFuture(streamingEndDatetime)) {

    movieStatus = 'ontime';
    value = <div className={`${style.movieLabel} at-label-normal-in-stream`}>配信中</div>;

  } else if (timeIsPast(streamingEndDatetime) && ondemandFlag === TvWebinarOndemandFlag.Ondemand) {

    movieStatus = 'ondemand';
    value = <div className={`${style.movieLabel} at-label-normal-ondemand`}>オンデマンド</div>;

  } else if (timeIsPast(streamingEndDatetime) && ondemandFlag !== TvWebinarOndemandFlag.Ondemand) {

    movieStatus = 'closed';
    value = <div className={`${style.movieLabel} at-label-normal-close-stream`}>公開終了</div>;

  }

  const companyName = companyContract?.companyLang?.companyNameFull ?? '';
  const editSpeakerPost = getOmittionSentence(speakerPost, 20);

  const {
    jstYear: startYear,
    jstMonth: startMonth,
    jstDay: startDay,
    jstWeekDay: startWeekly,
    jstTime: startTime,
  } = dividePartDate(streamingStartDatetime);
  const { jstTime: endTime } = dividePartDate(streamingEndDatetime);

  const streamingTerm =
    streamingStartDatetime && streamingEndDatetime
      ? `${startMonth}/${startDay} (${startWeekly}) ${startTime}〜${endTime}`
      : '';

  const filteredRecommendTexts = recommendTexts.filter(
    (recommendText): recommendText is string => typeof recommendText === 'string' && recommendText !== '',
  );

  return (
    <>
      <div className={`${style.atMovieListForWebinars}`} onClick={() => clickCard(webinarLinkRef)}>
        <div className={style.atMainContentArea}>
          <div className={`${style.thumbnailArea}`}>
            {thumbnailImage && <img src={thumbnailImage} alt="" />}
            {(movieStatus === 'ondemand' || movieStatus === 'ontime') && <AtPlayIcon />}
            {value}
          </div>
          <div className={`${style.textArea}`}>
            <div className={`${style.movieDetailArea}`}>
              <div className={`${style.movieTitleArea}`}>
                <a
                  className={`${style.movieTitle}`}
                  href={`/watch/${webinarId}`}
                  ref={webinarLinkRef}
                  target="_blank"
                  rel="noopener"
                >
                  {title}
                </a>
                <p className={`${style.movieSubtitle}`}>{subtitle}</p>
              </div>
              <i className={`fas fa-chevron-right ${style.chevronRight}`} />
            </div>
            <div className={`${style.movieCompanyOtherArea}`}>
              <div className={`${style.companyArea}`}>
                <p className={`${style.companyName}`}>{companyName}</p>
                <div className={`${style.speakerArea}`}>
                  {speakerImage && <img className={`${style.speakerImage}`} src={speakerImage} alt="" />}
                  <div className={`${style.speakerText}`}>
                    <p className={`${style.speakerPost}`}>{editSpeakerPost}</p>
                    <p className={`${style.speakerName}`}>{speakerName ? `${speakerName} 氏` : ''}</p>
                  </div>
                </div>
              </div>
              <div className={`${style.otherArea}`}>
                {movieStatus !== 'ondemand' && (
                  <p className={`${style.movieTerm}`}>
                    {streamingStartDatetime && (
                      <>
                        {startYear}年{startMonth}月{startDay}日 &nbsp;
                      </>
                    )}
                    {streamingStartDatetime && startTime}～{streamingEndDatetime && endTime}
                  </p>
                )}
                {displayCtaButton && (
                  <div className={`${style.buttonArea}`} onClick={(e) => e.stopPropagation()}>
                    {buttonValue}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <AtRecommendInfo recommendTexts={filteredRecommendTexts} />
      </div>
      {showAuthModal && (
        <AtAuthModalForWatchLater
          webinarId={webinarId}
          title={title}
          streamingTerm={streamingTerm}
          thumbnailImage={thumbnailImage}
          closeEventModal={() => setShowAuthModal(false)}
        />
      )}
    </>
  );

};

export default AtMovieListForWebinars;
