import { useState, VFC } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { UserType } from '../../generated/graphql';
import LoginModal from './LoginModal';

type ButtonType = 'inquiry' | 'download';
interface AtRequiredSigninModalProps {
  to?: string;
  buttonType: ButtonType;
  children: React.ReactNode;
  openLoginInNewTab?: boolean;
}

export const AtRequiredSigninModal: VFC<AtRequiredSigninModalProps> = (props) => {

  const { currentUser } = useAuth();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const {
    children, to, buttonType, openLoginInNewTab,
  } = props;

  const isMember = currentUser.userType === UserType.Member;
  if (isMember) {

    return <>{children}</>;

  }

  const path = to || location.pathname;
  return (
    <>
      {buttonType === 'inquiry' && (
        <div className="at-button-multiple" onClick={() => setOpen(true)}>
          <p>個別に質問 / 相談</p>
          <i className="fas fa-chevron-down" />
        </div>
      )}
      {buttonType === 'download' && (
        <div
          className="at-login-button-wrapper"
          onClick={(e) => {

            e.preventDefault();
            setOpen(true);

          }}
        >
          {children}
        </div>
      )}

      <LoginModal
        show={open}
        onClose={() => setOpen(false)}
        title="ログイン/新規会員登録はこちら"
        loginTitle={
          <>
            各種ダウンロード・登壇企業への
            <br />
            お問い合わせにはログインが必要です。
          </>
        }
        signupTitle={
          <>
            アペルザIDへご登録がお済みでない方は
            <br />
            会員登録へお進みください。（登録無料）
          </>
        }
        signupButtonName="新規登録する"
        openLoginInNewTab={openLoginInNewTab}
        path={path}
      />
    </>
  );

};
