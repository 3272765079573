import { gql, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import { Redirect, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { AtPagingRouteParams } from '../../App';
import { dividePartDate, timeIsFuture, timeIsPast } from '../../util/dateUtil';
import { getTvDomain } from '../../util/envUtil';
import type { Breadcrumb } from '../atoms/AtBreadcrumbs';
import { AtBreadcrumbs } from '../atoms/AtBreadcrumbs';
import AtMovieAttention from '../atoms/AtMovieAttention';
import AtPagination from '../atoms/AtPagination';
import paginationStyle from '../atoms/AtPagination.module.scss';
import { LoadingBox } from '../atoms/LoadingBox';
import AtBreadcrumbsJsonLd from '../molecules/AtBreadcrumbsJsonLd';
import AtMovieListDetail from '../molecules/AtMovieListDetail';
import './AtUpcomingFrame.scss';

const webinarsFurturesOpenQuery = gql`
  query tvWebinarsFurturesOpen($page: Int!, $size: Int!) {
    tvWebinarsFurturesOpen(page: $page, size: $size) {
      content {
        tvWebinarId
        companyContractId
        tvWebinarTitle
        tvWebinarSubtitle
        tvWebinarDescription
        tvWebinarStreamingStartDatetime
        tvWebinarStreamingEndDatetime
        tvWebinarOndemandFlag
        tvWebinarRecommendSentence1
        tvWebinarRecommendSentence2
        tvWebinarRecommendSentence3
        tvWebinarSpeakerName
        tvWebinarSpeakerImgFilePath
        tvWebinarSpeakerDepartment
        tvWebinarMainImgFilePath
        relTvWebinarSystem {
          tvWebinarId
          tvWebinarVimeoLiveEventId
          tvWebinarVimeoLiveEventStatus
          tvWebinarVimeoVideoId
          tvWebinarVimeoVideoStatus
          tvWebinarMedialiveChannelId
          tvWebinarMedialiveChannelStatus
          tvWebinarSlidoEventId
        }
        loggedInUserWatchStatus {
          tvWebinarId
          tvWatchLaterId
        }
      }
      page
      size
      totalElements
      totalPages
    }
  }
`;
export const AtUpcomingFrame = () => {

  const { pageNo } = useParams<AtPagingRouteParams>();
  const currentPage = pageNo ? parseInt(pageNo, 10) : 1;

  const { loading, error, data } = useQuery(webinarsFurturesOpenQuery, {
    variables: {
      page: currentPage,
      size: 10,
    },
  });

  useEffect(() => {

    window.scrollTo({
      top:      0,
      behavior: 'smooth',
    });

  }, [currentPage]);

  if (loading) return <LoadingBox />;
  if (error) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  const webinarValue: any = [];
  let date: string;
  let timeTerm: string;

  const tvWebinarsFurturesOpen: [any] = data.tvWebinarsFurturesOpen.content;
  const isInvalidPage = currentPage > (data?.tvWebinarsFurturesOpen.totalPages || 0);

  if (pageNo && !tvWebinarsFurturesOpen.length) {

    return <Redirect to={{ pathname: '/404', state: { invalidURL: window.location.pathname } }} />;

  }

  tvWebinarsFurturesOpen.forEach((tvWebinarsFurtureOpen, tvWebinarsFurtureOpenIndex) => {

    const recommendTexts: any = [];
    let webinarType = 'offTime';
    if (
      tvWebinarsFurtureOpen.tvWebinarStreamingStartDatetime &&
      tvWebinarsFurtureOpen.tvWebinarStreamingEndDatetime
    ) {

      const {
        jstMonth: webinarStartMonth,
        jstDay: webinarStartDay,
        jstWeekDay: webinarStartWeekDay,
        jstTime: webinarStartTime,
      } = dividePartDate(tvWebinarsFurtureOpen.tvWebinarStreamingStartDatetime);
      const { jstTime: webinarEndtime } = dividePartDate(tvWebinarsFurtureOpen.tvWebinarStreamingEndDatetime);

      date = `${webinarStartMonth}/${webinarStartDay} (${webinarStartWeekDay})`;
      timeTerm = `${webinarStartTime}〜${webinarEndtime}`;

      if (timeIsFuture(tvWebinarsFurtureOpen.tvWebinarStreamingStartDatetime)) {

        webinarType = 'openTime';

      } else if (
        timeIsPast(tvWebinarsFurtureOpen.tvWebinarStreamingStartDatetime) &&
        timeIsFuture(tvWebinarsFurtureOpen.tvWebinarStreamingEndDatetime)
      ) {

        webinarType = 'onTime';

      } else if (
        timeIsPast(tvWebinarsFurtureOpen.tvWebinarStreamingEndDatetime) &&
        tvWebinarsFurtureOpen.tvWebinarOndemandFlag === 'ONDEMAND'
      ) {

        webinarType = 'ondemand';

      }

    }

    recommendTexts.push(
      tvWebinarsFurtureOpen.tvWebinarRecommendSentence1,
      tvWebinarsFurtureOpen.tvWebinarRecommendSentence2,
      tvWebinarsFurtureOpen.tvWebinarRecommendSentence3,
    );
    webinarValue.push(
      <AtMovieListDetail
        key={`${tvWebinarsFurtureOpen.tvWebinarId}`}
        webinarId={tvWebinarsFurtureOpen.tvWebinarId}
        movieTerm={`${date} ${timeTerm}`}
        movieTitle={tvWebinarsFurtureOpen.tvWebinarTitle}
        movieSubtitle={tvWebinarsFurtureOpen.tvWebinarSubtitle}
        movieDescription={tvWebinarsFurtureOpen.tvWebinarDescription}
        movieRecommendTexts={recommendTexts}
        movieSpeakerName={tvWebinarsFurtureOpen.tvWebinarSpeakerName}
        movieSpeakerImgFilePath={tvWebinarsFurtureOpen.tvWebinarSpeakerImgFilePath}
        movieSpeakerDepartment={tvWebinarsFurtureOpen.tvWebinarSpeakerDepartment}
        companyContractId={tvWebinarsFurtureOpen.companyContractId}
        doesWatchLater={tvWebinarsFurtureOpen?.loggedInUserWatchStatus?.tvWatchLaterId != null}
        tvWatchLaterId={tvWebinarsFurtureOpen?.loggedInUserWatchStatus?.tvWatchLaterId}
        tvWebinarMainImgFilePath={tvWebinarsFurtureOpen?.tvWebinarMainImgFilePath}
        webinarType={webinarType}
      />,
    );

  });
  const breadcrumbs: Breadcrumb[] = [
    {
      text: 'トップ',
      url:  '/',
    },
    {
      text: '配信予定の動画',
    },
  ];

  return (
    <div className="at-upcoming-frame">
      <Helmet>{isInvalidPage && <meta name="robots" content="noindex" />}</Helmet>
      <AtBreadcrumbsJsonLd breadcrumbs={breadcrumbs} baseUrl={getTvDomain().slice(0, -1)} />
      <div className="at-upcomming-breadcrumbs">
        <AtBreadcrumbs breadcrumbs={breadcrumbs} />
      </div>
      <h1 className="at-upcoming-title">配信予定の動画</h1>
      {data.tvWebinarsFurturesOpen.totalElements > 0 && (
        <p className={`${paginationStyle.atPaginationHeader}`}>
          <AtMovieAttention />
          <div className="at-total">
            {data.tvWebinarsFurturesOpen.totalElements}件中{data.tvWebinarsFurturesOpen.content.length}
            件を表示
          </div>
        </p>
      )}
      {webinarValue}
      <AtPagination currentPage={currentPage} totalPages={data.tvWebinarsFurturesOpen.totalPages} />
    </div>
  );

};
