import { VFC } from 'react';
import style from './AtTechBackground.module.scss';
import { getStaticFileDomain } from '../../util/envUtil';

export interface AtTechBackgroundProps {
  contents?: React.ReactNode;
}

export const AtTechBackground: VFC<AtTechBackgroundProps> = (props) => {

  const { contents } = props;

  return (
    <div
      className={` ${style.background404} `}
      style={{ backgroundImage: `url(${getStaticFileDomain()}tv/images/mypage-title-bg.png)` }}
    >
      {contents}
    </div>
  );

};
