import { useCallback } from 'react';
import { addAnalyticsDataLayer } from '../../util/analyticsUtil';

// Reference: https://developer.mozilla.org/ja/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#localstorage_%E3%81%AE%E6%A9%9F%E8%83%BD%E6%A4%9C%E5%87%BA
const localStorageAvailable = () => {

  try {

    const storage = window.localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;

  } catch (e) {

    return false;

  }

};

type WebinarType = 'ondemand' | 'live';

interface ItemProps {
  userId: string;
  tvWebinarId: string;
  webinarType: WebinarType;
}

const setItem = ({ userId, tvWebinarId, webinarType }: ItemProps) => {

  if (!localStorageAvailable()) return;

  const key = `${userId}-${tvWebinarId}-${webinarType}`;
  window.localStorage.setItem(key, '1');

};

const findItem = ({ userId, tvWebinarId, webinarType }: ItemProps) => {

  if (!localStorageAvailable()) return false;

  const key = `${userId}-${tvWebinarId}-${webinarType}`;
  return window.localStorage.getItem(key) === '1';

};

const useWatchWebinarTrackEvent = () => {

  const watchWebinarTrackEvent = (webinarType: WebinarType, tvWebinarId: number, userId?: number) => {

    // 未ログインはイベントを記録しない
    if (!userId) return;

    const itemProps: ItemProps = {
      userId:      userId.toString(),
      tvWebinarId: tvWebinarId.toString(),
      webinarType,
    };

    // 既に存在すれば視聴済みと捉えてイベントを記録しない
    if (findItem(itemProps)) return;

    let eventName: string;
    switch (webinarType) {
      case 'live':
        eventName = 'tv_watch_live';
        break;

      case 'ondemand':
      default:
        eventName = 'tv_watch_ondemand';
        break;
    }

    addAnalyticsDataLayer({
      event:    eventName,
      video_id: tvWebinarId,
    });

    setItem(itemProps);

  };

  const watchOndemandTrackEvent = useCallback((tvWebinarId: number, userId?: number) => {

    watchWebinarTrackEvent('ondemand', tvWebinarId, userId);

  }, []);

  const watchLiveTrackEvent = useCallback((tvWebinarId: number, userId?: number) => {

    watchWebinarTrackEvent('live', tvWebinarId, userId);

  }, []);

  return {
    watchOndemandTrackEvent,
    watchLiveTrackEvent,
  };

};

export default useWatchWebinarTrackEvent;
