import React from 'react';

import { CompanyNoImageLogoType, TvWebinarDeliveryStatus, useTvWebinarsQuery } from '../../generated/graphql';
import AtMovieListForWebinars from './AtMovieListForWebinars';
import style from './AtMovieScheduleFrame.module.scss';

export const AtMovieScheduleFrame = () => {

  const { data, loading, error } = useTvWebinarsQuery({
    variables: {
      tvWebinarDeliveryStatus: TvWebinarDeliveryStatus.Streaming,
      page:                    1,
      size:                    3,
      isTopPage:               true,
      withCompanysNoLogo:      false,
      companyNoImageLogoType:  CompanyNoImageLogoType.Small,
    },
  });

  if (loading || error) {

    return <></>;

  }

  const tvWebinars = data?.tvWebinars.content;

  return (
    <>
      {tvWebinars && tvWebinars.length > 0 && (
        <section className={` ${style.scheduleMovieFrame} `}>
          <div className={` ${style.title} `}>
            <i className={` fas fa-video `} />
            <h1>配信中の動画</h1>
          </div>
          <div className={`${style.atMovielistArea}`}>
            {tvWebinars?.map(
              (tvWebinar) =>
                tvWebinar && (
                  <div key={tvWebinar.tvWebinarId} className={`${style.atMovielistContent}`}>
                    <AtMovieListForWebinars
                      webinarId={tvWebinar.tvWebinarId}
                      thumbnailImage={tvWebinar.tvWebinarMainImgFilePath}
                      companyContract={tvWebinar?.companyContract}
                      ondemandFlag={tvWebinar.tvWebinarOndemandFlag}
                      streamingStartDatetime={tvWebinar.tvWebinarStreamingStartDatetime?.toString()}
                      streamingEndDatetime={tvWebinar.tvWebinarStreamingEndDatetime?.toString()}
                      title={tvWebinar.tvWebinarTitle}
                      subtitle={tvWebinar.tvWebinarSubtitle}
                      speakerName={tvWebinar.tvWebinarSpeakerName}
                      speakerImage={tvWebinar.tvWebinarSpeakerImgFilePath}
                      speakerPost={tvWebinar.tvWebinarSpeakerDepartment}
                      watchLaterId={tvWebinar.loggedInUserWatchStatus?.tvWatchLaterId}
                      doesWatchLater={!!tvWebinar.loggedInUserWatchStatus?.tvWatchLaterId}
                      recommendTexts={[
                        tvWebinar.tvWebinarRecommendSentence1,
                        tvWebinar.tvWebinarRecommendSentence2,
                        tvWebinar.tvWebinarRecommendSentence3,
                      ]}
                    />
                  </div>
                ),
            )}
          </div>
        </section>
      )}
    </>
  );

};

export default AtMovieScheduleFrame;
