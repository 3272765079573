import { gql, useMutation } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { linkifyTextWithPretest } from '@aperza/ac-ui/lib/component/texteditor/plugins/link/isURL';
import { WebinarType, WebinarOndemandOnlyDatetime } from '../../constant/tvConstant';
import { useAuth } from '../../contexts/AuthContext';
import { UserType } from '../../generated/graphql';
import { dividePartDate } from '../../util/dateUtil';
import { AtButtonSurveySmall, AtButtonWatchLaterSmall } from '../atoms/AtButton';
import { AtCompanyImage } from '../atoms/AtCompanyImage';
import { AtAuthModalForWatchLater } from '../molecules/AtAuthModalForWatchLater';
import AtInquiry, { AtDocument } from '../molecules/AtInquiry';
import { AtDetailRecommendText } from '../pages/AtDetailRecommendText';
import { useIsMobile } from '../templates/MobileReady';
import AtEventScheduleFrameForDetail from './AtEventScheduleFrameForDetail';
import useTvWatchLaterTrackEvent from '../../hooks/tracking/useTvWatchLaterTrackEvent';

const addWatchMutation = gql`
  mutation createTvWebinarWatchLater($tvWebinarIds: [BigInteger!]!) {
    createTvWebinarWatchLater(tvWebinarIds: $tvWebinarIds) {
      tvWatchLaterResults {
        tvWebinarId
        tvWatchLaterId
      }
      tvWatchLaterRegistrationStatus
    }
  }
`;

const cancelWatchMutation = gql`
  mutation updateTvWatchLater($tvWatchLaterIds: [BigInteger!]!) {
    updateTvWatchLater(tvWatchLaterIds: $tvWatchLaterIds) {
      tvWebinarId
      tvWatchLaterId
    }
  }
`;

interface AtDetailCompanyProps {
  webinarId: number;
  webinarTitle: string;
  webinarSubtitle: string;
  webinarDescription: string;
  webinarPublishStartDatetime: string;
  webinarOndemandFirstDatetime: string;
  webinarStreamingStartDatetime: string;
  webinarStreamingEndDatetime: string;
  webinarSpeakerName: string;
  webinarSpeakerImgFilePath: string;
  webinarSpeakerDepartment: string;
  webinarSpeakerBusinessCardDocument: AtDocument;
  webinarMainImgFilePath: string;
  eventId?: number;
  watchLaterStatus: boolean;
  setWatchLaterStatus: React.Dispatch<React.SetStateAction<boolean>>;
  tvWatchLaterId: number;
  userType: UserType;
  webinarType: WebinarType;
  companyContract: any;
  recommendTexts: Array<string | undefined>;
  transcriptions: Array<{ transcription: string }>;
}

export const AtDetailCompany = ({
  webinarId,
  webinarTitle,
  webinarSubtitle,
  webinarDescription,
  webinarPublishStartDatetime,
  webinarOndemandFirstDatetime,
  webinarStreamingStartDatetime,
  webinarStreamingEndDatetime,
  webinarSpeakerName,
  webinarSpeakerImgFilePath,
  webinarSpeakerDepartment,
  webinarSpeakerBusinessCardDocument,
  webinarMainImgFilePath,
  eventId,
  watchLaterStatus,
  setWatchLaterStatus,
  tvWatchLaterId,
  webinarType,
  companyContract,
  recommendTexts,
  transcriptions,
}: AtDetailCompanyProps) => {

  const [currentTvWatchLaterId, setCurrentTvWatchLaterId] = useState(tvWatchLaterId);
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const { currentUser } = useAuth();
  const isMobile = useIsMobile();
  const { tvWatchLaterTrackEvent } = useTvWatchLaterTrackEvent();

  const {
    jstYear: webinarStartYear,
    jstMonth: webinarStartMonth,
    jstDay: webinarStartDay,
    jstTime: webinarStartTime,
  } = dividePartDate(webinarStreamingStartDatetime);
  const { jstTime: webinarEndtime } = dividePartDate(webinarStreamingEndDatetime);

  const streamingTerm = `${webinarStartYear}/${webinarStartMonth}/${webinarStartDay} ${webinarStartTime}〜${webinarEndtime}`;

  const {
    jstYear: webinarOndemandStartYear,
    jstMonth: webinarOndemandStartMonth,
    jstDay: webinarOndemandStartDay,
  } = dividePartDate(webinarOndemandFirstDatetime);

  const ondemandFirstDate = `${webinarOndemandStartYear}/${webinarOndemandStartMonth}/${webinarOndemandStartDay}`;

  const {
    jstYear: webinarPublishStartYear,
    jstMonth: webinarPublishStartMonth,
    jstDay: webinarPublishStartDay,
  } = dividePartDate(webinarPublishStartDatetime);

  const publishStartDate = `${webinarPublishStartYear}/${webinarPublishStartMonth}/${webinarPublishStartDay}`;

  const [AddWebinar, { loading: AddWebinarLoading }] = useMutation(addWatchMutation, {
    variables: {
      tvWebinarIds: [webinarId],
    },
    onCompleted: (data) => {

      setWatchLaterStatus(true);
      setCurrentTvWatchLaterId(data.createTvWebinarWatchLater.tvWatchLaterResults[0].tvWatchLaterId);
      tvWatchLaterTrackEvent(
        data.createTvWebinarWatchLater.tvWatchLaterResults.map((result: any) => result?.tvWebinarId),
      );

    },
  });

  const [CancelWebinar, { loading: CancelWebinarLoading }] = useMutation(cancelWatchMutation, {
    variables: {
      tvWatchLaterIds: [currentTvWatchLaterId],
    },
    onCompleted: (data) => {

      setWatchLaterStatus(false);

    },
  });

  const loading = AddWebinarLoading || CancelWebinarLoading;

  const openAuthModalForWatchLater = () => {

    setShowAuthModal(true);

  };

  const getWatchLaterButton = () => {

    if (watchLaterStatus) {

      return [
        <AtButtonWatchLaterSmall
          key={webinarId}
          label={'視聴リストから削除'}
          className={'fas fa-times'}
          onClickFunction={CancelWebinar}
          loading={loading}
          addedFlag
        />,
      ];

    }
    return [
      <AtButtonWatchLaterSmall
        key={webinarId}
        label={'視聴リストに追加'}
        className={'fas fa-plus'}
        onClickFunction={currentUser.userType === UserType.Member ? AddWebinar : openAuthModalForWatchLater}
        loading={loading}
      />,
    ];

  };

  let buttonValue: any = getWatchLaterButton();
  useEffect(() => {

    buttonValue = getWatchLaterButton();

  }, [watchLaterStatus]);

  const companyName = companyContract.companyLang.companyNameFull
    ? companyContract.companyLang.companyNameFull
    : '';
  const { companyLogoUrl } = companyContract.companyLang;

  const CompanyLogo = () => {

    if (companyLogoUrl) {

      if (companyContract.companyPagePublishFlag) {

        return (
          <a
            className="at-corporate-logo-area"
            href={`/company/${companyContract.companyContractDisplayId}`}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            rel="noopener"
          >
            <AtCompanyImage
              className="at-corporate-logo img img-fluid"
              width={90}
              src={companyLogoUrl}
              alt={companyName}
            />
          </a>
        );

      }

      return (
        <div className="at-corporate-logo-area">
          <AtCompanyImage
            className="at-corporate-logo img img-fluid"
            width={90}
            src={companyLogoUrl}
            alt={companyName}
          />
        </div>
      );

    }
    return <></>;

  };

  const getWebinarDeliveryDate = () => {

    if (webinarType !== 'ondemand') {

      return streamingTerm;

    }
    if (webinarOndemandFirstDatetime == null ||
      webinarOndemandFirstDatetime === WebinarOndemandOnlyDatetime) {

      return '';

    }
    if (webinarOndemandFirstDatetime < webinarPublishStartDatetime) {

      return `オンデマンド配信開始日：${publishStartDate}`;

    }
    return `オンデマンド配信開始日：${ondemandFirstDate}`;

  };

  const surveyPageUrl = `https://form.run/@aperzatv-survey?_field_11=${webinarId}`;

  return (
    <div className="at-main">
      <div className="at-title-area">
        <CompanyLogo />
        <div className="at-title-info-area">
          <p className="at-info">{getWebinarDeliveryDate()}</p>
          <h1 className="at-title">{webinarTitle}</h1>

          <div className="at-sub-info-area">
            {buttonValue}
            <AtButtonSurveySmall message="動画アンケートに答える" href={surveyPageUrl} targetBlankFlg />
            <AtDetailRecommendText recommendTexts={recommendTexts} />
          </div>
        </div>
        {showAuthModal && (
          <AtAuthModalForWatchLater
            webinarId={webinarId}
            title={webinarTitle}
            thumbnailImage={webinarMainImgFilePath}
            streamingTerm={streamingTerm}
            closeEventModal={() => setShowAuthModal(false)}
          />
        )}
      </div>

      {isMobile && (
        <div className="at-mobile-only-sub-info-area">
          <div className="at-sub-info-area">{buttonValue}</div>
          <div className="at-title-info-area at-recommended-text-area">
            <AtDetailRecommendText recommendTexts={recommendTexts} />
          </div>
        </div>
      )}

      <AtInquiry
        webinarId={webinarId}
        speakerImgFilePath={webinarSpeakerImgFilePath}
        companyContractDisplayId={companyContract.companyContractDisplayId}
        companyPagePublishFlag={companyContract.companyPagePublishFlag}
        webinarSpeakerCompanyName={companyName}
        webinarSpeakerDepartment={webinarSpeakerDepartment}
        webinarSpeakerName={webinarSpeakerName}
        webinarSpeakerBusinessCardDocument={webinarSpeakerBusinessCardDocument}
        webinarType={webinarType}
      />
      <p className="at-subtitle">{webinarSubtitle}</p>
      <div
        className="at-description"
        dangerouslySetInnerHTML={{
          __html: useMemo(() => linkifyTextWithPretest(webinarDescription), [webinarDescription]),
        }}
      />
      {transcriptions.length > 0 &&
        transcriptions.map((transcription, index) => (
          <p className="at-transcription" key={`transcription-${webinarId}-${index}`}>
            {transcription.transcription}
          </p>
        ))}
      {!isMobile && eventId ? (
        <AtEventScheduleFrameForDetail displayButton={false} eventId={eventId} />
      ) : null}
    </div>
  );

};

export default AtDetailCompany;
