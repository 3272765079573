import { VFC } from 'react';
import { useHistory } from 'react-router-dom';
import { timeIsFuture, timeIsPast } from '../../util/dateUtil';
import style from './AtNewEventExhibitionReportEvent.module.scss';
import { AtCard } from './AtCard';
import { AtLabel } from '../atoms/AtLabel';
import { AtPlayIcon } from '../atoms/AtPlayIcon';
import { TvWebinarOndemandFlag } from '../../generated/graphql';

export interface ExhibitionReportEventProps {
  imgSrc?: string;
  title?: string;
  subtitle?: string;
  label?: string;
  tvWebinarId?: string;
  ondemandFlag?: TvWebinarOndemandFlag;
  streamingStartDatetime?: string;
  streamingEndDatetime?: string;
  publishStartDatetime?: string;
  publishEndDatetime?: string;
  setShowCTAModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AtNewEventExhibitionReportEvent: VFC<ExhibitionReportEventProps> = (props) => {

  const {
    imgSrc,
    title,
    subtitle,
    label,
    tvWebinarId,
    ondemandFlag,
    streamingStartDatetime,
    streamingEndDatetime,
    publishStartDatetime,
    publishEndDatetime,
    setShowCTAModal,
  } = props;

  let value = <></>;
  let movieStatus: string = '';
  if (timeIsFuture(streamingStartDatetime)) {

    movieStatus = 'opentime';
    value = <div className="movie-label at-label-normal-will-stream">配信予定</div>;

  } else if (timeIsPast(streamingStartDatetime) && timeIsFuture(streamingEndDatetime)) {

    movieStatus = 'ontime';
    value = <div className="movie-label at-label-normal-in-stream">配信中</div>;

  } else if (ondemandFlag === 'ONDEMAND' && timeIsPast(streamingEndDatetime)) {

    movieStatus = 'ondemand';
    value = <div className="movie-label at-label-normal-ondemand">オンデマンド</div>;

  } else if (ondemandFlag !== 'ONDEMAND' && timeIsPast(streamingEndDatetime)) {

    movieStatus = 'closed';
    value = <div className="movie-label at-label-normal-close-stream">公開終了</div>;

  }

  const clickAction = () => {

    if (movieStatus === 'opentime') {

      setShowCTAModal(true);

    } else {

      window.open(`/watch/${tvWebinarId}`, '_blank')?.focus();

    }

  };

  if (timeIsPast(publishEndDatetime) || timeIsFuture(publishStartDatetime)) {

    return <></>;

  }

  return (
    <AtCard className={` ${style.card} `} onClick={clickAction}>
      <div className={style.container}>
        <div className={style.thumbnailArea}>
          <img src={imgSrc} className={` ${style.img} `} alt="thumbnail" loading="lazy" />
          {value}
          {(movieStatus === 'ondemand' || movieStatus === 'ontime') && <AtPlayIcon />}
        </div>
        <div className={` ${style.title} `}>{title}</div>
        <div className={` ${style.subTitle} `}>{subtitle}</div>
      </div>
    </AtCard>
  );

};
