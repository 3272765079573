import { VFC } from 'react';

interface AtCompanyImageProps {
  src?: string;
  width?: number;
  height?: number;
  scale?: number;
  className?: string;
  alt?: string;
  lazy?: boolean;
}

export const AtCompanyImage: VFC<AtCompanyImageProps> = (props) => {

  const {
    src,
    width = 1000,
    height,
    scale = 2,
    className,
    alt,
    lazy = false,
  } = props;

  let imageSrc = src;
  if (imageSrc && (width || height)) {

    const imageUrlData: URL = new URL(imageSrc);
    // Resize image if the path from img/company
    if (imageUrlData.pathname.startsWith('/img/company')) {

      imageSrc = `${imageUrlData.origin}/${width * scale}x${height ? height * scale : '-'}${imageUrlData.pathname}`;

    }

  }

  return (
    <img
      src={imageSrc}
      className={className}
      width={width}
      height={height}
      alt={alt}
      loading={lazy ? 'lazy' : undefined}
      onError={(e) => {

        // fallback image
        if (src) {

          e.currentTarget.src = src;

        }

      }}
    />
  );

};
