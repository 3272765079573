import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import AtModalDeleteTvWatchListLater from './AtModalDeleteTvWatchListLater';
import useTvWatchLaterTrackEvent from '../../hooks/tracking/useTvWatchLaterTrackEvent';

const addWatchMutation = gql`
  mutation createTvWebinarWatchLater($tvWebinarIds: [BigInteger!]!) {
    createTvWebinarWatchLater(tvWebinarIds: $tvWebinarIds) {
      tvWebinarId
      tvWatchLaterId
    }
  }
`;

const cancelWatchMutation = gql`
  mutation updateTvWatchLater($tvWatchLaterIds: [BigInteger!]!) {
    updateTvWatchLater(tvWatchLaterIds: $tvWatchLaterIds) {
      tvWebinarId
      tvWatchLaterId
    }
  }
`;

interface AtMovieListHorizontalPartProp {
  webinarId: number;
  watchLaterId?: number;
  optionalFuncWhenCanceling?: Function;
  movieStatus: string;
}

export const AtMovieListHorizontalPart = ({
  webinarId,
  watchLaterId,
  optionalFuncWhenCanceling,
  movieStatus,
}: AtMovieListHorizontalPartProp) => {

  const [show, setShow] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);
  const [currentWatchLaterIds, setCurrentWatchLaterIds] = useState([watchLaterId]);
  const { tvWatchLaterTrackEvent } = useTvWatchLaterTrackEvent();

  const [addWatchListFunc] = useMutation(addWatchMutation, {
    variables: {
      tvWebinarIds: [webinarId],
    },
    onCompleted: (response) => {

      setShowAddButton(false);
      response.createTvWebinarWatchLater.forEach((data: any) => {

        currentWatchLaterIds.splice(0);
        currentWatchLaterIds.push(data.tvWatchLaterId);

      });
      setCurrentWatchLaterIds([...currentWatchLaterIds]);
      // NOTE: コードとしては視聴登録できそうな雰囲気があるが、UIからだとキャンセルしかできない
      tvWatchLaterTrackEvent(
        response.createTvWebinarWatchLater.tvWatchLaterResults.map((result: any) => result?.tvWebinarId),
      );

    },
  });

  const [cancelWatchFunc] = useMutation(cancelWatchMutation, {
    variables: {
      tvWatchLaterIds: currentWatchLaterIds,
    },
    onCompleted: (response) => {

      setShowAddButton(true);
      if (optionalFuncWhenCanceling) {

        optionalFuncWhenCanceling();

      }

    },
  });

  const showLabel = () => {

    if (!showAddButton) {

      return '視聴リストから削除';

    }
    return '視聴リストに追加';

  };

  const addWatchList = () => {

    addWatchListFunc();

  };

  const onClickFunction = () => {

    if (!showAddButton) {

      setShow(true);

    } else {

      addWatchList();

    }

  };

  const cancelWatch = () => {

    setShow(false);
    cancelWatchFunc();

  };

  if (movieStatus !== 'closed') {

    return (
      <div className="at-button-small at-button-added" onClick={onClickFunction}>
        <p>{showLabel()}</p>
        <i className="fas fa-times" />
        <AtModalDeleteTvWatchListLater show={show} setShow={setShow} cancelWatch={cancelWatch} />
      </div>
    );

  }
  return <></>;

};
