import style from './AtHitCounter.module.scss';

interface AtHitCounterProps {
  totalHitCount?: number;
  displayCount?: number;
}

export const AtHitCounter = ({ totalHitCount = 0, displayCount = 0 }: AtHitCounterProps) => (
  <div className={style.hitCounterText}>
    {totalHitCount}件中{displayCount}件を表示
  </div>
);
