import { LoadingBox } from '@aperza/ac-contents-ui';
import { VFC } from 'react';
import { Redirect } from 'react-router-dom';
import { useTvEventPageQuery } from '../../generated/graphql';
import { AtEventLp } from './AtEventLp';
import { AtFeatureDetail } from './AtFeatureDetail';

export interface AtEventPageRouterProps {
  eventId: string;
}

export const AtEventPageRouter: VFC<AtEventPageRouterProps> = (props) => {

  const { eventId } = props;

  const {
    data, error, loading, refetch,
  } = useTvEventPageQuery({
    variables: {
      eventId: +eventId,
    },
  });

  if (loading) return <LoadingBox />;

  if (error) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  if (data?.tvEventPage?.tvEventType === 'EVENT_LP') {

    return <AtEventLp eventId={eventId} data={data} refetch={refetch} />;

  }
  return <AtFeatureDetail eventId={eventId} data={data} refetch={refetch} />;

};
