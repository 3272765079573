import { TdWebService } from '@aperza/td';

export class AperzaTVTreasure extends TdWebService {
  static test() {

    console.log('This is coming from inside the class');

  }

  static pageView(url: string, id?: number, companyContractDisplayId?: number) {

    const params = new URL(url).searchParams;

    this._tdService.trackEvent('aperza_web', {
      // user
      rd_ubid:   this.getRDUbidCookie(),
      member_id: id,
      ui_lang:   'ja',

      // event
      event:      1,
      media_type: 10,
      click_url:  '', // TODOf currently not tracking clicking, just page navigation

      // company
      obj_company_contract_id: companyContractDisplayId,

      // URL params
      utm_medium:   params.get('utm_medium'),
      utm_source:   params.get('utm_source'),
      utm_campaign: params.get('utm_campaign'),
      utm_term:     params.get('utm_term'),
      utm_content:  params.get('utm_content'),
    });

  }
}
