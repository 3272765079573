export const SIZE_OPTIONS = [
  { label: '10件', value: '10' },
  { label: '30件', value: '30' },
  { label: '50件', value: '50' },
];

export const keywordTooManyWords = (keywordParam: string | undefined | null): boolean =>
  !!keywordParam && keywordParam.replace(/　/g, ' ').split(' ').length > 10;

export const getPageFromParam = (pageParam: string | undefined | null): number => {

  if (!pageParam) {

    return 1;

  }
  const page = parseInt(pageParam, 10);
  if (Number.isNaN(page) || page < 1) {

    return 1;

  }
  return page;

};

export const getSizeFromParam = (sizeParam: string | undefined | null): number => {

  if (!sizeParam || !SIZE_OPTIONS.some((size) => sizeParam === size.value)) {

    return 10;

  }
  const size = parseInt(sizeParam, 10);
  if (Number.isNaN(size)) {

    return 10;

  }
  return size;

};
