import { UUIDUtil } from '@aperza/util';
import Cookies from 'js-cookie';

const getDomain = (): string => {

  if (window.location.hostname.split('.').length > 2) {

    return window.location.hostname.replace(/^[^.]*/, '');

  }
  return `.${window.location.hostname}`;

};

const getExpiresDay = (): Date => {

  const today = new Date();
  return new Date(today.setFullYear(today.getFullYear() + 10));

};

const allowSameSiteUbid = (ubidValue: string) => {

  Cookies.set('__ubid', ubidValue, {
    domain:   getDomain(),
    expires:  getExpiresDay(),
    path:     '/',
    sameSite: 'none',
    secure:   true,
  });

};

export const initCluezUbidGeneration = () => {

  let rdUbidDomain;
  if (document.domain.indexOf('test') > -1 || document.domain.indexOf('local') > -1) {

    rdUbidDomain = 'rd.test.cluez.biz';

  } else if (document.domain.indexOf('stg') > -1) {

    rdUbidDomain = 'rd.stg2.cluez.biz';

  } else {

    rdUbidDomain = 'rd.cluez.biz';

  }
  // get rd_ubid string
  const head = document.getElementsByTagName('head')[0];
  const iframe: any = document.createElement('iframe');
  iframe.src = `https://${rdUbidDomain}/tracking/index/`;
  iframe.style = 'width:1px; height:0; position:absolute; border-style:none;';
  head.appendChild(iframe);
  // eslint-disable-next-line no-multi-assign
  iframe.onload = iframe.onreadystatechange = function () {

    head.removeChild(iframe);

  };

};

export const syncCluezUbidGeneration = (ubidString: String) => {

  let rdUbidDomain;
  if (document.domain.indexOf('test') > -1 || document.domain.indexOf('local') > -1) {

    rdUbidDomain = 'rd.test.cluez.biz';

  } else if (document.domain.indexOf('stg') > -1) {

    rdUbidDomain = 'rd.stg2.cluez.biz';

  } else {

    rdUbidDomain = 'rd.cluez.biz';

  }
  // post rd_ubid string
  const head = document.getElementsByTagName('head')[0];
  const iframe: any = document.createElement('iframe');
  iframe.src = `https://${rdUbidDomain}/tracking/index/?trubid=${ubidString}`;
  iframe.style = 'width:1px; height:0; position:absolute; border-style:none;';
  head.appendChild(iframe);
  // eslint-disable-next-line no-multi-assign
  iframe.onload = iframe.onreadystatechange = function () {

    head.removeChild(iframe);

  };

};

const setUbidToCookieListener = (event: MessageEvent) => {

  const ubidstr = '__ubid=';
  if (event.origin.indexOf('.cluez.') === -1) return;

  if (navigator.cookieEnabled) {

    let eventdata = event.data;
    if (typeof eventdata === 'string') {

      eventdata = JSON.parse(eventdata);

    }
    if (eventdata.ubidString === undefined) return;

    // expires 10year
    const expiresDay = getExpiresDay();

    const domain = getDomain();
    // set rd_ubid cookie
    document.cookie = `${
      ubidstr + eventdata.ubidString
    }; domain=${domain}; expires=${expiresDay}; path=/; SameSite=none; Secure`;

  }

};

// eslint-disable-next-line camelcase
export const add__UBID = () => {

  const ubidValue = Cookies.get('__ubid');

  if (ubidValue !== undefined) {

    allowSameSiteUbid(ubidValue);

  } else {

    const newUbidValue = UUIDUtil.uuid();
    const expiresDay = getExpiresDay();
    const domain = getDomain();
    // set rd_ubid cookie
    document.cookie = `${`__ubid=${newUbidValue}`}; domain=${domain}; expires=${expiresDay}; path=/; SameSite=none; Secure`;

    syncCluezUbidGeneration(newUbidValue);

  }

};
