import { VFC } from 'react';
import style from './ScrollToTop.module.scss';

export interface ScrollToTopProps {}

export const ScrollToTop: VFC<ScrollToTopProps> = (props) => (
  <div
    onClick={() => {

      window.scrollTo({
        top:      0,
        behavior: 'smooth',
      });

    }}
    className={style.container}
  >
    <i className={`far fa-arrow-to-top ${style.icon}`} />
    <span className={` ${style.text} `}>トップ</span>
  </div>
);
