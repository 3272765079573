import { VFC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AtButtonNormalBack } from '../atoms/AtButton';
import { AtHeaderAndFooter } from '../templates/AtHeaderAndFooter';
import style from './AtCompanyPrivate.module.scss';

export interface AtCompanyPrivateProps {}

export const AtCompanyPrivate: VFC<AtCompanyPrivateProps> = (props) => {

  const location = useLocation<{ invalidURL?: string }>();
  const history = useHistory();

  if (location.state?.invalidURL) {

    window.history.replaceState({}, '', location.state.invalidURL);

  }

  const onClickGoBack = () => {

    history.goBack();

  };

  return (
    <AtHeaderAndFooter>
      <div className={`d-flex flex-column justify-content-center align-items-center h-100`}>
        <i className={`${style.lockIcon} far fa-lock`} />
        <div className={`${style.title}`}>非公開の企業情報です</div>
        <div className={`${style.description}`}>
          <div>閲覧を希望された企業情報は現在公開されていません。</div>
          <div>企業情報の照会やお問い合わせについては</div>
          <div>コンテンツページより登壇者宛にお問い合わせください。</div>
        </div>
        <AtButtonNormalBack message={'前の画面へ戻る'} onClick={onClickGoBack} />
      </div>
    </AtHeaderAndFooter>
  );

};
