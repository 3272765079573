import { VFC } from 'react';
// import style from './AtSearchSectionTitle.module.scss';

export interface AtSearchSectionTitleProps {
  icon: string;
  title: string;
}

export const AtSearchSectionTitle: VFC<AtSearchSectionTitleProps> = (props) => {

  const { icon, title } = props;

  return (
    <div>
      <i className={`fas ${icon} pr-1`} />
      <span className={` bold `}>{title}</span>
    </div>
  );

};
