export const configLocal = {
  tvDomain:          'https://tv.local.aperza.com:8050/',
  accountDomain:     'https://account.test.aperza.com/',
  staticDomain:      'https://static.test.aperza.com/',
  wwwDomain:         'https://www.test.aperza.com/',
  idLoginUrl:        'https://id.test.aperza.com/login/tv-local',
  idLogoutUrl:       'https://id.test.aperza.com/logout/tv-local',
  idSignupUrl:       'https://id.test.aperza.com/signup/tv-local/register.html',
  idChangeEmailUrl:  'https://id.test.aperza.com/changeemail/index.html',
  idTokenCookieName: 'localatkn',
  amplitudeApiKey:   'f3cbf82b4fd540379f28e20315e1fcd4',
};

export const configTest = {
  tvDomain:          'https://tv.test.aperza.com/',
  accountDomain:     'https://account.test.aperza.com/',
  staticDomain:      'https://static.test.aperza.com/',
  wwwDomain:         'https://www.test.aperza.com/',
  idLoginUrl:        'https://id.test.aperza.com/login/tv',
  idLogoutUrl:       'https://id.test.aperza.com/logout/tv',
  idSignupUrl:       'https://id.test.aperza.com/signup/tv/register.html',
  idChangeEmailUrl:  'https://id.test.aperza.com/changeemail/index.html',
  idTokenCookieName: 'testatkn',
  amplitudeApiKey:   'f3cbf82b4fd540379f28e20315e1fcd4',
};

export const configTest2 = {
  tvDomain:          'https://tv.test2.aperza.com/',
  accountDomain:     'https://account.test.aperza.com/',
  staticDomain:      'https://static.test2.aperza.com/',
  wwwDomain:         'https://www.test2.aperza.com/',
  idLoginUrl:        'https://id.test2.aperza.com/login/tv',
  idLogoutUrl:       'https://id.test2.aperza.com/logout/tv',
  idSignupUrl:       'https://id.test2.aperza.com/signup/tv/register.html',
  idChangeEmailUrl:  'https://id.test2.aperza.com/changeemail/index.html',
  idTokenCookieName: 'test2atkn',
  amplitudeApiKey:   'f3cbf82b4fd540379f28e20315e1fcd4',
};

export const configStg = {
  tvDomain:          'https://tv.stg.aperza.com/',
  accountDomain:     'https://account.stg.aperza.com/',
  staticDomain:      'https://static.stg.aperza.com/',
  wwwDomain:         'https://www.stg.aperza.com/',
  idLoginUrl:        'https://id.stg.aperza.com/login/tv',
  idLogoutUrl:       'https://id.stg.aperza.com/logout/tv',
  idSignupUrl:       'https://id.stg.aperza.com/signup/tv/register.html',
  idChangeEmailUrl:  'https://id.stg.aperza.com/changeemail/index.html',
  idTokenCookieName: 'stgatkn',
  amplitudeApiKey:   '3289ee002716b2004b0b57b55fbeb96e',
};

export const configProd = {
  tvDomain:          'https://tv.aperza.com/',
  accountDomain:     'https://account.aperza.com/',
  staticDomain:      'https://static.aperza.com/',
  wwwDomain:         'https://www.aperza.com/',
  idLoginUrl:        'https://id.aperza.com/login/tv',
  idLogoutUrl:       'https://id.aperza.com/logout/tv',
  idSignupUrl:       'https://id.aperza.com/signup/tv/register.html',
  idChangeEmailUrl:  'https://id.aperza.com/changeemail/index.html',
  idTokenCookieName: 'atkn',
  amplitudeApiKey:   '7404429e632ccc4099ef74a991a57ccf',
};
