import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(weekday);

const weekDayName = ['日', '月', '火', '水', '木', '金', '土'];

const getCurrentMoment = () => dayjs.utc();

const getJstCurrentMoment = () => dayjs.utc()
  .tz('Asia/Tokyo');

const getMoment = (baseDateTime?: string | Date) => dayjs(baseDateTime).utc();

const getJstMoment = (baseDateTime?: string | Date) => getMoment(baseDateTime).tz('Asia/Tokyo');

const timeIsFuture = (strDatetime: string | Date | undefined): boolean =>
  (strDatetime ? getMoment(strDatetime).isAfter(getCurrentMoment()) : false);

const timeIsPast = (strDatetime: string | Date | undefined): boolean =>
  (strDatetime ? getMoment(strDatetime).isBefore(getCurrentMoment()) : false);

const nowIsBetweenTimes = (
  strStartDatetime: string | undefined,
  strEndDatetime: string | undefined,
): boolean => {

  if (!strStartDatetime || !strEndDatetime) {

    return false;

  }
  return getCurrentMoment().isBetween(getMoment(strStartDatetime), getMoment(strEndDatetime));

};

const dividePartJstDate = (
  strDate: string | undefined,
  emptyString: boolean = false,
): {
  jstYear: number | string | undefined;
  jstMonth: number | string | undefined;
  jstDay: number | string | undefined;
  jstWeekDay: string | undefined;
  jstTime: string | undefined;
} => {

  if (!strDate) {

    return {
      jstYear:    emptyString ? '' : undefined,
      jstMonth:   emptyString ? '' : undefined,
      jstDay:     emptyString ? '' : undefined,
      jstWeekDay: emptyString ? '' : undefined,
      jstTime:    emptyString ? '' : undefined,
    };

  }

  const jstDate = dayjs(strDate).tz('Asia/Tokyo');

  return {
    jstYear:    jstDate.year() || '-',
    jstMonth:   jstDate.month() + 1 || '-',
    jstDay:     jstDate.date() || '-',
    jstWeekDay: weekDayName[jstDate.weekday()] || '-',
    jstTime:    jstDate.format('H:mm') || '-',
  };

};

const dividePartDate = (
  strDate: string | undefined,
  emptyString?: boolean,
): {
  jstYear: number | string | undefined;
  jstMonth: number | string | undefined;
  jstDay: number | string | undefined;
  jstWeekDay: string | undefined;
  jstTime: string | undefined;
} => {

  if (!strDate) {

    return {
      jstYear:    emptyString ? '' : undefined,
      jstMonth:   emptyString ? '' : undefined,
      jstDay:     emptyString ? '' : undefined,
      jstWeekDay: emptyString ? '' : undefined,
      jstTime:    emptyString ? '' : undefined,
    };

  }

  const jstDate = dayjs(strDate).utc()
    .tz('Asia/Tokyo');

  return {
    jstYear:    jstDate.year(),
    jstMonth:   jstDate.month() + 1,
    jstDay:     jstDate.date(),
    jstWeekDay: weekDayName[jstDate.weekday()],
    jstTime:    jstDate.format('H:mm'),
  };

};

const getDateAsString = (strDate?: string, dateFormat = 'YYYY-MM-DD', timezoneId = 'Asia/Tokyo'): string => {

  if (!strDate) {

    return '';

  }

  return dayjs(strDate).tz(timezoneId)
    .format(dateFormat);

};

const isDaysOfBeforeTheLiveStreaming = (strDateTime?: string | Date): boolean => {

  const today = getJstCurrentMoment().startOf('day');

  const streamingStartDay = dayjs(getJstMoment(strDateTime)).startOf('day');

  return today < streamingStartDay;

};

export {
  getCurrentMoment,
  getJstCurrentMoment,
  getMoment,
  getJstMoment,
  dividePartDate,
  dividePartJstDate,
  timeIsFuture,
  timeIsPast,
  nowIsBetweenTimes,
  getDateAsString,
  isDaysOfBeforeTheLiveStreaming,
};
