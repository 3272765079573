import { VFC } from 'react';
import style from './AtEventLpOnDemandEvents.module.scss';
import { AtEventLpOnDemandEvent } from './AtEventLpOnDemandEvent';
import { TvEventPageQuery } from '../../generated/graphql';

export interface AtEventLpOnDemandEventsProps {
  onDemandEvents: N<N<TvEventPageQuery['tvEventPage']>['relTvEventLpTvOndemandWebinars']>;
}

export const AtEventLpOnDemandEvents: VFC<AtEventLpOnDemandEventsProps> = (props) => {

  const { onDemandEvents } = props;

  return (
    <div
      className={style.container}
      style={{
        justifyContent: (onDemandEvents?.length > 4) ? 'left' : 'center',
      }}
    >
      {onDemandEvents.map((onDemandEvent, index) => (
        <AtEventLpOnDemandEvent
          key={index}
          imgSrc={onDemandEvent.tvWebinarMainImgFilePath}
          title={onDemandEvent.tvWebinarTitle}
          subtitle={onDemandEvent.tvWebinarSubtitle}
          tvWebinarId={onDemandEvent.id}
          ondemandFlag={onDemandEvent.tvWebinarOndemandFlag}
          startDatetime={onDemandEvent.tvWebinarStreamingStartDatetime}
          endDatetime={onDemandEvent.tvWebinarStreamingEndDatetime}
        />
      ))}
    </div>
  );

};
