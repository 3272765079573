import { UUIDUtil } from '@aperza/util';
import { FC, useState } from 'react';
import style from './CluezCatalogDownloadContainer.module.scss';
import { CatalogCluezDownloadButton } from '../atoms/CluezCatalogDownloadButton';
import CatalogCluezDownloadModal from '../atoms/CatalogCluezDownloadModal';
import { TvEventCatalog } from '../../generated/graphql';
import { getWwwDomain } from '../../util/envUtil';
import { CatalogImage } from '../atoms/CluezCatalogImage';

type CluezCatalogDownloadContainerProps = {
  catalogProp: TvEventCatalog;
  onClickDownloadButtonCallback?: () => void;
  onClickImageCallback?: () => void;
  onClickTitleCallback?: () => void;
  onClickCompanyNameCallback?: () => void;
};

type CluezCatalog = {
  requestId: string;
  companyId: number;
  catalogId: string;
};

export const CluezCatalogDownloadContainer: FC<CluezCatalogDownloadContainerProps> = ({
  catalogProp,
  onClickDownloadButtonCallback,
  onClickCompanyNameCallback,
  onClickImageCallback,
  onClickTitleCallback,
}) => {

  const [catalog, setCatalog] = useState<CluezCatalog>();
  const [showModal, setShowModal] = useState<boolean>(false);

  const onClickDownloadButton = () => {

    setCatalog({
      requestId: UUIDUtil.uuid(),
      companyId: catalogProp.companyId,
      catalogId: catalogProp.cluezCatalogId,
    });
    setShowModal(true);

    onClickDownloadButtonCallback?.();

  };

  const onClickImage = () => {

    onClickImageCallback?.();

  };

  const onClickTitle = () => {

    onClickTitleCallback?.();

  };

  const onClickCompanyName = () => {

    onClickCompanyNameCallback?.();

  };

  return (
    <div className={style.container}>
      <div className={style.catalogImg}>
        <a
          href={`${getWwwDomain()}catalog/page/${catalogProp.companyId}/${catalogProp.cluezCatalogId}`}
          target="_blank"
          onClick={() => onClickImage()}
        >
          <CatalogImage
            className="img"
            src={catalogProp.catalogImgUrl}
            alt="catalog thumbnail"
            defaultWidth="144"
            defaultHeight="200"
            loading="lazy"
          />
        </a>
      </div>
      <p className={style.catalogTitle}>
        <a
          href={`${getWwwDomain()}catalog/page/${catalogProp.companyId}/${catalogProp.cluezCatalogId}`}
          target="_blank"
          className={style.catalogLink}
          onClick={() => onClickTitle()}
        >
          {catalogProp.catalogTitle}
        </a>
      </p>
      <p className={style.companyName}>
        <a
          href={`${getWwwDomain()}company/page/${catalogProp.companyId}/`}
          target="_blank"
          className={style.catalogLink}
          onClick={() => onClickCompanyName()}
        >
          {catalogProp.companyName}
        </a>
      </p>
      <CatalogCluezDownloadButton onClick={onClickDownloadButton} />
      {catalog && (
        <CatalogCluezDownloadModal
          showModal={showModal}
          setShowModal={setShowModal}
          requestId={catalog.requestId}
          catalogId={catalog.catalogId}
          companyId={catalog.companyId}
        />
      )}
    </div>
  );

};
