import { VFC } from 'react';
import { Helmet } from 'react-helmet-async';
import { getStaticFileDomain, getTvDomain } from '../../util/envUtil';

export type OpenGraphType = 'video.other' | 'website';
export interface AtOpenGraphMetaProps {
  url?: string;
  siteName?: string;
  type?: OpenGraphType;
  image?: string;
  title?: string;
  description?: string;
}
/**
 * Wraps Helmet internally, make sure it's not wrapped in order to override
 */
export const AtOpenGraphMeta: VFC<AtOpenGraphMetaProps> = ({
  url = window.location.href,
  siteName = 'Apérza TV',
  type = 'website',
  image = `${getStaticFileDomain()}common/images/aperza2022/ogp/logo_tv.png`,
  title = 'Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト',
  description,
}) => (
  <Helmet>
    {siteName && <meta name="og:site_name" content={siteName} />}
    {type && <meta name="og:type" content={type} />}

    {/* Fragment inside helmet is not allowed */}
    {url && <meta name="og:url" content={url} />}
    {url && <meta property="twitter:url" content={url} />}
    {url && <meta property="twitter:domain" content={getTvDomain()} />}

    {title && <meta property="og:title" content={title} />}
    {title && <meta name="twitter:title" content={title} />}

    {description && <meta name="og:description" content={description} />}
    {description && <meta name="twitter:description" content={description} />}

    {image && <meta property="og:image" content={image} />}
    {image && <meta name="twitter:image" content={image} />}
    {image && <meta name="twitter:card" content="summary_large_image" />}
  </Helmet>
);
