import { EventTab } from '../component/organisms/AtFeatureIndexFrame';
import { MyPageTab } from '../component/pages/AtMypage';
import { TvWatchLaterStatus, TvWebinarDeliveryStatus } from '../generated/graphql';

export type WatchUrlPrefix = 'mypage/watchlist' | 'watch'

export const getMyPageUrlWithTab = (tabName: MyPageTab) => (tabName === 'top' ? '/mypage' : '/mypage/watchlist');

export const getWatchUrlWithTab = (watchUrlType: WatchUrlPrefix,
  tvWatchLaterStatus: TvWatchLaterStatus | TvWebinarDeliveryStatus) => {

  switch (tvWatchLaterStatus) {
    case TvWatchLaterStatus.BeforeStreaming:
      return `/${watchUrlType}/upcoming/p/1`;
    case TvWatchLaterStatus.Streaming:
      return `/${watchUrlType}/live/p/1`;
    case TvWatchLaterStatus.Ondemand:
      return `/${watchUrlType}/ondemand/p/1`;
    default:
      return `/${watchUrlType}`;
  }

};

export const getEventUrlWithTab = (eventUrlTab: EventTab) => {

  if (eventUrlTab !== 'ALL') {

    return `/event/${eventUrlTab.toLowerCase()}/p/1`;

  }

  return `/event`;

};

/**
 * returns null if tabName is invalid
 */
export const getWatchStatus = <T extends TvWatchLaterStatus | TvWebinarDeliveryStatus>(tabName?: string) => {

  if (!tabName) {

    return null;

  }

  const lowerCasedTabName = tabName.toLowerCase();
  if (lowerCasedTabName === 'upcoming') {

    return TvWatchLaterStatus.BeforeStreaming as T; // the valuses are same for TvWebinarDeliveryStatus so it can be cast-ed

  } if (lowerCasedTabName === 'live') {

    return TvWatchLaterStatus.Streaming as T;

  } if (lowerCasedTabName === 'ondemand') {

    return TvWatchLaterStatus.Ondemand as T;

  }

  return null;

};
