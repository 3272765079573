import React from 'react';
import AtExternalLink from '../atoms/AtExternalLink';

interface AtCatalogProps {
  catalogName: string;
  catalogUrl: string;
  documentImageUrl: string;
}

export const AtCatalog = ({ catalogName, catalogUrl, documentImageUrl }: AtCatalogProps) => (
  <div>
    <div className="at-related-catalog-content-area">
      <div className="at-catalog-image-area">
        <img className="at-catalog-image" loading="lazy" src={documentImageUrl} alt="アペルザカタログ" />
      </div>
      <div className="at-catalog-info-area">
        <p className="at-catalog-info">{catalogName}</p>
        <div className="at-catalog-link-area">
          <AtExternalLink name="カタログ詳細を見る" href={catalogUrl} bold fontSize="11px" />
        </div>
      </div>
    </div>
    <p className="at-catalog-separation" />
  </div>
);

export default AtCatalog;
