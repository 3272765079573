import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { RelatedWebinar } from '../../generated/graphql';
import AtLink from '../atoms/AtLink';
import '../atoms/AtLink.scss';
import { LoadingBox } from '../atoms/LoadingBox';
import AtEventSchedule from '../molecules/AtEventSchedule';
import './AtEventScheduleFrame.scss';

const eventPageQuery = gql`
  query {
    tvEventPages(tvEventType: EVENT, page: 1, size: 1, isTopPage: true) {
      content {
        tvEventId
        tvEventTitle
        tvEventSubtitle
        tvEventDescription
        tvEventMainImgFilePath
        tvEventOgpImgFilePath
        tvEventStartDatetime
        tvEventEndDatetime
        tvEventPublishFlag
        tvEventPublishStartDatetime
        tvEventPublishEndDatetime
        tvEventType
        createdOperator
        lastUpdatedOperator
        createTimestamp
        updateTimestamp
        eventApplyFlag
        relTvEventCompanyContracts {
          tvEventId
          companyContractId
          companyContract(withCompanysNoLogo: false) {
            companyContractId
            companyLang {
              companyLangId
              companyName
              companyLogoUrl
              companyNameFull
            }
          }
          displayOrder
        }
      }
    }
  }
`;

interface TvEventsProps {
  tvEventId?: number;
  tvEventTitle?: string;
  tvEventDescription?: string;
  tvEventMainImgFilePath?: string;
  tvEventPublishFlag?: boolean;
  tvEventStartDatetime?: string;
  tvEventEndDatetime?: string;
  tvEventPublishStartDatetime?: string;
  tvEventPublishEndDatetime?: string;
  tvEventType?: string;
  companyContracts?: any;
  tvExhibitionReports?: RelatedWebinar[];
  tvEventStatus?: JSX.Element;
  eventApplyFlag?: boolean
}

export interface AtEventScheduleFrameProps {
  displayButton: boolean;
}

export const AtEventScheduleFrame = ({ displayButton }: AtEventScheduleFrameProps) => {

  // ステージ情報取得
  const {
    loading, error, data, refetch,
  } = useQuery(eventPageQuery, {});

  if (loading) return <LoadingBox />;
  if (error) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  let eventPage: TvEventsProps = {};

  if (data.tvEventPages.content.length > 0) {

    eventPage = {
      tvEventId:                   data.tvEventPages.content[0].tvEventId,
      tvEventTitle:                data.tvEventPages.content[0].tvEventTitle,
      tvEventDescription:          data.tvEventPages.content[0].tvEventDescription,
      tvEventMainImgFilePath:      data.tvEventPages.content[0].tvEventMainImgFilePath,
      tvEventPublishFlag:          data.tvEventPages.content[0].tvEventtvEventPublishFlag,
      tvEventStartDatetime:        data.tvEventPages.content[0].tvEventStartDatetime,
      tvEventEndDatetime:          data.tvEventPages.content[0].tvEventEndDatetime,
      tvEventPublishStartDatetime: data.tvEventPages.content[0].tvEventPublishStartDatetime,
      tvEventPublishEndDatetime:   data.tvEventPages.content[0].tvEventPublishEndDatetime,
      companyContracts:            data.tvEventPages.content[0].relTvEventCompanyContracts,
      tvEventType:                 data.tvEventPages.content[0].tvEventType,
      tvExhibitionReports:         data.tvEventPages.content[0].tvExhibitionReports,
    };

  }

  if (!eventPage.tvEventType) return <></>;
  let eventTypeValue = '';

  switch (eventPage.tvEventType) {
    case 'EVENT':
    case 'EVENT_LP':
      eventTypeValue = 'イベント';
      break;
    case 'EXHIBITION':
      eventTypeValue = '展示会';
      break;
    case 'FEATURE':
      eventTypeValue = '特集';
      break;
    default:
      break;
  }

  return (
    <section className="stages-frame">
      <div className="title">
        <i className="fas fa-user-chart" />
        <h2>{`本日開催の${eventTypeValue}`}</h2>
        <AtLink name={`${eventTypeValue}詳細を見る`} href={`event/${eventPage.tvEventId}`} />
      </div>
      <div className="stages">
        <AtEventSchedule
          refetch={refetch}
          tvEventId={eventPage.tvEventId}
          tvEventTitle={eventPage.tvEventTitle}
          tvEventDescription={eventPage.tvEventDescription}
          tvEventMainImgFilePath={eventPage.tvEventMainImgFilePath}
          tvEventStartDatetime={eventPage.tvEventStartDatetime}
          tvEventEndDatetime={eventPage.tvEventEndDatetime}
          tvEventType={eventPage.tvEventType}
          companyContracts={eventPage.companyContracts}
          tvExhibitionReports={eventPage?.tvExhibitionReports}
          tvDisplayButton={displayButton}
          eventApplyFlag={eventPage?.eventApplyFlag}
        />
      </div>
    </section>
  );

};

export default AtEventScheduleFrame;
