import { useEffect } from 'react';

export const GptScriptInHeader = (): null => {

  useEffect(() => {

    const head = document.getElementsByTagName('head')[0] as HTMLElement;

    const scriptUrl = document.createElement('script');
    scriptUrl.type = 'text/javascript';
    scriptUrl.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
    scriptUrl.async = true;
    head.appendChild(scriptUrl);

  }, []);

  return null;

};
