import { useState } from 'react';
import style from './AtRecommendInfo.module.scss';

interface AtRecommendInfoProps {
  recommendTexts: Array<string | undefined>;
  verticalList?: boolean;
}

export const AtRecommendInfo = ({ recommendTexts, verticalList }: AtRecommendInfoProps) => {

  const [showAll, setShowAll] = useState<boolean>(false);

  const filteredRecommendTexts = recommendTexts.filter((recommendText) => recommendText);

  return (
    <div className={`${style.recommendInfoArea} ${verticalList && style.verticalList}`}>
      <div className={` ${style.header} `}>
        <i className={`far fa-comment-check ${style.commentCheck}`} />
        <p className={style.recommendTextArea}>こんな方にオススメです</p>
      </div>
      <div className={` ${style.sentenceArea} `}>
        <ul className={style.recommendSentenceList}>
          {!verticalList && !showAll && (
            <li className={style.recommendSentence}>{filteredRecommendTexts[0]}</li>
          )}
          {(verticalList || showAll) &&
            filteredRecommendTexts.map((recommendText) => (
              <li key={recommendText} className={style.recommendSentence}>
                {recommendText}
              </li>
            ))}
        </ul>
        {!verticalList && filteredRecommendTexts.length > 1 && (
          <div
            className={style.showMoreContent}
            onClick={(e) => {

              e.stopPropagation();
              setShowAll(!showAll);

            }}
          >
            <a className={style.showMoreText}>{showAll ? 'おりたたむ' : 'すべて見る'}</a>
            <i className={`fas ${showAll ? 'fa-chevron-up' : 'fa-chevron-down'}  ${style.showMoreIcon}`} />
          </div>
        )}
      </div>
    </div>
  );

};
