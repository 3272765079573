import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { clickCard } from '../../util/clickUtil';
import { useAuth } from '../../contexts/AuthContext';
import { UserType } from '../../generated/graphql';
import { getOmittionSentence } from '../../util/displayUtil';
import { AtButtonWatchLater } from '../atoms/AtButton';
import { AtAuthModalForWatchLater } from './AtAuthModalForWatchLater';
import './AtMovieListDetail.scss';
import { AtCompanyImage } from '../atoms/AtCompanyImage';
import useTvWatchLaterTrackEvent from '../../hooks/tracking/useTvWatchLaterTrackEvent';

const addWatchMutation = gql`
  mutation createTvWebinarWatchLater($tvWebinarIds: [BigInteger!]!) {
    createTvWebinarWatchLater(tvWebinarIds: $tvWebinarIds) {
      tvWatchLaterResults {
        tvWebinarId
        tvWatchLaterId
      }
      tvWatchLaterRegistrationStatus
    }
  }
`;

const cancelWatchMutation = gql`
  mutation updateTvWatchLater($tvWatchLaterIds: [BigInteger!]!) {
    updateTvWatchLater(tvWatchLaterIds: $tvWatchLaterIds) {
      tvWebinarId
      tvWatchLaterId
    }
  }
`;

export const companyContractQuery = gql`
  query companyContract($companyContractId: String!) {
    companyContract(companyContractId: $companyContractId) {
      companyLang {
        companyNameFull
        companyLogoUrl
      }
    }
  }
`;

interface AtMovieListDetailProp {
  webinarId: number;
  companyContractId: string;
  movieTerm: string;
  movieTitle: string;
  movieSubtitle: string;
  movieDescription: string;
  movieRecommendTexts: [any];
  movieSpeakerName: string;
  movieSpeakerImgFilePath: string;
  movieSpeakerDepartment: string;
  tvWatchLaterId: BigInteger;
  doesWatchLater: boolean;
  tvWebinarMainImgFilePath?: string;
  webinarType: String;
}

interface CompanyNameProps {
  webinarId: number;
  companyContractId: string;
  movieTerm: string;
  movieTitle: string;
  movieSubtitle: string;
  movieDescription: string;
  movieRecommendTexts: [any];
  movieSpeakerName: string;
  movieSpeakerImgFilePath: string;
  movieSpeakerDepartment: string;
  buttonValue: boolean;
  recommendValue: [any];
  isMember: boolean;
  tvWebinarMainImgFilePath?: string;
  webinarType: String;
}

export interface CompanyContractProps {
  companyContractId: string;
}

const CompanyName = (props: CompanyNameProps) => {

  const CompanyContract = ({ companyContractId }: CompanyContractProps) => {

    const webinarLinkRef = useRef<HTMLAnchorElement>(null);
    const department = getOmittionSentence(props.movieSpeakerDepartment, 13);

    const description = getOmittionSentence(props.movieDescription, 150);

    const speaker = () => {

      if (props.movieSpeakerName) {

        return (
          <div className="at-movie-speaker">
            <img className="at-movie-speaker-image" src={props.movieSpeakerImgFilePath} alt="speaker" />
            <div className="at-movie-speaker-particular">
              <p className="at-movie-speaker-post">{department}</p>
              <p className="at-movie-speaker-name">
                {props.movieSpeakerName ? `${props.movieSpeakerName} 氏` : ''}
              </p>
            </div>
          </div>
        );

      }
      return null;

    };

    const { loading: loadingC, error: errorC, data: dataC } = useQuery(companyContractQuery, {
      variables: {
        companyContractId,
      },
    });

    if (loadingC) return <p />;
    if (errorC) return <p>{errorC.stack}</p>;

    const companyName = dataC.companyContract.companyLang.companyNameFull
      ? dataC.companyContract.companyLang.companyNameFull
      : '';
    const companylogo = dataC.companyContract.companyLang.companyLogoUrl
      ? dataC.companyContract.companyLang.companyLogoUrl
      : '';

    let webinarTypeTabValue;
    switch (props.webinarType) {
      case 'openTime':
        webinarTypeTabValue = <div className="movie-label at-label-normal-will-stream">配信予定</div>;
        break;
      case 'onTime':
        webinarTypeTabValue = <div className="movie-label at-label-normal-in-stream">配信中</div>;
        break;
      case 'ondemand':
        webinarTypeTabValue = <div className="movie-label at-label-normal-ondemand">オンデマンド</div>;
        break;
      default:
        webinarTypeTabValue = <div className="movie-label at-label-normal-close-stream">配信終了</div>;
    }

    return (
      <div className="at-movie-list-detail" onClick={() => clickCard(webinarLinkRef)}>
        <div className="at-movie-list-detail-left">
          <div className="at-movie-term-area">
            <i className="fas fa-door-open" />
            <p className="at-movie-term">{props.movieTerm}</p>
          </div>
          <div className="at-movie-thumbnail-area">
            {props.tvWebinarMainImgFilePath && (
              <AtCompanyImage
                src={props.tvWebinarMainImgFilePath}
                width={235}
                height={132}
                className="at-movie-thumbnail"
                alt="movie"
              />
            )}
            {webinarTypeTabValue}
          </div>
          <div>{props.buttonValue}</div>
          <div className="at-movie-speaker-company-content">
            <p className="at-movie-speaker-company">{companyName}</p>
            {speaker()}
          </div>
        </div>
        <div className="at-movie-list-detail-right">
          <div className="at-movie-detail-area">
            <div className="at-movie-text-area">
              <a className="at-movie-title" href={`/watch/${String(props.webinarId)}`} ref={webinarLinkRef}>
                {props.movieTitle}
              </a>
              <p className="at-movie-subtitle">{props.movieSubtitle}</p>
              <p className="at-movie-description">{description}</p>
            </div>
            <i className="fas fa-chevron-right" />
          </div>
          <div className="at-movie-reccomend-area">
            <div className="at-movie-reccomend-title-area">
              <i className="far fa-comment-check" />
              <p className="at-movie-reccomend-title">こんな方にオススメです</p>
            </div>
            <div className="at-movie-reccomend-text-frame">{props.recommendValue}</div>
          </div>
        </div>
      </div>
    );

  };

  return <CompanyContract companyContractId={props.companyContractId} />;

};

export const AtMovieListDetail = ({
  webinarId,
  companyContractId,
  movieTerm,
  movieTitle,
  movieSubtitle,
  movieDescription,
  movieRecommendTexts,
  movieSpeakerName,
  movieSpeakerImgFilePath,
  movieSpeakerDepartment,
  tvWatchLaterId,
  doesWatchLater,
  tvWebinarMainImgFilePath,
  webinarType,
}: AtMovieListDetailProp) => {

  const [watchLaterStatus, setWatchLaterStatus] = useState(doesWatchLater);
  const [currentTvWatchLaterId, setCurrentTvWatchLaterId] = useState(tvWatchLaterId);
  const { currentUser } = useAuth();
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const { tvWatchLaterTrackEvent } = useTvWatchLaterTrackEvent();

  const [AddWebinar, { loading: AddWebinarLoading }] = useMutation(addWatchMutation, {
    variables: {
      tvWebinarIds: [webinarId],
    },
    onCompleted: (data) => {

      setWatchLaterStatus(true);
      setCurrentTvWatchLaterId(data.createTvWebinarWatchLater.tvWatchLaterResults[0].tvWatchLaterId);
      tvWatchLaterTrackEvent(
        data.createTvWebinarWatchLater.tvWatchLaterResults.map((result: any) => result?.tvWebinarId),
      );

    },
  });

  const [CancelWebinar, { loading: CancelWebinarLoading }] = useMutation(cancelWatchMutation, {
    variables: {
      tvWatchLaterIds: [currentTvWatchLaterId],
    },
    onCompleted: (data) => {

      setWatchLaterStatus(false);

    },
  });

  const loading = AddWebinarLoading || CancelWebinarLoading;

  const openAuthModalForWatchLater = () => {

    setShowAuthModal(true);

  };

  const getWatchLaterButton = () => {

    if (watchLaterStatus) {

      return [
        <AtButtonWatchLater
          key={webinarId}
          label="視聴リストから削除"
          className="fas fa-times"
          onClickFunction={CancelWebinar}
          loading={loading}
          addedFlag
          stopPropagation
        />,
      ];

    }
    return [
      <AtButtonWatchLater
        key={webinarId}
        label="視聴リストに追加"
        className="fas fa-plus"
        onClickFunction={currentUser.userType === UserType.Member ? AddWebinar : openAuthModalForWatchLater}
        loading={loading}
        stopPropagation
      />,
    ];

  };

  let buttonValue: any = getWatchLaterButton();

  useEffect(() => {

    buttonValue = getWatchLaterButton();

  }, [watchLaterStatus]);

  const recommendValue: any = [];
  movieRecommendTexts.forEach((movieRecommendText, movieRecommendIndex) => {

    movieRecommendText ? (
      recommendValue.push(
        <div key={movieRecommendIndex} className="at-movie-reccomend-text-area">
          <div className="eclipse" />
          <p className="at-movie-reccomend-text">{movieRecommendText}</p>
        </div>,
      )
    ) : (
      <></>
    );

  });

  return (
    <>
      <CompanyName
        webinarId={webinarId}
        companyContractId={companyContractId}
        movieTerm={movieTerm}
        movieTitle={movieTitle}
        movieSubtitle={movieSubtitle}
        movieDescription={movieDescription}
        movieRecommendTexts={movieRecommendTexts}
        movieSpeakerName={movieSpeakerName}
        movieSpeakerImgFilePath={movieSpeakerImgFilePath}
        movieSpeakerDepartment={movieSpeakerDepartment}
        buttonValue={buttonValue}
        recommendValue={recommendValue}
        isMember={currentUser.userType === UserType.Member}
        tvWebinarMainImgFilePath={tvWebinarMainImgFilePath}
        webinarType={webinarType}
      />
      {showAuthModal && (
        <AtAuthModalForWatchLater
          webinarId={webinarId}
          title={movieTitle}
          streamingTerm={movieTerm}
          thumbnailImage={tvWebinarMainImgFilePath}
          closeEventModal={() => setShowAuthModal(false)}
        />
      )}
    </>
  );

};

export default AtMovieListDetail;
