import { useCallback } from 'react';
import { RelatedWebinar } from '../../generated/graphql';
import { useTrackEvent } from './useTrackEvent';

const useClickRecommendedWebinarTrackEvent = () => {

  const { trackEvent } = useTrackEvent();

  const clickRecommendedWebinarTrackEvent = useCallback(
    (currentWebinarLocation: Location, recommendedWebinar: RelatedWebinar): void => {

      if (!(currentWebinarLocation.pathname && recommendedWebinar.id)) return;

      // webinarページのみを想定
      const regExp = /^\/watch\/([1-9][0-9]*)$/;
      const match = regExp.exec(currentWebinarLocation.pathname);
      if (match === null) return;

      const stringCurrentWebinarId = match[1];
      const currentWebinarId = Number(stringCurrentWebinarId);
      const recommendedWebinarId = Number(recommendedWebinar.id);

      trackEvent('Click Recommended Webinar', {
        current_webinar_id:     currentWebinarId,
        recommended_webinar_id: recommendedWebinarId,
      });

    },
    [trackEvent],
  );

  return { clickRecommendedWebinarTrackEvent };

};

export default useClickRecommendedWebinarTrackEvent;
