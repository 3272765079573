import { VFC } from 'react';
import { AcFormField } from '@aperza/ac-ui';
import { SIZE_OPTIONS } from '../../util/searchUtil';
import style from './AtPageSize.module.scss';

export interface AtPageSizeProps {
  currentSize: number;
  onSizeChanged: (currentSize: number) => void;
}

export const AtPageSize: VFC<AtPageSizeProps> = ({ currentSize, onSizeChanged }) => {

  const onChange = (_: any, value: string) => {

    onSizeChanged(parseInt(value, 10));

  };

  return (
    <div>
      <AcFormField
        type="select"
        onChange={onChange}
        options={SIZE_OPTIONS}
        value={currentSize.toString()}
        className={style.sizeSelect}
      />
    </div>
  );

};
