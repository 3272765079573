import { VFC } from 'react';
import { dividePartDate } from '../../util/dateUtil';
// import { AtLabel } from '../atoms/AtLabel';
import style from './AtEventModalBodyHeader.module.scss';

export interface AtEventModalBodyHeaderProps {
  eventTitle?: string;
  firstDayDate?: string;
  lastDayDate?: string;
  // firstDayEventType?: string;
}

export const AtEventModalBodyHeader: VFC<AtEventModalBodyHeaderProps> = (props) => {

  const { eventTitle, firstDayDate, lastDayDate /* firstDayEventType, */ } = props;

  const { jstYear: startDateYear, jstMonth: startDateMonth, jstDay: startDateDay } = dividePartDate(
    firstDayDate,
  );

  const { jstMonth: endDateMonth, jstDay: endDateDay } = dividePartDate(lastDayDate);

  return (
    <>
      <p className={`${style.event_start}`}>
        {startDateYear}年{startDateMonth}月{startDateDay}日
        {lastDayDate && `〜${endDateMonth}月${endDateDay}日`}開催
      </p>
      {/* <AtLabel labelKind={firstDayEventType} /> */}
      <div className={`${style.event_title}`}>{eventTitle}</div>
    </>
  );

};
