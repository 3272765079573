import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import { ToastContainer } from '@aperza/ac-ui';
import { AtOpenGraphMeta } from './component/atoms/AtOpenGraphMeta';
import AtRedirectInvalidPage from './component/atoms/AtRedirectInvalidPage';
import { At404Error } from './component/pages/At404Error';
import { At500Error } from './component/pages/At500Error';
import { AtBizCard } from './component/pages/AtBizCard';
import AtCompany from './component/pages/AtCompany';
import { AtDetail } from './component/pages/AtDetail';
import { AtEventPageRouter } from './component/pages/AtEventPageRouter';
import AtFeatureIndex from './component/pages/AtFeatureIndex';
import Home from './component/pages/AtHome';
import AtMovieIndex from './component/pages/AtMovieIndex';
import AtMypage from './component/pages/AtMypage';
import { AtSearch } from './component/pages/AtSearch';
import AtUpcoming from './component/pages/AtUpcoming';
import { MobileReady } from './component/templates/MobileReady';
import { AuthProvider } from './contexts/AuthContext';
import AuthRoute from './router/AuthRoute';
import { ApolloProvider, createApolloClient } from './service/AtApolloService';
import { AtPageViewTracking } from './util/AtPageViewTracking';
import { AtAmplitudeTracking } from './util/AtAmplitudeTracking';
import { AtStaticEventApply } from './component/pages/AtStaticEventApply';
import { GlobalHeader } from './component/organisms/GlobalHeader';
import style from './App.module.scss';
import { GlobalFooter } from './component/organisms/GlobalFooter';

interface AppProps {
  element: HTMLElement | null;
}

export type AtTabName = 'event' | 'feature' | 'upcoming' | 'live' | 'ondemand';
export interface AtPagingRouteParams {
  pageNo?: string;
  tabName?: AtTabName;
}

function App({ element }: AppProps) {

  const apolloClient = createApolloClient('aperza-tv');
  // until all pages are made to be responsive, remove the default 'viewport' meta tag
  document
    ?.querySelector('[name=\'viewport\'][content=\'width=device-width,initial-scale=1,shrink-to-fit=no\']')
    ?.remove();

  return (
    <div className="App">
      <HelmetProvider>
        <Helmet>
          <meta name="viewport" content="width=1280,initial-scale=1,shrink-to-fit=no" />
        </Helmet>
        <ApolloProvider client={apolloClient}>
          <AtOpenGraphMeta />
          <Router>
            <AuthProvider element={element}>
              <AtAmplitudeTracking />
              <AtPageViewTracking />
              <GlobalHeader />
              <div className={style.contentsArea}>
                <Switch>
                  <Route path="/" exact>
                    <Home />
                  </Route>
                  <Route path={['/event', '/event/p/:pageNo', '/event/:tabName/p/:pageNo']} exact>
                    <AtRedirectInvalidPage>
                      <AtFeatureIndex />
                    </AtRedirectInvalidPage>
                  </Route>
                  <Route
                    path="/event/:eventId"
                    exact
                    render={({ match }) => (
                      <MobileReady>
                        <AtEventPageRouter eventId={match.params.eventId} />
                      </MobileReady>
                    )}
                  />
                  <Route
                    path="/watch/:webinarId"
                    exact
                    render={({ match }) => (
                      <MobileReady>
                        <AtDetail tvWebinarId={match.params.webinarId} />
                      </MobileReady>
                    )}
                  />
                  <Route path={['/upcoming', '/upcoming/p/:pageNo']} exact>
                    <AtRedirectInvalidPage>
                      <AtUpcoming />
                    </AtRedirectInvalidPage>
                  </Route>
                  <Route
                    path={['/watch', '/watch/p/:pageNo', '/watch/:tabName', '/watch/:tabName/p/:pageNo']}
                    exact
                  >
                    <AtRedirectInvalidPage>
                      <AtMovieIndex />
                    </AtRedirectInvalidPage>
                  </Route>
                  <Route
                    path="/company/:companyContractDisplayId"
                    exact
                    render={({ match }) => (
                      <AtCompany companyContractDisplayId={match.params.companyContractDisplayId} />
                    )}
                  />
                  <Route path={['/search', '/search/:searchType']} exact>
                    <AtSearch />
                  </Route>
                  <Route
                    path={['/event/:tvEventId/apply']}
                    exact
                    render={({ match }) => <AtStaticEventApply tvEventId={Number(match.params.tvEventId)} />}
                  />
                  <AuthRoute
                    path="/watch/:webinarId/document/bizcard"
                    exact
                    render={({ match }) => (
                      <AtBizCard
                        tvWebinarId={(match.params.webinarId as unknown) as number}
                        documentType="bizcard"
                      />
                    )}
                  />
                  <AuthRoute
                    path="/watch/:webinarId/document/projection"
                    exact
                    render={({ match }) => (
                      <AtBizCard
                        tvWebinarId={(match.params.webinarId as unknown) as number}
                        documentType="projection"
                      />
                    )}
                  />
                  <AuthRoute path={['/mypage']} exact>
                    <AtRedirectInvalidPage>
                      <AtMypage key="mypage-top" pageName={'top'} />
                    </AtRedirectInvalidPage>
                  </AuthRoute>
                  <AuthRoute
                    path={[
                      '/mypage/watchlist',
                      '/mypage/watchlist/p/:pageNo',
                      '/mypage/watchlist/:tabName',
                      '/mypage/watchlist/:tabName/p/:pageNo',
                    ]}
                    exact
                  >
                    <AtRedirectInvalidPage>
                      <AtMypage key="mypage-watchlist" pageName={'watchlist'} />
                    </AtRedirectInvalidPage>
                  </AuthRoute>
                  <Route path="/500">
                    <At500Error key="error-500" />
                  </Route>
                  <Route path="*">
                    <At404Error key="error-404" />
                  </Route>
                </Switch>
              </div>
              <GlobalFooter />
              <ToastContainer />
            </AuthProvider>
          </Router>
        </ApolloProvider>
      </HelmetProvider>
    </div>
  );

}

export default App;
