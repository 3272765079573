import {
  Dispatch, SetStateAction, useRef, useState, VFC,
} from 'react';
import {
  FieldValues,
  RegisterOptions,
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext,
} from 'react-hook-form';
import style from './AtBizCardForm.module.scss';
import { AtFormAreaOneLine, FormSectionType } from '../atoms/AtFormAreaOneLine';
import { ConfirmConsent } from './static-event-apply/ConfirmConsent';

export type FormSection = {
  name: string;
  id: string;
  type: FormSectionType;
  placeholder?: string;
  required: boolean;
  registerOptions?: RegisterOptions;
  options?: { code: string | number; name: string }[];
  withOptionOtherComment: boolean;
  requiredOptionOtherComment: boolean;
  description?: string;
  disabled?: boolean;
  disabledComment?: string;
  onSelectChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  formInfo?: string;
};

export interface AtBizCardFormProps {
  formSections: FormSection[];
  onSuccessSubmit: SubmitHandler<FieldValues>;
  onErrorSubmit: SubmitErrorHandler<FieldValues>;
  consentConfirmation?: boolean;
  confirmed?: boolean;
  setConfirmed?: Dispatch<SetStateAction<boolean>>;
}

export const AtForm: VFC<AtBizCardFormProps> = ({
  formSections,
  onSuccessSubmit,
  onErrorSubmit,
  consentConfirmation = false,
  confirmed,
  setConfirmed,
}) => {

  const { handleSubmit } = useFormContext();

  const formSectionsComponents = formSections.map((formSection) => (
    <AtFormAreaOneLine
      key={formSection.id}
      name={formSection.name}
      id={formSection.id}
      placeholder={formSection.placeholder}
      type={formSection.type}
      required={formSection.required}
      registerOptions={formSection.registerOptions}
      options={formSection.options}
      withOptionOtherComment={formSection.withOptionOtherComment}
      requiredOptionOtherComment={formSection.requiredOptionOtherComment}
      description={formSection.description}
      onSelectChange={formSection.onSelectChange}
      readOnly={false}
      disabled={formSection.disabled}
      disabledComment={formSection.disabledComment}
      formInfo={formSection.formInfo}
    />
  ));

  const disableSubmitButton = consentConfirmation && !confirmed;

  return (
    <form className={` d-flex flex-column`} onSubmit={handleSubmit(onSuccessSubmit, onErrorSubmit)}>
      {formSectionsComponents}
      {consentConfirmation && <ConfirmConsent isChecked={confirmed} onChecked={setConfirmed} />}
      <input
        className={` ${style.submitButton} align-self-center `}
        style={disableSubmitButton ? { opacity: '0.5' } : {}}
        type="submit"
        value="入力内容を確認"
        disabled={disableSubmitButton}
      />
    </form>
  );

};
