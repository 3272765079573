import { VFC } from 'react';
import style from './AtEventBanner.module.scss';
import { AtEventCardWide } from './AtEventCardWide';

export interface AtEventBannerProps {
  eventId: string;
  setEventType: (eventTypeInput?: string) => void;
}

export const AtEventBanner: VFC<AtEventBannerProps> = (props) => {

  const { eventId, setEventType } = props;

  return (
    <div className={` ${style.bannerContainer} `}>
      <AtEventCardWide eventId={Number(eventId)} setEventType={setEventType} />
    </div>
  );

};
