/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import './AtModalDeleteTvWatchListLater.scss';
import '../atoms/AtButton.scss';

export function AtModalDeleteTvWatchListLater({ show, setShow, cancelWatch }: any) {

  if (show) {

    return (
      <div id="overlay" onClick={() => setShow(false)}>
        <div className="containar-fluid at-modal-delete-tvwatchlist" onClick={(e) => e.stopPropagation()}>
          <div className="row at-modal-delete-top">
            <div className="flex-fill col-6 at-modal-description">視聴リストから削除しますか？</div>
            <i className="flex-fill col-3 fal fa-times" onClick={() => setShow(false)} />
          </div>
          <div className="at-border" />
          <div className="row justify-content-around at-modal-delete-bottom">
            <p className="col-4 at-button-normal-back" onClick={() => setShow(false)}>キャンセル</p>
            <p className="col-4 at-button-normal-go" onClick={cancelWatch}>削除する</p>
          </div>
        </div>
      </div>
    );

  }
  return <></>;

}

export default AtModalDeleteTvWatchListLater;
