import React, { VFC } from 'react';
import './AtLink.scss';

export interface AtLinkProps {
  name: string;
  href: string;
}

export const AtLink = ({ name, href }: AtLinkProps) => (
  <a className="at-link" href={href}>
    {name}
  </a>
);

export interface AtLinkSubmitProps {
  children: React.ReactNode;
  onSubmit: () => void;
}

export const AtLinkSubmit: VFC<AtLinkSubmitProps> = (props) => (
  <a className="at-link" onClick={() => props.onSubmit()} role="button" tabIndex={0}>
    {props.children}
  </a>
);

export default AtLink;
