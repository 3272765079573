import { AperzaUser, Header } from '@aperza/header-footer';
import { AppConfig } from '@aperza/util';
import { useAuth } from '../../contexts/AuthContext';
import { AtCreateTvWatchLaterCompleteModal } from '../molecules/AtCreateTvWatchLaterCompleteModal';

export function GlobalHeader() {

  const { currentUser, unreadMessageCount } = useAuth();

  return (
    <>
      <AtCreateTvWatchLaterCompleteModal />
      <Header
        type={AppConfig.APERZA_TV}
        user={currentUser as AperzaUser}
        unreadMessageCount={unreadMessageCount}
        fixed
      />
    </>
  );

}
