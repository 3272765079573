import { VFC } from 'react';
import style from './AtCheckbox.module.scss';

export interface AtCheckboxProps {
  label?: string;
  id?: string;
  checked?: boolean;
  onClick?: () => void;
  dashed?: boolean;
}

export const AtCheckbox: VFC<AtCheckboxProps> = (props) => {

  const {
    label, id, checked, onClick, dashed,
  } = props;

  return (
    <label className={` ${style.at_checkbox} `}>
      <input type="checkbox" id={id} onClick={onClick} checked={checked} readOnly />
      <span className={`${style.checkArea} ${checked && style.checkmark} ${dashed && style.dashed}`} />
      <span className={` ${style.label} `}>{label}</span>
    </label>
  );

};
