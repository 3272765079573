import { AtPageSize } from '../atoms/AtPageSize';
// import style from './AtSearchHeader.module.scss';
import { AtPaginationHeader } from '../atoms/AtPaginationHeader';

interface AtSearchHeaderProps {
  totalElements: number;
  contentLength: number;
  size: number;
  onSizeChanged: (currentSize: number) => void;
}

export const AtSearchHeader = ({
  totalElements,
  contentLength,
  size,
  onSizeChanged,
}: AtSearchHeaderProps) => (
  <div className={` d-flex justify-content-between `}>
    <AtPaginationHeader totalElements={totalElements} contentLength={contentLength} />
    <AtPageSize currentSize={size} onSizeChanged={onSizeChanged} />
  </div>
);

export default AtSearchHeader;
