import { VFC } from 'react';
import { webinarDay } from '../organisms/AtCTAModal';
import style from './AtCTAModalDay.module.scss';
import { dividePartJstDate, nowIsBetweenTimes } from '../../util/dateUtil';
import { EventTimeTableQuery } from '../../generated/graphql';
import { AtWebinarDayList } from './AtWebinarDayList';

export interface AtCTAModalDayProps {
  day: webinarDay;
  registeredTvWebinarIds: number[];
  selectedTvWebinarIds: number[];
  add: (webinarId: number) => void;
  remove: (webinarId: number) => void;
}

export const AtCTAModalDay: VFC<AtCTAModalDayProps> = (props) => {

  const {
    day, registeredTvWebinarIds, selectedTvWebinarIds, add, remove,
  } = props;

  if (!day) {

    return null;

  }

  const {
    jstYear: startDateYear,
    jstMonth: startDateMonth,
    jstDay: startDateDay,
    jstWeekDay: startWeekDay,
  } = dividePartJstDate(`${day.schedule || day.tvEventScheduleDate}`);

  const exhibitingWebinars = day.webinars?.filter((webinar) =>
    nowIsBetweenTimes(webinar.tvWebinarPublishStartDatetime, webinar.tvWebinarPublishEndDatetime));

  const exhibitingContents = day.content?.filter((webinar) =>
    nowIsBetweenTimes(webinar.tvWebinarPublishStartDatetime, webinar.tvWebinarPublishEndDatetime));

  if (exhibitingWebinars?.length === 0 && exhibitingContents?.length === 0) {

    return <></>;

  }

  return (
    <div className={` ${style.container} `}>
      <div className={` ${style.day} `}>
        {`${startDateYear}/${startDateMonth}/${startDateDay}（${startWeekDay}）`}
      </div>
      <AtWebinarDayList
        webinars={exhibitingWebinars || exhibitingContents!}
        add={add}
        remove={remove}
        registeredTvWebinarIds={registeredTvWebinarIds}
        selectedTvWebinarIds={selectedTvWebinarIds}
      />
    </div>
  );

};
