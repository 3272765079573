import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';

const _initializeTagManager = () => {

  const tagManagerArgs = {
    gtmId:     'GTM-T3FXPP',
    dataLayer: {
      rd_ubid: Cookies.get('__ubid'),
    },
  };

  TagManager.initialize(tagManagerArgs);

};

const addAnalyticsDataLayer = (dataLayer?: object, dataLayerName?: string) => {

  let dataLayerWithoutWhitespace = dataLayerName;
  if (dataLayerName) {

    dataLayerWithoutWhitespace = dataLayerName.replace(/\s/g, '');

  }

  TagManager.dataLayer({
    dataLayer,
    dataLayerName: dataLayerWithoutWhitespace,
  });

};

const intializeAnalytics = () => {

  _initializeTagManager();

};

export { intializeAnalytics, addAnalyticsDataLayer };
