import { ReactNode, VFC } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import style from './LoginModal.module.scss';

export interface LoginModalProps {
  title: string;
  loginTitle: string | JSX.Element;
  signupTitle: ReactNode;
  signupButtonName: string;
  show: boolean;
  onClose: () => void;
  openLoginInNewTab?: boolean;
  path?: string;
}

const LoginModal: VFC<LoginModalProps> = ({
  show,
  onClose,
  title,
  loginTitle,
  signupTitle,
  signupButtonName,
  openLoginInNewTab,
  path,
}) => {

  const {
    signinUrlWithCurrentPath, signupUrlWithCurrentPath, signin, signup,
  } = useAuth();

  return (
    <>
      {show && (
        <div id={style.overlay} onClick={onClose}>
          <div className={style.atModalRequiredSignin} onClick={(e) => e.stopPropagation()}>
            <div className="d-flex flex-column">
              <div className={`d-flex align-items-center border-bottom ${style.atModalRequiredSigninTop}`}>
                <div className={`${style.atModalDescription}`}>{title}</div>
                <i className="ml-auto fal fa-times" onClick={onClose} />
              </div>
              <div className={style.atModalBody}>
                <p>{loginTitle}</p>
                {path && (
                  <a
                    className={style.atButtonNormalGo}
                    onClick={() => signin(path, openLoginInNewTab)}
                    target={openLoginInNewTab ? '_blank' : 'self'}
                  >
                    ログインする
                  </a>
                )}
                {!path && (
                  <a
                    className={style.atButtonNormalGo}
                    href={signinUrlWithCurrentPath}
                    target={openLoginInNewTab ? '_blank' : 'self'}
                  >
                    ログインする
                  </a>
                )}
                <p>{signupTitle}</p>
                {path && (
                  <a
                    className={style.atButtonNormalBack}
                    onClick={() => signup(path, openLoginInNewTab)}
                    target={openLoginInNewTab ? '_blank' : 'self'}
                  >
                    新規登録する
                  </a>
                )}
                {!path && (
                  <a
                    className={style.atButtonNormalBack}
                    href={signupUrlWithCurrentPath}
                    target={openLoginInNewTab ? '_blank' : 'self'}
                  >
                    {signupButtonName}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

};

export default LoginModal;
