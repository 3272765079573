import { useCallback } from 'react';
import { addAnalyticsDataLayer } from '../../util/analyticsUtil';

const useTvWatchLaterTrackEvent = () => {

  const tvWatchLaterTrackEvent = useCallback((webinarIds: (number | undefined)[] | undefined) => {

    const _webinarIds = webinarIds?.filter((id) => id !== undefined);

    addAnalyticsDataLayer({
      event:       'tv_watch_later',
      video_count: _webinarIds?.length,
      video_ids:   _webinarIds,
    });

  }, []);

  return {
    tvWatchLaterTrackEvent,
  };

};

export default useTvWatchLaterTrackEvent;
