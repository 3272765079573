import { VFC, useRef, useState } from 'react';
import style from './AtEventLpTimetable.module.scss';
import { AtEventLpTitle } from '../molecules/AtEventLpTitle';
import { AtDateTabs } from '../molecules/AtDateTabs';
import { AtTimetableEvents } from '../molecules/AtTimetableEvents';
import { useIsMobile } from '../templates/MobileReady';
import { TvEventPageQuery } from '../../generated/graphql';

export interface AtEventLpTimetableProps {
  eventGroups: N<N<TvEventPageQuery['tvEventPage']>['eventTimetable']>;
  sectionTitle: string;
  setShowCTAModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AtEventLpTimetable: VFC<AtEventLpTimetableProps> = (props) => {

  const { eventGroups, sectionTitle, setShowCTAModal } = props;

  const dateRowRef = useRef<HTMLDivElement>(null);

  const [inViewTabs, setInViewTabs] = useState<{ [key: number]: boolean }>({ 0: true });

  const isMobile = useIsMobile();

  const eventGroupsMax7 = eventGroups.slice(0, 7);

  const dateStrings = eventGroupsMax7.map((eventGroup) => eventGroup.schedule);

  return (
    <div id={sectionTitle} className={style.container}>
      <AtEventLpTitle textColor={'#2A2A2A'} title={sectionTitle} />
      <AtDateTabs
        scrollButtons={isMobile && eventGroupsMax7.length > 3}
        dateRowRef={dateRowRef}
        dateStrings={dateStrings}
        inViewTabs={inViewTabs}
      />
      <AtTimetableEvents
        setShowCTAModal={setShowCTAModal}
        eventGroups={eventGroupsMax7}
        setInViewTabs={setInViewTabs}
        dateRowRef={dateRowRef}
      />
    </div>
  );

};
