import React, { useEffect, useState } from 'react';
import { AtButtonOk } from '../atoms/AtButton';
import style from './AtCreateTvWatchLaterCompleteModal.module.scss';
import { useAuth } from '../../contexts/AuthContext';
import useTvWatchLaterTrackEvent from '../../hooks/tracking/useTvWatchLaterTrackEvent';

const tvWatchLaterCreateStatus = {
  createdEvent:   'EVENT',
  createdWebinar: 'WEBINAR',
  notCreated:     'notCreated',
};

export const AtCreateTvWatchLaterCompleteModal = () => {

  const { getTvWatchLaterCreate, getTvWatchLaterWebinarIds } = useAuth();
  const createdEventFlag = getTvWatchLaterCreate() === tvWatchLaterCreateStatus.createdEvent;
  const createdWebinarFlag = getTvWatchLaterCreate() === tvWatchLaterCreateStatus.createdWebinar;
  const [showModal, setShowModal] = useState<boolean>(createdEventFlag || createdWebinarFlag);
  const webinarIds = getTvWatchLaterWebinarIds();
  const { tvWatchLaterTrackEvent } = useTvWatchLaterTrackEvent();

  useEffect(() => {

    if (showModal) {

      let webinarIdArray;
      try {

        webinarIdArray = JSON.parse(webinarIds);

      } catch (e) {

        console.log('no webinar ids');

      }
      if (webinarIdArray) {

        tvWatchLaterTrackEvent(webinarIdArray);

      }

    }

  }, []);

  return (
    <>
      {showModal && (
        <div className={`${style.overlay}`}>
          <div className={`${style.overlayArea}`} onClick={() => setShowModal(false)} />
          <div className={`${style.atCreateTvWatchLaterCompleteModal}`}>
            <div className={`${style.atCreateTvWatchLaterCompleteModalHeader} modal-header`}>
              <p className={`${style.atCreateTvWatchLaterCompleteModalHeaderTitle} header-title`}>
                {createdEventFlag && 'イベント参加登録が完了しました'}
                {createdWebinarFlag && '視聴リストへの追加が完了しました'}
              </p>
              <i className="fas fa-times" onClick={() => setShowModal(false)} />
            </div>
            <p className={`${style.atCreateTvWatchLaterCompleteModalText}`}>
              {createdEventFlag && '参加されるイベントの動画を視聴リストに追加しました。'}
              {createdWebinarFlag && '配信前にリマインドメールをお送りします。'}
            </p>
            <div className={`${style.atCreateTvWatchLaterCompleteModalButtonArea}`}>
              <AtButtonOk onSubmit={() => setShowModal(false)}>OK</AtButtonOk>
            </div>
          </div>
        </div>
      )}
    </>
  );

};
