import sampleSize from 'lodash.samplesize';
import { RelatedWebinar } from '../../generated/graphql';
import { AtRecommendedWebinarRow } from '../molecules/AtRecommendedWebinarRow';
import style from './AtRecommendedWebinarVideoArea.module.scss';

interface AtRecommendedWebinarProps {
  recommendedWebinars: [RelatedWebinar];
}

export const AtRecommendedWebinarVideoArea = ({ recommendedWebinars }: AtRecommendedWebinarProps) => {

  const getRandomList = () => sampleSize(recommendedWebinars.slice(2, recommendedWebinars.length), 18);

  const firstTwoRecommendedWebinars = () =>
    recommendedWebinars.slice(0, 2)?.map((recommendedWebinar) => (
      <div key={recommendedWebinar.id}>
        <AtRecommendedWebinarRow webinar={recommendedWebinar} />
      </div>
    ));

  const renderRecommendedWebinars = () => (
    <div className={style.atRecommendedWebinarContentArea}>
      {recommendedWebinars.length > 0 && firstTwoRecommendedWebinars()}
      {getRandomList()?.map((recommendedWebinar, index) => (
        <div key={recommendedWebinar.id}>
          <AtRecommendedWebinarRow lazy={index >= 3} webinar={recommendedWebinar} />
        </div>
      ))}
    </div>
  );

  return (
    <div className={style.atRecommendedWebinarArea}>
      <div className={style.atRecommendedWebinarTitleArea}>
        <p className={style.atOtherTitle}>
          <i className="far fa-tv fa-fw fs-14" />
          <span className={style.title}>こちらの動画もいかがですか？</span>
        </p>
      </div>
      {renderRecommendedWebinars()}
    </div>
  );

};

export default AtRecommendedWebinarVideoArea;
