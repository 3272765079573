import { useCallback } from 'react';
import { MovieStatus } from '../../component/molecules/AtMovieListHorizontal';
import { useTrackEvent } from './useTrackEvent';

const useClickWebinarOnWatchlistTrackEvent = () => {

  const { trackEvent } = useTrackEvent();

  const clickWebinarOnWatchlistTrackEvent = useCallback(
    (webinarId: number, movieStatus: MovieStatus) => {

      trackEvent('Click Webinar On Watchlist', {
        webinar_id:   webinarId,
        movie_status: movieStatus || undefined,
      });

    },
    [trackEvent],
  );

  return { clickWebinarOnWatchlistTrackEvent };

};

export default useClickWebinarOnWatchlistTrackEvent;
