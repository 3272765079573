import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LogRocket from 'logrocket';
import { useAuth } from '../contexts/AuthContext';
import { trackPageView } from './amplitudeWrapper';
import { useTrackEvent } from '../hooks/tracking/useTrackEvent';

export const AtAmplitudeTracking = (): null => {

  const { setUserProperties } = useTrackEvent();

  const location = useLocation();
  const { currentUser } = useAuth();

  useEffect(() => {

    trackPageView(currentUser);

  }, [location, currentUser]);

  useEffect(() => {

    LogRocket.getSessionURL((sessionUrl) => {

      setUserProperties({ logrocket_session_url: sessionUrl });

    });

  }, [setUserProperties]);

  return null;

};
