export const TvWebinarType = {
  OpenTime: 'openTime',
  StandBy:  'standBy',
  OnTime:   'onTime',
  WaitTime: 'waitTime',
  OffTime:  'offTime',
  Ondemand: 'ondemand',
} as const;

export type WebinarType = typeof TvWebinarType[keyof typeof TvWebinarType];

export const WebinarOndemandOnlyDatetime: string = '1970-01-01T00:00:00Z';
