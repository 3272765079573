import React, { MouseEvent, VFC } from 'react';
import { LoadingBox } from './LoadingBox';
import style from './AtButton.module.scss';

interface AtButtonProps {
  message?: string;
  href?: string;
  targetBlankFlg?: boolean;
  iconClass?: string;
  onClick?: () => void;
}

export const AtButtonNormalBack = ({
  message, href, targetBlankFlg, onClick,
}: AtButtonProps) => {

  const target = targetBlankFlg ? '_blank' : '';
  return (
    <a className="at-button-normal-back" href={href} target={target} onClick={onClick}>
      {message}
    </a>
  );

};

export const AtButtonMultiple = ({
  message, href, targetBlankFlg, iconClass,
}: AtButtonProps) => {

  const target = targetBlankFlg ? '_blank' : '';
  return (
    <a className="at-button-multiple" href={href} target={target}>
      <p>{message}</p>
      <i className={iconClass} />
    </a>
  );

};

interface AtButtonWatchLaterProp {
  label: string;
  className: string;
  onClickFunction?: () => void;
  loading?: boolean;
  addedFlag?: boolean;
  stopPropagation?: boolean;
}

export const AtButtonWatchLater = ({
  label,
  className,
  onClickFunction,
  loading,
  addedFlag,
  stopPropagation,
}: AtButtonWatchLaterProp) => {

  const doOnClickFunction = (e: MouseEvent<HTMLDivElement>) => {

    if (loading) return;

    if (stopPropagation) {

      e.stopPropagation();

    }

    if (onClickFunction) {

      onClickFunction();

    }

  };

  return (
    <div className={` at-button-wide ${addedFlag ? 'at-button-added' : ''} `} onClick={doOnClickFunction}>
      <p>{label}</p>
      <i className={className} />
      {loading && <LoadingBox />}
    </div>
  );

};

interface AtButtonWatchLaterSmallProp {
  label: string;
  className: string;
  onClickFunction?: Function;
  loading?: boolean;
  addedFlag?: boolean;
}

export const AtButtonWatchLaterSmall = ({
  label,
  className,
  onClickFunction,
  loading,
  addedFlag,
}: AtButtonWatchLaterSmallProp) => {

  const doOnClickFunction = () => {

    if (loading) return;
    if (onClickFunction) {

      onClickFunction();

    }

  };

  return (
    <div className={` at-button-small ${addedFlag ? 'at-button-added' : ''} `} onClick={doOnClickFunction}>
      <p>{label}</p>
      <i className={className} />
      {loading && <LoadingBox />}
    </div>
  );

};

interface AtButtonAddEventProp {
  openEventModal: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

interface AtButtonSurveySmallProps {
  message?: string;
  href?: string;
  targetBlankFlg?: boolean;
}

export const AtButtonSurveySmall = ({ message, href, targetBlankFlg }: AtButtonSurveySmallProps) => {

  const target = targetBlankFlg ? '_blank' : '';
  return (
    <a className="at-button-survey-small" href={href} target={target}>
      {message}
    </a>
  );

};

export const AtButtonAddEvent = ({ openEventModal, disabled = false }: AtButtonAddEventProp) => (
  <button
    type="button"
    className={`${disabled ? style.disabled : ''} at-button-multiple`}
    onClick={openEventModal}
    disabled={disabled}
  >
    <p>イベントに参加</p>
    <i className="far fa-layer-plus" />
  </button>
);

export interface AtButtonOkProp {
  children: React.ReactNode;
  onSubmit: () => void;
  disabled?: boolean;
  className?: string;
}

export const AtButtonOk: VFC<AtButtonOkProp> = (props) => (
  <div
    className={`at-button-normal-go ${props.className}`}
    style={props.disabled ? { opacity: 0.5 } : {}}
    onClick={() => {

      if (!props.disabled) {

        props.onSubmit();

      }

    }}
  >
    {props.children}
  </div>
);

export interface AtBasicButtonProps {
  children: React.ReactNode;
  onSubmit: () => void;
  className?: string;
  style?: { [styleName: string]: string | undefined };
  disabled?: boolean;
}
export const AtBasicButton: VFC<AtBasicButtonProps> = (props) => (
  <div
    style={{ ...props.style, opacity: props.disabled ? 0.5 : undefined }}
    className={`at-button-large-back ${props.className}`}
    onClick={props.disabled ? undefined : props.onSubmit}
  >
    {props.children}
  </div>
);

export interface AtSearchTabButtonProps {
  children: React.ReactNode;
  onClick: () => void;
}

export const AtSearchTabButton: VFC<AtSearchTabButtonProps> = (props) => (
  <div className="at-button-search-tab" onClick={props.onClick}>
    {props.children}
  </div>
);
