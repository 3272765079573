import React, { useRef, useEffect } from 'react';
import style from './TableScrollButtons.module.scss';

export interface TableScrollButtonsProps {
  left?: boolean;
  right?: boolean;
  tableElement?: React.RefObject<HTMLDivElement>;
  className?: string;
}

export const TableScrollButtons = ({
  left, right, tableElement, className,
}: TableScrollButtonsProps) => {

  /// scroll listener /////////////

  const leftScrollButton = useRef<HTMLDivElement>(null);
  const rightScrollButton = useRef<HTMLDivElement>(null);

  // https://css-tricks.com/styling-based-on-scroll-position/
  const debounce = (fn: () => void) => {

    let frame: number;

    return () => {

      if (frame) {

        cancelAnimationFrame(frame);

      }

      frame = requestAnimationFrame(() => {

        fn();

      });

    };

  };

  const storeScroll = () => {

    const scrollPositionLeft = tableElement?.current?.scrollLeft || 0;
    const totalWidth = tableElement?.current?.scrollWidth || 0;
    const visibleWidth = tableElement?.current?.offsetWidth || 0;
    const scrollPositionRight = scrollPositionLeft + visibleWidth;

    if (leftScrollButton.current) {

      leftScrollButton.current.dataset.edge = String(scrollPositionLeft === 0);

    }
    if (rightScrollButton.current) {

      // sometimes not possible to scroll all the way
      rightScrollButton.current.dataset.edge = String(scrollPositionRight > totalWidth - 1);

    }

  };

  // eslint-disable-next-line
  useEffect(() => {
    if (tableElement?.current) {

      tableElement.current?.addEventListener('scroll', debounce(storeScroll));
      storeScroll();

      return tableElement.current?.removeEventListener('scroll', debounce(storeScroll));

    }

  }, []);

  /// left /////////////

  if (left) {

    const scrollLeft = () => {

      tableElement?.current?.scrollBy({
        left:     -200,
        behavior: 'smooth',
      });

    };

    // TODO be able to hold the button to scroll

    return (
      <div
        id="leftScrollButton"
        className={`align-items-center justify-content-center d-inline-flex ${
          className ?? style.scrollButton
        } ${style.leftScrollButton} `}
        onClick={scrollLeft}
        ref={leftScrollButton}
      >
        <i className={`fal fa-angle-left fa-3x`} />
      </div>
    );

  }

  /// right /////////////

  if (right) {

    const scrollRight = () => {

      tableElement?.current?.scrollBy({
        left:     200,
        behavior: 'smooth',
      });

    };

    return (
      <div
        id="rightScrollButton"
        className={`align-items-center justify-content-center d-inline-flex ${
          className ?? style.scrollButton
        } ${style.rightScrollButton}`}
        onClick={scrollRight}
        ref={rightScrollButton}
      >
        <i className={`fal fa-angle-right fa-3x`} />
      </div>
    );

  }

  console.error('Define a side for the table scroll button');
  return null;

};
