import { VFC } from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { UserType } from '../generated/graphql';

const AuthRoute: VFC<RouteProps> = (props) => {

  const { children, ...rest } = props;
  const { currentUser, signin, currentPath } = useAuth();

  const render = (routeProps: RouteComponentProps) => {

    if (currentUser.userType === UserType.Guest) {

      signin(currentPath);
      return <></>;

    }

    if (props.render) {

      return props.render(routeProps);

    }

    return children;

  };

  return <Route {...rest} render={render} />;

};

export default AuthRoute;
