import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useCompanyPageQuery } from '../../generated/graphql';
import { getTvDomain } from '../../util/envUtil';
import { removeEndSlash } from '../../util/pathUtil';
import { AperzaTVTreasure } from '../../util/treasureExtension';
import type { Breadcrumb } from '../atoms/AtBreadcrumbs';
import { AtBreadcrumbs } from '../atoms/AtBreadcrumbs';
import { LoadingBox } from '../atoms/LoadingBox';
import TitleAndMeta from '../atoms/TitleAndMeta';
import AtBreadcrumbsJsonLd from '../molecules/AtBreadcrumbsJsonLd';
import { AtCompanyHeader } from '../molecules/AtCompanyHeader';
import { AtCompanySidebar } from '../molecules/AtCompanySidebar';
import { AtCompanyMainContent } from '../organisms/AtCompanyMainContent';
import { AtHeaderAndFooter } from '../templates/AtHeaderAndFooter';
import style from './AtCompany.module.scss';
import { AtCompanyPrivate } from './AtCompanyPrivate';

interface AtCompanyProps {
  companyContractDisplayId: string;
}

export const AtCompany = ({ companyContractDisplayId }: AtCompanyProps) => {

  const isInvalidCompanyContractDisplayId = Number.isNaN(parseInt(companyContractDisplayId, 10));
  const [totalWebinarCount, setTotalWebinarCount] = useState(0);
  const { currentUser } = useAuth();
  const { data, loading, error } = useCompanyPageQuery({
    variables: {
      companyContractDisplayId: parseInt(companyContractDisplayId, 10),
    },
    skip:        isInvalidCompanyContractDisplayId,
    onCompleted: () => {

      AperzaTVTreasure.pageView(
        window.location.href,
        currentUser?.userId,
        data?.companyPage?.companyContractDisplayId,
      );

    },
  });

  if (isInvalidCompanyContractDisplayId) {

    return <Redirect to={{ pathname: '/404', state: { invalidURL: window.location.pathname } }} />;

  }

  if (loading) return <LoadingBox />;
  if (error) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  // the backend returns no companyPageDetail if private.
  // must check if private first
  if (data?.companyPage?.companyPagePublishFlag === false) {

    return <AtCompanyPrivate />;

  }

  if (!data?.companyPage?.companyPageDetail) {

    return <Redirect to={{ pathname: '/404', state: { invalidURL: window.location.pathname } }} />;

  }

  const breadcrumbs: Breadcrumb[] = [
    {
      text: 'トップ',
      url:  '/',
    },
    {
      text: `${data.companyPage.companyPageDetail.name}`,
    },
  ];

  return (
    <div>
      <TitleAndMeta
        title={`${data.companyPage.companyPageDetail.name}のセミナー・動画 | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`}
        canonicalHref={getTvDomain().slice(0, -1) + removeEndSlash(window.location.pathname)}
      />
      <AtBreadcrumbsJsonLd breadcrumbs={breadcrumbs} baseUrl={getTvDomain().slice(0, -1)} />
      <AtHeaderAndFooter fixedSidebar>
        <div>
          <div className={`${style.atCompanyDetailBreadcrumbs}`}>
            <AtBreadcrumbs breadcrumbs={breadcrumbs} />
          </div>
          <AtCompanyHeader
            companyDetail={data.companyPage.companyPageDetail}
            totalWebinarCount={totalWebinarCount}
          />
          <div className={`${style.mainContentAndSidbarContainer} d-flex justify-content-end`}>
            <AtCompanyMainContent
              companyDetail={data.companyPage.companyPageDetail}
              companyContractDisplayId={parseInt(companyContractDisplayId, 10)}
              onWebinarsLoaded={(webinarData) => {

                setTotalWebinarCount(webinarData.tvWebinarsByCompanyContractDisplayId.totalElements);

              }}
            />
            <AtCompanySidebar companyDetail={data.companyPage.companyPageDetail} />
          </div>
        </div>
      </AtHeaderAndFooter>
    </div>
  );

};

export default AtCompany;
