import { useRef, useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { RelatedWebinar } from '../../generated/graphql';
import { clickCard } from '../../util/clickUtil';
import { dividePartDate, timeIsFuture, timeIsPast } from '../../util/dateUtil';
import { getOmittionSentence } from '../../util/displayUtil';
import { getStaticFileDomain, getTvDomain } from '../../util/envUtil';
import '../atoms/AtButton.scss';
import { AtLabel } from '../atoms/AtLabel';
import '../atoms/AtLabel.scss';
import { AtCTAModal } from '../organisms/AtCTAModal';

import './AtEventSchedule.scss';
import { AtCompanyImage } from '../atoms/AtCompanyImage';
import { EventApplyButtonSmall } from './EventApplyButtonSmall';

/**
 * TVイベントスケジュールProps
 */
export interface AtEventScheduleProps {
  tvEventId?: number;
  tvEventMainImgFilePath?: string;
  tvEventType?: string;
  otherExhibitors?: number;
  tvEventStartDatetime?: string;
  tvEventEndDatetime?: string;
  tvEventTitle?: string;
  tvEventDescription?: string;
  companyContracts?: any[];
  tvExhibitionReports?: RelatedWebinar[];
  tvDisplayButton: boolean;
  refetch?: () => Promise<ApolloQueryResult<any>>;
  eventApplyFlag?: boolean;
}

/**
 * TVイベントスケジュール
 * @param param0
 * @returns
 */
export const AtEventSchedule = ({
  tvEventId,
  tvEventMainImgFilePath,
  tvEventType,
  otherExhibitors,
  tvEventStartDatetime,
  tvEventEndDatetime,
  tvEventTitle,
  tvEventDescription,
  companyContracts,
  tvExhibitionReports,
  tvDisplayButton,
  refetch,
  eventApplyFlag = false,
}: AtEventScheduleProps) => {

  const [showEventModal, setShowEventModal] = useState(false);
  const eventLinkRef = useRef<HTMLAnchorElement>(null);

  const eventImage =
    tvEventMainImgFilePath || `${getStaticFileDomain()}tv/images/default-event-thumbnail.png`;
  const eventType = tvEventType || 'EXHIBITION';
  let eventTypeVertical = 'Exhibition';
  switch (eventType) {
    case 'EXHIBITION':
      eventTypeVertical = 'Exhibition';
      break;
    case 'EVENT':
    case 'EVENT_LP':
      eventTypeVertical = 'Event';
      break;
    case 'FEATURE':
    default:
      eventTypeVertical = 'Feature';
      break;
  }
  const eventLabel = tvEventType || '展示会';
  const { jstMonth: eventStartMonth, jstDay: eventStartDay, jstWeekDay: eventStartWeekDay } = dividePartDate(
    tvEventStartDatetime,
    true,
  );
  const eventStartSlash = tvEventStartDatetime ? '/' : '';
  const eventDateTilde = tvEventStartDatetime ? '～' : '';
  const { jstMonth: eventEndMonth, jstDay: eventEndDay, jstWeekDay: eventEndWeekDay } = dividePartDate(
    tvEventEndDatetime,
    true,
  );
  const eventEndSlash = tvEventEndDatetime ? '/' : '';

  const displayEndDateFlag = eventStartMonth !== eventEndMonth || eventStartDay !== eventEndDay;

  const eventId = tvEventId || 0;

  const eventTitle = tvEventTitle || '';

  const eventDescription = tvEventDescription || '';

  const description = getOmittionSentence(eventDescription, 140);

  const upComingEventFilter = (relatedWebinar: RelatedWebinar) =>
    timeIsFuture(relatedWebinar.tvWebinarStreamingStartDatetime) &&
    timeIsPast(relatedWebinar.tvWebinarPublishStartDatetime);

  let upComingExhibitionReports: RelatedWebinar[] = [];

  if (tvExhibitionReports && tvExhibitionReports.length > 0) {

    upComingExhibitionReports = tvExhibitionReports.filter(upComingEventFilter) ?? [];

  }

  const entryButton = () => {

    if (!timeIsFuture(tvEventEndDatetime) || !tvDisplayButton) return null;

    return (
      <EventApplyButtonSmall
        eventApplyFlag={eventApplyFlag}
        eventId={eventId.toString()}
        setShowCTAModal={setShowEventModal}
        endDate={tvEventEndDatetime}
      />
    );

  };

  const companyLogoValue: any = [];
  let exhibitorsTextValue = <></>;
  let exhibitorsRemainingValue = <></>;
  let displayCount = 1;
  const MAX_LOGO_DISPLAY_COUNT = 6;

  if (companyContracts) {

    companyContracts.forEach((companyContractsDetail) => {

      if (companyContractsDetail.companyContract.companyLang.companyLogoUrl) {

        if (displayCount <= MAX_LOGO_DISPLAY_COUNT) {

          const { companyLogoUrl, companyNameFull } = companyContractsDetail.companyContract.companyLang;
          companyLogoValue.push(
            <div className="at-exhibitors-logo" key={companyContractsDetail.companyContractId}>
              <AtCompanyImage src={companyLogoUrl} alt={companyNameFull} width={40} />
            </div>,
          );

        }
        displayCount += 1;

      }

    });

    exhibitorsTextValue =
      companyLogoValue.length > 0 ? <p className="at-exhibitors-text">出展企業</p> : <></>;

    if (otherExhibitors && otherExhibitors > 0) {

      exhibitorsRemainingValue = <p className="at-exhibitors-remaining-text">他{otherExhibitors}件</p>;

    }

  }

  if (eventType === 'FEATURE') {

    return (
      <div className="at-event-schedule" key={tvEventId} onClick={() => clickCard(eventLinkRef)}>
        <div className="at-exhibition-left-area">
          <img src={eventImage} alt={eventTitle} width={320} />
          <p className="at-top-gradation" />
          <p className="at-bottom-gradation" />
          <p className="at-exhibition">{eventTypeVertical}</p>
          <AtLabel labelKind={eventLabel} />
          <a className="at-exhibition-title" href={`${getTvDomain()}event/${tvEventId}`} ref={eventLinkRef}>
            {eventTitle}
          </a>
        </div>
        <div className="at-exhibition-right-area">
          <p className="at-exhibition-text">{eventDescription}</p>
          {exhibitorsTextValue}
          <div className="at-exhibitors-logo-area">
            {companyLogoValue}
            <div className="at-exhibitors-remaining-area">{exhibitorsRemainingValue}</div>
          </div>
        </div>
        <p className="at-exhibition-arrow">
          <i className="fal fa-chevron-right" />
        </p>
      </div>
    );

  }
  return (
    <>
      <div className="at-event-schedule" key={tvEventId} onClick={() => clickCard(eventLinkRef)}>
        <div className="at-exhibition-left-area">
          <img src={eventImage} alt={eventTitle} />
          <p className="at-top-gradation" />
          <p className="at-bottom-gradation" />
          <p className="at-exhibition">{eventTypeVertical}</p>
          <AtLabel labelKind={eventLabel} />
          {displayEndDateFlag && (
            <>
              <p className="at-exhibition-start-month">{eventStartMonth}</p>
              <p className="at-exhibition-start-slash">{eventStartSlash}</p>
              <p className="at-exhibition-start-day">{eventStartDay}</p>
              <p className="at-exhibition-start-week">{eventStartWeekDay}</p>
              <p className="at-exhibition-wavyline">{eventDateTilde}</p>
            </>
          )}
          <p className="at-exhibition-end-month">{eventEndMonth}</p>
          <p className="at-exhibition-end-slash">{eventEndSlash}</p>
          <p className="at-exhibition-end-day">{eventEndDay}</p>
          <p className="at-exhibition-end-week">{eventEndWeekDay}</p>
          <a className="at-exhibition-title" href={`${getTvDomain()}event/${tvEventId}`} ref={eventLinkRef}>
            {eventTitle}
          </a>
        </div>
        <div className="at-exhibition-right-area">
          <p className="at-exhibition-text">{description}</p>
          {exhibitorsTextValue}
          <div className="at-exhibitors-logo-area">
            {companyLogoValue}
            <div className="at-exhibitors-remaining-area">{exhibitorsRemainingValue}</div>
          </div>
        </div>
        {entryButton()}
        <p className="at-exhibition-arrow">
          <i className="fal fa-chevron-right" />
        </p>
      </div>
      {showEventModal && (
        <AtCTAModal
          refetch={refetch}
          eventId={String(eventId)}
          eventType={eventType}
          title={eventTitle}
          upComingEvents={upComingExhibitionReports}
          closeModal={() => setShowEventModal(false)}
        />
      )}
    </>
  );

};

export default AtEventSchedule;
