import { VFC } from 'react';
import AtMovieListDetailFrame from '../organisms/AtMovieListDetailFrame';
import style from './AtSearchNoResults.module.scss';
import { keywordTooManyWords } from '../../util/searchUtil';

export const AtSearchNoResults: VFC = () => {

  const queryParam = new URLSearchParams(window.location.search);
  const keyword = queryParam.get('k') || '';

  const renderHints = () => {

    if (keyword.length > 100) {

      return <>・キーワードは100文字以内で検索する</>;

    }

    if (keywordTooManyWords(keyword)) {

      return <>・キーワードは10個以内で検索する</>;

    }

    return (
      <>
        ・キーワードに誤字・脱字がないか確認する
        <br />
        ・別のキーワードで検索してみる
        <br />
        ・より一般的なキーワードに変えてみる
        <br />
        ・キーワードの数を減らしてみる
      </>
    );

  };

  return (
    <div className={` ${style.container} `}>
      <div className={` ${style.topPart} `}>
        「<span className={` ${style.keyword} `}>{keyword}</span>」に一致する情報は見つかりませんでした。
      </div>
      <p className={` ${style.bottomPart} `}>
        以下のヒントをお読みいただき、再度検索をお試しください
        <br />
        {renderHints()}
        <br />
      </p>
      <div className={` ${style.movieListPart} `}>
        <AtMovieListDetailFrame />
      </div>
    </div>
  );

};
