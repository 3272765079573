import { VFC } from 'react';
import { TvWebinarDocumentType } from '../../generated/graphql';
import style from './AtBizCardPreviewContainer.module.scss';

export interface AtBizCardPreviewContainerProps {
  webinarTitle: string;
  catalogName: string;
  catalogSubName: string;
  catalogImageUrl: string;
  documentType: TvWebinarDocumentType;
  companyNameFull: string;
}

export const AtBizCardPreviewContainer: VFC<AtBizCardPreviewContainerProps> = (props) => {

  const speakerName = props.catalogName ? `${props.catalogName} 氏` : '';

  return (
    <div className={` ${style.bizCardFormContainer} `}>
      {TvWebinarDocumentType.AperzaTvBusinesscard === props.documentType && (
        <div className={` ${style.mainSection} `}>
          <div className={` ${style.bizCardImageArea} `}>
            <img className={` ${style.bizCardImage} `} src={props.catalogImageUrl} alt="アペルザTV登壇者" />
          </div>
          <div className={` ${style.bizCardContentsArea} `}>
            <div className={`${style.labelNormalGray} mb-1`}>名刺</div>
            <div className={` ${style.firstRow}`}>{props.companyNameFull}</div>
            <div className={` ${style.secondRow}`}>
              {props.catalogSubName}
              <div className={` ${style.secondSubRow}`}>{speakerName}</div>
            </div>
            <div className={` ${style.thirdRow} `}>{props.webinarTitle}</div>
          </div>
        </div>
      )}
      {TvWebinarDocumentType.AperzaTvProjectionDocument === props.documentType && (
        <div className={` ${style.mainSection} `}>
          <div className={` ${style.projectionImageArea} `}>
            <img
              className={` ${style.projectionImage} `}
              src={props.catalogImageUrl}
              alt="アペルザTV投影資料"
            />
          </div>
          <div className={` ${style.projectionContentsArea} `}>
            <div className={`${style.labelNormalLightBlue} mb-1`}>投影資料</div>
            <div className={` ${style.firstRow}`}>{props.webinarTitle}の投影資料</div>
            <div className={` ${style.thirdRow} `}>{props.webinarTitle}</div>
          </div>
        </div>
      )}
    </div>
  );

};
