import { useState } from 'react';
import { AtInfoTab } from '../atoms/AtTab';
import style from './AtSupportInfoForVideo.module.scss';

const confirmation = 'アペルザIDプロフィール情報の取扱いについて';
const supportInfo = '視聴できない場合はこちら';

type TabType = typeof confirmation | typeof supportInfo;
const tabStyle = { width: '50%' };

interface AtSupportInfoForVideoProps {
  companyName: string;
}

export const AtSupportInfoForVideo = ({ companyName }: AtSupportInfoForVideoProps) => {

  const [tab, setTab] = useState<TabType>(confirmation);

  return (
    <div className={style.supportInfo}>
      <div className={style.tabArea}>
        <AtInfoTab
          activeFlag={tab === confirmation}
          content={confirmation}
          onClick={() => setTab(confirmation)}
        />
        <AtInfoTab
          activeFlag={tab === supportInfo}
          content={supportInfo}
          onClick={() => setTab(supportInfo)}
        />
      </div>
      <div className={style.infoArea}>
        {tab === confirmation && (
          <>
            <p className={style.info}>
              ご視聴されたお客様の情報（お客様がアペルザIDにご登録された企業名、名前、メールアドレス、住所、電話番号などのプロフィール情報）は、弊社プライバシーポリシーに則り掲載企業へ提供されます。
              <br />
              あらかじめ同意の上、ご視聴してください。
              <br />　・{companyName}
              <br />
              なお、お客様の情報の提供を受けた掲載企業は、お問合せへのご回答や営業活動（メールなどによる広告宣伝を含む）等において、それぞれ掲載企業が定めるプライバシーポリシー等の方針に沿って取り扱われます。
              詳しくは掲載企業のウェブサイトなどをご確認いただくか、掲載企業に直接お問い合わせください。
            </p>
          </>
        )}
        {tab === supportInfo && (
          <p className={style.info}>
            [1]本ページは<strong>GoogleChrome</strong>
            を推奨ブラウザとしております。それ以外のブラウザでアクセスの場合、動画が正しく再生できない場合がございます。
            <br />
            [2]動画プレイヤーや再生メニューなどが表示されない場合、
            <a href="https://www.aperza.com/corp/term/ja/environment_tv.html">推奨環境</a>
            をご確認ください。または<strong>ブラウザを一度終了し、再起動して</strong>
            いただきますと解消する場合がございます。
            <br />
            [3]上記を試しても解決しない場合、
            <a href="https://form.run/@aperzatv-inquiry ">問い合わせページ</a>
            より、ご利用のブラウザやOSのバージョンと併せて、「視聴ができなかった」旨をお知らせください。
          </p>
        )}
      </div>
    </div>
  );

};
