import { VFC } from 'react';
import style from './AtEventLpTitle.module.scss';
import { AtDots } from '../atoms/AtDots';

export interface AtEventLpTitleProps {
  title?: string;
  textColor?: string;
  displayDots?: boolean;
  smallHeader?: boolean;
}

export const AtEventLpTitle: VFC<AtEventLpTitleProps> = (props) => {

  const {
    title, textColor, displayDots = true, smallHeader = false,
  } = props;

  if (title === undefined) return <></>;

  return (
    <div className={style.container}>
      <div style={{ color: textColor }} className={` ${smallHeader ? style.smallHeader : style.header}`}>
        <h2>{title}</h2>
      </div>
      {displayDots && <AtDots />}
    </div>
  );

};
