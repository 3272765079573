import { VFC, useRef } from 'react';
import { TableScrollButtons } from '@aperza/ac-contents-ui';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import style from './AtDateTabs.module.scss';
import { AtScheduleTab as AtDateTab } from '../atoms/AtTab';
import { useIsMobile } from '../templates/MobileReady';
import { dividePartDate } from '../../util/dateUtil';

export interface AtDateTabsProps {
  dateRowRef?: React.RefObject<HTMLDivElement>;
  inViewTabs?: { [key: number]: boolean };
  scrollButtons?: boolean;
  dateStrings: string[];
}

export const AtDateTabs: VFC<AtDateTabsProps> = (props) => {

  const {
    dateRowRef, dateStrings, inViewTabs, scrollButtons,
  } = props;

  const scrollElement = useRef<HTMLDivElement>(null);

  const isMobile = useIsMobile();

  // height in pixels
  const header = 80;
  const dateTabPC = 76;
  const dateTabMobile = 54;

  const dateComponents = dateStrings.map((dateString) => {

    const { jstMonth, jstWeekDay, jstDay } = dividePartDate(dateString);

    return (
      <>
        {jstMonth}/{jstDay}
        <span className={` ${style.weekDay} `}>({jstWeekDay})</span>
      </>
    );

  });

  return (
    <div className={` ${style.container} `}>
      {scrollButtons && (
        <TableScrollButtons
          className={` ${style.scrollButtons} ${style.leftScroll}`}
          left
          tableElement={dateRowRef ?? scrollElement}
        />
      )}
      <div
        ref={dateRowRef ?? scrollElement}
        className={` ${style.tabs} ${scrollButtons ? style.scrollButtonsMargin : ''} `}
      >
        {dateComponents.map((dateComponent, index) => (
          <AnchorLink
            key={index}
            href={`#eventGroup_${index}`}
            offset={isMobile ? `${dateTabMobile}` : `${header + dateTabPC}`}
          >
            <AtDateTab
              className={style.dateTabOverride}
              date={dateComponent}
              activeFlag={inViewTabs?.[index]}
            />
          </AnchorLink>
        ))}
      </div>
      {scrollButtons && (
        <TableScrollButtons
          className={` ${style.scrollButtons} ${style.rightScroll} `}
          right
          tableElement={dateRowRef ?? scrollElement}
        />
      )}
    </div>
  );

};
