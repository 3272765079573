import { VFC } from 'react';
import InView from 'react-intersection-observer';
import scrollIntoView from 'scroll-into-view-if-needed';
import style from './AtTimetableEvents.module.scss';
import { AtTimetableEventGroup } from './AtTimetableEventGroup';
import { TvEventPageQuery } from '../../generated/graphql';

export interface AtTimetableEventsProps {
  eventGroups: N<N<TvEventPageQuery['tvEventPage']>['eventTimetable']>;
  setInViewTabs: React.Dispatch<React.SetStateAction<{ [key: number]: boolean }>>;
  dateRowRef: React.RefObject<HTMLDivElement>;
  setShowCTAModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AtTimetableEvents: VFC<AtTimetableEventsProps> = (props) => {

  const {
    eventGroups, setInViewTabs, dateRowRef, setShowCTAModal,
  } = props;

  return (
    <div className={style.container}>
      {eventGroups.map((eventGroup, index) => (
        <InView
          onChange={(eventGroupInView) => {

            setInViewTabs((prevInViewTabs) => ({ ...prevInViewTabs, [index]: eventGroupInView }));

            const myTarget = document.querySelector(`a[href="#eventGroup_${index}"]`);

            if (eventGroupInView && myTarget) {

              scrollIntoView(myTarget, {
                behavior: 'smooth',
                block:    'nearest',
                boundary: dateRowRef.current,
              });

            }

          }}
          rootMargin={'-50% 0px -50% 0px'}
          key={index}
        >
          <AtTimetableEventGroup setShowCTAModal={setShowCTAModal} eventGroup={eventGroup} index={index} />
        </InView>
      ))}
    </div>
  );

};
