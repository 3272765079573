import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { RelatedWebinar } from '../../generated/graphql';
import { getTvDomain } from '../../util/envUtil';
import AtLink from '../atoms/AtLink';
import '../atoms/AtLink.scss';
import { LoadingBox } from '../atoms/LoadingBox';
import AtEventSchedule from '../molecules/AtEventSchedule';
import { useIsMobile } from '../templates/MobileReady';
import './AtEventScheduleFrame.scss';

const eventPageQuery = gql`
  query tvEventPage($eventId: BigInteger!) {
    tvEventPage(tvEventId: $eventId) {
      tvEventId
      tvEventTitle
      tvEventSubtitle
      tvEventDescription
      tvEventMainImgFilePath
      tvEventOgpImgFilePath
      tvEventStartDatetime
      tvEventEndDatetime
      tvEventPublishFlag
      tvEventPublishStartDatetime
      tvEventPublishEndDatetime
      tvEventType
      createdOperator
      lastUpdatedOperator
      createTimestamp
      updateTimestamp
      relTvEventCompanyContracts {
        tvEventId
        companyContractId
        companyContract(withCompanysNoLogo: false) {
          companyContractId
          companyLang {
            companyLangId
            companyName
            companyLogoUrl
            companyNameFull
          }
        }
        displayOrder
      }
      tvExhibitionReports {
        id
        companyContractId
        companyNameFull
        loggedInUserWatchStatus {
          tvWebinarId
          tvWatchLaterId
        }
        tvWebinarPublishFlag
        tvWebinarPublishStartDatetime
        tvWebinarPublishEndDatetime
        tvWebinarTitle
        tvWebinarSubtitle
        tvWebinarStreamingStartDatetime
        tvWebinarStreamingEndDatetime
        tvWebinarMoviePlayTime
        tvWebinarMainImgFilePath
        tvWebinarOndemandFlag
        tvWebinarSpeakerName
        tvWebinarSpeakerImgFilePath
        tvWebinarSpeakerDepartment
        createTimestamp
        updateTimestamp
      }
    }
  }
`;

export interface TvEventProps {
  tvEventId?: number;
  tvEventTitle?: string;
  tvEventDescription?: string;
  tvEventMainImgFilePath?: string;
  tvEventStartDatetime?: string;
  tvEventEndDatetime?: string;
  tvEventPublishFlag?: boolean;
  tvEventPublishStartDatetime?: string;
  tvEventPublishEndDatetime?: string;
  tvEventType?: string;
  companyContracts?: any;
  tvExhibitionReports?: RelatedWebinar[];
  tvEventStatus?: JSX.Element;
  eventApplyFlag?: boolean;
}

export interface AtEventScheduleFrameForDetailProps {
  displayButton: boolean;
  eventId: number;
}

export const AtEventScheduleFrameForDetail = ({
  displayButton,
  eventId,
}: AtEventScheduleFrameForDetailProps) => {

  // ステージ情報取得
  const {
    loading, error, data, refetch,
  } = useQuery(eventPageQuery, {
    variables: {
      eventId,
    },
  });
  const isMobile = useIsMobile();

  if (loading) return <LoadingBox />;
  if (error) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  let eventPage: TvEventProps = {};

  if (data.tvEventPage) {

    eventPage = {
      tvEventId:                   data.tvEventPage.tvEventId,
      tvEventTitle:                data.tvEventPage.tvEventTitle,
      tvEventDescription:          data.tvEventPage.tvEventDescription,
      tvEventMainImgFilePath:      data.tvEventPage.tvEventMainImgFilePath,
      tvEventStartDatetime:        data.tvEventPage.tvEventStartDatetime,
      tvEventEndDatetime:          data.tvEventPage.tvEventEndDatetime,
      tvEventPublishFlag:          data.tvEventPage.tvEventtvEventPublishFlag,
      tvEventPublishStartDatetime: data.tvEventPage.tvEventPublishStartDatetime,
      tvEventPublishEndDatetime:   data.tvEventPage.tvEventPublishEndDatetime,
      companyContracts:            data.tvEventPage.relTvEventCompanyContracts,
      tvEventType:                 data.tvEventPage.tvEventType,
      tvExhibitionReports:         data.tvEventPage.tvExhibitionReports,
    };

  }

  if (!eventPage.tvEventType) return <></>;
  let eventTypeValue = '';

  switch (eventPage.tvEventType) {
    case 'EVENT':
    case 'EVENT_LP':
      eventTypeValue = 'イベント';
      break;
    case 'EXHIBITION':
      eventTypeValue = '展示会';
      break;
    case 'FEATURE':
      eventTypeValue = '特集';
      break;
    default:
      break;
  }

  const eventlink = `${eventTypeValue}詳細を見る`;

  return (
    <section className="stages-frame">
      <div className="title">
        <i className="fas fa-user-chart" />
        <h2>{!isMobile ? `この動画の${eventTypeValue}` : `${eventTypeValue}詳細を見る`}</h2>
        <AtLink name={eventlink} href={`${getTvDomain()}event/${eventPage.tvEventId}`} />
      </div>
      <div className="stages">
        <AtEventSchedule
          refetch={refetch}
          tvEventId={eventPage.tvEventId}
          tvEventTitle={eventPage.tvEventTitle}
          tvEventDescription={eventPage.tvEventDescription}
          tvEventMainImgFilePath={eventPage.tvEventMainImgFilePath}
          tvEventStartDatetime={eventPage.tvEventStartDatetime}
          tvEventEndDatetime={eventPage.tvEventEndDatetime}
          tvEventType={eventPage.tvEventType}
          companyContracts={eventPage.companyContracts}
          tvExhibitionReports={eventPage?.tvExhibitionReports}
          tvDisplayButton={displayButton}
          eventApplyFlag={eventPage?.eventApplyFlag}
        />
      </div>
    </section>
  );

};

export default AtEventScheduleFrameForDetail;
