import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** java.math.BigInteger */
  BigInteger: number;
  /** Local Date type */
  LocalDate: string;
  /** Local Date Time type */
  LocalDateTime: string;
  /** Local Time type */
  LocalTime: any;
  /** Long type */
  Long: number;
  _FieldSet: any;
};







export enum BusinessType {
  Manufacturer = 'MANUFACTURER',
  EquipmentManufacturer = 'EQUIPMENT_MANUFACTURER',
  Trader = 'TRADER',
  MfrOther = 'MFR_OTHER',
  Other = 'OTHER'
}

export type Buyer = {
  __typename?: 'Buyer';
  approvalFlag?: Maybe<BuyerContactApprovalFlag>;
};

export enum BuyerContactApprovalFlag {
  DataIncomplete = 'DATA_INCOMPLETE',
  Unapplied = 'UNAPPLIED',
  Unapproval = 'UNAPPROVAL',
  Approval = 'APPROVAL',
  Suspending = 'SUSPENDING',
  Rejection = 'REJECTION'
}

export type CompanyContract = {
  __typename?: 'CompanyContract';
  companyContractId: Scalars['String'];
  companyContractDisplayId: Scalars['BigInteger'];
  companyLang?: Maybe<CompanyLang>;
  companyPagePublishFlag: Scalars['Boolean'];
};

export type CompanyLang = {
  __typename?: 'CompanyLang';
  companyLangId: Scalars['String'];
  companyName: Scalars['String'];
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyNameFull?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
};

export enum CompanyNoImageLogoType {
  Small = 'SMALL',
  Large = 'LARGE'
}

export type CompanyPage = {
  __typename?: 'CompanyPage';
  id: Scalars['ID'];
  companyContractDisplayId: Scalars['BigInteger'];
  inquiryFlag: Scalars['Boolean'];
  companyPagePublishFlag: Scalars['Boolean'];
  companyPageDetail?: Maybe<CompanyPageDetail>;
};

export type CompanyPageDetail = {
  __typename?: 'CompanyPageDetail';
  id: Scalars['ID'];
  name: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  subdivisionName?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  /**  業種 */
  industryType: Array<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  capitalDescription?: Maybe<Scalars['String']>;
  foundationDescription?: Maybe<Scalars['String']>;
  businessDescription?: Maybe<Scalars['String']>;
  mainCustomerDescription?: Maybe<Scalars['String']>;
  employeeDescription?: Maybe<Scalars['String']>;
  salesDescription?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

export type CreateTvWatchResult = {
  __typename?: 'CreateTvWatchResult';
  tvWatchLaterResults?: Maybe<Array<Maybe<TvWatchLaterResult>>>;
  tvWatchLaterRegistrationStatus: TvWatchLaterRegistrationStatus;
};

export type EmbedTvWebinar = {
  __typename?: 'EmbedTvWebinar';
  id: Scalars['ID'];
  title: Scalars['String'];
  videoId?: Maybe<Scalars['String']>;
  streamingStartDateTime?: Maybe<Scalars['LocalDateTime']>;
  streamingEndDateTime?: Maybe<Scalars['LocalDateTime']>;
  ondemandFlag: TvWebinarOndemandFlag;
  mainImageFilePath?: Maybe<Scalars['String']>;
  moviePlayTime?: Maybe<Scalars['LocalTime']>;
};

export enum ErrorDetail {
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE'
}

export enum ErrorType {
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented whitelist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION'
}

export type EventApplicant = {
  __typename?: 'EventApplicant';
  eventId?: Maybe<Scalars['BigInteger']>;
  userId?: Maybe<Scalars['BigInteger']>;
  mailAddress?: Maybe<Scalars['String']>;
};

export type Form = {
  __typename?: 'Form';
  /**  form id */
  formId: Scalars['BigInteger'];
  /**  form items */
  formItems?: Maybe<Array<FormItem>>;
};

export type FormItem = {
  __typename?: 'FormItem';
  /**  form item id */
  formItemId: Scalars['BigInteger'];
  /**  form item title */
  formItemTitle: Scalars['String'];
  /**  form item place holder */
  formItemPlaceHolder?: Maybe<Scalars['String']>;
  /**  form item type */
  formItemType: FormItemType;
  /**  form item answer required flag */
  formItemAnswerRequired: FormItemAnswerRequired;
  /**  form item validation rule */
  formItemValidation?: Maybe<Scalars['String']>;
  /**  form item asnwers */
  formItemAnswers?: Maybe<Array<FormItemAnswer>>;
  /**  add select other */
  withOptionOtherComment: Scalars['Boolean'];
  /**  select other required comment */
  requiredOptionOtherComment: Scalars['Boolean'];
  /**  display order */
  displayOrder: Scalars['Int'];
};

export type FormItemAnswer = {
  __typename?: 'FormItemAnswer';
  /**  form item answer id */
  formItemAnswerId: Scalars['BigInteger'];
  /**  form item answer title */
  formItemAnswerTitle: Scalars['String'];
  /**  display order */
  displayOrder: Scalars['Int'];
};

export enum FormItemAnswerRequired {
  /**  Optional */
  Optional = 'OPTIONAL',
  /**  Required */
  Required = 'REQUIRED'
}

export enum FormItemType {
  /**  Dropdown List */
  DropdownSingleSelect = 'DROPDOWN_SINGLE_SELECT',
  /**  Checkbox */
  MultiSelect = 'MULTI_SELECT',
  /**  Image Select */
  ImageSelect = 'IMAGE_SELECT',
  /**  Radio Button */
  SingleSelect = 'SINGLE_SELECT',
  /**  single line text */
  SingleTextbox = 'SINGLE_TEXTBOX',
  /**  multi line text */
  Commentbox = 'COMMENTBOX',
  /**  rate */
  Rate = 'RATE',
  /**  slider */
  Slider = 'SLIDER',
  /**  file upload */
  FileUpload = 'FILE_UPLOAD'
}

export type IdBusinessType = {
  __typename?: 'IdBusinessType';
  code: BusinessType;
  name: Scalars['String'];
  jobCategories: Array<IdJobCategoryType>;
};

export type IdJobCategoryType = {
  __typename?: 'IdJobCategoryType';
  code: JobCategoryType;
  name: Scalars['String'];
};

export type IdPostClass = {
  __typename?: 'IdPostClass';
  code: PostClass;
  name: Scalars['String'];
};

export enum IntroductoryType {
  Within_1Month = 'WITHIN_1_MONTH',
  Within_3Month = 'WITHIN_3_MONTH',
  Within_6Month = 'WITHIN_6_MONTH',
  Within_1Year = 'WITHIN_1_YEAR',
  Undecided = 'UNDECIDED'
}

export enum JobCategoryType {
  ManufacturerIndustrialScience = 'MANUFACTURER_INDUSTRIAL_SCIENCE',
  ManufacturerManufacture = 'MANUFACTURER_MANUFACTURE',
  ManufacturerQualityManagementAndAssurance = 'MANUFACTURER_QUALITY_MANAGEMENT_AND_ASSURANCE',
  ManufacturerMaintenance = 'MANUFACTURER_MAINTENANCE',
  ManufacturerProductionControl = 'MANUFACTURER_PRODUCTION_CONTROL',
  ManufacturerMaterialProcurementAndPurchaseing = 'MANUFACTURER_MATERIAL_PROCUREMENT_AND_PURCHASEING',
  ManufacturerProductPlanningAndDesign = 'MANUFACTURER_PRODUCT_PLANNING_AND_DESIGN',
  ManufacturerRAndD = 'MANUFACTURER_R_AND_D',
  ManufacturerSalesAndMarketing = 'MANUFACTURER_SALES_AND_MARKETING',
  ManufacturerManagement = 'MANUFACTURER_MANAGEMENT',
  ManufacturerOther = 'MANUFACTURER_OTHER',
  EquipmentManufacturerMechanicalDesign = 'EQUIPMENT_MANUFACTURER_MECHANICAL_DESIGN',
  EquipmentManufacturerElectricalDesign = 'EQUIPMENT_MANUFACTURER_ELECTRICAL_DESIGN',
  EquipmentManufacturerProductionAssembly = 'EQUIPMENT_MANUFACTURER_PRODUCTION_ASSEMBLY',
  EquipmentManufacturerSales = 'EQUIPMENT_MANUFACTURER_SALES',
  EquipmentManufacturerMaterialProcurementAndPurchaseing = 'EQUIPMENT_MANUFACTURER_MATERIAL_PROCUREMENT_AND_PURCHASEING',
  EquipmentManufacturerManagement = 'EQUIPMENT_MANUFACTURER_MANAGEMENT',
  EquipmentManufacturerOther = 'EQUIPMENT_MANUFACTURER_OTHER',
  TraderSales = 'TRADER_SALES',
  TraderTechnical = 'TRADER_TECHNICAL',
  TraderManagement = 'TRADER_MANAGEMENT',
  TraderOther = 'TRADER_OTHER',
  MfrOtherSales = 'MFR_OTHER_SALES',
  MfrOtherTechnical = 'MFR_OTHER_TECHNICAL',
  MfrOtherManagement = 'MFR_OTHER_MANAGEMENT',
  MfrOtherOther = 'MFR_OTHER_OTHER',
  OtherSales = 'OTHER_SALES',
  OtherTechnical = 'OTHER_TECHNICAL',
  OtherManagement = 'OTHER_MANAGEMENT',
  OtherOther = 'OTHER_OTHER'
}





export enum MotivationType {
  Infomation = 'INFOMATION',
  Consideration = 'CONSIDERATION'
}

export type Mutation = {
  __typename?: 'Mutation';
  createTvWatchLater: CreateTvWatchResult;
  createTvWebinarWatchLater: CreateTvWatchResult;
  createTvEventWatchLater: CreateTvWatchResult;
  updateTvWatchLater: Array<Maybe<TvWatchLaterResult>>;
  registerTvWebinarQuestionnaire: Scalars['String'];
  createTvWebinarInquiry?: Maybe<Scalars['BigInteger']>;
  updateTvNotificationSetting: Array<TvNotificationSetting>;
  applyTvEvent: Scalars['BigInteger'];
};


export type MutationCreateTvWatchLaterArgs = {
  tvWebinarIds: Array<Scalars['BigInteger']>;
  watchLaterRegistrationRoute?: Maybe<WatchLaterRegistrationRoute>;
};


export type MutationCreateTvWebinarWatchLaterArgs = {
  tvWebinarIds: Array<Scalars['BigInteger']>;
};


export type MutationCreateTvEventWatchLaterArgs = {
  tvWebinarIds: Array<Scalars['BigInteger']>;
  tvEventId: Scalars['BigInteger'];
};


export type MutationUpdateTvWatchLaterArgs = {
  tvWatchLaterIds: Array<Scalars['BigInteger']>;
};


export type MutationRegisterTvWebinarQuestionnaireArgs = {
  tvWebinarQuestionnaireAnswer: TvWebinarQuestionnaireAnswerInput;
};


export type MutationCreateTvWebinarInquiryArgs = {
  tvWebinarInquiry: TvWebinarInquiryInput;
};


export type MutationUpdateTvNotificationSettingArgs = {
  tvNotificationSetting: TvNotificationSettingInput;
};


export type MutationApplyTvEventArgs = {
  tvEventApplyInput: TvEventApplyInput;
};

export type NewMarks = {
  __typename?: 'NewMarks';
  newWebinarFurturesOpenFlag: Scalars['Boolean'];
  newEventFlag: Scalars['Boolean'];
  newWebinarFlag: Scalars['Boolean'];
};

export enum NotificationSettingFlag {
  Notice = 'NOTICE',
  NotNotice = 'NOT_NOTICE'
}

export enum NotificationSettingType {
  RemindNotice = 'REMIND_NOTICE',
  MissWatchNotice = 'MISS_WATCH_NOTICE',
  OndemandNotice = 'ONDEMAND_NOTICE'
}

export enum PositionFlag {
  NotChecked = 'NOT_CHECKED',
  Checked = 'CHECKED'
}

export enum PostClass {
  CompanyPresident = 'COMPANY_PRESIDENT',
  Officer = 'OFFICER',
  DepartmentManager = 'DEPARTMENT_MANAGER',
  SectionManager = 'SECTION_MANAGER',
  ChiefClerk = 'CHIEF_CLERK',
  General = 'GENERAL'
}

export type Prefecture = {
  __typename?: 'Prefecture';
  /**  prefecture code */
  code: Scalars['String'];
  /**  prefecture name */
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /**  Listing TvEvents */
  tvEventPages: TvEventPages;
  /**  get tvEvent */
  tvEventPage?: Maybe<TvEventPage>;
  /**  Listing TvWebinars */
  tvWebinars: TvWebinars;
  /**  Listing TvWebinarsFurturesOpen */
  tvWebinarsFurturesOpen: TvWebinars;
  /**  Search TvWebinars */
  searchTvWebinars: TvWebinarSearchResults;
  /**  Search TvEvents */
  searchTvEvents: TvEventPages;
  /**  Search Future TvEvents */
  searchFutureTvEvents: TvEventPages;
  /**  Search Old TvEvents */
  searchOldTvEvents: TvEventPages;
  /**  Search TvFeatures */
  searchTvFeatures: TvEventPages;
  /**  get tvWebinar */
  tvWebinar?: Maybe<TvWebinar>;
  /**  Listing tvEventSchedules */
  tvEventSchedules?: Maybe<TvEventSchedules>;
  /**  get tvEventSchedule */
  tvEventSchedule?: Maybe<TvEventSchedule>;
  /**  Listing TvWatchLaters */
  tvWatchLaters: TvWatchLaters;
  /**  get companyContract */
  companyContract?: Maybe<CompanyContract>;
  /**  get newMarks */
  newMarks: NewMarks;
  /**  get user */
  user: User;
  /**  get prefectures */
  prefectures: Array<Prefecture>;
  /**  get buisness type */
  businessTypes: Array<IdBusinessType>;
  postClasses?: Maybe<Array<IdPostClass>>;
  /**  get tvNotificationSetting */
  tvNotificationSetting: Array<TvNotificationSetting>;
  /**  get tvTopMainVisuals */
  tvTopMainVisuals?: Maybe<TvTopMainVisuals>;
  /**  get tvTopRankings */
  tvTopRankings?: Maybe<TvTopRankings>;
  /**  get webinar download document info */
  tvWebinarQuestionnaire?: Maybe<TvWebinarQuestionnaire>;
  /**  get company page */
  companyPage?: Maybe<CompanyPage>;
  /**  get events associated with company for pagination */
  tvEventPagesByCompanyContractDisplayId: TvEventPages;
  /**  get webinars associated with company for pagination */
  tvWebinarsByCompanyContractDisplayId: TvWebinars;
  /**  get webinar for embed page */
  embedTvWebinar?: Maybe<EmbedTvWebinar>;
  /**  get webinars for event modal in event lp page */
  eventTimetable: Array<RegisteredWebinars>;
  eventApplicant?: Maybe<EventApplicant>;
  unreadMessageCount: Scalars['Long'];
  _service?: Maybe<_Service>;
};


export type QueryTvEventPagesArgs = {
  tvEventType?: Maybe<TvEventType>;
  page: Scalars['Int'];
  size: Scalars['Int'];
  isTopPage?: Maybe<Scalars['Boolean']>;
};


export type QueryTvEventPageArgs = {
  tvEventId: Scalars['BigInteger'];
};


export type QueryTvWebinarsArgs = {
  tvWebinarDeliveryStatus: TvWebinarDeliveryStatus;
  keyword?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  size: Scalars['Int'];
  isTopPage?: Maybe<Scalars['Boolean']>;
};


export type QueryTvWebinarsFurturesOpenArgs = {
  keyword?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QuerySearchTvWebinarsArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  keyword: Scalars['String'];
  withTvEventsFlag: Scalars['Boolean'];
};


export type QuerySearchTvEventsArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  keyword: Scalars['String'];
};


export type QuerySearchFutureTvEventsArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  keyword: Scalars['String'];
};


export type QuerySearchOldTvEventsArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  keyword: Scalars['String'];
};


export type QuerySearchTvFeaturesArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  keyword: Scalars['String'];
};


export type QueryTvWebinarArgs = {
  tvWebinarId: Scalars['BigInteger'];
};


export type QueryTvEventSchedulesArgs = {
  tvEventId: Scalars['BigInteger'];
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryTvEventScheduleArgs = {
  tvEventScheduleId: Scalars['String'];
};


export type QueryTvWatchLatersArgs = {
  tvWatchLaterStatus?: Maybe<TvWatchLaterStatus>;
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryCompanyContractArgs = {
  companyContractId: Scalars['String'];
  withCompanysNoLogo?: Maybe<Scalars['Boolean']>;
  companyNoImageLogoType?: Maybe<CompanyNoImageLogoType>;
};


export type QueryNewMarksArgs = {
  criteriaHour: Scalars['Int'];
};


export type QueryTvTopMainVisualsArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryTvTopRankingsArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryTvWebinarQuestionnaireArgs = {
  tvWebinarId: Scalars['BigInteger'];
  documentType: TvWebinarDocumentType;
};


export type QueryCompanyPageArgs = {
  companyContractDisplayId: Scalars['BigInteger'];
};


export type QueryTvEventPagesByCompanyContractDisplayIdArgs = {
  companyContractDisplayId: Scalars['BigInteger'];
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryTvWebinarsByCompanyContractDisplayIdArgs = {
  companyContractDisplayId: Scalars['BigInteger'];
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryEmbedTvWebinarArgs = {
  tvWebinarId: Scalars['BigInteger'];
};


export type QueryEventTimetableArgs = {
  tvEventId?: Maybe<Scalars['BigInteger']>;
};


export type QueryEventApplicantArgs = {
  tvEventId: Scalars['BigInteger'];
};

export type RegisterFormItemAnswer = {
  /**  form item id */
  formItemId: Scalars['BigInteger'];
  /**  form item answer id */
  formItemAnswerId: Scalars['BigInteger'];
  /**  answer text */
  answerText?: Maybe<Scalars['String']>;
};

export type RegisteredWebinars = {
  __typename?: 'RegisteredWebinars';
  schedule: Scalars['LocalDate'];
  webinars: Array<TvWebinar>;
};

export type RelTvEventCompanyContract = {
  __typename?: 'RelTvEventCompanyContract';
  tvEventId: Scalars['BigInteger'];
  companyContractId: Scalars['String'];
  companyContract: CompanyContract;
  displayOrder?: Maybe<Scalars['Int']>;
};


export type RelTvEventCompanyContractCompanyContractArgs = {
  withCompanysNoLogo?: Maybe<Scalars['Boolean']>;
  companyNoImageLogoType?: Maybe<CompanyNoImageLogoType>;
};

export type RelTvEventScheduleTvWebinar = {
  __typename?: 'RelTvEventScheduleTvWebinar';
  tvEventScheduleId: Scalars['String'];
  tvWebinarId: Scalars['BigInteger'];
};

export type RelTvWebinarRelationCatalogUrl = {
  __typename?: 'RelTvWebinarRelationCatalogUrl';
  tvWebinarId: Scalars['BigInteger'];
  tvWebinarRelationCatalogUrl: Scalars['String'];
  tvWebinarRelationCatalogName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
};

export type RelTvWebinarSystem = {
  __typename?: 'RelTvWebinarSystem';
  tvWebinarId: Scalars['BigInteger'];
  tvWebinarVimeoLiveEventId?: Maybe<Scalars['BigInteger']>;
  tvWebinarLiveUrl?: Maybe<Scalars['String']>;
  tvWebinarVimeoLiveEventStatus: TvWebinarStatus;
  tvWebinarVimeoVideoId?: Maybe<Scalars['BigInteger']>;
  tvWebinarOndemandUrl?: Maybe<Scalars['String']>;
  tvWebinarVimeoVideoStatus: TvWebinarVimeoVideoStatus;
  tvWebinarMedialiveChannelId?: Maybe<Scalars['BigInteger']>;
  tvWebinarMedialiveChannelStatus: TvWebinarStatus;
  tvWebinarSlidoEventId?: Maybe<Scalars['String']>;
  tvWebinarChatUrl?: Maybe<Scalars['String']>;
  createdOperator?: Maybe<Scalars['BigInteger']>;
  lastUpdatedOperator?: Maybe<Scalars['BigInteger']>;
  createTimestamp?: Maybe<Scalars['LocalDateTime']>;
  updateTimestamp?: Maybe<Scalars['LocalDateTime']>;
};

/**  既存のTvWebinarは情報量が冗長なのでコンパクト版の型 */
export type RelatedWebinar = {
  __typename?: 'RelatedWebinar';
  id: Scalars['ID'];
  companyContractId: Scalars['String'];
  /**  Via CompanyNameFullDataLoader */
  companyNameFull?: Maybe<Scalars['String']>;
  tvWebinarPublishFlag?: Maybe<TvWebinarPublishFlag>;
  tvWebinarPublishStartDatetime?: Maybe<Scalars['LocalDateTime']>;
  tvWebinarPublishEndDatetime?: Maybe<Scalars['LocalDateTime']>;
  tvWebinarTitle?: Maybe<Scalars['String']>;
  tvWebinarSubtitle?: Maybe<Scalars['String']>;
  tvWebinarStreamingStartDatetime?: Maybe<Scalars['LocalDateTime']>;
  tvWebinarStreamingEndDatetime?: Maybe<Scalars['LocalDateTime']>;
  tvWebinarMoviePlayTime?: Maybe<Scalars['LocalTime']>;
  tvWebinarMainImgFilePath?: Maybe<Scalars['String']>;
  tvWebinarOndemandFlag?: Maybe<TvWebinarOndemandFlag>;
  tvWebinarSpeakerName?: Maybe<Scalars['String']>;
  tvWebinarSpeakerImgFilePath?: Maybe<Scalars['String']>;
  tvWebinarSpeakerDepartment?: Maybe<Scalars['String']>;
  /**  Via LoggedInUserWatchStatusDataLoader */
  loggedInUserWatchStatus?: Maybe<TvWatchLaterResult>;
  createTimestamp?: Maybe<Scalars['LocalDateTime']>;
  updateTimestamp?: Maybe<Scalars['LocalDateTime']>;
};

export type TvDocument = {
  __typename?: 'TvDocument';
  documentId: Scalars['String'];
  documentName: Scalars['String'];
  documentUrl?: Maybe<Scalars['String']>;
  documentImageUrl?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
};

export type TvEventApplyInput = {
  tvEventId: Scalars['BigInteger'];
  userAuthentication?: Maybe<UserAuthenticationInput>;
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  phoneNumber: Scalars['String'];
  companyName: Scalars['String'];
  departmentName: Scalars['String'];
  subdivisionCode: Scalars['String'];
};

export type TvEventCatalog = {
  __typename?: 'TvEventCatalog';
  cluezCatalogId: Scalars['ID'];
  documentId: Scalars['String'];
  companyId: Scalars['BigInteger'];
  catalogImgUrl: Scalars['String'];
  catalogTitle: Scalars['String'];
  companyName: Scalars['String'];
};

/**  イベント概要 */
export type TvEventOverview = {
  __typename?: 'TvEventOverview';
  id: Scalars['ID'];
  tvEventId: Scalars['BigInteger'];
  displayOrder: Scalars['Int'];
  imagePath: Scalars['String'];
  description: Scalars['String'];
};

export type TvEventPage = {
  __typename?: 'TvEventPage';
  tvEventId: Scalars['BigInteger'];
  tvEventTitle: Scalars['String'];
  tvEventSubtitle?: Maybe<Scalars['String']>;
  tvEventDescription?: Maybe<Scalars['String']>;
  tvEventMainImgFilePath?: Maybe<Scalars['String']>;
  tvEventOgpImgFilePath?: Maybe<Scalars['String']>;
  tvEventStartDatetime?: Maybe<Scalars['LocalDateTime']>;
  tvEventEndDatetime?: Maybe<Scalars['LocalDateTime']>;
  tvEventPublishFlag: TvEventPublishFlag;
  tvEventPublishStartDatetime?: Maybe<Scalars['LocalDateTime']>;
  tvEventPublishEndDatetime?: Maybe<Scalars['LocalDateTime']>;
  tvEventType: TvEventType;
  otherExhibitors: Scalars['Int'];
  createdOperator?: Maybe<Scalars['BigInteger']>;
  lastUpdatedOperator?: Maybe<Scalars['BigInteger']>;
  createTimestamp?: Maybe<Scalars['LocalDateTime']>;
  updateTimestamp?: Maybe<Scalars['LocalDateTime']>;
  relTvEventCompanyContracts?: Maybe<Array<Maybe<RelTvEventCompanyContract>>>;
  /**  登録ボタン基本背景色 */
  tvEventLpCtaButtonBackgroundColor?: Maybe<Scalars['String']>;
  /**  登録ボタン基本テキスト色 */
  tvEventLpCtaButtonTextColor?: Maybe<Scalars['String']>;
  /**  イベント概要セクション背景基本色 */
  tvEventLpBackgroundColor?: Maybe<Scalars['String']>;
  /**
   * イベント概要セクション情報 (画像とテキスト複数件)
   * Nested Fetcher
   */
  tvEventOverviews?: Maybe<Array<TvEventOverview>>;
  /**
   * このイベントに関連するオンデマンドウェビナー
   * Nested Fetcher
   */
  relTvEventLpTvOndemandWebinars?: Maybe<Array<RelatedWebinar>>;
  /**  Nested Fetcher */
  tvExhibitionReports: Array<RelatedWebinar>;
  /**
   * このイベントに登録したウェビナー
   * Nested Fetcher
   */
  eventTimetable?: Maybe<Array<RegisteredWebinars>>;
  /**
   * 旧イベントページ用
   * nested fetcher
   */
  tvEventSchedules?: Maybe<TvEventSchedules>;
  /**  パートナー企業名一覧 */
  tvEventPartnerCompanies: Array<Scalars['String']>;
  /**  イベント参加登録の要否判定用 */
  eventApplyFlag: Scalars['Boolean'];
  catalogs?: Maybe<Array<TvEventCatalog>>;
};

export type TvEventPages = {
  __typename?: 'TvEventPages';
  /**  event page contents */
  content?: Maybe<Array<Maybe<TvEventPage>>>;
  /**  current page number */
  page: Scalars['Int'];
  /**  item count per page */
  size: Scalars['Int'];
  /**  total item count */
  totalElements: Scalars['Long'];
  /**  total page count */
  totalPages: Scalars['Int'];
};

export enum TvEventPublishFlag {
  Private = 'PRIVATE',
  Publish = 'PUBLISH'
}

export type TvEventSchedule = {
  __typename?: 'TvEventSchedule';
  tvEventScheduleId: Scalars['String'];
  tvEventId: Scalars['BigInteger'];
  tvEventType?: Maybe<TvEventType>;
  tvEventScheduleSequence: Scalars['Int'];
  tvEventScheduleDate?: Maybe<Scalars['LocalDate']>;
  tvEventScheduleStartTime?: Maybe<Scalars['LocalTime']>;
  tvEventScheduleEndTime?: Maybe<Scalars['LocalTime']>;
  content?: Maybe<Array<Maybe<TvWebinar>>>;
};

export type TvEventSchedules = {
  __typename?: 'TvEventSchedules';
  /**  tvEventSchedule page contents */
  content?: Maybe<Array<TvEventSchedule>>;
  /**  current page number */
  page: Scalars['Int'];
  /**  item count per page */
  size: Scalars['Int'];
  /**  total item count */
  totalElements: Scalars['Long'];
  /**  total page count */
  totalPages: Scalars['Int'];
};

export enum TvEventType {
  All = 'ALL',
  Event = 'EVENT',
  Exhibition = 'EXHIBITION',
  Feature = 'FEATURE',
  EventLp = 'EVENT_LP'
}

export type TvNotificationSetting = {
  __typename?: 'TvNotificationSetting';
  notificationSettingName: Scalars['String'];
  notificationSettingDescription: Scalars['String'];
  notificationSettingType: NotificationSettingType;
  notificationSettingFlag: NotificationSettingFlag;
};

export type TvNotificationSettingInput = {
  /**  Initial release is optional for backward compatibility(Default REMIND_NOTICE) */
  notificationSettingType?: Maybe<NotificationSettingType>;
  notificationSettingFlag: NotificationSettingFlag;
};

export type TvTopMainVisual = {
  __typename?: 'TvTopMainVisual';
  tvTopMainVisualId: Scalars['BigInteger'];
  tvTopMainVisualTvEventIds: Array<Scalars['BigInteger']>;
  tvTopMainVisualTvEvents: Array<TvEventPage>;
  tvTopMainVisualPublishStartDatetime?: Maybe<Scalars['LocalDateTime']>;
  tvTopMainVisualPublishEndDatetime?: Maybe<Scalars['LocalDateTime']>;
  createdOperator?: Maybe<Scalars['BigInteger']>;
  lastUpdatedOperator?: Maybe<Scalars['BigInteger']>;
  createTimestamp?: Maybe<Scalars['LocalDateTime']>;
  updateTimestamp?: Maybe<Scalars['LocalDateTime']>;
};

export type TvTopMainVisuals = {
  __typename?: 'TvTopMainVisuals';
  /**  webinar page contents */
  content?: Maybe<Array<Maybe<TvTopMainVisual>>>;
  /**  current page number */
  page: Scalars['Int'];
  /**  item count per page */
  size: Scalars['Int'];
  /**  total item count */
  totalElements: Scalars['Long'];
  /**  total page count */
  totalPages: Scalars['Int'];
};

export type TvTopRanking = {
  __typename?: 'TvTopRanking';
  tvTopRankingId: Scalars['BigInteger'];
  tvTopRankingTitle: Scalars['String'];
  tvTopRankingTvWebinarIds: Array<Scalars['BigInteger']>;
  tvTopRankingTvWebinars: Array<TvWebinar>;
  tvTopRankingPublishStartDatetime?: Maybe<Scalars['LocalDateTime']>;
  tvTopRankingPublishEndDatetime?: Maybe<Scalars['LocalDateTime']>;
  createdOperator?: Maybe<Scalars['BigInteger']>;
  lastUpdatedOperator?: Maybe<Scalars['BigInteger']>;
  createTimestamp?: Maybe<Scalars['LocalDateTime']>;
  updateTimestamp?: Maybe<Scalars['LocalDateTime']>;
};

export type TvTopRankings = {
  __typename?: 'TvTopRankings';
  /**  webinar page contents */
  content?: Maybe<Array<Maybe<TvTopRanking>>>;
  /**  current page number */
  page: Scalars['Int'];
  /**  item count per page */
  size: Scalars['Int'];
  /**  total item count */
  totalElements: Scalars['Long'];
  /**  total page count */
  totalPages: Scalars['Int'];
};

export type TvWatchLater = {
  __typename?: 'TvWatchLater';
  tvWatchLaterId: Scalars['BigInteger'];
  userId: Scalars['BigInteger'];
  tvWebinarId: Scalars['BigInteger'];
  createdOperator?: Maybe<Scalars['BigInteger']>;
  lastUpdatedOperator?: Maybe<Scalars['BigInteger']>;
  createTimestamp?: Maybe<Scalars['LocalDateTime']>;
  updateTimestamp?: Maybe<Scalars['LocalDateTime']>;
  content?: Maybe<TvWebinar>;
};

export enum TvWatchLaterRegistrationStatus {
  Registered = 'REGISTERED',
  Temporary = 'TEMPORARY'
}

export type TvWatchLaterResult = {
  __typename?: 'TvWatchLaterResult';
  tvWebinarId: Scalars['BigInteger'];
  tvWatchLaterId: Scalars['BigInteger'];
};

export enum TvWatchLaterStatus {
  All = 'ALL',
  BeforeStreaming = 'BEFORE_STREAMING',
  Streaming = 'STREAMING',
  Ondemand = 'ONDEMAND'
}

export type TvWatchLaters = {
  __typename?: 'TvWatchLaters';
  /**  WatchLater page contents */
  content?: Maybe<Array<Maybe<TvWatchLater>>>;
  /**  current page number */
  page: Scalars['Int'];
  /**  item count per page */
  size: Scalars['Int'];
  /**  total item count */
  totalElements: Scalars['Long'];
  /**  total page count */
  totalPages: Scalars['Int'];
};

export type TvWebinar = {
  __typename?: 'TvWebinar';
  tvWebinarId: Scalars['BigInteger'];
  companyContractId: Scalars['String'];
  /**  company contract */
  companyContract: CompanyContract;
  tvWebinarPublishFlag?: Maybe<TvWebinarPublishFlag>;
  tvWebinarPublishStartDatetime?: Maybe<Scalars['LocalDateTime']>;
  tvWebinarPublishEndDatetime?: Maybe<Scalars['LocalDateTime']>;
  tvWebinarTitle?: Maybe<Scalars['String']>;
  tvWebinarSubtitle?: Maybe<Scalars['String']>;
  tvWebinarDescription?: Maybe<Scalars['String']>;
  tvWebinarRecommendSentence1?: Maybe<Scalars['String']>;
  tvWebinarRecommendSentence2?: Maybe<Scalars['String']>;
  tvWebinarRecommendSentence3?: Maybe<Scalars['String']>;
  tvWebinarMovieFilePath?: Maybe<Scalars['String']>;
  tvWebinarStreamingStartDatetime?: Maybe<Scalars['LocalDateTime']>;
  tvWebinarStreamingEndDatetime?: Maybe<Scalars['LocalDateTime']>;
  tvWebinarMoviePlayTime?: Maybe<Scalars['LocalTime']>;
  tvWebinarMainImgFilePath?: Maybe<Scalars['String']>;
  tvWebinarOndemandFlag?: Maybe<TvWebinarOndemandFlag>;
  tvWebinarOndemandFirstDatetime?: Maybe<Scalars['LocalDateTime']>;
  tvWebinarSpeakerName?: Maybe<Scalars['String']>;
  tvWebinarSpeakerImgFilePath?: Maybe<Scalars['String']>;
  tvWebinarSpeakerDepartment?: Maybe<Scalars['String']>;
  tvWebinarSpeakerBusinessCardDocument?: Maybe<TvDocument>;
  tvWebinarProjectionDocument?: Maybe<TvDocument>;
  relTvWebinarSystem?: Maybe<RelTvWebinarSystem>;
  relTvWebinarRelationCatalogs?: Maybe<Array<Maybe<TvDocument>>>;
  tvEventPages?: Maybe<Array<Maybe<TvEventPage>>>;
  tvWebinarTranscriptions: Array<TvWebinarTranscription>;
  loggedInUserWatchStatus?: Maybe<TvWatchLaterResult>;
  /**  nested fetch */
  recommendedWebinars: Array<RelatedWebinar>;
  createdOperator?: Maybe<Scalars['BigInteger']>;
  lastUpdatedOperator?: Maybe<Scalars['BigInteger']>;
  createTimestamp?: Maybe<Scalars['LocalDateTime']>;
  updateTimestamp?: Maybe<Scalars['LocalDateTime']>;
  tvWebinarMaxGuestViewingTimeInSeconds?: Maybe<Scalars['Int']>;
};


export type TvWebinarCompanyContractArgs = {
  withCompanysNoLogo?: Maybe<Scalars['Boolean']>;
  companyNoImageLogoType?: Maybe<CompanyNoImageLogoType>;
};

export enum TvWebinarDeliveryStatus {
  All = 'ALL',
  BeforeStreaming = 'BEFORE_STREAMING',
  Streaming = 'STREAMING',
  Ondemand = 'ONDEMAND'
}

export enum TvWebinarDocumentType {
  AperzaTvBusinesscard = 'APERZA_TV_BUSINESSCARD',
  AperzaTvProjectionDocument = 'APERZA_TV_PROJECTION_DOCUMENT'
}

export type TvWebinarInquiryInput = {
  /**  webinar id */
  tvWebinarId: Scalars['BigInteger'];
  /**  inquiry text */
  inquiryText?: Maybe<Scalars['String']>;
  /**  company name of AperzaID */
  companyName?: Maybe<Scalars['String']>;
  /**  department name of AperzaID */
  departmentName?: Maybe<Scalars['String']>;
  /**  last name of AperzaID */
  lastName?: Maybe<Scalars['String']>;
  /**  first name of AperzaID */
  firstName?: Maybe<Scalars['String']>;
};

export enum TvWebinarOndemandFlag {
  NoOndemand = 'NO_ONDEMAND',
  Ondemand = 'ONDEMAND'
}

export enum TvWebinarPublishFlag {
  Private = 'PRIVATE',
  Publish = 'PUBLISH'
}

export type TvWebinarQuestionnaire = {
  __typename?: 'TvWebinarQuestionnaire';
  /**  webinar id */
  tvWebinarId: Scalars['BigInteger'];
  /**  company contract id */
  companyContractId: Scalars['String'];
  /**  company contract */
  companyContract: CompanyContract;
  /**  webinar title */
  tvWebinarTitle: Scalars['String'];
  /**  image url for questionnaire */
  questionnaireImageUrl: Scalars['String'];
  /**  title for questionnaire */
  questionnaireTitle: Scalars['String'];
  /**  subTitle for questionnaire */
  questionnaireSubTitle?: Maybe<Scalars['String']>;
  /**  document type */
  documentType: TvWebinarDocumentType;
  /**  form */
  form?: Maybe<Form>;
  /**  last name for AperzaID */
  lastName?: Maybe<Scalars['String']>;
  /**  first name  for AperzaID */
  firstName?: Maybe<Scalars['String']>;
  /**  tel  for AperzaID(PhoneNumber) */
  tel?: Maybe<Scalars['String']>;
  /**  company name  for AperzaID */
  companyName?: Maybe<Scalars['String']>;
  /**  office name  for AperzaID */
  officeName?: Maybe<Scalars['String']>;
  /**  department name  for AperzaID */
  departmentName?: Maybe<Scalars['String']>;
  /**  business type  for AperzaID */
  businessType?: Maybe<BusinessType>;
  /**  job category type  for AperzaID */
  jobCategoryType?: Maybe<JobCategoryType>;
  /**  post class for AperzaID(postCode) */
  postClass?: Maybe<PostClass>;
  /**  post name for AperzaID */
  postName?: Maybe<Scalars['String']>;
  /**  sudivition code  for AperzaID */
  subdivisionCode?: Maybe<Scalars['String']>;
};


export type TvWebinarQuestionnaireCompanyContractArgs = {
  withCompanysNoLogo?: Maybe<Scalars['Boolean']>;
  companyNoImageLogoType?: Maybe<CompanyNoImageLogoType>;
};

export type TvWebinarQuestionnaireAnswerInput = {
  /**  tv webinar id */
  tvWebinarId: Scalars['BigInteger'];
  /**  document type */
  documentType: TvWebinarDocumentType;
  /**  form answers */
  answers: Array<RegisterFormItemAnswer>;
  /**  last name for AperzaID */
  lastName?: Maybe<Scalars['String']>;
  /**  first name  for AperzaID */
  firstName?: Maybe<Scalars['String']>;
  /**  tel  for AperzaID(PhoneNumber) */
  tel?: Maybe<Scalars['String']>;
  /**  company name  for AperzaID */
  companyName?: Maybe<Scalars['String']>;
  /**  office name  for AperzaID */
  officeName?: Maybe<Scalars['String']>;
  /**  department name  for AperzaID */
  departmentName?: Maybe<Scalars['String']>;
  /**  business type  for AperzaID */
  businessType?: Maybe<BusinessType>;
  /**  job category type  for AperzaID */
  jobCategoryType?: Maybe<JobCategoryType>;
  /**  post class for AperzaID(postCode) */
  postClass: PostClass;
  /** post name for AperzaID */
  postName?: Maybe<Scalars['String']>;
  /**  sudivition code  for AperzaID */
  subdivisionCode?: Maybe<Scalars['String']>;
};

export type TvWebinarSearchResults = {
  __typename?: 'TvWebinarSearchResults';
  /**  webinar page contents */
  content?: Maybe<Array<TvWebinar>>;
  /**  current page number */
  page: Scalars['Int'];
  /**  item count per page */
  size: Scalars['Int'];
  /**  total item count */
  totalElements: Scalars['Long'];
  /**  total page count */
  totalPages: Scalars['Int'];
  /**  events */
  tvEventPages?: Maybe<Array<TvEventPage>>;
  /**  future events */
  futureTvEventPages?: Maybe<Array<TvEventPage>>;
  /**  old events */
  oldTvEventPages?: Maybe<Array<TvEventPage>>;
  /**  features */
  tvFeaturePages?: Maybe<Array<TvEventPage>>;
};

export enum TvWebinarStatus {
  NotCreated = 'NOT_CREATED',
  Configured = 'CONFIGURED',
  Stopped = 'STOPPED',
  Deleted = 'DELETED'
}

export type TvWebinarTranscription = {
  __typename?: 'TvWebinarTranscription';
  transcription: Scalars['String'];
};

export enum TvWebinarVimeoVideoStatus {
  NotCreated = 'NOT_CREATED',
  Created = 'CREATED',
  Undefined = 'UNDEFINED',
  Deleted = 'DELETED',
  Replaced = 'REPLACED'
}

export type TvWebinars = {
  __typename?: 'TvWebinars';
  /**  webinar page contents */
  content?: Maybe<Array<Maybe<TvWebinar>>>;
  /**  current page number */
  page: Scalars['Int'];
  /**  item count per page */
  size: Scalars['Int'];
  /**  total item count */
  totalElements: Scalars['Long'];
  /**  total page count */
  totalPages: Scalars['Int'];
};

export type UnreadCount = {
  __typename?: 'UnreadCount';
  unreadCount: Scalars['BigInteger'];
};

export type User = {
  __typename?: 'User';
  /**  User Type Guest or Member */
  userType: UserType;
  /**  User Id (on Member) */
  userId?: Maybe<Scalars['BigInteger']>;
  /**  Display Name(Nick Name) (on Member) */
  displayName?: Maybe<Scalars['String']>;
  /**  mail address (on Member) */
  mailAddress?: Maybe<Scalars['String']>;
  /**  user photo url (on Member) */
  photoUrl?: Maybe<Scalars['String']>;
  /**  last name (on Member) */
  lastName?: Maybe<Scalars['String']>;
  /**  first name (on Member) */
  firstName?: Maybe<Scalars['String']>;
  /**  phonetic last name (on Member) */
  phoneticLastName?: Maybe<Scalars['String']>;
  /**  phonetic first name (on Member) */
  phoneticFirstName?: Maybe<Scalars['String']>;
  /**  company name */
  companyName?: Maybe<Scalars['String']>;
  /**  office name */
  officeName?: Maybe<Scalars['String']>;
  /**  department name */
  departmentName?: Maybe<Scalars['String']>;
  /**  tel */
  tel?: Maybe<Scalars['String']>;
  subdivisionCode?: Maybe<Scalars['String']>;
  buyer?: Maybe<Buyer>;
};

export type UserAuthenticationInput = {
  mailAddress?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export enum UserType {
  Guest = 'GUEST',
  Member = 'MEMBER'
}

export enum WatchLaterRegistrationRoute {
  Event = 'EVENT',
  Webinar = 'WEBINAR'
}


export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String'];
};

export type RegisterTvWebinarQuestionnaireMutationVariables = Exact<{
  tvWebinarQuestionnaireAnswer: TvWebinarQuestionnaireAnswerInput;
}>;


export type RegisterTvWebinarQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'registerTvWebinarQuestionnaire'>
);

export type CreateTvWebinarInquiryMutationVariables = Exact<{
  tvWebinarInquiry: TvWebinarInquiryInput;
}>;


export type CreateTvWebinarInquiryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createTvWebinarInquiry'>
);

export type CreateTvEventWatchLaterMutationVariables = Exact<{
  tvWebinarIds: Array<Scalars['BigInteger']> | Scalars['BigInteger'];
  tvEventId: Scalars['BigInteger'];
}>;


export type CreateTvEventWatchLaterMutation = (
  { __typename?: 'Mutation' }
  & { createTvEventWatchLater: (
    { __typename?: 'CreateTvWatchResult' }
    & Pick<CreateTvWatchResult, 'tvWatchLaterRegistrationStatus'>
    & { tvWatchLaterResults?: Maybe<Array<Maybe<(
      { __typename?: 'TvWatchLaterResult' }
      & Pick<TvWatchLaterResult, 'tvWebinarId' | 'tvWatchLaterId'>
    )>>> }
  ) }
);

export type UpdateTvNotificationSettingMutationVariables = Exact<{
  notificationSettingType: NotificationSettingType;
  notificationSettingFlag: NotificationSettingFlag;
}>;


export type UpdateTvNotificationSettingMutation = (
  { __typename?: 'Mutation' }
  & { updateTvNotificationSetting: Array<(
    { __typename?: 'TvNotificationSetting' }
    & Pick<TvNotificationSetting, 'notificationSettingName' | 'notificationSettingDescription' | 'notificationSettingType' | 'notificationSettingFlag'>
  )> }
);

export type ApplyTvEventMutationVariables = Exact<{
  tvEventApplyInput: TvEventApplyInput;
}>;


export type ApplyTvEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'applyTvEvent'>
);

export type CompanyPageQueryVariables = Exact<{
  companyContractDisplayId: Scalars['BigInteger'];
}>;


export type CompanyPageQuery = (
  { __typename?: 'Query' }
  & { companyPage?: Maybe<(
    { __typename?: 'CompanyPage' }
    & Pick<CompanyPage, 'id' | 'companyContractDisplayId' | 'inquiryFlag' | 'companyPagePublishFlag'>
    & { companyPageDetail?: Maybe<(
      { __typename?: 'CompanyPageDetail' }
      & CompanyPageDetailFieldsFragment
    )> }
  )> }
);

export type CompanyPageEventsQueryVariables = Exact<{
  companyContractDisplayId: Scalars['BigInteger'];
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
}>;


export type CompanyPageEventsQuery = (
  { __typename?: 'Query' }
  & { tvEventPagesByCompanyContractDisplayId: (
    { __typename?: 'TvEventPages' }
    & Pick<TvEventPages, 'page' | 'size' | 'totalPages' | 'totalElements'>
    & { content?: Maybe<Array<Maybe<(
      { __typename?: 'TvEventPage' }
      & { relTvEventCompanyContracts?: Maybe<Array<Maybe<(
        { __typename?: 'RelTvEventCompanyContract' }
        & RelTvEventCompanyContractFieldsFragment
      )>>> }
      & TvEventPageFieldsFragment
    )>>> }
  ) }
);

export type CompanyPageWebinarsQueryVariables = Exact<{
  companyContractDisplayId: Scalars['BigInteger'];
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
}>;


export type CompanyPageWebinarsQuery = (
  { __typename?: 'Query' }
  & { tvWebinarsByCompanyContractDisplayId: (
    { __typename?: 'TvWebinars' }
    & Pick<TvWebinars, 'page' | 'size' | 'totalPages' | 'totalElements'>
    & { content?: Maybe<Array<Maybe<(
      { __typename?: 'TvWebinar' }
      & { companyContract: (
        { __typename?: 'CompanyContract' }
        & CompanyContractFieldsFragment
      ) }
      & TvWebinarFieldsFragment
    )>>> }
  ) }
);

export type TvEventPageQueryVariables = Exact<{
  eventId: Scalars['BigInteger'];
}>;


export type TvEventPageQuery = (
  { __typename?: 'Query' }
  & { tvEventPage?: Maybe<(
    { __typename?: 'TvEventPage' }
    & Pick<TvEventPage, 'tvEventId' | 'tvEventTitle' | 'tvEventSubtitle' | 'tvEventDescription' | 'tvEventMainImgFilePath' | 'tvEventStartDatetime' | 'tvEventEndDatetime' | 'tvEventPublishStartDatetime' | 'tvEventPublishEndDatetime' | 'tvEventType' | 'tvEventLpCtaButtonBackgroundColor' | 'tvEventLpCtaButtonTextColor' | 'tvEventLpBackgroundColor' | 'eventApplyFlag' | 'tvEventPartnerCompanies'>
    & { catalogs?: Maybe<Array<(
      { __typename?: 'TvEventCatalog' }
      & Pick<TvEventCatalog, 'cluezCatalogId' | 'documentId' | 'companyId' | 'catalogImgUrl' | 'catalogTitle' | 'companyName'>
    )>>, relTvEventCompanyContracts?: Maybe<Array<Maybe<(
      { __typename?: 'RelTvEventCompanyContract' }
      & RelTvEventCompanyContractFieldsFragment
    )>>>, tvEventOverviews?: Maybe<Array<(
      { __typename?: 'TvEventOverview' }
      & Pick<TvEventOverview, 'id' | 'tvEventId' | 'displayOrder' | 'imagePath' | 'description'>
    )>>, relTvEventLpTvOndemandWebinars?: Maybe<Array<(
      { __typename?: 'RelatedWebinar' }
      & Pick<RelatedWebinar, 'id' | 'companyContractId' | 'tvWebinarPublishFlag' | 'tvWebinarPublishStartDatetime' | 'tvWebinarPublishEndDatetime' | 'tvWebinarTitle' | 'tvWebinarSubtitle' | 'tvWebinarStreamingStartDatetime' | 'tvWebinarStreamingEndDatetime' | 'tvWebinarMoviePlayTime' | 'tvWebinarMainImgFilePath' | 'tvWebinarOndemandFlag' | 'tvWebinarSpeakerName' | 'tvWebinarSpeakerImgFilePath' | 'tvWebinarSpeakerDepartment' | 'createTimestamp' | 'updateTimestamp'>
    )>>, tvExhibitionReports: Array<(
      { __typename?: 'RelatedWebinar' }
      & Pick<RelatedWebinar, 'id' | 'companyContractId' | 'companyNameFull' | 'tvWebinarPublishFlag' | 'tvWebinarPublishStartDatetime' | 'tvWebinarPublishEndDatetime' | 'tvWebinarTitle' | 'tvWebinarSubtitle' | 'tvWebinarStreamingStartDatetime' | 'tvWebinarStreamingEndDatetime' | 'tvWebinarMoviePlayTime' | 'tvWebinarMainImgFilePath' | 'tvWebinarOndemandFlag' | 'tvWebinarSpeakerName' | 'tvWebinarSpeakerImgFilePath' | 'tvWebinarSpeakerDepartment' | 'createTimestamp' | 'updateTimestamp'>
      & { loggedInUserWatchStatus?: Maybe<(
        { __typename?: 'TvWatchLaterResult' }
        & Pick<TvWatchLaterResult, 'tvWebinarId' | 'tvWatchLaterId'>
      )> }
    )>, eventTimetable?: Maybe<Array<(
      { __typename?: 'RegisteredWebinars' }
      & Pick<RegisteredWebinars, 'schedule'>
      & { webinars: Array<(
        { __typename?: 'TvWebinar' }
        & Pick<TvWebinar, 'tvWebinarId' | 'tvWebinarMainImgFilePath' | 'tvWebinarOndemandFlag' | 'tvWebinarStreamingStartDatetime' | 'tvWebinarStreamingEndDatetime' | 'tvWebinarPublishStartDatetime' | 'tvWebinarPublishEndDatetime' | 'tvWebinarMoviePlayTime' | 'tvWebinarTitle' | 'tvWebinarSubtitle' | 'tvWebinarDescription' | 'tvWebinarSpeakerName' | 'tvWebinarSpeakerImgFilePath' | 'tvWebinarSpeakerDepartment' | 'tvWebinarRecommendSentence1' | 'tvWebinarRecommendSentence2' | 'tvWebinarRecommendSentence3'>
        & { companyContract: (
          { __typename?: 'CompanyContract' }
          & Pick<CompanyContract, 'companyContractId' | 'companyContractDisplayId' | 'companyPagePublishFlag'>
          & { companyLang?: Maybe<(
            { __typename?: 'CompanyLang' }
            & Pick<CompanyLang, 'companyLangId' | 'companyName' | 'companyLogoUrl' | 'companyNameFull'>
          )> }
        ), loggedInUserWatchStatus?: Maybe<(
          { __typename?: 'TvWatchLaterResult' }
          & Pick<TvWatchLaterResult, 'tvWebinarId' | 'tvWatchLaterId'>
        )> }
      )> }
    )>>, tvEventSchedules?: Maybe<(
      { __typename?: 'TvEventSchedules' }
      & { content?: Maybe<Array<(
        { __typename?: 'TvEventSchedule' }
        & Pick<TvEventSchedule, 'tvEventScheduleId' | 'tvEventId' | 'tvEventType' | 'tvEventScheduleSequence' | 'tvEventScheduleDate' | 'tvEventScheduleStartTime' | 'tvEventScheduleEndTime'>
        & { content?: Maybe<Array<Maybe<(
          { __typename?: 'TvWebinar' }
          & Pick<TvWebinar, 'tvWebinarId' | 'tvWebinarSubtitle' | 'tvWebinarDescription' | 'tvWebinarSpeakerName' | 'tvWebinarSpeakerDepartment' | 'tvWebinarSpeakerImgFilePath' | 'tvWebinarPublishStartDatetime' | 'tvWebinarPublishEndDatetime' | 'tvWebinarOndemandFlag' | 'tvWebinarStreamingStartDatetime' | 'tvWebinarStreamingEndDatetime' | 'tvWebinarTitle' | 'tvWebinarMainImgFilePath'>
          & { companyContract: (
            { __typename?: 'CompanyContract' }
            & Pick<CompanyContract, 'companyContractId'>
            & { companyLang?: Maybe<(
              { __typename?: 'CompanyLang' }
              & Pick<CompanyLang, 'companyLangId' | 'companyName' | 'companyLogoUrl' | 'companyNameFull'>
            )> }
          ), loggedInUserWatchStatus?: Maybe<(
            { __typename?: 'TvWatchLaterResult' }
            & Pick<TvWatchLaterResult, 'tvWebinarId' | 'tvWatchLaterId'>
          )> }
        )>>> }
      )>> }
    )> }
  )> }
);

export type EventTimeTableQueryVariables = Exact<{
  tvEventId: Scalars['BigInteger'];
}>;


export type EventTimeTableQuery = (
  { __typename?: 'Query' }
  & { eventTimetable: Array<(
    { __typename?: 'RegisteredWebinars' }
    & Pick<RegisteredWebinars, 'schedule'>
    & { webinars: Array<(
      { __typename?: 'TvWebinar' }
      & Pick<TvWebinar, 'tvWebinarId' | 'tvWebinarStreamingStartDatetime' | 'tvWebinarStreamingEndDatetime' | 'tvWebinarPublishStartDatetime' | 'tvWebinarPublishEndDatetime' | 'tvWebinarTitle' | 'tvWebinarMainImgFilePath'>
      & { companyContract: (
        { __typename?: 'CompanyContract' }
        & Pick<CompanyContract, 'companyContractId'>
        & { companyLang?: Maybe<(
          { __typename?: 'CompanyLang' }
          & Pick<CompanyLang, 'companyLangId' | 'companyName' | 'companyLogoUrl' | 'companyNameFull'>
        )> }
      ), loggedInUserWatchStatus?: Maybe<(
        { __typename?: 'TvWatchLaterResult' }
        & Pick<TvWatchLaterResult, 'tvWebinarId' | 'tvWatchLaterId'>
      )> }
    )> }
  )> }
);

export type EventApplicantQueryVariables = Exact<{
  tvEventId: Scalars['BigInteger'];
}>;


export type EventApplicantQuery = (
  { __typename?: 'Query' }
  & { eventApplicant?: Maybe<(
    { __typename?: 'EventApplicant' }
    & Pick<EventApplicant, 'eventId' | 'userId' | 'mailAddress'>
  )> }
);

export type TvEventSchedulesPaginationQueryVariables = Exact<{
  eventId: Scalars['BigInteger'];
  page: Scalars['Int'];
  size: Scalars['Int'];
}>;


export type TvEventSchedulesPaginationQuery = (
  { __typename?: 'Query' }
  & { tvEventSchedules?: Maybe<(
    { __typename?: 'TvEventSchedules' }
    & Pick<TvEventSchedules, 'page' | 'size' | 'totalElements' | 'totalPages'>
    & { content?: Maybe<Array<(
      { __typename?: 'TvEventSchedule' }
      & Pick<TvEventSchedule, 'tvEventScheduleId' | 'tvEventId' | 'tvEventScheduleSequence' | 'tvEventScheduleDate' | 'tvEventScheduleStartTime' | 'tvEventScheduleEndTime'>
      & { content?: Maybe<Array<Maybe<(
        { __typename?: 'TvWebinar' }
        & Pick<TvWebinar, 'tvWebinarId' | 'companyContractId' | 'tvWebinarPublishFlag' | 'tvWebinarPublishStartDatetime' | 'tvWebinarPublishEndDatetime' | 'tvWebinarTitle' | 'tvWebinarSubtitle' | 'tvWebinarDescription' | 'tvWebinarSpeakerName' | 'tvWebinarSpeakerDepartment' | 'tvWebinarMainImgFilePath' | 'tvWebinarSpeakerImgFilePath' | 'tvWebinarStreamingStartDatetime' | 'tvWebinarStreamingEndDatetime' | 'tvWebinarOndemandFlag'>
        & { companyContract: (
          { __typename?: 'CompanyContract' }
          & Pick<CompanyContract, 'companyContractId'>
          & { companyLang?: Maybe<(
            { __typename?: 'CompanyLang' }
            & Pick<CompanyLang, 'companyLangId' | 'companyName' | 'companyLogoUrl' | 'companyNameFull'>
          )> }
        ) }
      )>>> }
    )>> }
  )>, tvEventPage?: Maybe<(
    { __typename?: 'TvEventPage' }
    & Pick<TvEventPage, 'tvEventEndDatetime'>
  )> }
);

export type TvEventSchedulesQueryVariables = Exact<{
  tvEventId: Scalars['BigInteger'];
}>;


export type TvEventSchedulesQuery = (
  { __typename?: 'Query' }
  & { tvEventSchedules?: Maybe<(
    { __typename?: 'TvEventSchedules' }
    & { content?: Maybe<Array<(
      { __typename?: 'TvEventSchedule' }
      & Pick<TvEventSchedule, 'tvEventScheduleId' | 'tvEventId' | 'tvEventType' | 'tvEventScheduleSequence' | 'tvEventScheduleDate' | 'tvEventScheduleStartTime' | 'tvEventScheduleEndTime'>
      & { content?: Maybe<Array<Maybe<(
        { __typename?: 'TvWebinar' }
        & Pick<TvWebinar, 'tvWebinarId' | 'tvWebinarStreamingStartDatetime' | 'tvWebinarStreamingEndDatetime' | 'tvWebinarPublishStartDatetime' | 'tvWebinarPublishEndDatetime' | 'tvWebinarTitle' | 'tvWebinarMainImgFilePath'>
        & { companyContract: (
          { __typename?: 'CompanyContract' }
          & Pick<CompanyContract, 'companyContractId'>
          & { companyLang?: Maybe<(
            { __typename?: 'CompanyLang' }
            & Pick<CompanyLang, 'companyLangId' | 'companyName' | 'companyLogoUrl' | 'companyNameFull'>
          )> }
        ), loggedInUserWatchStatus?: Maybe<(
          { __typename?: 'TvWatchLaterResult' }
          & Pick<TvWatchLaterResult, 'tvWebinarId' | 'tvWatchLaterId'>
        )> }
      )>>> }
    )>> }
  )> }
);

export type CompanyContractFieldsFragment = (
  { __typename?: 'CompanyContract' }
  & Pick<CompanyContract, 'companyContractId' | 'companyContractDisplayId' | 'companyPagePublishFlag'>
  & { companyLang?: Maybe<(
    { __typename?: 'CompanyLang' }
    & Pick<CompanyLang, 'companyLangId' | 'companyName' | 'companyLogoUrl' | 'companyNameFull' | 'companyDescription'>
  )> }
);

export type RelTvEventCompanyContractFieldsFragment = (
  { __typename?: 'RelTvEventCompanyContract' }
  & Pick<RelTvEventCompanyContract, 'tvEventId' | 'companyContractId' | 'displayOrder'>
  & { companyContract: (
    { __typename?: 'CompanyContract' }
    & CompanyContractFieldsFragment
  ) }
);

export type TvEventPageFieldsFragment = (
  { __typename?: 'TvEventPage' }
  & Pick<TvEventPage, 'tvEventId' | 'tvEventTitle' | 'tvEventSubtitle' | 'tvEventDescription' | 'tvEventMainImgFilePath' | 'tvEventStartDatetime' | 'tvEventEndDatetime' | 'tvEventPublishStartDatetime' | 'tvEventPublishEndDatetime' | 'tvEventType' | 'tvEventPublishFlag' | 'otherExhibitors' | 'createdOperator' | 'lastUpdatedOperator' | 'createTimestamp' | 'updateTimestamp' | 'tvEventPartnerCompanies' | 'eventApplyFlag'>
  & { tvExhibitionReports: Array<(
    { __typename?: 'RelatedWebinar' }
    & Pick<RelatedWebinar, 'id' | 'companyContractId' | 'companyNameFull' | 'tvWebinarPublishFlag' | 'tvWebinarPublishStartDatetime' | 'tvWebinarPublishEndDatetime' | 'tvWebinarTitle' | 'tvWebinarSubtitle' | 'tvWebinarStreamingStartDatetime' | 'tvWebinarStreamingEndDatetime' | 'tvWebinarMoviePlayTime' | 'tvWebinarMainImgFilePath' | 'tvWebinarOndemandFlag' | 'tvWebinarSpeakerName' | 'tvWebinarSpeakerImgFilePath' | 'tvWebinarSpeakerDepartment' | 'createTimestamp' | 'updateTimestamp'>
    & { loggedInUserWatchStatus?: Maybe<(
      { __typename?: 'TvWatchLaterResult' }
      & Pick<TvWatchLaterResult, 'tvWebinarId' | 'tvWatchLaterId'>
    )> }
  )> }
);

export type TvWebinarFieldsFragment = (
  { __typename?: 'TvWebinar' }
  & Pick<TvWebinar, 'tvWebinarId' | 'tvWebinarMainImgFilePath' | 'tvWebinarOndemandFlag' | 'tvWebinarOndemandFirstDatetime' | 'tvWebinarStreamingStartDatetime' | 'tvWebinarStreamingEndDatetime' | 'tvWebinarMoviePlayTime' | 'tvWebinarTitle' | 'tvWebinarSubtitle' | 'tvWebinarSpeakerName' | 'tvWebinarSpeakerImgFilePath' | 'tvWebinarSpeakerDepartment' | 'tvWebinarRecommendSentence1' | 'tvWebinarRecommendSentence2' | 'tvWebinarRecommendSentence3'>
  & { loggedInUserWatchStatus?: Maybe<(
    { __typename?: 'TvWatchLaterResult' }
    & Pick<TvWatchLaterResult, 'tvWebinarId' | 'tvWatchLaterId'>
  )> }
);

export type CompanyPageDetailFieldsFragment = (
  { __typename?: 'CompanyPageDetail' }
  & Pick<CompanyPageDetail, 'id' | 'name' | 'logoUrl' | 'countryCode' | 'zipcode' | 'subdivisionName' | 'cityName' | 'address' | 'building' | 'tel' | 'fax' | 'industryType' | 'tagline' | 'description' | 'capitalDescription' | 'foundationDescription' | 'businessDescription' | 'mainCustomerDescription' | 'employeeDescription' | 'salesDescription' | 'websiteUrl'>
);

export type GetTvWebinarQuestionnaireQueryVariables = Exact<{
  tvWebinarId: Scalars['BigInteger'];
  documentType: TvWebinarDocumentType;
}>;


export type GetTvWebinarQuestionnaireQuery = (
  { __typename?: 'Query' }
  & { tvWebinarQuestionnaire?: Maybe<(
    { __typename?: 'TvWebinarQuestionnaire' }
    & Pick<TvWebinarQuestionnaire, 'tvWebinarId' | 'companyContractId' | 'tvWebinarTitle' | 'questionnaireImageUrl' | 'questionnaireTitle' | 'questionnaireSubTitle' | 'documentType' | 'lastName' | 'firstName' | 'tel' | 'companyName' | 'officeName' | 'departmentName' | 'businessType' | 'jobCategoryType' | 'postClass' | 'postName' | 'subdivisionCode'>
    & { companyContract: (
      { __typename?: 'CompanyContract' }
      & CompanyContractFieldsFragment
    ), form?: Maybe<(
      { __typename?: 'Form' }
      & Pick<Form, 'formId'>
      & { formItems?: Maybe<Array<(
        { __typename?: 'FormItem' }
        & Pick<FormItem, 'formItemId' | 'formItemTitle' | 'formItemPlaceHolder' | 'formItemType' | 'formItemAnswerRequired' | 'formItemValidation' | 'withOptionOtherComment' | 'requiredOptionOtherComment' | 'displayOrder'>
        & { formItemAnswers?: Maybe<Array<(
          { __typename?: 'FormItemAnswer' }
          & Pick<FormItemAnswer, 'formItemAnswerId' | 'formItemAnswerTitle' | 'displayOrder'>
        )>> }
      )>> }
    )> }
  )>, prefectures: Array<(
    { __typename?: 'Prefecture' }
    & Pick<Prefecture, 'code' | 'name'>
  )>, businessTypes: Array<(
    { __typename?: 'IdBusinessType' }
    & Pick<IdBusinessType, 'code' | 'name'>
    & { jobCategories: Array<(
      { __typename?: 'IdJobCategoryType' }
      & Pick<IdJobCategoryType, 'code' | 'name'>
    )> }
  )>, postClasses?: Maybe<Array<(
    { __typename?: 'IdPostClass' }
    & Pick<IdPostClass, 'code' | 'name'>
  )>> }
);

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'unreadMessageCount'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'userType' | 'userId' | 'displayName' | 'mailAddress' | 'photoUrl' | 'lastName' | 'firstName' | 'phoneticLastName' | 'phoneticFirstName' | 'companyName' | 'officeName' | 'departmentName' | 'tel' | 'subdivisionCode'>
    & { buyer?: Maybe<(
      { __typename?: 'Buyer' }
      & Pick<Buyer, 'approvalFlag'>
    )> }
  ) }
);

export type TvWebinarsQueryVariables = Exact<{
  tvWebinarDeliveryStatus: TvWebinarDeliveryStatus;
  page: Scalars['Int'];
  size: Scalars['Int'];
  isTopPage?: Maybe<Scalars['Boolean']>;
  withCompanysNoLogo?: Maybe<Scalars['Boolean']>;
  companyNoImageLogoType?: Maybe<CompanyNoImageLogoType>;
}>;


export type TvWebinarsQuery = (
  { __typename?: 'Query' }
  & { tvWebinars: (
    { __typename?: 'TvWebinars' }
    & Pick<TvWebinars, 'page' | 'size' | 'totalElements' | 'totalPages'>
    & { content?: Maybe<Array<Maybe<(
      { __typename?: 'TvWebinar' }
      & { companyContract: (
        { __typename?: 'CompanyContract' }
        & CompanyContractFieldsFragment
      ) }
      & TvWebinarFieldsFragment
    )>>> }
  ) }
);

export type EmbedTvWebinarQueryVariables = Exact<{
  tvWebinarId: Scalars['BigInteger'];
}>;


export type EmbedTvWebinarQuery = (
  { __typename?: 'Query' }
  & { embedTvWebinar?: Maybe<(
    { __typename?: 'EmbedTvWebinar' }
    & Pick<EmbedTvWebinar, 'id' | 'title' | 'videoId' | 'streamingStartDateTime' | 'streamingEndDateTime' | 'ondemandFlag' | 'mainImageFilePath' | 'moviePlayTime'>
  )> }
);

export type GetTvNotificationSettingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTvNotificationSettingQuery = (
  { __typename?: 'Query' }
  & { tvNotificationSetting: Array<(
    { __typename?: 'TvNotificationSetting' }
    & Pick<TvNotificationSetting, 'notificationSettingName' | 'notificationSettingDescription' | 'notificationSettingType' | 'notificationSettingFlag'>
  )> }
);

export type GetStaticApplyPageQueryVariables = Exact<{
  tvEventId: Scalars['BigInteger'];
}>;


export type GetStaticApplyPageQuery = (
  { __typename?: 'Query' }
  & { prefectures: Array<(
    { __typename?: 'Prefecture' }
    & Pick<Prefecture, 'code' | 'name'>
  )>, businessTypes: Array<(
    { __typename?: 'IdBusinessType' }
    & Pick<IdBusinessType, 'code' | 'name'>
    & { jobCategories: Array<(
      { __typename?: 'IdJobCategoryType' }
      & Pick<IdJobCategoryType, 'code' | 'name'>
    )> }
  )>, postClasses?: Maybe<Array<(
    { __typename?: 'IdPostClass' }
    & Pick<IdPostClass, 'code' | 'name'>
  )>>, tvEventPage?: Maybe<(
    { __typename?: 'TvEventPage' }
    & Pick<TvEventPage, 'tvEventId' | 'tvEventTitle' | 'tvEventSubtitle' | 'tvEventStartDatetime' | 'tvEventEndDatetime'>
    & { catalogs?: Maybe<Array<(
      { __typename?: 'TvEventCatalog' }
      & Pick<TvEventCatalog, 'cluezCatalogId' | 'documentId' | 'companyId' | 'catalogImgUrl' | 'catalogTitle' | 'companyName'>
    )>> }
  )> }
);

export type SearchTvWebinarsQueryVariables = Exact<{
  page: Scalars['Int'];
  size: Scalars['Int'];
  keyword: Scalars['String'];
  withTvEventsFlag: Scalars['Boolean'];
}>;


export type SearchTvWebinarsQuery = (
  { __typename?: 'Query' }
  & { searchTvWebinars: (
    { __typename?: 'TvWebinarSearchResults' }
    & Pick<TvWebinarSearchResults, 'page' | 'size' | 'totalElements' | 'totalPages'>
    & { content?: Maybe<Array<(
      { __typename?: 'TvWebinar' }
      & { companyContract: (
        { __typename?: 'CompanyContract' }
        & CompanyContractFieldsFragment
      ) }
      & TvWebinarFieldsFragment
    )>>, futureTvEventPages?: Maybe<Array<(
      { __typename?: 'TvEventPage' }
      & { relTvEventCompanyContracts?: Maybe<Array<Maybe<(
        { __typename?: 'RelTvEventCompanyContract' }
        & RelTvEventCompanyContractFieldsFragment
      )>>> }
      & TvEventPageFieldsFragment
    )>>, oldTvEventPages?: Maybe<Array<(
      { __typename?: 'TvEventPage' }
      & { relTvEventCompanyContracts?: Maybe<Array<Maybe<(
        { __typename?: 'RelTvEventCompanyContract' }
        & RelTvEventCompanyContractFieldsFragment
      )>>> }
      & TvEventPageFieldsFragment
    )>>, tvFeaturePages?: Maybe<Array<(
      { __typename?: 'TvEventPage' }
      & { relTvEventCompanyContracts?: Maybe<Array<Maybe<(
        { __typename?: 'RelTvEventCompanyContract' }
        & RelTvEventCompanyContractFieldsFragment
      )>>> }
      & TvEventPageFieldsFragment
    )>> }
  ) }
);

export type SearchAtWebinarTabQueryVariables = Exact<{
  page: Scalars['Int'];
  size: Scalars['Int'];
  keyword: Scalars['String'];
}>;


export type SearchAtWebinarTabQuery = (
  { __typename?: 'Query' }
  & { searchTvWebinars: (
    { __typename?: 'TvWebinarSearchResults' }
    & Pick<TvWebinarSearchResults, 'page' | 'size' | 'totalElements' | 'totalPages'>
    & { content?: Maybe<Array<(
      { __typename?: 'TvWebinar' }
      & { companyContract: (
        { __typename?: 'CompanyContract' }
        & CompanyContractFieldsFragment
      ) }
      & TvWebinarFieldsFragment
    )>> }
  ) }
);

export type SearchAtEventTabQueryVariables = Exact<{
  page: Scalars['Int'];
  size: Scalars['Int'];
  keyword: Scalars['String'];
}>;


export type SearchAtEventTabQuery = (
  { __typename?: 'Query' }
  & { searchTvEvents: (
    { __typename?: 'TvEventPages' }
    & Pick<TvEventPages, 'page' | 'size' | 'totalElements' | 'totalPages'>
    & { content?: Maybe<Array<Maybe<(
      { __typename?: 'TvEventPage' }
      & { relTvEventCompanyContracts?: Maybe<Array<Maybe<(
        { __typename?: 'RelTvEventCompanyContract' }
        & RelTvEventCompanyContractFieldsFragment
      )>>> }
      & TvEventPageFieldsFragment
    )>>> }
  ) }
);

export type SearchAtUpcomingEventTabQueryVariables = Exact<{
  page: Scalars['Int'];
  size: Scalars['Int'];
  keyword: Scalars['String'];
}>;


export type SearchAtUpcomingEventTabQuery = (
  { __typename?: 'Query' }
  & { searchFutureTvEvents: (
    { __typename?: 'TvEventPages' }
    & Pick<TvEventPages, 'page' | 'size' | 'totalElements' | 'totalPages'>
    & { content?: Maybe<Array<Maybe<(
      { __typename?: 'TvEventPage' }
      & { relTvEventCompanyContracts?: Maybe<Array<Maybe<(
        { __typename?: 'RelTvEventCompanyContract' }
        & RelTvEventCompanyContractFieldsFragment
      )>>> }
      & TvEventPageFieldsFragment
    )>>> }
  ) }
);

export type SearchAtArchiveEventTabQueryVariables = Exact<{
  page: Scalars['Int'];
  size: Scalars['Int'];
  keyword: Scalars['String'];
}>;


export type SearchAtArchiveEventTabQuery = (
  { __typename?: 'Query' }
  & { searchOldTvEvents: (
    { __typename?: 'TvEventPages' }
    & Pick<TvEventPages, 'page' | 'size' | 'totalElements' | 'totalPages'>
    & { content?: Maybe<Array<Maybe<(
      { __typename?: 'TvEventPage' }
      & { relTvEventCompanyContracts?: Maybe<Array<Maybe<(
        { __typename?: 'RelTvEventCompanyContract' }
        & RelTvEventCompanyContractFieldsFragment
      )>>> }
      & TvEventPageFieldsFragment
    )>>> }
  ) }
);

export type SearchAtFeatureTabQueryVariables = Exact<{
  page: Scalars['Int'];
  size: Scalars['Int'];
  keyword: Scalars['String'];
}>;


export type SearchAtFeatureTabQuery = (
  { __typename?: 'Query' }
  & { searchTvFeatures: (
    { __typename?: 'TvEventPages' }
    & Pick<TvEventPages, 'page' | 'size' | 'totalElements' | 'totalPages'>
    & { content?: Maybe<Array<Maybe<(
      { __typename?: 'TvEventPage' }
      & { relTvEventCompanyContracts?: Maybe<Array<Maybe<(
        { __typename?: 'RelTvEventCompanyContract' }
        & RelTvEventCompanyContractFieldsFragment
      )>>> }
      & TvEventPageFieldsFragment
    )>>> }
  ) }
);

export const CompanyContractFieldsFragmentDoc = gql`
    fragment CompanyContractFields on CompanyContract {
  companyContractId
  companyContractDisplayId
  companyPagePublishFlag
  companyLang {
    companyLangId
    companyName
    companyLogoUrl
    companyNameFull
    companyDescription
  }
}
    `;
export const RelTvEventCompanyContractFieldsFragmentDoc = gql`
    fragment RelTvEventCompanyContractFields on RelTvEventCompanyContract {
  tvEventId
  companyContractId
  companyContract(withCompanysNoLogo: false) {
    ...CompanyContractFields
  }
  displayOrder
}
    ${CompanyContractFieldsFragmentDoc}`;
export const TvEventPageFieldsFragmentDoc = gql`
    fragment TvEventPageFields on TvEventPage {
  tvEventId
  tvEventTitle
  tvEventSubtitle
  tvEventDescription
  tvEventMainImgFilePath
  tvEventStartDatetime
  tvEventEndDatetime
  tvEventPublishStartDatetime
  tvEventPublishEndDatetime
  tvEventType
  tvEventPublishFlag
  otherExhibitors
  createdOperator
  lastUpdatedOperator
  createTimestamp
  updateTimestamp
  tvEventPartnerCompanies
  eventApplyFlag
  tvExhibitionReports {
    id
    companyContractId
    companyNameFull
    loggedInUserWatchStatus {
      tvWebinarId
      tvWatchLaterId
    }
    tvWebinarPublishFlag
    tvWebinarPublishStartDatetime
    tvWebinarPublishEndDatetime
    tvWebinarTitle
    tvWebinarSubtitle
    tvWebinarStreamingStartDatetime
    tvWebinarStreamingEndDatetime
    tvWebinarMoviePlayTime
    tvWebinarMainImgFilePath
    tvWebinarOndemandFlag
    tvWebinarSpeakerName
    tvWebinarSpeakerImgFilePath
    tvWebinarSpeakerDepartment
    createTimestamp
    updateTimestamp
  }
}
    `;
export const TvWebinarFieldsFragmentDoc = gql`
    fragment TvWebinarFields on TvWebinar {
  tvWebinarId
  tvWebinarMainImgFilePath
  tvWebinarOndemandFlag
  tvWebinarOndemandFirstDatetime
  tvWebinarStreamingStartDatetime
  tvWebinarStreamingEndDatetime
  tvWebinarMoviePlayTime
  tvWebinarTitle
  tvWebinarSubtitle
  tvWebinarSpeakerName
  tvWebinarSpeakerImgFilePath
  tvWebinarSpeakerDepartment
  tvWebinarRecommendSentence1
  tvWebinarRecommendSentence2
  tvWebinarRecommendSentence3
  loggedInUserWatchStatus {
    tvWebinarId
    tvWatchLaterId
  }
}
    `;
export const CompanyPageDetailFieldsFragmentDoc = gql`
    fragment CompanyPageDetailFields on CompanyPageDetail {
  id
  name
  logoUrl
  countryCode
  zipcode
  subdivisionName
  cityName
  address
  building
  tel
  fax
  industryType
  tagline
  description
  capitalDescription
  foundationDescription
  businessDescription
  mainCustomerDescription
  employeeDescription
  salesDescription
  websiteUrl
}
    `;
export const RegisterTvWebinarQuestionnaireDocument = gql`
    mutation registerTvWebinarQuestionnaire($tvWebinarQuestionnaireAnswer: TvWebinarQuestionnaireAnswerInput!) {
  registerTvWebinarQuestionnaire(
    tvWebinarQuestionnaireAnswer: $tvWebinarQuestionnaireAnswer
  )
}
    `;
export type RegisterTvWebinarQuestionnaireMutationFn = Apollo.MutationFunction<RegisterTvWebinarQuestionnaireMutation, RegisterTvWebinarQuestionnaireMutationVariables>;

/**
 * __useRegisterTvWebinarQuestionnaireMutation__
 *
 * To run a mutation, you first call `useRegisterTvWebinarQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterTvWebinarQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerTvWebinarQuestionnaireMutation, { data, loading, error }] = useRegisterTvWebinarQuestionnaireMutation({
 *   variables: {
 *      tvWebinarQuestionnaireAnswer: // value for 'tvWebinarQuestionnaireAnswer'
 *   },
 * });
 */
export function useRegisterTvWebinarQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<RegisterTvWebinarQuestionnaireMutation, RegisterTvWebinarQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterTvWebinarQuestionnaireMutation, RegisterTvWebinarQuestionnaireMutationVariables>(RegisterTvWebinarQuestionnaireDocument, options);
      }
export type RegisterTvWebinarQuestionnaireMutationHookResult = ReturnType<typeof useRegisterTvWebinarQuestionnaireMutation>;
export type RegisterTvWebinarQuestionnaireMutationResult = Apollo.MutationResult<RegisterTvWebinarQuestionnaireMutation>;
export type RegisterTvWebinarQuestionnaireMutationOptions = Apollo.BaseMutationOptions<RegisterTvWebinarQuestionnaireMutation, RegisterTvWebinarQuestionnaireMutationVariables>;
export const CreateTvWebinarInquiryDocument = gql`
    mutation createTvWebinarInquiry($tvWebinarInquiry: TvWebinarInquiryInput!) {
  createTvWebinarInquiry(tvWebinarInquiry: $tvWebinarInquiry)
}
    `;
export type CreateTvWebinarInquiryMutationFn = Apollo.MutationFunction<CreateTvWebinarInquiryMutation, CreateTvWebinarInquiryMutationVariables>;

/**
 * __useCreateTvWebinarInquiryMutation__
 *
 * To run a mutation, you first call `useCreateTvWebinarInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTvWebinarInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTvWebinarInquiryMutation, { data, loading, error }] = useCreateTvWebinarInquiryMutation({
 *   variables: {
 *      tvWebinarInquiry: // value for 'tvWebinarInquiry'
 *   },
 * });
 */
export function useCreateTvWebinarInquiryMutation(baseOptions?: Apollo.MutationHookOptions<CreateTvWebinarInquiryMutation, CreateTvWebinarInquiryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTvWebinarInquiryMutation, CreateTvWebinarInquiryMutationVariables>(CreateTvWebinarInquiryDocument, options);
      }
export type CreateTvWebinarInquiryMutationHookResult = ReturnType<typeof useCreateTvWebinarInquiryMutation>;
export type CreateTvWebinarInquiryMutationResult = Apollo.MutationResult<CreateTvWebinarInquiryMutation>;
export type CreateTvWebinarInquiryMutationOptions = Apollo.BaseMutationOptions<CreateTvWebinarInquiryMutation, CreateTvWebinarInquiryMutationVariables>;
export const CreateTvEventWatchLaterDocument = gql`
    mutation createTvEventWatchLater($tvWebinarIds: [BigInteger!]!, $tvEventId: BigInteger!) {
  createTvEventWatchLater(tvWebinarIds: $tvWebinarIds, tvEventId: $tvEventId) {
    tvWatchLaterResults {
      tvWebinarId
      tvWatchLaterId
    }
    tvWatchLaterRegistrationStatus
  }
}
    `;
export type CreateTvEventWatchLaterMutationFn = Apollo.MutationFunction<CreateTvEventWatchLaterMutation, CreateTvEventWatchLaterMutationVariables>;

/**
 * __useCreateTvEventWatchLaterMutation__
 *
 * To run a mutation, you first call `useCreateTvEventWatchLaterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTvEventWatchLaterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTvEventWatchLaterMutation, { data, loading, error }] = useCreateTvEventWatchLaterMutation({
 *   variables: {
 *      tvWebinarIds: // value for 'tvWebinarIds'
 *      tvEventId: // value for 'tvEventId'
 *   },
 * });
 */
export function useCreateTvEventWatchLaterMutation(baseOptions?: Apollo.MutationHookOptions<CreateTvEventWatchLaterMutation, CreateTvEventWatchLaterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTvEventWatchLaterMutation, CreateTvEventWatchLaterMutationVariables>(CreateTvEventWatchLaterDocument, options);
      }
export type CreateTvEventWatchLaterMutationHookResult = ReturnType<typeof useCreateTvEventWatchLaterMutation>;
export type CreateTvEventWatchLaterMutationResult = Apollo.MutationResult<CreateTvEventWatchLaterMutation>;
export type CreateTvEventWatchLaterMutationOptions = Apollo.BaseMutationOptions<CreateTvEventWatchLaterMutation, CreateTvEventWatchLaterMutationVariables>;
export const UpdateTvNotificationSettingDocument = gql`
    mutation updateTvNotificationSetting($notificationSettingType: NotificationSettingType!, $notificationSettingFlag: NotificationSettingFlag!) {
  updateTvNotificationSetting(
    tvNotificationSetting: {notificationSettingType: $notificationSettingType, notificationSettingFlag: $notificationSettingFlag}
  ) {
    notificationSettingName
    notificationSettingDescription
    notificationSettingType
    notificationSettingFlag
  }
}
    `;
export type UpdateTvNotificationSettingMutationFn = Apollo.MutationFunction<UpdateTvNotificationSettingMutation, UpdateTvNotificationSettingMutationVariables>;

/**
 * __useUpdateTvNotificationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateTvNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTvNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTvNotificationSettingMutation, { data, loading, error }] = useUpdateTvNotificationSettingMutation({
 *   variables: {
 *      notificationSettingType: // value for 'notificationSettingType'
 *      notificationSettingFlag: // value for 'notificationSettingFlag'
 *   },
 * });
 */
export function useUpdateTvNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTvNotificationSettingMutation, UpdateTvNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTvNotificationSettingMutation, UpdateTvNotificationSettingMutationVariables>(UpdateTvNotificationSettingDocument, options);
      }
export type UpdateTvNotificationSettingMutationHookResult = ReturnType<typeof useUpdateTvNotificationSettingMutation>;
export type UpdateTvNotificationSettingMutationResult = Apollo.MutationResult<UpdateTvNotificationSettingMutation>;
export type UpdateTvNotificationSettingMutationOptions = Apollo.BaseMutationOptions<UpdateTvNotificationSettingMutation, UpdateTvNotificationSettingMutationVariables>;
export const ApplyTvEventDocument = gql`
    mutation applyTvEvent($tvEventApplyInput: TvEventApplyInput!) {
  applyTvEvent(tvEventApplyInput: $tvEventApplyInput)
}
    `;
export type ApplyTvEventMutationFn = Apollo.MutationFunction<ApplyTvEventMutation, ApplyTvEventMutationVariables>;

/**
 * __useApplyTvEventMutation__
 *
 * To run a mutation, you first call `useApplyTvEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyTvEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyTvEventMutation, { data, loading, error }] = useApplyTvEventMutation({
 *   variables: {
 *      tvEventApplyInput: // value for 'tvEventApplyInput'
 *   },
 * });
 */
export function useApplyTvEventMutation(baseOptions?: Apollo.MutationHookOptions<ApplyTvEventMutation, ApplyTvEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyTvEventMutation, ApplyTvEventMutationVariables>(ApplyTvEventDocument, options);
      }
export type ApplyTvEventMutationHookResult = ReturnType<typeof useApplyTvEventMutation>;
export type ApplyTvEventMutationResult = Apollo.MutationResult<ApplyTvEventMutation>;
export type ApplyTvEventMutationOptions = Apollo.BaseMutationOptions<ApplyTvEventMutation, ApplyTvEventMutationVariables>;
export const CompanyPageDocument = gql`
    query companyPage($companyContractDisplayId: BigInteger!) {
  companyPage(companyContractDisplayId: $companyContractDisplayId) {
    id
    companyContractDisplayId
    inquiryFlag
    companyPagePublishFlag
    companyPageDetail {
      ...CompanyPageDetailFields
    }
  }
}
    ${CompanyPageDetailFieldsFragmentDoc}`;

/**
 * __useCompanyPageQuery__
 *
 * To run a query within a React component, call `useCompanyPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyPageQuery({
 *   variables: {
 *      companyContractDisplayId: // value for 'companyContractDisplayId'
 *   },
 * });
 */
export function useCompanyPageQuery(baseOptions: Apollo.QueryHookOptions<CompanyPageQuery, CompanyPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyPageQuery, CompanyPageQueryVariables>(CompanyPageDocument, options);
      }
export function useCompanyPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyPageQuery, CompanyPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyPageQuery, CompanyPageQueryVariables>(CompanyPageDocument, options);
        }
export type CompanyPageQueryHookResult = ReturnType<typeof useCompanyPageQuery>;
export type CompanyPageLazyQueryHookResult = ReturnType<typeof useCompanyPageLazyQuery>;
export type CompanyPageQueryResult = Apollo.QueryResult<CompanyPageQuery, CompanyPageQueryVariables>;
export const CompanyPageEventsDocument = gql`
    query companyPageEvents($companyContractDisplayId: BigInteger!, $page: Int = 1, $size: Int = 2) {
  tvEventPagesByCompanyContractDisplayId(
    companyContractDisplayId: $companyContractDisplayId
    page: $page
    size: $size
  ) {
    content {
      ...TvEventPageFields
      relTvEventCompanyContracts {
        ...RelTvEventCompanyContractFields
      }
    }
    page
    size
    totalPages
    totalElements
  }
}
    ${TvEventPageFieldsFragmentDoc}
${RelTvEventCompanyContractFieldsFragmentDoc}`;

/**
 * __useCompanyPageEventsQuery__
 *
 * To run a query within a React component, call `useCompanyPageEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPageEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyPageEventsQuery({
 *   variables: {
 *      companyContractDisplayId: // value for 'companyContractDisplayId'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useCompanyPageEventsQuery(baseOptions: Apollo.QueryHookOptions<CompanyPageEventsQuery, CompanyPageEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyPageEventsQuery, CompanyPageEventsQueryVariables>(CompanyPageEventsDocument, options);
      }
export function useCompanyPageEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyPageEventsQuery, CompanyPageEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyPageEventsQuery, CompanyPageEventsQueryVariables>(CompanyPageEventsDocument, options);
        }
export type CompanyPageEventsQueryHookResult = ReturnType<typeof useCompanyPageEventsQuery>;
export type CompanyPageEventsLazyQueryHookResult = ReturnType<typeof useCompanyPageEventsLazyQuery>;
export type CompanyPageEventsQueryResult = Apollo.QueryResult<CompanyPageEventsQuery, CompanyPageEventsQueryVariables>;
export const CompanyPageWebinarsDocument = gql`
    query companyPageWebinars($companyContractDisplayId: BigInteger!, $page: Int = 1, $size: Int = 2) {
  tvWebinarsByCompanyContractDisplayId(
    companyContractDisplayId: $companyContractDisplayId
    page: $page
    size: $size
  ) {
    content {
      ...TvWebinarFields
      companyContract(withCompanysNoLogo: true, companyNoImageLogoType: SMALL) {
        ...CompanyContractFields
      }
    }
    page
    size
    totalPages
    totalElements
  }
}
    ${TvWebinarFieldsFragmentDoc}
${CompanyContractFieldsFragmentDoc}`;

/**
 * __useCompanyPageWebinarsQuery__
 *
 * To run a query within a React component, call `useCompanyPageWebinarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPageWebinarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyPageWebinarsQuery({
 *   variables: {
 *      companyContractDisplayId: // value for 'companyContractDisplayId'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useCompanyPageWebinarsQuery(baseOptions: Apollo.QueryHookOptions<CompanyPageWebinarsQuery, CompanyPageWebinarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyPageWebinarsQuery, CompanyPageWebinarsQueryVariables>(CompanyPageWebinarsDocument, options);
      }
export function useCompanyPageWebinarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyPageWebinarsQuery, CompanyPageWebinarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyPageWebinarsQuery, CompanyPageWebinarsQueryVariables>(CompanyPageWebinarsDocument, options);
        }
export type CompanyPageWebinarsQueryHookResult = ReturnType<typeof useCompanyPageWebinarsQuery>;
export type CompanyPageWebinarsLazyQueryHookResult = ReturnType<typeof useCompanyPageWebinarsLazyQuery>;
export type CompanyPageWebinarsQueryResult = Apollo.QueryResult<CompanyPageWebinarsQuery, CompanyPageWebinarsQueryVariables>;
export const TvEventPageDocument = gql`
    query tvEventPage($eventId: BigInteger!) {
  tvEventPage(tvEventId: $eventId) {
    tvEventId
    tvEventTitle
    tvEventSubtitle
    tvEventDescription
    tvEventMainImgFilePath
    tvEventStartDatetime
    tvEventEndDatetime
    tvEventPublishStartDatetime
    tvEventPublishEndDatetime
    tvEventType
    tvEventLpCtaButtonBackgroundColor
    tvEventLpCtaButtonTextColor
    tvEventLpBackgroundColor
    eventApplyFlag
    catalogs {
      cluezCatalogId
      documentId
      companyId
      catalogImgUrl
      catalogTitle
      companyName
    }
    relTvEventCompanyContracts {
      ...RelTvEventCompanyContractFields
    }
    tvEventPartnerCompanies
    tvEventOverviews {
      id
      tvEventId
      displayOrder
      imagePath
      description
    }
    relTvEventLpTvOndemandWebinars {
      id
      companyContractId
      tvWebinarPublishFlag
      tvWebinarPublishStartDatetime
      tvWebinarPublishEndDatetime
      tvWebinarTitle
      tvWebinarSubtitle
      tvWebinarStreamingStartDatetime
      tvWebinarStreamingEndDatetime
      tvWebinarMoviePlayTime
      tvWebinarMainImgFilePath
      tvWebinarOndemandFlag
      tvWebinarSpeakerName
      tvWebinarSpeakerImgFilePath
      tvWebinarSpeakerDepartment
      createTimestamp
      updateTimestamp
    }
    tvExhibitionReports {
      id
      companyContractId
      companyNameFull
      loggedInUserWatchStatus {
        tvWebinarId
        tvWatchLaterId
      }
      tvWebinarPublishFlag
      tvWebinarPublishStartDatetime
      tvWebinarPublishEndDatetime
      tvWebinarTitle
      tvWebinarSubtitle
      tvWebinarStreamingStartDatetime
      tvWebinarStreamingEndDatetime
      tvWebinarMoviePlayTime
      tvWebinarMainImgFilePath
      tvWebinarOndemandFlag
      tvWebinarSpeakerName
      tvWebinarSpeakerImgFilePath
      tvWebinarSpeakerDepartment
      createTimestamp
      updateTimestamp
    }
    eventTimetable {
      schedule
      webinars {
        tvWebinarId
        tvWebinarMainImgFilePath
        tvWebinarOndemandFlag
        tvWebinarStreamingStartDatetime
        tvWebinarStreamingEndDatetime
        tvWebinarPublishStartDatetime
        tvWebinarPublishEndDatetime
        tvWebinarMoviePlayTime
        tvWebinarTitle
        tvWebinarSubtitle
        tvWebinarDescription
        tvWebinarSpeakerName
        tvWebinarSpeakerImgFilePath
        tvWebinarSpeakerDepartment
        tvWebinarRecommendSentence1
        tvWebinarRecommendSentence2
        tvWebinarRecommendSentence3
        companyContract(withCompanysNoLogo: true, companyNoImageLogoType: LARGE) {
          companyContractId
          companyContractDisplayId
          companyPagePublishFlag
          companyLang {
            companyLangId
            companyName
            companyLogoUrl
            companyNameFull
          }
        }
        loggedInUserWatchStatus {
          tvWebinarId
          tvWatchLaterId
        }
      }
    }
    tvEventSchedules {
      content {
        tvEventScheduleId
        tvEventId
        tvEventType
        tvEventScheduleSequence
        tvEventScheduleDate
        tvEventScheduleStartTime
        tvEventScheduleEndTime
        content {
          tvWebinarId
          companyContract {
            companyContractId
            companyLang {
              companyLangId
              companyName
              companyLogoUrl
              companyNameFull
            }
          }
          tvWebinarSubtitle
          tvWebinarDescription
          tvWebinarSpeakerName
          tvWebinarSpeakerDepartment
          tvWebinarSpeakerImgFilePath
          tvWebinarPublishStartDatetime
          tvWebinarPublishEndDatetime
          tvWebinarOndemandFlag
          tvWebinarStreamingStartDatetime
          tvWebinarStreamingEndDatetime
          tvWebinarTitle
          tvWebinarMainImgFilePath
          loggedInUserWatchStatus {
            tvWebinarId
            tvWatchLaterId
          }
        }
      }
    }
  }
}
    ${RelTvEventCompanyContractFieldsFragmentDoc}`;

/**
 * __useTvEventPageQuery__
 *
 * To run a query within a React component, call `useTvEventPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTvEventPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTvEventPageQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useTvEventPageQuery(baseOptions: Apollo.QueryHookOptions<TvEventPageQuery, TvEventPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TvEventPageQuery, TvEventPageQueryVariables>(TvEventPageDocument, options);
      }
export function useTvEventPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TvEventPageQuery, TvEventPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TvEventPageQuery, TvEventPageQueryVariables>(TvEventPageDocument, options);
        }
export type TvEventPageQueryHookResult = ReturnType<typeof useTvEventPageQuery>;
export type TvEventPageLazyQueryHookResult = ReturnType<typeof useTvEventPageLazyQuery>;
export type TvEventPageQueryResult = Apollo.QueryResult<TvEventPageQuery, TvEventPageQueryVariables>;
export const EventTimeTableDocument = gql`
    query eventTimeTable($tvEventId: BigInteger!) {
  eventTimetable(tvEventId: $tvEventId) {
    schedule
    webinars {
      tvWebinarId
      companyContract {
        companyContractId
        companyLang {
          companyLangId
          companyName
          companyLogoUrl
          companyNameFull
        }
      }
      tvWebinarStreamingStartDatetime
      tvWebinarStreamingEndDatetime
      tvWebinarPublishStartDatetime
      tvWebinarPublishEndDatetime
      tvWebinarTitle
      tvWebinarMainImgFilePath
      loggedInUserWatchStatus {
        tvWebinarId
        tvWatchLaterId
      }
    }
  }
}
    `;

/**
 * __useEventTimeTableQuery__
 *
 * To run a query within a React component, call `useEventTimeTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventTimeTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventTimeTableQuery({
 *   variables: {
 *      tvEventId: // value for 'tvEventId'
 *   },
 * });
 */
export function useEventTimeTableQuery(baseOptions: Apollo.QueryHookOptions<EventTimeTableQuery, EventTimeTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventTimeTableQuery, EventTimeTableQueryVariables>(EventTimeTableDocument, options);
      }
export function useEventTimeTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventTimeTableQuery, EventTimeTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventTimeTableQuery, EventTimeTableQueryVariables>(EventTimeTableDocument, options);
        }
export type EventTimeTableQueryHookResult = ReturnType<typeof useEventTimeTableQuery>;
export type EventTimeTableLazyQueryHookResult = ReturnType<typeof useEventTimeTableLazyQuery>;
export type EventTimeTableQueryResult = Apollo.QueryResult<EventTimeTableQuery, EventTimeTableQueryVariables>;
export const EventApplicantDocument = gql`
    query eventApplicant($tvEventId: BigInteger!) {
  eventApplicant(tvEventId: $tvEventId) {
    eventId
    userId
    mailAddress
  }
}
    `;

/**
 * __useEventApplicantQuery__
 *
 * To run a query within a React component, call `useEventApplicantQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventApplicantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventApplicantQuery({
 *   variables: {
 *      tvEventId: // value for 'tvEventId'
 *   },
 * });
 */
export function useEventApplicantQuery(baseOptions: Apollo.QueryHookOptions<EventApplicantQuery, EventApplicantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventApplicantQuery, EventApplicantQueryVariables>(EventApplicantDocument, options);
      }
export function useEventApplicantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventApplicantQuery, EventApplicantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventApplicantQuery, EventApplicantQueryVariables>(EventApplicantDocument, options);
        }
export type EventApplicantQueryHookResult = ReturnType<typeof useEventApplicantQuery>;
export type EventApplicantLazyQueryHookResult = ReturnType<typeof useEventApplicantLazyQuery>;
export type EventApplicantQueryResult = Apollo.QueryResult<EventApplicantQuery, EventApplicantQueryVariables>;
export const TvEventSchedulesPaginationDocument = gql`
    query tvEventSchedulesPagination($eventId: BigInteger!, $page: Int!, $size: Int!) {
  tvEventSchedules(tvEventId: $eventId, page: $page, size: $size) {
    content {
      tvEventScheduleId
      tvEventId
      tvEventScheduleSequence
      tvEventScheduleDate
      tvEventScheduleStartTime
      tvEventScheduleEndTime
      content {
        tvWebinarId
        companyContractId
        companyContract(withCompanysNoLogo: true, companyNoImageLogoType: SMALL) {
          companyContractId
          companyLang {
            companyLangId
            companyName
            companyLogoUrl
            companyNameFull
          }
        }
        tvWebinarPublishFlag
        tvWebinarPublishStartDatetime
        tvWebinarPublishEndDatetime
        tvWebinarTitle
        tvWebinarSubtitle
        tvWebinarDescription
        tvWebinarSpeakerName
        tvWebinarSpeakerDepartment
        tvWebinarMainImgFilePath
        tvWebinarSpeakerImgFilePath
        tvWebinarStreamingStartDatetime
        tvWebinarStreamingEndDatetime
        tvWebinarOndemandFlag
      }
    }
    page
    size
    totalElements
    totalPages
  }
  tvEventPage(tvEventId: $eventId) {
    tvEventEndDatetime
  }
}
    `;

/**
 * __useTvEventSchedulesPaginationQuery__
 *
 * To run a query within a React component, call `useTvEventSchedulesPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTvEventSchedulesPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTvEventSchedulesPaginationQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useTvEventSchedulesPaginationQuery(baseOptions: Apollo.QueryHookOptions<TvEventSchedulesPaginationQuery, TvEventSchedulesPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TvEventSchedulesPaginationQuery, TvEventSchedulesPaginationQueryVariables>(TvEventSchedulesPaginationDocument, options);
      }
export function useTvEventSchedulesPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TvEventSchedulesPaginationQuery, TvEventSchedulesPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TvEventSchedulesPaginationQuery, TvEventSchedulesPaginationQueryVariables>(TvEventSchedulesPaginationDocument, options);
        }
export type TvEventSchedulesPaginationQueryHookResult = ReturnType<typeof useTvEventSchedulesPaginationQuery>;
export type TvEventSchedulesPaginationLazyQueryHookResult = ReturnType<typeof useTvEventSchedulesPaginationLazyQuery>;
export type TvEventSchedulesPaginationQueryResult = Apollo.QueryResult<TvEventSchedulesPaginationQuery, TvEventSchedulesPaginationQueryVariables>;
export const TvEventSchedulesDocument = gql`
    query tvEventSchedules($tvEventId: BigInteger!) {
  tvEventSchedules(tvEventId: $tvEventId, page: 1, size: 10) {
    content {
      tvEventScheduleId
      tvEventId
      tvEventType
      tvEventScheduleSequence
      tvEventScheduleDate
      tvEventScheduleStartTime
      tvEventScheduleEndTime
      content {
        tvWebinarId
        companyContract {
          companyContractId
          companyLang {
            companyLangId
            companyName
            companyLogoUrl
            companyNameFull
          }
        }
        tvWebinarStreamingStartDatetime
        tvWebinarStreamingEndDatetime
        tvWebinarPublishStartDatetime
        tvWebinarPublishEndDatetime
        tvWebinarTitle
        tvWebinarMainImgFilePath
        loggedInUserWatchStatus {
          tvWebinarId
          tvWatchLaterId
        }
      }
    }
  }
}
    `;

/**
 * __useTvEventSchedulesQuery__
 *
 * To run a query within a React component, call `useTvEventSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTvEventSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTvEventSchedulesQuery({
 *   variables: {
 *      tvEventId: // value for 'tvEventId'
 *   },
 * });
 */
export function useTvEventSchedulesQuery(baseOptions: Apollo.QueryHookOptions<TvEventSchedulesQuery, TvEventSchedulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TvEventSchedulesQuery, TvEventSchedulesQueryVariables>(TvEventSchedulesDocument, options);
      }
export function useTvEventSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TvEventSchedulesQuery, TvEventSchedulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TvEventSchedulesQuery, TvEventSchedulesQueryVariables>(TvEventSchedulesDocument, options);
        }
export type TvEventSchedulesQueryHookResult = ReturnType<typeof useTvEventSchedulesQuery>;
export type TvEventSchedulesLazyQueryHookResult = ReturnType<typeof useTvEventSchedulesLazyQuery>;
export type TvEventSchedulesQueryResult = Apollo.QueryResult<TvEventSchedulesQuery, TvEventSchedulesQueryVariables>;
export const GetTvWebinarQuestionnaireDocument = gql`
    query getTvWebinarQuestionnaire($tvWebinarId: BigInteger!, $documentType: TvWebinarDocumentType!) {
  tvWebinarQuestionnaire(tvWebinarId: $tvWebinarId, documentType: $documentType) {
    tvWebinarId
    companyContractId
    companyContract {
      ...CompanyContractFields
    }
    tvWebinarTitle
    questionnaireImageUrl
    questionnaireTitle
    questionnaireSubTitle
    documentType
    form {
      formId
      formItems {
        formItemId
        formItemTitle
        formItemPlaceHolder
        formItemType
        formItemAnswerRequired
        formItemValidation
        formItemAnswers {
          formItemAnswerId
          formItemAnswerTitle
          displayOrder
        }
        withOptionOtherComment
        requiredOptionOtherComment
        displayOrder
      }
    }
    lastName
    firstName
    tel
    companyName
    officeName
    departmentName
    businessType
    jobCategoryType
    postClass
    postName
    subdivisionCode
  }
  prefectures {
    code
    name
  }
  businessTypes {
    code
    name
    jobCategories {
      code
      name
    }
  }
  postClasses {
    code
    name
  }
}
    ${CompanyContractFieldsFragmentDoc}`;

/**
 * __useGetTvWebinarQuestionnaireQuery__
 *
 * To run a query within a React component, call `useGetTvWebinarQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTvWebinarQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTvWebinarQuestionnaireQuery({
 *   variables: {
 *      tvWebinarId: // value for 'tvWebinarId'
 *      documentType: // value for 'documentType'
 *   },
 * });
 */
export function useGetTvWebinarQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<GetTvWebinarQuestionnaireQuery, GetTvWebinarQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTvWebinarQuestionnaireQuery, GetTvWebinarQuestionnaireQueryVariables>(GetTvWebinarQuestionnaireDocument, options);
      }
export function useGetTvWebinarQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTvWebinarQuestionnaireQuery, GetTvWebinarQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTvWebinarQuestionnaireQuery, GetTvWebinarQuestionnaireQueryVariables>(GetTvWebinarQuestionnaireDocument, options);
        }
export type GetTvWebinarQuestionnaireQueryHookResult = ReturnType<typeof useGetTvWebinarQuestionnaireQuery>;
export type GetTvWebinarQuestionnaireLazyQueryHookResult = ReturnType<typeof useGetTvWebinarQuestionnaireLazyQuery>;
export type GetTvWebinarQuestionnaireQueryResult = Apollo.QueryResult<GetTvWebinarQuestionnaireQuery, GetTvWebinarQuestionnaireQueryVariables>;
export const GetUserDocument = gql`
    query getUser {
  user {
    userType
    userId
    displayName
    mailAddress
    photoUrl
    lastName
    firstName
    phoneticLastName
    phoneticFirstName
    companyName
    officeName
    departmentName
    tel
    subdivisionCode
    buyer {
      approvalFlag
    }
  }
  unreadMessageCount
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const TvWebinarsDocument = gql`
    query tvWebinars($tvWebinarDeliveryStatus: TvWebinarDeliveryStatus!, $page: Int!, $size: Int!, $isTopPage: Boolean, $withCompanysNoLogo: Boolean, $companyNoImageLogoType: CompanyNoImageLogoType) {
  tvWebinars(
    tvWebinarDeliveryStatus: $tvWebinarDeliveryStatus
    page: $page
    size: $size
    isTopPage: $isTopPage
  ) {
    content {
      ...TvWebinarFields
      companyContract(
        withCompanysNoLogo: $withCompanysNoLogo
        companyNoImageLogoType: $companyNoImageLogoType
      ) {
        ...CompanyContractFields
      }
    }
    page
    size
    totalElements
    totalPages
  }
}
    ${TvWebinarFieldsFragmentDoc}
${CompanyContractFieldsFragmentDoc}`;

/**
 * __useTvWebinarsQuery__
 *
 * To run a query within a React component, call `useTvWebinarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTvWebinarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTvWebinarsQuery({
 *   variables: {
 *      tvWebinarDeliveryStatus: // value for 'tvWebinarDeliveryStatus'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      isTopPage: // value for 'isTopPage'
 *      withCompanysNoLogo: // value for 'withCompanysNoLogo'
 *      companyNoImageLogoType: // value for 'companyNoImageLogoType'
 *   },
 * });
 */
export function useTvWebinarsQuery(baseOptions: Apollo.QueryHookOptions<TvWebinarsQuery, TvWebinarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TvWebinarsQuery, TvWebinarsQueryVariables>(TvWebinarsDocument, options);
      }
export function useTvWebinarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TvWebinarsQuery, TvWebinarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TvWebinarsQuery, TvWebinarsQueryVariables>(TvWebinarsDocument, options);
        }
export type TvWebinarsQueryHookResult = ReturnType<typeof useTvWebinarsQuery>;
export type TvWebinarsLazyQueryHookResult = ReturnType<typeof useTvWebinarsLazyQuery>;
export type TvWebinarsQueryResult = Apollo.QueryResult<TvWebinarsQuery, TvWebinarsQueryVariables>;
export const EmbedTvWebinarDocument = gql`
    query embedTvWebinar($tvWebinarId: BigInteger!) {
  embedTvWebinar(tvWebinarId: $tvWebinarId) {
    id
    title
    videoId
    streamingStartDateTime
    streamingEndDateTime
    ondemandFlag
    mainImageFilePath
    moviePlayTime
  }
}
    `;

/**
 * __useEmbedTvWebinarQuery__
 *
 * To run a query within a React component, call `useEmbedTvWebinarQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmbedTvWebinarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmbedTvWebinarQuery({
 *   variables: {
 *      tvWebinarId: // value for 'tvWebinarId'
 *   },
 * });
 */
export function useEmbedTvWebinarQuery(baseOptions: Apollo.QueryHookOptions<EmbedTvWebinarQuery, EmbedTvWebinarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmbedTvWebinarQuery, EmbedTvWebinarQueryVariables>(EmbedTvWebinarDocument, options);
      }
export function useEmbedTvWebinarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmbedTvWebinarQuery, EmbedTvWebinarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmbedTvWebinarQuery, EmbedTvWebinarQueryVariables>(EmbedTvWebinarDocument, options);
        }
export type EmbedTvWebinarQueryHookResult = ReturnType<typeof useEmbedTvWebinarQuery>;
export type EmbedTvWebinarLazyQueryHookResult = ReturnType<typeof useEmbedTvWebinarLazyQuery>;
export type EmbedTvWebinarQueryResult = Apollo.QueryResult<EmbedTvWebinarQuery, EmbedTvWebinarQueryVariables>;
export const GetTvNotificationSettingDocument = gql`
    query getTvNotificationSetting {
  tvNotificationSetting {
    notificationSettingName
    notificationSettingDescription
    notificationSettingType
    notificationSettingFlag
  }
}
    `;

/**
 * __useGetTvNotificationSettingQuery__
 *
 * To run a query within a React component, call `useGetTvNotificationSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTvNotificationSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTvNotificationSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTvNotificationSettingQuery(baseOptions?: Apollo.QueryHookOptions<GetTvNotificationSettingQuery, GetTvNotificationSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTvNotificationSettingQuery, GetTvNotificationSettingQueryVariables>(GetTvNotificationSettingDocument, options);
      }
export function useGetTvNotificationSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTvNotificationSettingQuery, GetTvNotificationSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTvNotificationSettingQuery, GetTvNotificationSettingQueryVariables>(GetTvNotificationSettingDocument, options);
        }
export type GetTvNotificationSettingQueryHookResult = ReturnType<typeof useGetTvNotificationSettingQuery>;
export type GetTvNotificationSettingLazyQueryHookResult = ReturnType<typeof useGetTvNotificationSettingLazyQuery>;
export type GetTvNotificationSettingQueryResult = Apollo.QueryResult<GetTvNotificationSettingQuery, GetTvNotificationSettingQueryVariables>;
export const GetStaticApplyPageDocument = gql`
    query getStaticApplyPage($tvEventId: BigInteger!) {
  prefectures {
    code
    name
  }
  businessTypes {
    code
    name
    jobCategories {
      code
      name
    }
  }
  postClasses {
    code
    name
  }
  tvEventPage(tvEventId: $tvEventId) {
    tvEventId
    tvEventTitle
    tvEventSubtitle
    tvEventStartDatetime
    tvEventEndDatetime
    catalogs {
      cluezCatalogId
      documentId
      companyId
      catalogImgUrl
      catalogTitle
      companyName
    }
  }
}
    `;

/**
 * __useGetStaticApplyPageQuery__
 *
 * To run a query within a React component, call `useGetStaticApplyPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaticApplyPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaticApplyPageQuery({
 *   variables: {
 *      tvEventId: // value for 'tvEventId'
 *   },
 * });
 */
export function useGetStaticApplyPageQuery(baseOptions: Apollo.QueryHookOptions<GetStaticApplyPageQuery, GetStaticApplyPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStaticApplyPageQuery, GetStaticApplyPageQueryVariables>(GetStaticApplyPageDocument, options);
      }
export function useGetStaticApplyPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaticApplyPageQuery, GetStaticApplyPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStaticApplyPageQuery, GetStaticApplyPageQueryVariables>(GetStaticApplyPageDocument, options);
        }
export type GetStaticApplyPageQueryHookResult = ReturnType<typeof useGetStaticApplyPageQuery>;
export type GetStaticApplyPageLazyQueryHookResult = ReturnType<typeof useGetStaticApplyPageLazyQuery>;
export type GetStaticApplyPageQueryResult = Apollo.QueryResult<GetStaticApplyPageQuery, GetStaticApplyPageQueryVariables>;
export const SearchTvWebinarsDocument = gql`
    query searchTvWebinars($page: Int!, $size: Int!, $keyword: String!, $withTvEventsFlag: Boolean!) {
  searchTvWebinars(
    page: $page
    size: $size
    keyword: $keyword
    withTvEventsFlag: $withTvEventsFlag
  ) {
    page
    size
    totalElements
    totalPages
    content {
      ...TvWebinarFields
      companyContract(withCompanysNoLogo: true, companyNoImageLogoType: SMALL) {
        ...CompanyContractFields
      }
    }
    futureTvEventPages {
      ...TvEventPageFields
      relTvEventCompanyContracts {
        ...RelTvEventCompanyContractFields
      }
    }
    oldTvEventPages {
      ...TvEventPageFields
      relTvEventCompanyContracts {
        ...RelTvEventCompanyContractFields
      }
    }
    tvFeaturePages {
      ...TvEventPageFields
      relTvEventCompanyContracts {
        ...RelTvEventCompanyContractFields
      }
    }
  }
}
    ${TvWebinarFieldsFragmentDoc}
${CompanyContractFieldsFragmentDoc}
${TvEventPageFieldsFragmentDoc}
${RelTvEventCompanyContractFieldsFragmentDoc}`;

/**
 * __useSearchTvWebinarsQuery__
 *
 * To run a query within a React component, call `useSearchTvWebinarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTvWebinarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTvWebinarsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      keyword: // value for 'keyword'
 *      withTvEventsFlag: // value for 'withTvEventsFlag'
 *   },
 * });
 */
export function useSearchTvWebinarsQuery(baseOptions: Apollo.QueryHookOptions<SearchTvWebinarsQuery, SearchTvWebinarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchTvWebinarsQuery, SearchTvWebinarsQueryVariables>(SearchTvWebinarsDocument, options);
      }
export function useSearchTvWebinarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTvWebinarsQuery, SearchTvWebinarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchTvWebinarsQuery, SearchTvWebinarsQueryVariables>(SearchTvWebinarsDocument, options);
        }
export type SearchTvWebinarsQueryHookResult = ReturnType<typeof useSearchTvWebinarsQuery>;
export type SearchTvWebinarsLazyQueryHookResult = ReturnType<typeof useSearchTvWebinarsLazyQuery>;
export type SearchTvWebinarsQueryResult = Apollo.QueryResult<SearchTvWebinarsQuery, SearchTvWebinarsQueryVariables>;
export const SearchAtWebinarTabDocument = gql`
    query searchAtWebinarTab($page: Int!, $size: Int!, $keyword: String!) {
  searchTvWebinars(
    page: $page
    size: $size
    keyword: $keyword
    withTvEventsFlag: false
  ) {
    page
    size
    totalElements
    totalPages
    content {
      ...TvWebinarFields
      companyContract(withCompanysNoLogo: true, companyNoImageLogoType: SMALL) {
        ...CompanyContractFields
      }
    }
  }
}
    ${TvWebinarFieldsFragmentDoc}
${CompanyContractFieldsFragmentDoc}`;

/**
 * __useSearchAtWebinarTabQuery__
 *
 * To run a query within a React component, call `useSearchAtWebinarTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAtWebinarTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAtWebinarTabQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useSearchAtWebinarTabQuery(baseOptions: Apollo.QueryHookOptions<SearchAtWebinarTabQuery, SearchAtWebinarTabQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAtWebinarTabQuery, SearchAtWebinarTabQueryVariables>(SearchAtWebinarTabDocument, options);
      }
export function useSearchAtWebinarTabLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAtWebinarTabQuery, SearchAtWebinarTabQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAtWebinarTabQuery, SearchAtWebinarTabQueryVariables>(SearchAtWebinarTabDocument, options);
        }
export type SearchAtWebinarTabQueryHookResult = ReturnType<typeof useSearchAtWebinarTabQuery>;
export type SearchAtWebinarTabLazyQueryHookResult = ReturnType<typeof useSearchAtWebinarTabLazyQuery>;
export type SearchAtWebinarTabQueryResult = Apollo.QueryResult<SearchAtWebinarTabQuery, SearchAtWebinarTabQueryVariables>;
export const SearchAtEventTabDocument = gql`
    query searchAtEventTab($page: Int!, $size: Int!, $keyword: String!) {
  searchTvEvents(page: $page, size: $size, keyword: $keyword) {
    page
    size
    totalElements
    totalPages
    content {
      ...TvEventPageFields
      relTvEventCompanyContracts {
        ...RelTvEventCompanyContractFields
      }
    }
  }
}
    ${TvEventPageFieldsFragmentDoc}
${RelTvEventCompanyContractFieldsFragmentDoc}`;

/**
 * __useSearchAtEventTabQuery__
 *
 * To run a query within a React component, call `useSearchAtEventTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAtEventTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAtEventTabQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useSearchAtEventTabQuery(baseOptions: Apollo.QueryHookOptions<SearchAtEventTabQuery, SearchAtEventTabQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAtEventTabQuery, SearchAtEventTabQueryVariables>(SearchAtEventTabDocument, options);
      }
export function useSearchAtEventTabLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAtEventTabQuery, SearchAtEventTabQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAtEventTabQuery, SearchAtEventTabQueryVariables>(SearchAtEventTabDocument, options);
        }
export type SearchAtEventTabQueryHookResult = ReturnType<typeof useSearchAtEventTabQuery>;
export type SearchAtEventTabLazyQueryHookResult = ReturnType<typeof useSearchAtEventTabLazyQuery>;
export type SearchAtEventTabQueryResult = Apollo.QueryResult<SearchAtEventTabQuery, SearchAtEventTabQueryVariables>;
export const SearchAtUpcomingEventTabDocument = gql`
    query searchAtUpcomingEventTab($page: Int!, $size: Int!, $keyword: String!) {
  searchFutureTvEvents(page: $page, size: $size, keyword: $keyword) {
    page
    size
    totalElements
    totalPages
    content {
      ...TvEventPageFields
      relTvEventCompanyContracts {
        ...RelTvEventCompanyContractFields
      }
    }
  }
}
    ${TvEventPageFieldsFragmentDoc}
${RelTvEventCompanyContractFieldsFragmentDoc}`;

/**
 * __useSearchAtUpcomingEventTabQuery__
 *
 * To run a query within a React component, call `useSearchAtUpcomingEventTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAtUpcomingEventTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAtUpcomingEventTabQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useSearchAtUpcomingEventTabQuery(baseOptions: Apollo.QueryHookOptions<SearchAtUpcomingEventTabQuery, SearchAtUpcomingEventTabQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAtUpcomingEventTabQuery, SearchAtUpcomingEventTabQueryVariables>(SearchAtUpcomingEventTabDocument, options);
      }
export function useSearchAtUpcomingEventTabLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAtUpcomingEventTabQuery, SearchAtUpcomingEventTabQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAtUpcomingEventTabQuery, SearchAtUpcomingEventTabQueryVariables>(SearchAtUpcomingEventTabDocument, options);
        }
export type SearchAtUpcomingEventTabQueryHookResult = ReturnType<typeof useSearchAtUpcomingEventTabQuery>;
export type SearchAtUpcomingEventTabLazyQueryHookResult = ReturnType<typeof useSearchAtUpcomingEventTabLazyQuery>;
export type SearchAtUpcomingEventTabQueryResult = Apollo.QueryResult<SearchAtUpcomingEventTabQuery, SearchAtUpcomingEventTabQueryVariables>;
export const SearchAtArchiveEventTabDocument = gql`
    query searchAtArchiveEventTab($page: Int!, $size: Int!, $keyword: String!) {
  searchOldTvEvents(page: $page, size: $size, keyword: $keyword) {
    page
    size
    totalElements
    totalPages
    content {
      ...TvEventPageFields
      relTvEventCompanyContracts {
        ...RelTvEventCompanyContractFields
      }
    }
  }
}
    ${TvEventPageFieldsFragmentDoc}
${RelTvEventCompanyContractFieldsFragmentDoc}`;

/**
 * __useSearchAtArchiveEventTabQuery__
 *
 * To run a query within a React component, call `useSearchAtArchiveEventTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAtArchiveEventTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAtArchiveEventTabQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useSearchAtArchiveEventTabQuery(baseOptions: Apollo.QueryHookOptions<SearchAtArchiveEventTabQuery, SearchAtArchiveEventTabQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAtArchiveEventTabQuery, SearchAtArchiveEventTabQueryVariables>(SearchAtArchiveEventTabDocument, options);
      }
export function useSearchAtArchiveEventTabLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAtArchiveEventTabQuery, SearchAtArchiveEventTabQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAtArchiveEventTabQuery, SearchAtArchiveEventTabQueryVariables>(SearchAtArchiveEventTabDocument, options);
        }
export type SearchAtArchiveEventTabQueryHookResult = ReturnType<typeof useSearchAtArchiveEventTabQuery>;
export type SearchAtArchiveEventTabLazyQueryHookResult = ReturnType<typeof useSearchAtArchiveEventTabLazyQuery>;
export type SearchAtArchiveEventTabQueryResult = Apollo.QueryResult<SearchAtArchiveEventTabQuery, SearchAtArchiveEventTabQueryVariables>;
export const SearchAtFeatureTabDocument = gql`
    query searchAtFeatureTab($page: Int!, $size: Int!, $keyword: String!) {
  searchTvFeatures(page: $page, size: $size, keyword: $keyword) {
    page
    size
    totalElements
    totalPages
    content {
      ...TvEventPageFields
      relTvEventCompanyContracts {
        ...RelTvEventCompanyContractFields
      }
    }
  }
}
    ${TvEventPageFieldsFragmentDoc}
${RelTvEventCompanyContractFieldsFragmentDoc}`;

/**
 * __useSearchAtFeatureTabQuery__
 *
 * To run a query within a React component, call `useSearchAtFeatureTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAtFeatureTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAtFeatureTabQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useSearchAtFeatureTabQuery(baseOptions: Apollo.QueryHookOptions<SearchAtFeatureTabQuery, SearchAtFeatureTabQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAtFeatureTabQuery, SearchAtFeatureTabQueryVariables>(SearchAtFeatureTabDocument, options);
      }
export function useSearchAtFeatureTabLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAtFeatureTabQuery, SearchAtFeatureTabQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAtFeatureTabQuery, SearchAtFeatureTabQueryVariables>(SearchAtFeatureTabDocument, options);
        }
export type SearchAtFeatureTabQueryHookResult = ReturnType<typeof useSearchAtFeatureTabQuery>;
export type SearchAtFeatureTabLazyQueryHookResult = ReturnType<typeof useSearchAtFeatureTabLazyQuery>;
export type SearchAtFeatureTabQueryResult = Apollo.QueryResult<SearchAtFeatureTabQuery, SearchAtFeatureTabQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    