import React, { VFC } from 'react';
import { RelTvEventCompanyContract } from '../../../../generated/graphql';
import { AtCompanyImage } from '../../../atoms/AtCompanyImage';
import { AtModal } from '../../../organisms/AtModal';
import style from './AtExhibitingCompanyModal.module.scss';

export type AtExhibitingCompanyModalProps = {
  company: RelTvEventCompanyContract;
  setShowModal: Function;
};

export const AtExhibitingCompanyModal: VFC<AtExhibitingCompanyModalProps> = (props) => {

  const { company, setShowModal } = props;

  return (
    <AtModal
      closeEventModal={() => setShowModal(false)}
      classNameModalContent={style.modalContent}
      classNameModalDialog={style.modalDialog}
      classNameScrollContainer={style.scrollContainer}
    >
      {company.companyContract.companyPagePublishFlag ? (
        <a
          className="at-corporate-logo-area"
          href={`/company/${company.companyContract.companyContractDisplayId}`}
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
          rel="noopener"
          onClick={() => setShowModal(false)}
        >
          <AtCompanyImage
            className={style.companyLogo}
            src={company.companyContract.companyLang?.companyLogoUrl}
            alt={`${company.companyContract.companyLang?.companyNameFull}`}
          />
        </a>
      ) : (
        <AtCompanyImage
          className={style.companyLogo}
          src={company.companyContract.companyLang?.companyLogoUrl}
          alt={`${company.companyContract.companyLang?.companyNameFull}`}
        />
      )}
      <p className={style.companyName}>{company.companyContract.companyLang?.companyNameFull}</p>
      <p className={style.companyDescription}>{company.companyContract.companyLang?.companyDescription}</p>
    </AtModal>
  );

};
