import { AcTextTruncate } from '@aperza/ac-ui';
import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { AtButtonMultiple } from '../atoms/AtButtonMultiple';
import { LoadingBox } from '../atoms/LoadingBox';
import style from './AtAuthModalForWatchLater.module.scss';

interface AtAuthModalForWatchLaterProps {
  webinarId: number;
  title: string;
  streamingTerm: string;
  thumbnailImage?: string;
  closeEventModal: () => void;
}

const addWatchMutation = gql`
  mutation createTvWebinarWatchLater($tvWebinarIds: [BigInteger!]!) {
    createTvWebinarWatchLater(tvWebinarIds: $tvWebinarIds) {
      tvWatchLaterResults {
        tvWebinarId
        tvWatchLaterId
      }
      tvWatchLaterRegistrationStatus
    }
  }
`;

export const AtAuthModalForWatchLater = ({
  webinarId,
  title,
  streamingTerm,
  thumbnailImage,
  closeEventModal,
}: AtAuthModalForWatchLaterProps) => {

  const { signin, signup, currentPath } = useAuth();
  const [addFunc, { loading: nowProcessing }] = useMutation(addWatchMutation);

  const addEvent = async (signinOrSignUp: (path: string) => void) => {

    if (nowProcessing) return;

    await addFunc({
      variables: {
        tvWebinarIds: webinarId,
      },
    });
    signinOrSignUp(currentPath);

  };

  return (
    <div className={style.overlay}>
      <div className={style.overlayArea} onClick={() => closeEventModal()} />
      <div className={style.modal}>
        <div className={style.modalHeader}>
          <p className={style.modalHeaderTitle}>視聴リストに追加しますか？</p>
          <i className={`fas fa-times ${style.closeIcon}`} onClick={() => closeEventModal()} />
        </div>
        <div className={style.modalBody}>
          <div className={style.displayTime}>
            <div className={`fas fa-clock ${style.clock}`} />
            <p className={style.streamingTime}>{streamingTerm}</p>
          </div>
          <div className={style.container}>
            {thumbnailImage && (
              <div className={style.thumbnailArea}>
                <img className={style.thumbnail} src={thumbnailImage} alt="thumbnail" />
              </div>
            )}
            <div className={style.webinarTitle}>
              <AcTextTruncate text={title} maxLines={2} maxWidth={'100%'} />
            </div>
          </div>
        </div>
        <p className={style.modalInfo}>
          アペルザIDへログイン、または新規登録の上
          <br />
          動画を視聴リストに追加する
        </p>
        <div className={style.modalButtonArea}>
          <div className={style.normalButton} onClick={() => addEvent(signup)}>
            新規登録して視聴リストに追加
          </div>
          <div className={style.buttonMultiplePosition}>
            <AtButtonMultiple clickHandler={() => addEvent(signin)}>
              ログインして視聴リストに追加
            </AtButtonMultiple>
          </div>
        </div>
      </div>
    </div>
  );

};
