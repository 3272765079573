import { useIsMobile } from '../templates/MobileReady';
import { dividePartDate } from '../../util/dateUtil';
import './AtEventModalMovie.scss';
import style from './AtEventModalMovie.module.scss';
import { getOmittionSentence } from '../../util/displayUtil';
import { AtCheckbox } from '../atoms/AtCheckbox';
import type { TvEventSchedulesQuery } from '../../generated/graphql';

interface AtEventModalMovieProp {
  add: (key: number) => void;
  remove: (key: number) => void;
  webinar: N<N<N<N<TvEventSchedulesQuery['tvEventSchedules']>['content']>[number]['content']>[number]>;
  registeredTvWebinarIds: any[];
  selectedTvWebinarIds: number[];
}

export const AtEventModalMovie = ({
  add,
  remove,
  webinar,
  registeredTvWebinarIds,
  selectedTvWebinarIds: currentTvWebinarIds,
}: AtEventModalMovieProp) => {

  const isMobile = useIsMobile();

  const {
    jstMonth: month, jstDay: day, jstWeekDay: weekDay, jstTime: startTime,
  } = dividePartDate(
    webinar.tvWebinarStreamingStartDatetime,
  );
  const { jstTime: endTime } = dividePartDate(webinar.tvWebinarStreamingEndDatetime);

  const dateTimeTerm = month ? `${month}/${day} (${weekDay}) ${startTime}〜${endTime}` : '';

  const title = webinar.tvWebinarTitle ? webinar.tvWebinarTitle : '';
  const image = webinar.tvWebinarMainImgFilePath;
  const companyName = getOmittionSentence(webinar.companyContract.companyLang?.companyNameFull, 30);
  const isAlreadyRegistered = registeredTvWebinarIds.includes(webinar.tvWebinarId);

  const AlreadyRegistered = () => {

    if (isMobile) {

      return (
        <div className={` ${style.checkboxWrapper} `}>
          <label className={` ${style.at_alreadyRegistered} `}>
            {dateTimeTerm}
            <div className={` ${style.alreadyRegistered} `}> ※参加済み</div>
          </label>
        </div>
      );

    }

    return (
      <label className="at-alreadyRegistered">
        {dateTimeTerm}
        <div className="alreadyRegistered"> ※参加済み</div>
      </label>
    );

  };

  const toggleWatchLater = () => {

    if (currentTvWebinarIds.includes(webinar.tvWebinarId)) {

      remove(webinar.tvWebinarId);

    } else {

      add(webinar.tvWebinarId);

    }

  };

  const WatchLaterCheckBox = () => (
    <label className="at-checkbox">
      <input
        type="checkbox"
        id={`checkbox-webinar-${String(webinar.tvWebinarId)}`}
        onClick={(e) =>
          // without this, the label element triggers an extra click on the input element,
          // which then triggers the onClick on the parent a second time
          // https://stackoverflow.com/questions/10819699/simple-click-on-labels-makes-double-click
          e.stopPropagation()}
        checked={currentTvWebinarIds.includes(webinar.tvWebinarId)}
        readOnly
      />
      <span className="checkmark" />
      {dateTimeTerm}
    </label>
  );

  if (isMobile) {

    return (
      <div
        className={` ${style.container} `}
        onClick={(e) => {

          e.preventDefault();

          if (!isAlreadyRegistered) toggleWatchLater();

        }}
      >
        {isAlreadyRegistered ? (
          <AlreadyRegistered />
        ) : (
          <div className={` ${style.checkboxWrapper} `}>
            <AtCheckbox
              label={dateTimeTerm}
              id={`checkbox-webinar-${String(webinar.tvWebinarId)}`}
              checked={currentTvWebinarIds.includes(webinar.tvWebinarId)}
            />
          </div>
        )}
        <div className={` ${style.body} `}>
          <img className={` ${style.movieImg} `} src={image} alt={webinar.tvWebinarTitle} />
          <div className={` ${style.bodyRight} `}>
            <div className={` ${style.title} `}>{title}</div>
          </div>
        </div>
        <div className={` ${style.companyName} `}>{companyName}</div>
      </div>
    );

  }

  return (
    <div
      className="at-event-modal-movie"
      onClick={() => {

        if (!isAlreadyRegistered) toggleWatchLater();

      }}
    >
      {isAlreadyRegistered ? <AlreadyRegistered /> : <WatchLaterCheckBox />}
      <div className="movie-modal-company-info">
        <div className="movie-modal-company-name">{companyName}</div>
      </div>
      <div className="movie-modal-main">
        <img className="movie-modal-main-img" src={image} alt={webinar.tvWebinarTitle} />
        <p className="movie-modal-main-title max-lines-3 wrap">{title}</p>
      </div>
    </div>
  );

};
