import React, { VFC } from 'react';
import AtDocumentDownload from '../molecules/AtDocumentDownload';

interface AtProjectionDocAreaProps {
  webinarId: number;
  webinarTitle: string;
  projectionImage: string;
}

export const AtProjectionDocArea: VFC<AtProjectionDocAreaProps> = (props) => {

  const { webinarId, webinarTitle, projectionImage } = props;

  return (
    <>
      <div className="at-projection-doc-area">
        <p className="at-other-title">投影資料</p>
        <div className="at-projection-contents">
          <div className="at-projection-image-area">
            <img className="at-projection-image" src={projectionImage} alt="アペルザ投影資料" />
          </div>
          <p className="at-document-info">{webinarTitle}の投影資料</p>
        </div>

        <AtDocumentDownload
          to={`/watch/${webinarId}/document/projection`}
          wrapperClassName="at-document-download-btn"
          text="投影資料をダウンロード"
        />
      </div>
    </>
  );

};

export default AtProjectionDocArea;
