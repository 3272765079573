import { FC } from 'react';
import style from './EventDescription.module.scss';

type EventDescriptionProps = {
  eventTitle: string;
  catchCopy: string;
  schedule: string;
  organizeMethod?: string;
};

export const EventDescription: FC<EventDescriptionProps> = ({
  eventTitle,
  catchCopy,
  schedule,
  organizeMethod = '開催方式：オンライン開催',
}) => (
  <div className={style.container}>
    <h2 className={style.title}>{eventTitle}</h2>
    <p className={style.sub}>{catchCopy}</p>
    <div className={style.scheduleContainer}>
      <p className={style.schedule}>{schedule}</p>
      <p className={style.method}>{organizeMethod}</p>
    </div>
  </div>
);
