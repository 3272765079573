export const formatZipcode = (zipcode?: string, countryCode?: string) :string => {

  if (!zipcode) return '';

  if (countryCode === 'JP' || countryCode === undefined) {

    return `〒${zipcode.slice(0, 3)}-${zipcode.slice(3)}\n`;

  }

  return `${zipcode}\n`;

};
