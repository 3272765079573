import React, { Fragment, useState, VFC } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import style from './AtFormAreaOneLine.module.scss';

export type FormSectionType = 'textarea' | 'select' | 'text' | 'name' | 'radio' | 'checkbox' | 'password';

export interface AtFormAreaOneLineProps {
  name: string;
  id: string;
  type?: FormSectionType;
  placeholder?: string;
  required: boolean;
  registerOptions?: RegisterOptions;
  options?: { code: string | number; name: string }[];
  withOptionOtherComment: boolean;
  requiredOptionOtherComment: boolean;
  description?: string;
  readOnly: boolean;
  disabled?: boolean;
  disabledComment?: string;
  onSelectChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  formInfo?: string;
}

export const AtFormAreaOneLine: VFC<AtFormAreaOneLineProps> = (props) => {

  const {
    name,
    id,
    type,
    placeholder,
    required,
    registerOptions,
    options,
    description,
    withOptionOtherComment,
    requiredOptionOtherComment,
    onSelectChange,
    readOnly,
    disabled,
    disabledComment,
    formInfo,
  } = props;

  const {
    register,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();

  const isOtherChecked = withOptionOtherComment && watch(id).includes('2');
  const otherAnswer = getValues(`${id}-other`);

  const requiredLabel = required ? (
    <span className="at-label-small-required ml-1 mb-1 align-middle">必須</span>
  ) : (
    <span className="at-label-small-optional ml-1 mb-1 align-middle">任意</span>
  );

  const onSelectChangeEvent = (event: React.ChangeEvent<HTMLSelectElement>) => {

    if (onSelectChange) {

      onSelectChange(event);

    }

  };

  const getConfirmOptionValue = (argId: string, argOptions?: { code: string | number; name: string }[]) => {

    const values = getValues(argId);
    if (argOptions) {

      if (Array.isArray(values)) {

        const list = argOptions
          .filter((option) => values.findIndex((value) => +option.code === +value) >= 0)
          .map((option) => (
            <Fragment key={option.code}>
              {option.name}
              <br />
            </Fragment>
          ));
        return list.length > 0 ? <>{list}</> : <></>;

      }
      return argOptions
        .filter((option) =>
          (typeof option.code === 'string' ? option.code === values : option.code === +values))
        .map((option) => option.name)[0];

    }
    return '';

  };
  let inputComponent;

  const [revealPassword, setRevealPassword] = useState<boolean>(true);

  const maskPassword = (password: string): string => password.replaceAll(/./g, '*');

  switch (type) {
    case 'textarea':
      inputComponent = readOnly ? (
        <span className={` ${style.readOnlyText} `}>{getValues(id)}</span>
      ) : (
        <>
          <textarea
            className={` w-100 `}
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            {...register(id, {
              required: {
                value:   requiredOptionOtherComment,
                message: `「${name}」をご入力ください`,
              },
              maxLength: {
                value:   500,
                message: `「${name}」は500文字以内でご入力ください`,
              },
            })}
          />
          {disabled && disabledComment && <div className={`${style.disabledComment}`}>{disabledComment}</div>}
          <ErrorMessage
            errors={errors}
            name={id}
            render={({ message }) => (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                <div>{message}</div>
              </div>
            )}
          />
        </>
      );
      break;
    case 'select':
      inputComponent = readOnly ? (
        <span className={` ${style.readOnlyText} `}>{getConfirmOptionValue(id, options)}</span>
      ) : (
        <>
          <div className={style.selectWrap}>
            <select
              className={'w-100'}
              {...register(id, registerOptions)}
              disabled={disabled}
              onChange={onSelectChangeEvent}
            >
              <option hidden key="0" value="">
                選択してください
              </option>
              {options?.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          {withOptionOtherComment && isOtherChecked && (
            <textarea
              className={` w-100 `}
              id={id}
              placeholder={placeholder}
              {...register(`${id}.other`, {
                required: {
                  value:   requiredOptionOtherComment,
                  message: `「${name}」をご入力ください`,
                },
                maxLength: {
                  value:   500,
                  message: `「${name}」は500文字以内でご入力ください`,
                },
              })}
            />
          )}
          {disabled && disabledComment && <div className={`${style.disabledComment}`}>{disabledComment}</div>}
          <ErrorMessage
            errors={errors}
            name={id}
            render={({ message }) => (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                <div>{message}</div>
              </div>
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`${id}-other`}
            render={({ message }) => (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                <div>{message}</div>
              </div>
            )}
          />
        </>
      );
      break;
    case 'radio':
      inputComponent = readOnly ? (
        <span className={` ${style.readOnlyText} `}>{getConfirmOptionValue(id, options)}</span>
      ) : (
        <>
          <div className={` d-flex flex-column `}>
            {options?.map((option) => (
              <label key={option.code} className={` d-flex align-items-center`}>
                <input
                  value={option.code}
                  className={` flex-grow-0 mr-2`}
                  type="radio"
                  {...register(id, registerOptions)}
                  disabled={disabled}
                />
                {option.name}
              </label>
            ))}
            {withOptionOtherComment && isOtherChecked && (
              <textarea
                className={` w-100 `}
                id={id}
                placeholder={placeholder}
                {...register(`${id}.other`, {
                  required: {
                    value:   requiredOptionOtherComment,
                    message: `「${name}」をご入力ください`,
                  },
                  maxLength: {
                    value:   500,
                    message: `「${name}」は500文字以内でご入力ください`,
                  },
                })}
              />
            )}
          </div>
          {disabled && disabledComment && <div className={`${style.disabledComment}`}>{disabledComment}</div>}
          <ErrorMessage
            errors={errors}
            name={id}
            render={({ message }) => (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                <div>{message}</div>
              </div>
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`${id}-other`}
            render={({ message }) => (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                <div>{message}</div>
              </div>
            )}
          />
        </>
      );
      break;
    case 'checkbox':
      inputComponent = readOnly ? (
        <>
          <span className={` ${style.readOnlyText} `}>{getConfirmOptionValue(id, options)}</span>
          {withOptionOtherComment && isOtherChecked && (
            <span className={` ${style.readOnlyText} `}>{otherAnswer}</span>
          )}
        </>
      ) : (
        <>
          <div className={` d-flex flex-column `}>
            {options?.map((option) => (
              <label key={option.code} className={` d-flex align-items-center`}>
                <input
                  value={option.code}
                  className={` flex-grow-0 mr-2`}
                  type="checkbox"
                  {...register(id, registerOptions)}
                  disabled={disabled}
                />
                {option.name}
              </label>
            ))}
            {withOptionOtherComment && isOtherChecked && (
              <textarea
                className={` w-100 `}
                id={id}
                placeholder={'内容をご記入ください'}
                {...register(`${id}-other`, {
                  required: {
                    value:   requiredOptionOtherComment,
                    message: `「${name}」をご入力ください`,
                  },
                  maxLength: {
                    value:   500,
                    message: `「${name}」は500文字以内でご入力ください`,
                  },
                })}
              />
            )}
          </div>
          {disabled && disabledComment && <div className={`${style.disabledComment}`}>{disabledComment}</div>}
          <ErrorMessage
            errors={errors}
            name={id}
            render={({ message }) => (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                <div>{message}</div>
              </div>
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`${id}-other`}
            render={({ message }) => (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                <div>{message}</div>
              </div>
            )}
          />
        </>
      );
      break;
    case 'name':
      inputComponent = readOnly ? (
        <span className={` ${style.readOnlyText} `}>
          {getValues('name.lastName')} {getValues('name.firstName')}
        </span>
      ) : (
        <>
          <div className={` d-flex align-items-center  `}>
            <span className={` pr-3 ${style.nameSubLabels}`}>姓</span>
            <input
              className={` w-100 `}
              type={'text'}
              placeholder={'姓'}
              disabled={disabled}
              {...register('name.lastName', {
                required: {
                  value:   required,
                  message: `「${name}（姓）」をご入力ください`,
                },
                maxLength: {
                  value:   40,
                  message: `「${name}（姓）」は40文字以内でご入力ください`,
                },
              })}
            />
            <span className={`pl-4 pr-3 ${style.nameSubLabels}`}>名</span>
            <input
              className={` w-100 `}
              type={'text'}
              placeholder={'名'}
              disabled={disabled}
              {...register('name.firstName', {
                required: {
                  value:   required,
                  message: `「${name}（名）」をご入力ください`,
                },
                maxLength: {
                  value:   40,
                  message: `「${name}（名）」は40文字以内でご入力ください`,
                },
              })}
            />
          </div>
          {disabled && disabledComment && <div className={`${style.disabledComment}`}>{disabledComment}</div>}
          <ErrorMessage
            errors={errors}
            name={'name.lastName'}
            render={({ message }) => (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                <div>{message}</div>
              </div>
            )}
          />
          <ErrorMessage
            errors={errors}
            name={'name.firstName'}
            render={({ message }) => (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                <div>{message}</div>
              </div>
            )}
          />
        </>
      );
      break;
    case 'password':
      inputComponent = readOnly ? (
        <span className={` ${style.readOnlyText} `}>{maskPassword(getValues('password'))}</span>
      ) : (
        <>
          <div className={style.passwordForm}>
            <input
              className={'w-100'}
              type={revealPassword ? 'password' : 'text'}
              placeholder={placeholder}
              disabled={disabled}
              {...register('password', {
                required: {
                  value:   required,
                  message: `「${name}」をご入力ください`,
                },
                maxLength: {
                  value:   30,
                  message: `「${name}」は30文字以内でご入力ください`,
                },
                minLength: {
                  value:   8,
                  message: `「${name}」は8文字以上でご入力ください`,
                },
              })}
            />
            <i
              className={`far fa-eye ${style.passwordReveal}`}
              onClick={() => setRevealPassword(!revealPassword)}
            />
          </div>
          {formInfo && <p className={style.formInfo}>{formInfo}</p>}
          {disabled && disabledComment && <div className={`${style.disabledComment}`}>{disabledComment}</div>}
          <ErrorMessage
            errors={errors}
            name={'password'}
            render={({ message }) => (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                <div>{message}</div>
              </div>
            )}
          />
        </>
      );
      break;
    case 'text':
    default:
      inputComponent = readOnly ? (
        <span className={` ${style.readOnlyText} `}>{getValues(id)}</span>
      ) : (
        <>
          <input
            className={'w-100'}
            type={type}
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            {...register(id, registerOptions)}
          />
          {formInfo && <p className={style.formInfo}>{formInfo}</p>}
          {disabled && disabledComment && <div className={`${style.disabledComment}`}>{disabledComment}</div>}
          <ErrorMessage
            errors={errors}
            name={id}
            render={({ message }) => (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                <div>{message}</div>
              </div>
            )}
          />
        </>
      );
      break;
  }

  return (
    <div className={` d-flex py-4 ${style.formAreaContainer}`}>
      <div className={` d-flex flex-column justify-content-center ${style.labelNameWidth}`}>
        <label htmlFor={id}>
          <span className={` ${style.labelName} p-1`}>{name}</span>
          {!readOnly && requiredLabel}
        </label>
        {description && <span className={` ${style.description} px-1`}>{description}</span>}
      </div>
      <div className={` ${style.inputWidth} `}>{inputComponent}</div>
    </div>
  );

};
