export const getStartVideoTime = (queryString: URLSearchParams) => {

  const startVideoArg = queryString.get('t');

  if (startVideoArg !== null) {

    const regResults = /^((?<hours>\d*)h)?((?<minutes>\d*)m)?((?<seconds>\d*)s?)?$/.exec(startVideoArg);
    const hours = parseInt(regResults?.groups?.hours || '0', 10);
    const minutes = parseInt(regResults?.groups?.minutes || '0', 10);
    const seconds = parseInt(regResults?.groups?.seconds || '0', 10);
    return hours * 60 * 60 + minutes * 60 + seconds;

  }
  return 0;

};

export const timeStringToSeconds = (timeString: string): number => {

  if (timeString && typeof timeString === 'string') {

    const regResults = /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/.exec(timeString);
    if (regResults === null) return 0;

    const hours = parseInt(regResults[1] || '0', 10);
    const minutes = parseInt(regResults[2] || '0', 10);
    const seconds = parseInt(regResults[3] || '0', 10);
    return hours * 60 * 60 + minutes * 60 + seconds;

  }
  return 0;

};
