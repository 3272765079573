import { EnvUtil } from '@aperza/util';
import {
  configLocal, configTest, configTest2, configStg, configProd,
} from './config';

const getConfig = () => {

  const env: string = EnvUtil.getAppEnv();
  if (env === EnvUtil.ENV_TEST) {

    return configTest;

  }
  if (env === EnvUtil.ENV_TEST_2) {

    return configTest2;

  }
  if (env === EnvUtil.ENV_STG) {

    return configStg;

  }
  if (env === EnvUtil.ENV_PROD) {

    return configProd;

  }
  return configLocal;

};

const getTvDomain = (): string => {

  const config = getConfig();
  return config.tvDomain;

};

const getIdLoginUrl = (): string => {

  const config = getConfig();
  return config.idLoginUrl;

};

const getIdLogoutUrl = (): string => {

  const config = getConfig();
  return config.idLogoutUrl;

};

const getIdSignupUrl = (): string => {

  const config = getConfig();
  return config.idSignupUrl;

};

const getChangeEmailUrl = (): string => {

  const config = getConfig();
  return config.idChangeEmailUrl;

};

const getAccountDomain = (): string => {

  const config = getConfig();
  return config.accountDomain;

};

const getWwwDomain = (): string => {

  const config = getConfig();
  return config.wwwDomain;

};

const getStaticFileDomain = (): string => {

  const config = getConfig();
  return config.staticDomain;

};

const getIdTokenCookieName = (): string => {

  const config = getConfig();
  return config.idTokenCookieName;

};

const getAmplitudeApiKey = (): string => getConfig().amplitudeApiKey;

export {
  getTvDomain,
  getIdLoginUrl,
  getIdLogoutUrl,
  getIdSignupUrl,
  getChangeEmailUrl,
  getAccountDomain,
  getWwwDomain,
  getStaticFileDomain,
  getIdTokenCookieName,
  getAmplitudeApiKey,
};
