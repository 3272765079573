import React from 'react';
import { getStaticFileDomain } from '../../util/envUtil';
import AtCatalog from '../molecules/AtCatalog';
import { AtDocument } from '../molecules/AtInquiry';

interface AtCatalogAreaProps {
  catalogs: [AtDocument];
}

export const AtCatalogArea = ({ catalogs }: AtCatalogAreaProps) => {

  const renderCatalogs = () => catalogs.map((catalog) =>
    <div key={catalog.displayOrder}>
      <AtCatalog
        catalogName={catalog.documentName}
        catalogUrl={catalog.documentUrl!}
        documentImageUrl={catalog.documentImageUrl!}
      />
    </div>);

  return (
    <div className="at-related-catalog-area">
      <div className="at-related-catalog-title-area">
        <p className="at-other-title">関連カタログ</p>
        <p className="at-related-catalog-fromfrom">from</p>
        <p className="at-related-catalog-from">
          <img loading="lazy" src={`${getStaticFileDomain()}tv/images/logo_catalog_2022.png`} alt="アペルザカタログ" />
        </p>
      </div>
      <p className="at-catalog-separation" />
      {renderCatalogs()}
      {/* 1次リリーススコープアウト <div className="at-share-url">
                <i className="fas fa-share"></i>
                <p>URLを共有</p>
            </div> */}
    </div>
  );

};

export default AtCatalogArea;
