import { gql, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import { Redirect, useParams } from 'react-router-dom';
import { AtPagingRouteParams } from '../../App';
import { getTvDomain } from '../../util/envUtil';
import { getEventUrlWithTab } from '../../util/urlUtil';
import type { Breadcrumb } from '../atoms/AtBreadcrumbs';
import { AtBreadcrumbs } from '../atoms/AtBreadcrumbs';
import AtPagination from '../atoms/AtPagination';
import paginationStyle from '../atoms/AtPagination.module.scss';
import AtTab from '../atoms/AtTab';
import { LoadingBox } from '../atoms/LoadingBox';
import AtBreadcrumbsJsonLd from '../molecules/AtBreadcrumbsJsonLd';
import AtEventSchedule from '../molecules/AtEventSchedule';
import './AtFeatureIndexFrame.scss';

const eventpageQuery = gql`
  query tvEventPages($tvEventType: TvEventType, $page: Int!, $size: Int!) {
    tvEventPages(tvEventType: $tvEventType, page: $page, size: $size) {
      content {
        tvEventId
        tvEventTitle
        tvEventSubtitle
        tvEventDescription
        tvEventMainImgFilePath
        tvEventStartDatetime
        tvEventEndDatetime
        tvEventPublishStartDatetime
        tvEventPublishEndDatetime
        tvEventType
        otherExhibitors
        createdOperator
        lastUpdatedOperator
        createTimestamp
        updateTimestamp
        eventApplyFlag
        relTvEventCompanyContracts {
          tvEventId
          companyContractId
          companyContract(withCompanysNoLogo: false) {
            companyContractId
            companyLang {
              companyLangId
              companyName
              companyLogoUrl
              companyNameFull
            }
          }
          displayOrder
        }
        tvExhibitionReports {
          id
          companyContractId
          companyNameFull
          loggedInUserWatchStatus {
            tvWebinarId
            tvWatchLaterId
          }
          tvWebinarPublishFlag
          tvWebinarPublishStartDatetime
          tvWebinarPublishEndDatetime
          tvWebinarTitle
          tvWebinarSubtitle
          tvWebinarStreamingStartDatetime
          tvWebinarStreamingEndDatetime
          tvWebinarMoviePlayTime
          tvWebinarMainImgFilePath
          tvWebinarOndemandFlag
          tvWebinarSpeakerName
          tvWebinarSpeakerImgFilePath
          tvWebinarSpeakerDepartment
          createTimestamp
          updateTimestamp
        }
      }
      page
      size
      totalElements
      totalPages
    }
  }
`;

const eventTabValues = ['ALL', 'EVENT', 'FEATURE'] as const;
export type EventTab = typeof eventTabValues[number];

const breadcrumbs: Breadcrumb[] = [
  {
    text: 'トップ',
    url:  '/',
  },
  {
    text: 'イベント・特集一覧',
  },
];

export const AtFeatureIndexFrame = () => {

  const { pageNo, tabName } = useParams<AtPagingRouteParams>();
  const currentTab = tabName?.toUpperCase() as EventTab;
  const currentPage = pageNo ? parseInt(pageNo, 10) : 1;

  const isTabNameInvalid = currentTab !== undefined && !eventTabValues.includes(currentTab);
  const {
    loading, error, data, refetch,
  } = useQuery(eventpageQuery, {
    variables: {
      tvEventType: currentTab,
      page:        currentPage,
      size:        10,
    },
    skip: isTabNameInvalid,
  });

  if (isTabNameInvalid) {

    return <Redirect to={{ pathname: '/404', state: { invalidURL: window.location.pathname } }} />;

  }

  if (loading) return <LoadingBox />;
  if (error) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  const eventPages: [any] = data.tvEventPages.content;
  const isInvalidPage = currentPage > data.tvEventPages.totalPages;

  if (!eventPages.length) {

    return <Redirect to={{ pathname: '/404', state: { invalidURL: window.location.pathname } }} />;

  }

  const renderEventSchedules = () =>
    eventPages.map((eventPage) => (
      <AtEventSchedule
        refetch={refetch}
        key={eventPage.tvEventId}
        tvEventId={eventPage.tvEventId}
        tvEventTitle={eventPage.tvEventTitle}
        tvEventDescription={eventPage.tvEventDescription}
        tvEventMainImgFilePath={eventPage.tvEventMainImgFilePath}
        tvEventStartDatetime={eventPage.tvEventStartDatetime}
        tvEventEndDatetime={eventPage.tvEventEndDatetime}
        tvEventType={eventPage.tvEventType}
        otherExhibitors={eventPage.otherExhibitors}
        companyContracts={eventPage.relTvEventCompanyContracts}
        tvExhibitionReports={eventPage?.tvExhibitionReports}
        tvDisplayButton
        eventApplyFlag={eventPage?.eventApplyFlag}
      />
    ));

  const hasContent = eventPages.length > 0;

  return (
    <div className="at-feature-index-frame">
      <Helmet>{isInvalidPage && <meta name="robots" content="noindex" />}</Helmet>
      <AtBreadcrumbsJsonLd breadcrumbs={breadcrumbs} baseUrl={getTvDomain().slice(0, -1)} />
      <div className="at-feature-index-breadcrumbs">
        <AtBreadcrumbs breadcrumbs={breadcrumbs} />
      </div>
      <h1 className="at-feature-index-title">イベント・特集一覧</h1>
      <div className="title-area">
        <div className="at-feature-index-tabs">
          <AtTab activeFlag={!currentTab} date={'すべて'} to={getEventUrlWithTab('ALL')} />
          <AtTab activeFlag={currentTab === 'EVENT'} date={'イベント'} to={getEventUrlWithTab('EVENT')} />
          {/* Temporarily hidden for AB-15535 reason
          <AtTab
            activeFlag={tabState === 'EXHIBITION'}
            date={"展示会"}
            clickFunction={() => setTabState('EXHIBITION')}/>
          */}
          <AtTab activeFlag={currentTab === 'FEATURE'} date={'特集'} to={getEventUrlWithTab('FEATURE')} />
        </div>
      </div>
      <div className="at-feature-indexs">
        {hasContent && (
          <p className={`${paginationStyle.atPaginationHeader}`}>
            {data.tvEventPages.totalElements}件中{data.tvEventPages.content.length}件を表示
          </p>
        )}
        {renderEventSchedules()}
        {hasContent && <AtPagination currentPage={currentPage} totalPages={data.tvEventPages.totalPages} />}
      </div>
    </div>
  );

};

export default AtFeatureIndexFrame;
