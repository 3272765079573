import { VFC } from 'react';
import style from './AtNewEventLpExhibitionReports.module.scss';
import { AtEventLpTitle } from '../molecules/AtEventLpTitle';
import { RelatedWebinar } from '../../generated/graphql';
import { AtNewEventExhibitionReportEvent } from '../molecules/AtNewEventExhibitionReportEvent';
import { AtDots } from '../atoms/AtDots';

export interface AtNewEventLpExhibitionReportsProps {
  exhibitionReports: RelatedWebinar[];
  sectionTitle: string;
  setShowCTAModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ALIGN_MAXLIMIT = 3;

export const AtNewEventLpExhibitionReports: VFC<AtNewEventLpExhibitionReportsProps> = (props) => {

  const { exhibitionReports, sectionTitle, setShowCTAModal } = props;

  const exhibitionReportTitle = () => (
    <div className={style.titleContainer}>
      <div className={` ${style.titleHeader}`}>
        <div> レポーターによる展示会ブースの現地取材</div>
        <div className={` ${style.report}`}> 展示会レポート </div>
      </div>
      <AtDots />
    </div>
  );

  return (
    <div id={sectionTitle.replace(/\r\n/, '')} className={style.container}>
      {exhibitionReportTitle()}
      <div
        className={style.containerList}
        style={{
          justifyContent: exhibitionReports?.length > ALIGN_MAXLIMIT ? 'left' : 'center',
        }}
      >
        {exhibitionReports.map((exhibitionReport, index) => (
          <AtNewEventExhibitionReportEvent
            key={index}
            setShowCTAModal={setShowCTAModal}
            imgSrc={exhibitionReport.tvWebinarMainImgFilePath}
            label={exhibitionReport.tvWebinarOndemandFlag}
            title={exhibitionReport.tvWebinarTitle}
            subtitle={exhibitionReport.tvWebinarSubtitle}
            tvWebinarId={exhibitionReport.id}
            ondemandFlag={exhibitionReport.tvWebinarOndemandFlag}
            streamingStartDatetime={exhibitionReport.tvWebinarStreamingStartDatetime}
            streamingEndDatetime={exhibitionReport.tvWebinarStreamingEndDatetime}
            publishStartDatetime={exhibitionReport.tvWebinarPublishStartDatetime}
            publishEndDatetime={exhibitionReport.tvWebinarPublishEndDatetime}
          />
        ))}
      </div>
    </div>
  );

};
