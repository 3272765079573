import { VFC } from 'react';
import style from './AtWebinarDayListHeader.module.scss';
import { AtCheckbox } from './AtCheckbox';
import { CollapseChevron } from './CollapseChevron';

export interface AtWebinarDayListHeaderProps {
  collapsed?: boolean;
  onClickCheckbox?: () => void;
  onClickCollapse: () => void;
  numberRegistered: number;
  numberSelected: number;
}

export const AtWebinarDayListHeader: VFC<AtWebinarDayListHeaderProps> = (props) => {

  const {
    collapsed, onClickCheckbox, onClickCollapse, numberRegistered, numberSelected,
  } = props;

  return (
    <div className={` ${style.container} `}>
      <AtCheckbox
        onClick={onClickCheckbox}
        dashed={numberRegistered > 0 && numberSelected === 0}
        checked={numberSelected > 0}
        label={`${numberRegistered + numberSelected}件の動画を選択中`}
      />
      <CollapseChevron floatRight collapsed={collapsed} onClick={onClickCollapse} />
    </div>
  );

};
