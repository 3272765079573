import React, { MouseEvent, VFC } from 'react';
import { Link } from 'react-router-dom';
import { removeEndSlash } from '../../util/pathUtil';
import style from './AtPagination.module.scss';

export interface AtPaginationProps {
  currentPage: number;
  totalPages: number;
  /**
   * Override navigation when this is handled.
   */
  onPageChanged?: (currentPage: number, event: MouseEvent) => void;
}
const pageRegex = /\/p\/\d+/;

const removePageInUrl = () => {

  window.history.replaceState(null, '', window.location.pathname.replace(pageRegex, ''));

};

export const AtPagination: VFC<AtPaginationProps> = ({
  currentPage,
  onPageChanged,
  totalPages,
}) => {

  const getUrl = (page: number) => {

    const locationWithoutSlash = removeEndSlash(window.location.pathname);

    if (!locationWithoutSlash.match(pageRegex)) {

      return `${locationWithoutSlash}/p/${page}`;

    }

    // replace the page part
    return locationWithoutSlash.replace(pageRegex, `/p/${page}`);

  };

  const onPageChangeWrapper = (
    page: number, event: MouseEvent,
  ) => {

    if (onPageChanged) {

      event.preventDefault();
      onPageChanged(page, event);

    }

  };

  /** pagination */
  const pageValue: any = [];
  if (totalPages < 10) {

    // 全体のページ数が10ページ未満の場合
    for (let i = 1; i <= totalPages; i += 1) {

      pageValue.push(
        <Link
          key={i}
          className={`${style.atPaginationContentArea} ${currentPage === i ? `${style.selected}` : ''}`}
          onClick={(e) => onPageChangeWrapper(i, e)}
          to={getUrl(i)}
        >
          <p>{i}</p>
        </Link>,
      );

    }

  } else if (totalPages >= 10) {

    let paginationStartNo: number = 1;
    if (currentPage + 4 >= totalPages) {

      // 全体のページ数が10ページ以上かつ(最大ページ数-４)ページ～最大ページを表示する場合
      paginationStartNo = totalPages - 8;

    } else if (currentPage - 4 > 1 && currentPage + 4 < totalPages) {

      // 全体のページ数が10ページ以上かつ(現在のページ数-４)ページ～(現在のページ数+４)ページを表示する場合
      paginationStartNo = currentPage - 4;

    }

    for (let i = paginationStartNo; paginationStartNo !== 0 && i <= paginationStartNo + 8; i += 1) {

      pageValue.push(
        <Link
          key={i}
          className={`${style.atPaginationContentArea} ${currentPage === i ? `${style.selected}` : ''}`}
          onClick={(e) => onPageChangeWrapper(i, e)}
          to={getUrl(i)}
        >
          <p>{i}</p>
        </Link>,
      );

    }

  }

  const nextPage = currentPage === totalPages ? totalPages : currentPage + 1;
  const prevPage = currentPage <= 1 ? 1 : currentPage - 1;

  return (
    <div className={`${style.atPaginationArea}`}>
      <div className={`${style.atPagination}`}>

        <Link
          className={`${style.atPaginationContentArea} ${currentPage === 1 ? `${style.inactive}` : ''}`}
          onClick={(e) => onPageChangeWrapper(1, e)}
          to={getUrl(1)}
          data-testid="first-page"
        >
          <i className={`fas fa-chevron-double-left `} />
        </Link>
        <Link
          className={`${style.atPaginationContentArea} ${currentPage === 1 ? `${style.inactive}` : ''}`}
          onClick={(e) => onPageChangeWrapper(prevPage, e)}
          to={getUrl(prevPage)}
          data-testid="prev-page"
        >
          <i className={`fas fa-chevron-left `} />
        </Link>
        {pageValue}
        <Link
          className={`${style.atPaginationContentArea} ${
            currentPage === totalPages || totalPages === 1 || totalPages === 0 ? `${style.inactive}` : ''
          }`}
          onClick={(e) => onPageChangeWrapper(nextPage, e)}
          to={getUrl(nextPage)}
          data-testid="next-page"
        >
          <i className={`fas fa-chevron-right`} />
        </Link>

        <Link
          className={`${style.atPaginationContentArea} ${
            currentPage === totalPages || totalPages === 1 || totalPages === 0 ? `${style.inactive}` : ''
          }`}
          onClick={(e) => onPageChangeWrapper(totalPages, e)}
          data-testid="last-page"
          to={getUrl(totalPages)}
        >
          <i className={`fas fa-chevron-double-right`} />
        </Link>
      </div>
    </div>
  );

};

export default AtPagination;
export { removePageInUrl };
