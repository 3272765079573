import { VFC } from 'react';
import { useHistory } from 'react-router-dom';
import { timeIsFuture, timeIsPast } from '../../util/dateUtil';
import style from './AtEventLpOnDemandEvent.module.scss';
import { AtCard } from './AtCard';
import { AtLabel } from '../atoms/AtLabel';
import { AtPlayIcon } from '../atoms/AtPlayIcon';
import { TvWebinarOndemandFlag } from '../../generated/graphql';

export interface AtEventLpOnDemandEventProps {
  imgSrc?: string;
  title?: string;
  subtitle?: string;
  label?: string;
  tvWebinarId?: string;
  ondemandFlag?: TvWebinarOndemandFlag;
  startDatetime?: string;
  endDatetime?: string;
}

export const AtEventLpOnDemandEvent: VFC<AtEventLpOnDemandEventProps> = (props) => {

  const {
    imgSrc, title, subtitle, label, tvWebinarId, ondemandFlag, startDatetime, endDatetime,
  } = props;

  const clickAction = () => {

    window.open(`/watch/${tvWebinarId}`, '_blank')?.focus();

  };

  return (
    <AtCard className={` ${style.card} `} onClick={clickAction}>
      <div className={style.container}>
        <div className={style.thumbnailArea}>
          <img src={imgSrc} className={` ${style.img} `} alt="speaker" />
          {((timeIsPast(startDatetime) && timeIsFuture(endDatetime)) || (ondemandFlag === 'ONDEMAND' && timeIsPast(endDatetime))) && <AtPlayIcon />}
        </div>
        <AtLabel className={` ${style.label} `} labelKind={label || ondemandFlag} />
        <a href={`/watch/${tvWebinarId}`} onClick={(e) => e.stopPropagation()} target="_blank">
          <div className={` ${style.title} `}>{title}</div>
          <div className={` ${style.subtitle} `}>{subtitle}</div>
        </a>
      </div>
    </AtCard>
  );

};
