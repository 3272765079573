import { Dispatch, FC, SetStateAction } from 'react';
import {
  FieldValues, SubmitErrorHandler, SubmitHandler, useFormContext,
} from 'react-hook-form';
import { useTrackEvent } from '../../../hooks/tracking/useTrackEvent';
import { AtForm, FormSection } from '../../molecules/AtForm';
import style from './Confirm.module.scss';

interface AtBizCardFormContainerProps {
  formSections: FormSection[];
  tvEventId: number;
  confirmed: boolean;
  setConfirmed: Dispatch<SetStateAction<boolean>>;
}

export const AtStaticEventApplyFormContainer: FC<AtBizCardFormContainerProps> = ({
  formSections,
  tvEventId,
  confirmed,
  setConfirmed,
}) => {

  // @ts-ignore
  const { setScreen } = useFormContext();
  const { trackEvent } = useTrackEvent();

  const onSuccessSubmit: SubmitHandler<FieldValues> = (data) => {

    window.scrollTo(0, 0);
    setScreen('confirm');
    trackEvent('Confirm To Event Input Form', {
      event_id:   tvEventId,
      is_success: true,
    });

  };
  const onErrorSubmit: SubmitErrorHandler<FieldValues> = (data) => {

    window.scrollTo(0, 0);
    trackEvent('Confirm To Event Input Form', {
      event_id:   tvEventId,
      is_success: false,
    });

  };
  return (
    <div className={` ${style.bizCardFormContainer} `}>
      <div className={` ${style.mainSection} `}>
        <AtForm
          formSections={formSections}
          onSuccessSubmit={onSuccessSubmit}
          onErrorSubmit={onErrorSubmit}
          consentConfirmation
          confirmed={confirmed}
          setConfirmed={setConfirmed}
        />
      </div>
    </div>
  );

};
