import { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import { CatalogDownloadPageType } from '../../component/organisms/static-event/CatalogList';
import { TvEventCatalog } from '../../generated/graphql';
import { judgeEventPageAccessTiming } from '../../util/amplitudeWrapper';
import { useTrackEvent } from './useTrackEvent';

export type ClickCatalogCallBackArgs = {
  tvEventId?: number;
  renderedAt: React.MutableRefObject<Dayjs>;
  tvEventStartDatetime?: string;
  tvEventEndDatetime?: string;
  catalog: TvEventCatalog;
  accessPage: CatalogDownloadPageType;
};

const useClickCatalogListTrackEvent = () => {

  const { trackEvent } = useTrackEvent();

  const clickCatalogDownloadTrackEvent = useCallback(
    ({
      tvEventId,
      renderedAt,
      tvEventStartDatetime,
      tvEventEndDatetime,
      catalog,
      accessPage,
    }: ClickCatalogCallBackArgs) => {

      const accessTiming = judgeEventPageAccessTiming(
        renderedAt.current,
        tvEventStartDatetime,
        tvEventEndDatetime,
      );

      trackEvent('Click Catalog Download', {
        event_id:         tvEventId,
        cluez_company_id: catalog.companyId,
        cluez_catalog_id: Number(catalog.cluezCatalogId),
        catalog_title:    catalog.catalogTitle,
        access_page:      accessPage,
        access_timing:    accessTiming,
      });

    },
    [trackEvent],
  );

  const clickCatalogImageTrackEvent = useCallback(
    ({
      tvEventId,
      renderedAt,
      tvEventStartDatetime,
      tvEventEndDatetime,
      catalog,
      accessPage,
    }: ClickCatalogCallBackArgs) => {

      const accessTiming = judgeEventPageAccessTiming(
        renderedAt.current,
        tvEventStartDatetime,
        tvEventEndDatetime,
      );

      trackEvent('Click Catalog Image', {
        event_id:         tvEventId,
        cluez_company_id: catalog.companyId,
        cluez_catalog_id: Number(catalog.cluezCatalogId),
        catalog_title:    catalog.catalogTitle,
        access_page:      accessPage,
        access_timing:    accessTiming,
      });

    },
    [trackEvent],
  );

  const clickCatalogTitleTrackEvent = useCallback(
    ({
      tvEventId,
      renderedAt,
      tvEventStartDatetime,
      tvEventEndDatetime,
      catalog,
      accessPage,
    }: ClickCatalogCallBackArgs) => {

      const accessTiming = judgeEventPageAccessTiming(
        renderedAt.current,
        tvEventStartDatetime,
        tvEventEndDatetime,
      );

      trackEvent('Click Catalog Title', {
        event_id:         tvEventId,
        cluez_company_id: catalog.companyId,
        cluez_catalog_id: Number(catalog.cluezCatalogId),
        catalog_title:    catalog.catalogTitle,
        access_page:      accessPage,
        access_timing:    accessTiming,
      });

    },
    [trackEvent],
  );

  const clickCatalogCompanyNameTrackEvent = useCallback(
    ({
      tvEventId,
      renderedAt,
      tvEventStartDatetime,
      tvEventEndDatetime,
      catalog,
      accessPage,
    }: ClickCatalogCallBackArgs) => {

      const accessTiming = judgeEventPageAccessTiming(
        renderedAt.current,
        tvEventStartDatetime,
        tvEventEndDatetime,
      );

      trackEvent('Click Catalog CompanyName', {
        event_id:         tvEventId,
        cluez_company_id: catalog.companyId,
        cluez_catalog_id: Number(catalog.cluezCatalogId),
        catalog_title:    catalog.catalogTitle,
        access_page:      accessPage,
        access_timing:    accessTiming,
      });

    },
    [trackEvent],
  );

  return {
    clickCatalogDownloadTrackEvent,
    clickCatalogImageTrackEvent,
    clickCatalogTitleTrackEvent,
    clickCatalogCompanyNameTrackEvent,
  };

};

export default useClickCatalogListTrackEvent;
