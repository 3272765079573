import { ApolloQueryResult } from '@apollo/client';
import { TvEventPages } from '../../generated/graphql';
import AtPagination from '../atoms/AtPagination';
import AtEventSchedule from './AtEventSchedule';
import style from './AtSearchEventListVertical.module.scss';
import AtSearchHeader from './AtSearchHeader';

interface AtSearchEventListVerticalProps {
  page: number;
  size: number;
  tvEventPages: TvEventPages;
  onPageChanged: (currentPage: number) => void;
  onSizeChanged: (currentSize: number) => void;
  refetch: () => Promise<ApolloQueryResult<any>>;
}

export const AtSearchEventListVertical = ({
  page,
  size,
  tvEventPages,
  onPageChanged,
  onSizeChanged,
  refetch,
}: AtSearchEventListVerticalProps) => {

  const eventPageValues = tvEventPages.content?.map((eventPage) => {

    if (!eventPage) {

      return null;

    }
    const companyContracts = eventPage.relTvEventCompanyContracts ?? [];
    return (
      <div key={eventPage.tvEventId} className={` ${style.atEventlistContent} `}>
        <AtEventSchedule
          refetch={refetch}
          key={eventPage.tvEventId}
          tvEventId={eventPage.tvEventId}
          tvEventTitle={eventPage.tvEventTitle}
          tvEventDescription={eventPage.tvEventDescription}
          tvEventMainImgFilePath={eventPage.tvEventMainImgFilePath}
          tvEventStartDatetime={eventPage.tvEventStartDatetime?.toString()}
          tvEventEndDatetime={eventPage.tvEventEndDatetime?.toString()}
          tvEventType={eventPage.tvEventType}
          otherExhibitors={eventPage.otherExhibitors}
          companyContracts={companyContracts}
          tvExhibitionReports={eventPage?.tvExhibitionReports}
          tvDisplayButton
          eventApplyFlag={eventPage?.eventApplyFlag}
        />
      </div>
    );

  });

  return (
    <>
      <AtSearchHeader
        totalElements={tvEventPages.totalElements}
        contentLength={tvEventPages.content?.length ?? 0}
        size={size}
        onSizeChanged={onSizeChanged}
      />
      <div className={` ${style.atEventlistArea} `}>{eventPageValues}</div>
      <AtPagination
        currentPage={page}
        onPageChanged={onPageChanged}
        totalPages={tvEventPages.totalPages}
      />
    </>
  );

};

export default AtSearchEventListVertical;
