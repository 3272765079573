import React, { MouseEvent } from 'react';
import style from './AtShowMoreButton.module.scss';
import { LoadingBox } from './LoadingBox';

export interface AtShowMoreButtonProps {
  hide?: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
}

export const AtShowMoreButton = ({ onClick, hide, loading }: AtShowMoreButtonProps) => {

  if (hide) return null;

  return (
    <button
      type="button"
      className={`${style.showMoreButton} d-flex justify-content-center align-items-center`}
      onClick={onClick}
      disabled={loading}
    >
      {!loading ? 'もっと見る' : <LoadingBox />}
    </button>
  );

};

export default AtShowMoreButton;
