import { VFC } from 'react';
import { getStaticFileDomain } from '../../util/envUtil';
import './AtLogo.scss';

export interface AtLogoProps {
  thumbnail?: boolean;
  height?: string;
  disableClick?: boolean;
  thumbnailColor?: 'white';
  lazy?: boolean;
}

export const AtLogo: VFC<AtLogoProps> = ({
  thumbnail, height, disableClick, thumbnailColor, lazy,
}) => {

  const href = disableClick ? undefined : '/';

  if (height) {

    return (
      <a className="at-logo-height" href={href} style={{ height }}>
        <img
          loading={lazy ? 'lazy' : 'eager'}
          src={`${getStaticFileDomain()}tv/images/logo_tv_2022.png`}
          alt="logo"
        />
      </a>
    );

  }

  if (thumbnail) {

    return (
      <a className="at-logo-thumbnail" href={href}>
        {thumbnailColor === 'white' && (
          <img
            loading={lazy ? 'lazy' : 'eager'}
            src={`${getStaticFileDomain()}tv/images/logo_tv_2022_white.png`}
            alt="logo"
          />
        )}
        {thumbnailColor !== 'white' && (
          <img
            loading={lazy ? 'lazy' : 'eager'}
            src={`${getStaticFileDomain()}tv/images/logo_tv_2022.png`}
            alt="logo"
          />
        )}
      </a>
    );

  }

  return (
    <a className="at-logo" href={href}>
      <img
        loading={lazy ? 'lazy' : 'eager'}
        src={`${getStaticFileDomain()}tv/images/logo_tv_2022.png`}
        alt="logo"
      />
    </a>
  );

};
