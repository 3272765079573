import { VFC } from 'react';
import { Link } from 'react-router-dom';
import { AtRequiredSigninModal } from './AtRequiredSigninModal';

export interface AtDocumentDownloadProps {
  to: string;
  wrapperClassName?: string;
  text?: string;
}

const AtDocumentDownload: VFC<AtDocumentDownloadProps> = ({ to, wrapperClassName, text }) => (
  <AtRequiredSigninModal buttonType="download" to={to} openLoginInNewTab>
    <div className={wrapperClassName}>
      <Link to={to} target={'_blank'} rel="noopener">
        {text}
      </Link>
    </div>
  </AtRequiredSigninModal>
);

export default AtDocumentDownload;
