import { VFC } from 'react';
import { useHistory } from 'react-router-dom';
import style from './AtPlayIcon.module.scss';

export interface AtPlayIconProps {
  watchUrl?: string;
  openInNewTab?: boolean
}

export const AtPlayIcon: VFC<AtPlayIconProps> = ({ watchUrl, openInNewTab }) => {

  const history = useHistory();

  const clickAction = () => {

    if (openInNewTab) {

      window.open(`${watchUrl}`, '_blank')?.focus();

    } else {

      history.push(`${watchUrl}`);

    }

  };

  return (
    <div className={`${style.container} `} onClick={watchUrl ? clickAction : undefined}>
      <i className={`${style.circle} fas fa-circle`} />
      <i className={`${style.play} fas fa-play`} />
    </div>
  );

};
