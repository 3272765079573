import { VFC, createContext, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from 'react-responsive';

type MobileReadyType = {
  isMobile: boolean;
};

const MobileReadyContext = createContext<MobileReadyType>({
  isMobile: false,
});

export interface MobileReadyProps {
  children?: React.ReactNode;
}

/**
 * A page will be 'isMobile' if it is a descendant of this context AND has a viewport width less than `max-width`
 */
export const MobileReady: VFC<MobileReadyProps> = ({ children }) => {

  const isMobileMediaQuery = useMediaQuery({ query: '(max-width: 767px)' });

  const value: MobileReadyType = {
    isMobile: isMobileMediaQuery,
  };

  return (
    <MobileReadyContext.Provider value={value}>
      <Helmet>
        {/* Reset the viewport meta tag */}
        <meta name="viewport" content="width=device-width" />
      </Helmet>
      {children}
    </MobileReadyContext.Provider>
  );

};

export const useIsMobile = () => useContext(MobileReadyContext).isMobile;
