/* eslint-disable react-hooks/exhaustive-deps */
import { ApolloError } from '@apollo/client';
import Cookies from 'js-cookie';
import React, {
  createContext, useContext, useEffect, useState, VFC,
} from 'react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { EnvUtil } from '@aperza/util';
import {
  GetUserQuery, useGetUserLazyQuery, User, UserType,
} from '../generated/graphql';
import { identifyUser } from '../util/amplitudeWrapper';
import {
  getAccountDomain,
  getChangeEmailUrl,
  getIdLoginUrl,
  getIdLogoutUrl,
  getIdSignupUrl,
  getIdTokenCookieName,
} from '../util/envUtil';

export type AuthContextType = {
  currentUser: User;
  unreadMessageCount: number;
  signup: (path?: string, openInNewTab?: boolean) => void;
  /**
   * @returns encoded current path
   */
  currentPath: string;
  /**
   * @returns signup url and returns to the original path after signup
   */
  signupUrlWithCurrentPath: string;

  /**
   * @returns signin url and returns to the original path after signin
   */
  signinUrlWithCurrentPath: string;

  signin: (path?: string, openInNewTab?: boolean) => void;
  logout: () => void;
  changeEmail: () => void;
  changePassword: () => void;
  accountService: () => void;
  accountServiceUrl: string;
  getTvWatchLaterCreate: () => string | undefined;
  getTvWatchLaterWebinarIds: () => string | undefined;
};

const AuthContext = createContext<AuthContextType>({
  currentUser:               { userType: UserType.Guest },
  unreadMessageCount:        0,
  signup:                    (path?: string, openInNewTab?: boolean) => {},
  signin:                    (path?: string, openInNewTab?: boolean) => {},
  currentPath:               '',
  signupUrlWithCurrentPath:  '',
  signinUrlWithCurrentPath:  '',
  logout:                    () => {},
  changeEmail:               () => {},
  changePassword:            () => {},
  accountService:            () => {},
  accountServiceUrl:         '',
  getTvWatchLaterCreate:     () => undefined,
  getTvWatchLaterWebinarIds: () => undefined,
});

export const useAuth = () => useContext(AuthContext);

export interface AuthProvideProps {
  children: React.ReactNode;
  element: HTMLElement | null;
}

export const AuthProvider: VFC<AuthProvideProps> = (props) => {

  const [currentUser, setCurrentUser] = useState<User>({ userType: UserType.Guest });
  const [loading, setLoading] = useState<Boolean>(true);
  const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0);
  const env: string = EnvUtil.getAppEnv();
  const [getUserLazyQuery] = useGetUserLazyQuery({
    onCompleted(response: GetUserQuery) {

      // if user.exists
      if (response && response.user) {

        identifyUser(response.user);

        // if user is logged
        if (response.user.userType === UserType.Member && response.user.userId != null) {

          // init logrocket

          let logRocketKey = 'xdhunq/aperza-web-test';
          if (env === EnvUtil.ENV_STG) {

            logRocketKey = 'xdhunq/aperza-web-stg';

          } else if (env === EnvUtil.ENV_PROD) {

            logRocketKey = 'xdhunq/aperza-web-prod';

          }

          // AB-19717 - add extra filter for e2e and aperza member
          if (
            !response.user.mailAddress?.startsWith('e2e-') &&
            !response.user.mailAddress?.endsWith('@aperza.biz')
          ) {

            LogRocket.identify(response.user.userId.toString());
            LogRocket.init(logRocketKey);
            setupLogRocketReact(LogRocket);

          }

          // END init logrocket

        }

        setCurrentUser(response.user);
        setUnreadMessageCount(response.unreadMessageCount);

      }

      setLoading(false);

    },
    onError(error: ApolloError) {},
    fetchPolicy: 'network-only',
  });

  const getSignupUrl = (path: string = '') =>
    (path.length > 0 ? `${getIdSignupUrl()}?path=${path}` : getIdSignupUrl());

  const getSigninUrl = (path: string = '') =>
    (path.length > 0 ? `${getIdLoginUrl()}?path=${path}` : getIdLoginUrl());

  const currentPath = encodeURIComponent(window.location.pathname + window.location.search);
  const signupUrlWithCurrentPath = getSignupUrl(currentPath);
  const signinUrlWithCurrentPath = getSigninUrl(currentPath);

  const signup = (path: string = '', openInNewTab = false) => {

    openInNewTab
      ? window.open(getSignupUrl(path), '_blank')?.focus()
      : window.location.assign(getSignupUrl(path));

  };

  const signin = (path: string = '', openInNewTab = false) => {

    openInNewTab
      ? window.open(getSigninUrl(path), '_blank')?.focus()
      : window.location.assign(getSigninUrl(path));

  };

  const logout = () => {

    window.location.assign(getIdLogoutUrl());

  };

  const changeEmail = () => {

    window.location.assign(getChangeEmailUrl());

  };

  const changePassword = () => {

    window.location.assign(`${getAccountDomain()}password/`);

  };

  const accountServiceUrl = `${getAccountDomain()}account`;

  const accountService = () => {

    window.location.assign(accountServiceUrl);

  };

  const getTvWatchLaterCreate = () => props.element?.dataset.aperzatvwatchlatercreate;

  const getTvWatchLaterWebinarIds = () => props.element?.dataset.webinarIds;

  useEffect(() => {

    if (Cookies.get(getIdTokenCookieName())) {

      const execGetUser = async () => {

        await getUserLazyQuery();

      };
      execGetUser();

    } else {

      setLoading(false);

    }

  }, []);

  const value: AuthContextType = {
    currentUser,
    unreadMessageCount,
    signup,
    signin,
    currentPath,
    signupUrlWithCurrentPath,
    signinUrlWithCurrentPath,
    logout,
    changeEmail,
    changePassword,
    accountService,
    accountServiceUrl,
    getTvWatchLaterCreate,
    getTvWatchLaterWebinarIds,
  };

  return <AuthContext.Provider value={value}>{!loading && props.children}</AuthContext.Provider>;

};
