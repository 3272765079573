import { LoadingBox } from './LoadingBox';
import style from './AtButtonDeleteForWebinarList.module.scss';

interface AtButtonDeleteForWebinarListProp {
  label: string;
  iconStyle: string;
  onClickFunction?: () => void;
  loading?: boolean;
}

export const AtButtonDeleteForWebinarList = ({
  label,
  iconStyle,
  onClickFunction,
  loading,
}: AtButtonDeleteForWebinarListProp) => {

  const doOnClickFunction = () => {

    if (loading) return;
    if (onClickFunction) {

      onClickFunction();

    }

  };

  return (
    <div className={`${style.atButtonSmall}`} onClick={doOnClickFunction}>
      <p className={`${style.label}`}>{label}</p>
      <div className={`${style.atButtonSmallDivide}`} />
      <i className={`${style.icon} ${iconStyle}`} />
      {loading && <LoadingBox />}
    </div>
  );

};
