import { useRef } from 'react';
import { TvEventPageQuery } from '../../generated/graphql';
import { clickCard } from '../../util/clickUtil';
import {
  dividePartDate, getCurrentMoment, getMoment, timeIsFuture, timeIsPast,
} from '../../util/dateUtil';
import { useIsMobile } from '../templates/MobileReady';
import { AtPlayIcon } from '../atoms/AtPlayIcon';
import style from './AtMovieListVertical.module.scss';
import './AtMovieListVertical.scss';

interface AtMovieListVerticalProps {
  movie: N<
  N<N<N<TvEventPageQuery['tvEventPage']>['tvEventSchedules']>['content']>[number]['content']
  >[number];
  eventType?: string;
}

export const AtMovieListVertical = ({ movie, eventType }: AtMovieListVerticalProps) => {

  const isMobile = useIsMobile();
  const webinarLinkRef = useRef<HTMLAnchorElement>(null);
  const webinarId = movie?.tvWebinarId ? movie.tvWebinarId : '';
  const thunbnail = movie?.tvWebinarMainImgFilePath;
  const subtitle =
    (movie?.tvWebinarSubtitle?.length ?? 0) < 38
      ? movie?.tvWebinarSubtitle
      : `${movie!.tvWebinarSubtitle!.substring(0, 38)}...`;
  const description =
    (movie?.tvWebinarDescription?.length ?? 0) < 72
      ? movie?.tvWebinarDescription
      : `${movie!.tvWebinarDescription!.substring(0, 72)}...`;
  const speakerName = movie?.tvWebinarSpeakerName ? `${movie.tvWebinarSpeakerName} 氏` : '';
  const speakerPost = movie?.tvWebinarSpeakerDepartment;
  const speakerImg = movie?.tvWebinarSpeakerImgFilePath;
  const companyName = movie?.companyContract?.companyLang?.companyNameFull ?? '';
  let department;
  if ((speakerPost?.length ?? 0) < 15) {

    department = speakerPost ?? '';

  } else {

    department = `${speakerPost!.substring(0, 15)}...`;

  }

  const streamingEndTime = getMoment(movie?.tvWebinarStreamingEndDatetime).tz('Asia/Tokyo');
  const { jstTime: startTime } = dividePartDate(movie?.tvWebinarStreamingStartDatetime);
  const { jstTime: endTime } = dividePartDate(movie?.tvWebinarStreamingEndDatetime);

  const webinarTimeValue = streamingEndTime.isBefore(getCurrentMoment()) ? (
    <></>
  ) : (
    <div className={` mb-3 `}>
      <i className={` fas fa-clock `} />
      <span className={` ml-2 `}>
        {startTime}〜{endTime}
      </span>
    </div>
  );

  const addMovieClass = eventType === 'FEATURE' ? 'feature-movie' : '';

  let movieStatusLabel = <></>;
  let movieStatus: string = '';
  const ondemandFlag = movie?.tvWebinarOndemandFlag === 'ONDEMAND';

  if (timeIsFuture(movie?.tvWebinarStreamingStartDatetime)) {

    movieStatus = 'opentime';
    movieStatusLabel = <div className="movie-label at-label-normal-will-stream">配信予定</div>;

  } else if (
    timeIsPast(movie?.tvWebinarStreamingStartDatetime) &&
    timeIsFuture(movie?.tvWebinarStreamingEndDatetime)
  ) {

    movieStatus = 'ontime';
    movieStatusLabel = <div className="movie-label at-label-normal-in-stream">配信中</div>;

  } else if (ondemandFlag && timeIsPast(movie?.tvWebinarStreamingEndDatetime)) {

    movieStatus = 'ondemand';
    movieStatusLabel = <div className="movie-label at-label-normal-ondemand">オンデマンド</div>;

  } else if (!ondemandFlag && timeIsPast(movie?.tvWebinarStreamingEndDatetime)) {

    movieStatus = 'closed';
    movieStatusLabel = <div className="movie-label at-label-normal-close-stream">公開終了</div>;

  }

  if (isMobile) {

    return (
      <div
        className={` ${style.at_movie_list_vertical} ${
          /* eventType === 'FEATURE' ? style.feature_movie : //enable when feature page created */ ''
        }`}
        key={webinarId}
        onClick={() => clickCard(webinarLinkRef)}
      >
        {/* movie image */}
        <div
          className={` ${style.at_thumbnail} ${movieStatus === 'closed' ? style.at_movie_over : ''}`}
          style={{
            backgroundImage:    `url(${thunbnail})`,
            backgroundSize:     'contain',
            backgroundPosition: 'center',
            backgroundRepeat:   'no-repeat',
          }}
        >
          {movieStatus === 'closed' && <div className={` ${style.movie_status} `}>公開終了</div>}
          {movieStatusLabel}
          {(movieStatus === 'ondemand' || movieStatus === 'ontime') && <AtPlayIcon />}
        </div>
        {/* -movie image */}
        <div className={` ${style.movie_information} `}>
          {/* movie description */}
          {webinarTimeValue}
          <a className={` ${style.movie_title} `} href={`/watch/${webinarId}`} ref={webinarLinkRef}>
            {movie?.tvWebinarTitle}
          </a>
          <p className={` ${style.movie_subtitle} `}>{subtitle}</p>
          <p className={` ${style.movie_description} `}>
            {description}
            <a href={`/watch/${webinarId}`}> もっと見る</a>
          </p>
          {/* -movie description */}
          {/* company information */}
          <div className={` ${style.movie_company_information} `}>
            <div className={` ${style.movie_company_name} text-truncate`}>{companyName}</div>
            <div className="d-flex">
              <img className={` ${style.movie_company_speaker_image} `} src={speakerImg} alt="speaker" />
              <div>
                <div className={` ${style.movie_company_post} `}>{department}</div>
                <div className={` ${style.movie_company_speaker} `}>{speakerName}</div>
              </div>
            </div>
            {/* -company information */}
          </div>
        </div>
      </div>
    );

  }

  return (
    <div
      className={` at-movie-list-vertical ${addMovieClass} d-flex flex-column `}
      key={webinarId}
      onClick={() => clickCard(webinarLinkRef)}
    >
      <div className="thumbnail-area">
        <img src={thunbnail} alt="movie" />
        {movieStatus === 'closed' && (
          <div className="movie-status d-flex align-items-center justify-content-center w-100 h-100">
            公開終了
          </div>
        )}
        {movieStatusLabel}
        {(movieStatus === 'ondemand' || movieStatus === 'ontime') && <AtPlayIcon />}
      </div>
      <div className="movie-information">
        {webinarTimeValue}
        <a className="movie-title" href={`/watch/${webinarId}`} ref={webinarLinkRef}>
          {movie?.tvWebinarTitle}
        </a>
        <p className="movie-subtitle">{subtitle}</p>
        <p className="movie-description">
          {description}
          <a href={`/watch/${webinarId}`}> もっと見る</a>
        </p>
      </div>
      <div className="movie-company-information">
        <div className="movie-company-name text-truncate">{companyName}</div>
        <div className="d-flex flex-row flex-nowrap">
          <img className="movie-company-speaker-image" src={speakerImg} alt="" />
          <div>
            <div className="movie-company-post">{department}</div>
            <div className="movie-company-speaker">{speakerName}</div>
          </div>
        </div>
      </div>
    </div>
  );

};
