import { VFC, useState, useRef } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import style from './AtWebinarDayList.module.scss';
import { AtWebinarDayListHeader } from '../atoms/AtWebinarDayListHeader';
import { TvEventSchedulesQuery } from '../../generated/graphql';
import { AtEventModalMovie } from './AtEventModalMovie';

export interface AtWebinarDayListProps {
  webinars: N<N<N<N<TvEventSchedulesQuery['tvEventSchedules']>['content']>[number]['content']>[number]>[];
  registeredTvWebinarIds: number[];
  selectedTvWebinarIds: number[];
  add: (key: number) => void;
  remove: (key: number) => void;
}

export const AtWebinarDayList: VFC<AtWebinarDayListProps> = (props) => {

  const {
    webinars, registeredTvWebinarIds, selectedTvWebinarIds, add, remove,
  } = props;

  const accordionContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [collapsed, setCollapsed] = useState<boolean>(true);

  const scrollHeight = accordionContainerRef.current?.scrollHeight ?? 0;

  const numberSelected = webinars.filter((webinar) => selectedTvWebinarIds.includes(webinar.tvWebinarId))
    .length;
  const numberRegistered = webinars.filter((webinar) => registeredTvWebinarIds.includes(webinar.tvWebinarId))
    .length;

  const onClickCollapse = async () => {

    setCollapsed(!collapsed);

    // await to give time for the accordion to open, so that there is enough height to scroll the screen
    await new Promise((resolve) => setTimeout(resolve, 400));

    // only scroll when opening
    if (collapsed) {

      // don't scroll the background too
      const boundary = document.getElementById(`ModalScrollBoundary`);

      scrollIntoView(containerRef.current!, {
        behavior: 'smooth',
        block:    'start',
        boundary,
      });

    }

  };

  const onClickCheckbox = () => {

    if (numberSelected > 0) {

      webinars.forEach((webinar) => {

        remove(+webinar.tvWebinarId);

      });

    } else {

      webinars.forEach((webinar) => {

        add(+webinar.tvWebinarId);

      });

    }

  };

  return (
    <div ref={containerRef} className={` ${style.container} `}>
      <AtWebinarDayListHeader
        onClickCollapse={onClickCollapse}
        onClickCheckbox={onClickCheckbox}
        numberSelected={numberSelected}
        numberRegistered={numberRegistered}
        collapsed={collapsed}
      />
      <div
        ref={accordionContainerRef}
        className={` ${style.list}`}
        style={{ height: `${collapsed ? 0 : scrollHeight}px` }}
      >
        {webinars.map((webinar) => (
          <AtEventModalMovie
            key={webinar.tvWebinarId}
            webinar={webinar}
            registeredTvWebinarIds={registeredTvWebinarIds}
            selectedTvWebinarIds={selectedTvWebinarIds}
            add={add}
            remove={remove}
          />
        ))}
      </div>
    </div>
  );

};
