import { gql, useApolloClient, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import AtMovieListHorizontalSimple from '../molecules/AtMovieListHorizontalSimple';
import './AtRankingFrame.scss';

const rankingQuery = gql`
  query {
    tvTopRankings(page: 1, size: 1) {
      content {
        tvTopRankingId
        tvTopRankingTitle
        tvTopRankingTvWebinarIds
        tvTopRankingPublishStartDatetime
        tvTopRankingPublishEndDatetime
        createdOperator
        lastUpdatedOperator
        createTimestamp
        updateTimestamp
      }
      page
      size
      totalElements
      totalPages
    }
  }
`;

const webinerDetailQuery = gql`
  query tvWebinarCompany($tvWebinarId: BigInteger!) {
    tvWebinar(tvWebinarId: $tvWebinarId) {
      tvWebinarId
      companyContractId
      tvWebinarPublishFlag
      tvWebinarPublishStartDatetime
      tvWebinarPublishEndDatetime
      tvWebinarTitle
      tvWebinarSubtitle
      tvWebinarDescription
      tvWebinarOndemandFirstDatetime
      tvWebinarStreamingStartDatetime
      tvWebinarStreamingEndDatetime
      tvWebinarMoviePlayTime
      tvWebinarSpeakerName
      tvWebinarSpeakerImgFilePath
      tvWebinarSpeakerDepartment
      createdOperator
      lastUpdatedOperator
      createTimestamp
      updateTimestamp
      relTvWebinarSystem {
        tvWebinarId
        tvWebinarVimeoLiveEventId
        tvWebinarVimeoLiveEventStatus
        tvWebinarVimeoVideoId
        tvWebinarVimeoVideoStatus
        tvWebinarMedialiveChannelId
        tvWebinarMedialiveChannelStatus
        tvWebinarSlidoEventId
      }
      tvEventPages {
        tvEventId
      }
    }
  }
`;

export const AtRankingFrame = () => {

  const { loading, error, data } = useQuery(rankingQuery, {});
  const [rankingValues, setRankingValue] = useState<any[]>([]);

  const promises: any = [];
  const apolloClient = useApolloClient();

  const callWebinerDetails = async (webinarIds: number[]) => {

    const webinarDetailsPromises = webinarIds.map((webinarId: number) =>
      apolloClient.query({
        query:     webinerDetailQuery,
        variables: {
          tvWebinarId: webinarId,
        },
      }));

    Promise.all(webinarDetailsPromises).then((webinarDetailsArray: any[]) => {

      let count = 1;
      const _rankingValues: any[] = [];
      webinarIds.forEach((webinarId: number) => {

        const tvWebinar = webinarDetailsArray.find(
          (response) => response?.data?.tvWebinar?.tvWebinarId === webinarId,
        )?.data?.tvWebinar;
        if (tvWebinar) {

          _rankingValues.push(
            <AtMovieListHorizontalSimple key={count} rankingNo={count} tvWebinar={tvWebinar} />,
          );
          count += 1;

        }

      });
      setRankingValue(_rankingValues);

    });

  };

  useEffect(() => {

    if (data?.tvTopRankings?.content[0]?.tvTopRankingTvWebinarIds) {

      callWebinerDetails(data?.tvTopRankings?.content[0]?.tvTopRankingTvWebinarIds);

    }

  }, [JSON.stringify(data?.tvTopRankings?.content[0]?.tvTopRankingTvWebinarIds)]);

  return (
    <>
      {loading && <p>Loading...</p>}
      {error && <p>データの取得に失敗しました</p>}
      {!loading && !error && (
        <section className="ranking-frame">
          <div className="title">
            <i className="fas fa-trophy" />
            <h2>総合ランキング</h2>
          </div>
          <div className="rankings">{rankingValues.map((ranking) => ranking)}</div>
        </section>
      )}
    </>
  );

};

export default AtRankingFrame;
