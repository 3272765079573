import { VFC } from 'react';
import { Modal } from 'react-bootstrap';
import style from './AtModal.module.scss';
import { AtModalHeader } from '../molecules/AtModalHeader';
// import { AtButtonMultiple } from '../atoms/AtButtonMultiple';

export interface AtModalProps {
  closeEventModal: () => void;
  title?: string;
  children: React.ReactNode;
  classNameModalContainer?: string;
  classNameModalContent?: string;
  classNameModalDialog?: string;
  classNameScrollContainer?: string;
}

export const AtModal: VFC<AtModalProps> = (props) => {

  const {
    closeEventModal,
    title,
    children,
    classNameModalContainer = '',
    classNameModalContent = '',
    classNameModalDialog = '',
    classNameScrollContainer = '',
  } = props;

  return (
    <Modal
      dialogClassName={classNameModalDialog || style.modalDialog}
      contentClassName={classNameModalContent || style.modalContent}
      show
      onHide={closeEventModal}
      centered
    >
      <AtModalHeader title={title} closeEventModal={closeEventModal} />
      <div className={` ${classNameModalDialog || style.scrollContainer} `} id="ModalScrollBoundary">
        <div className={`${style.container} ${classNameModalContainer}`}>
          {children}
          {/* <AtButtonMultiple
          className={` ${style.button} `}
          clickHandler={() => console.log('%cclick:', 'color:yellow')}
          >
          視聴予約をする
        </AtButtonMultiple> */}
        </div>
      </div>
    </Modal>
  );

};
