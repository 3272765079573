import { Gpt } from 'react-gpt-ads';

export const AtGpt = () => (
  <div style={{ marginTop: '16px' }}>
    <Gpt
      adUnit="/171796974/TV_ALL_PC_ALL_RCT_F_PROD"
      name="div-gpt-ad-1691472348915-0"
      size={[300, 250]}
    />
  </div>
);
