import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';
import { VFC } from 'react';
import { AtFormAreaOneLine } from '../atoms/AtFormAreaOneLine';
import style from './AtBizCardConfirmConfirm.module.scss';
import { FormSection } from './AtForm';

export interface AtBizCardConfirmConfirmProps {
  formSections: FormSection[];
  onCompleteSubmit: SubmitHandler<FieldValues>;
  submitButtonValue: string;
  onClickBackButtonCallback?: () => void;
}

export const AtFormSubmit: VFC<AtBizCardConfirmConfirmProps> = (props) => {

  const {
    handleSubmit,
    watch,
    // @ts-ignore
    setScreen,
    formState,
  } = useFormContext();
  const { isSubmitting } = formState;
  const watchAllFields = watch();

  const formSectionsComponents = props.formSections.map((formSection) => (
    <AtFormAreaOneLine
      key={formSection.id}
      name={formSection.name}
      id={formSection.id}
      placeholder={formSection.placeholder}
      type={formSection.type}
      required={formSection.required}
      options={formSection.options}
      withOptionOtherComment={formSection.withOptionOtherComment}
      requiredOptionOtherComment={formSection.requiredOptionOtherComment}
      description={formSection.description}
      readOnly
    />
  ));

  return (
    <form className={` d-flex flex-column`} onSubmit={handleSubmit(props.onCompleteSubmit)}>
      {formSectionsComponents}
      <div className={` d-flex justify-content-between`}>
        <button
          type="button"
          onClick={() => {

            window.scrollTo(0, 0);
            setScreen('input');
            props.onClickBackButtonCallback?.();

          }}
          className={` ${style.returnButton} `}
        >
          戻る
        </button>
        <input
          disabled={isSubmitting}
          className={` ${style.submitButton} ${isSubmitting ? style.submitting : ''}`}
          type="submit"
          value={props.submitButtonValue}
        />
      </div>
    </form>
  );

};
