import { VFC } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { AtHeaderAndFooter } from '../templates/AtHeaderAndFooter';
import { AtButtonNormalBack } from '../atoms/AtButton';
import style from './At404Error.module.scss';
import { AtTechBackground } from '../atoms/AtTechBackground';

export const At500Error: VFC = () => {

  const location = useLocation<{ invalidURL?: string }>();

  if (location.state?.invalidURL) {

    window.history.replaceState({}, '', location.state.invalidURL);

  } else {

    // ユーザーが意図的に/500にアクセスしようとした場合は、404ページに送ります。
    return <Redirect to={{ pathname: '/404', state: { invalidURL: location.pathname } }} />;

  }

  return (
    <AtHeaderAndFooter>
      <div className={`${style.pageContainer}`}>
        <AtTechBackground contents="500" />
        <p className={` ${style.message} text-center`}>
          一時的なエラーが発生しました。時間をおいて再度リロードしてください。
          <br />
          トップページに戻って操作するか、再度アクセスしてください。
        </p>
        <AtButtonNormalBack message={'トップページに戻る'} href={'/'} />
      </div>
    </AtHeaderAndFooter>
  );

};
