import { useState, VFC } from 'react';
import { RelTvEventCompanyContract } from '../../../../generated/graphql';
import { AtCompanyImage } from '../../../atoms/AtCompanyImage';
import style from './AtExhibitingCompanyLogo.module.scss';
import { AtExhibitingCompanyModal } from './AtExhibitingCompanyModal';

export type AtExhibitingCompanyLogoProps = {
  company: RelTvEventCompanyContract;
};

export const AtExhibitingCompanyLogo: VFC<AtExhibitingCompanyLogoProps> = ({ company }) => {

  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <div onClick={() => setShowModal(true)}>
        <AtCompanyImage
          className={style.companyLogo}
          src={company.companyContract.companyLang?.companyLogoUrl}
          alt={`${company.companyContract.companyLang?.companyNameFull}`}
          lazy
        />
      </div>
      {showModal && <AtExhibitingCompanyModal company={company} setShowModal={setShowModal} />}
    </>
  );

};
