import { Dayjs } from 'dayjs';
import { FC, useRef } from 'react';
import { TvEventCatalog } from '../../../generated/graphql';
import { getCurrentMoment } from '../../../util/dateUtil';
import { CluezCatalogDownloadContainer } from '../../molecules/CluezCatalogDownloadContainer';
import style from './CatalogList.module.scss';
import useClickCatalogListTrackEvent, {
  ClickCatalogCallBackArgs,
} from '../../../hooks/tracking/useClickCatalogListTrackEvent';

export type CatalogDownloadPageType = 'top' | 'complete';

type CatalogListProps = {
  catalogs: TvEventCatalog[];
  sectionTitle: string;
  tvEventId?: number;
  tvEventStartDatetime?: string;
  tvEventEndDatetime?: string;
  accessPage: CatalogDownloadPageType;
};

export const CatalogList: FC<CatalogListProps> = ({
  catalogs,
  sectionTitle,
  tvEventId,
  tvEventStartDatetime,
  tvEventEndDatetime,
  accessPage,
}) => {

  const {
    clickCatalogDownloadTrackEvent,
    clickCatalogCompanyNameTrackEvent,
    clickCatalogImageTrackEvent,
    clickCatalogTitleTrackEvent,
  } = useClickCatalogListTrackEvent();

  const renderedAt = useRef<Dayjs>(getCurrentMoment());

  return (
    <div id={sectionTitle.replace(/\r\n/, '')} className={style.container}>
      <h2 className={style.title}>{sectionTitle}</h2>
      <h3 className={style.feature}>注目のカタログ</h3>
      <p className={style.subInfo}>
        本特集テーマにご興味のあるお客様に、アペルザ掲載中のカタログから厳選してお届けする注目のカタログです。
      </p>
      <div className={style.catalogWrapper}>
        {catalogs.map((catalog) => {

          const trackingTarget: ClickCatalogCallBackArgs = {
            tvEventId,
            renderedAt,
            tvEventStartDatetime,
            tvEventEndDatetime,
            catalog,
            accessPage,
          };

          return (
            <CluezCatalogDownloadContainer
              key={catalog.cluezCatalogId}
              catalogProp={catalog}
              onClickDownloadButtonCallback={() => clickCatalogDownloadTrackEvent(trackingTarget)}
              onClickImageCallback={() => clickCatalogImageTrackEvent(trackingTarget)}
              onClickTitleCallback={() => clickCatalogTitleTrackEvent(trackingTarget)}
              onClickCompanyNameCallback={() => clickCatalogCompanyNameTrackEvent(trackingTarget)}
            />
          );

        })}
      </div>
    </div>
  );

};
