import { FieldPolicy, FieldReadFunction, InMemoryCacheConfig } from '@apollo/client';

/**
 * See https://www.apollographql.com/docs/react/pagination/core-api/
 * Merges content for show more, handling empty incoming, existing types
 */

// Keep type generic to avoid always modifying this for new merges.
const _mergeContents = (existing: any, incoming: any) => {

  if (!incoming) return existing;
  if (!existing || existing.length === 0) return incoming;

  const { content, ...rest } = incoming;

  const result = rest;

  // merge content with the newly requested paged content
  result.content = [...existing.content, ...content];

  return result;

};

const showMoreEventPolicy: { [fieldName: string]: FieldPolicy<any> | FieldReadFunction<any> } = {
  tvWebinarsByCompanyContractDisplayId: {
    keyArgs: false,
    merge(existing = [], incoming) {

      return _mergeContents(existing, incoming);

    },
  },

  tvEventPagesByCompanyContractDisplayId: {
    keyArgs: false,
    merge(existing = [], incoming) {

      return _mergeContents(existing, incoming);

    },
  },
};

export const apolloCacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: { ...showMoreEventPolicy },
    },
  },
};
