import { identify, Identify, track } from '@amplitude/analytics-browser';
import { useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { AmplitudeEventProperties } from '../../util/amplitude/eventProperty';
import { AmplitudeEventType } from '../../util/amplitude/eventType';
import { AmplitudeUserProperties } from '../../util/amplitude/userProperty';
import { isTrackingTarget } from '../../util/amplitudeWrapper';

export const useTrackEvent = () => {

  const { currentUser } = useAuth();

  const trackEvent = useCallback(
    (eventType: AmplitudeEventType, eventProperties?: AmplitudeEventProperties) => {

      if (!isTrackingTarget(currentUser)) return;

      track(eventType, eventProperties);

    },
    [currentUser],
  );

  const setUserProperties = useCallback((userProperties: AmplitudeUserProperties) => {

    const identifyObj = new Identify();

    Object.entries(userProperties).forEach(([key, value]) => {

      identifyObj.set(key, value);

    });

    identify(identifyObj);

  }, []);

  return { trackEvent, setUserProperties };

};
