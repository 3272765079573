import { useCallback } from 'react';
import { addAnalyticsDataLayer } from '../../util/analyticsUtil';

const useClickInquiryTrackEvent = () => {

  const clickSendInquiryOnWebinarTrackEvent = useCallback((tvWebinarId: number) => {

    // event名については AB-20628 のチケットを参照
    addAnalyticsDataLayer({
      event:    'tv_inquiry_video',
      video_id: tvWebinarId,
    });

  }, []);

  return {
    clickSendInquiryOnWebinarTrackEvent,
  };

};

export default useClickInquiryTrackEvent;
