import React, { VFC } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { AtPagingRouteParams } from '../../App';

interface AtPageRedirectProps {
  redirectUrl?: string;
  children: React.ReactNode;
}

/**
 * @returns
 *  1. Redirect to 404 if the dynamic path values are invalid
 *  2. The child component, if the pageNo is valid
 */
export const AtRedirectInvalidPage: VFC<AtPageRedirectProps> = (props) => {

  const { redirectUrl, children } = props;

  const { pageNo: pageInUrl } = useParams<AtPagingRouteParams>();
  if (pageInUrl && (Number.isNaN(parseInt(pageInUrl, 10)) || parseInt(pageInUrl, 10) <= 0)) {

    return <Redirect to={{ pathname: redirectUrl || '/404', state: { invalidURL: window.location.pathname } }} />;

  }

  return <>{children}</>;

};

export default AtRedirectInvalidPage;
