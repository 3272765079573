import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { TvWebinarType, WebinarType } from '../../constant/tvConstant';
import { useAuth } from '../../contexts/AuthContext';
import { TvWebinarOndemandFlag } from '../../generated/graphql';
import { addAnalyticsDataLayer } from '../../util/analyticsUtil';
import { getJstCurrentMoment, getJstMoment } from '../../util/dateUtil';
import { getTvDomain, getStaticFileDomain } from '../../util/envUtil';
import { removeEndSlash } from '../../util/pathUtil';
import { AperzaTVTreasure } from '../../util/treasureExtension';
import { timeStringToSeconds, getStartVideoTime } from '../../util/videoTimeUtil';
import { isDocumentAreaShow } from '../../util/webinarUtils';
import type { Breadcrumb } from '../atoms/AtBreadcrumbs';
import { AtBreadcrumbs } from '../atoms/AtBreadcrumbs';
import '../atoms/AtButton.scss';
import '../atoms/AtLabel.scss';
import '../atoms/AtLogo.scss';
import { LoadingBox } from '../atoms/LoadingBox';
import TitleAndMeta from '../atoms/TitleAndMeta';
import AtBreadcrumbsJsonLd from '../molecules/AtBreadcrumbsJsonLd';
import LoginModal from '../molecules/LoginModal';
import AtCatalogArea from '../organisms/AtCatalogArea';
import AtDetailCompany from '../organisms/AtDetailCompany';
import AtEventScheduleFrameForDetail from '../organisms/AtEventScheduleFrameForDetail';
import AtProjectionDocArea from '../organisms/AtProjectionDocArea';
import AtRecommendedWebinarArea from '../organisms/AtRecommendedWebinarArea';
import AtVideoArea from '../organisms/AtVideoArea';
import { useIsMobile } from '../templates/MobileReady';
import './AtDetail.scss';
import { GptScriptInHeader } from '../../util/gptUtil';
import { AtGpt } from '../atoms/AtGpt';

const webinarDetailQuery = gql`
  query tvWebinarCompany($tvWebinarId: BigInteger!) {
    tvWebinar(tvWebinarId: $tvWebinarId) {
      tvWebinarId
      companyContractId
      companyContract(withCompanysNoLogo: true, companyNoImageLogoType: LARGE) {
        companyContractId
        companyContractDisplayId
        companyPagePublishFlag
        companyLang {
          companyLangId
          companyName
          companyLogoUrl
          companyNameFull
        }
      }
      tvWebinarPublishFlag
      tvWebinarPublishStartDatetime
      tvWebinarPublishEndDatetime
      tvWebinarTitle
      tvWebinarSubtitle
      tvWebinarDescription
      tvWebinarOndemandFlag
      tvWebinarOndemandFirstDatetime
      tvWebinarStreamingStartDatetime
      tvWebinarStreamingEndDatetime
      tvWebinarMoviePlayTime
      tvWebinarSpeakerName
      tvWebinarSpeakerImgFilePath
      tvWebinarSpeakerDepartment
      tvWebinarRecommendSentence1
      tvWebinarRecommendSentence2
      tvWebinarRecommendSentence3
      tvWebinarMaxGuestViewingTimeInSeconds
      tvWebinarSpeakerBusinessCardDocument {
        documentId
        documentName
        documentUrl
        documentImageUrl
        displayOrder
      }
      tvWebinarProjectionDocument {
        documentId
        documentName
        documentUrl
        documentImageUrl
        displayOrder
      }
      tvWebinarMainImgFilePath
      createdOperator
      lastUpdatedOperator
      createTimestamp
      updateTimestamp
      relTvWebinarSystem {
        tvWebinarId
        tvWebinarLiveUrl
        tvWebinarOndemandUrl
        tvWebinarChatUrl
      }
      relTvWebinarRelationCatalogs {
        documentId
        documentName
        documentUrl
        documentImageUrl
        displayOrder
      }
      recommendedWebinars {
        id
        tvWebinarMainImgFilePath
        tvWebinarOndemandFlag
        tvWebinarTitle
        tvWebinarSubtitle
        companyNameFull
      }
      tvEventPages {
        tvEventId
      }
      tvWebinarTranscriptions {
        transcription
      }
      loggedInUserWatchStatus {
        tvWebinarId
        tvWatchLaterId
      }
    }
  }
`;

interface AtDetailProps {
  tvWebinarId: string;
}

export const AtDetail = ({ tvWebinarId }: AtDetailProps) => {

  const nowTime = getJstCurrentMoment();
  const [startVideoTime, setStartVideoTime] = useState(0);
  const [watchLaterStatus, setWatchLaterStatus] = useState<boolean>(false);
  const [initFlag, setInitFlag] = useState(false);
  const [webinarType, setWebinarType] = useState<WebinarType>(TvWebinarType.OffTime);
  const { currentUser } = useAuth();
  const { search } = useLocation();
  const qs = new URLSearchParams(search);
  const [showGuestLoginModal, setShowGuestLoginModal] = useState(false);
  const isMobile = useIsMobile();

  const webinarIdNum = Number(tvWebinarId);
  const isInvalidTvWebinarId = Number.isNaN(webinarIdNum);

  addAnalyticsDataLayer(
    {
      tvWebinarId,
      tvWebinarTestGroup: parseInt(tvWebinarId, 10) % 2 === 0 ? 'even' : 'odd',
    },
    'WatchWebinarPage',
  );

  const { loading, error, data } = useQuery(webinarDetailQuery, {
    variables: {
      tvWebinarId,
    },
    onCompleted: () => {

      if (data.tvWebinar) {

        // 配信日0時
        const standByStartMoment = getJstMoment(data.tvWebinar.tvWebinarStreamingStartDatetime).startOf(
          'day',
        );
        // 配信開始日時15分前
        const onTimeStartMoment = getJstMoment(data.tvWebinar.tvWebinarStreamingStartDatetime).add(
          -15,
          'minute',
        );
        // 配信終了日時5分後
        const waitTimeStartMoment = getJstMoment(data.tvWebinar.tvWebinarStreamingEndDatetime).add(
          5,
          'minute',
        );
        // 配信終了日時15分後
        const offTimeStartMoment = getJstMoment(data.tvWebinar.tvWebinarStreamingEndDatetime).add(
          15,
          'minute',
        );

        // 初期配信ステータス
        if (nowTime.isBefore(standByStartMoment)) {

          setWebinarType(TvWebinarType.OpenTime);

        } else if (nowTime.isBetween(standByStartMoment, onTimeStartMoment)) {

          setWebinarType(TvWebinarType.StandBy);

        } else if (nowTime.isBetween(onTimeStartMoment, waitTimeStartMoment)) {

          setWebinarType(TvWebinarType.OnTime);

        } else if (nowTime.isBetween(waitTimeStartMoment, offTimeStartMoment)) {

          setWebinarType(TvWebinarType.WaitTime);

        } else if (
          nowTime.isAfter(offTimeStartMoment) &&
          data.tvWebinar.tvWebinarOndemandFlag === TvWebinarOndemandFlag.Ondemand
        ) {

          setWebinarType(TvWebinarType.Ondemand);

        }

        const startTimeByQs = getStartVideoTime(qs);
        const moviePlayTime = timeStringToSeconds(data.tvWebinar.tvWebinarMoviePlayTime);
        if (moviePlayTime > startTimeByQs) {

          setStartVideoTime(startTimeByQs);

        }
        setWatchLaterStatus(!!data.tvWebinar?.loggedInUserWatchStatus?.tvWatchLaterId);
        setInitFlag(true);

        AperzaTVTreasure.pageView(
          window.location.href,
          currentUser?.userId,
          data?.tvWebinar.companyContract?.companyContractDisplayId,
        );

      }

    },
    skip: isInvalidTvWebinarId,
  });

  if (loading) return <LoadingBox />;
  if (error) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  if (isInvalidTvWebinarId) {

    return <Redirect to={{ pathname: '/404', state: { invalidURL: window.location.pathname } }} />;

  }

  if (!data.tvWebinar) {

    return <Redirect to={{ pathname: '/404', state: { invalidURL: window.location.pathname } }} />;

  }

  const catalogs = data.tvWebinar.relTvWebinarRelationCatalogs;
  const recommendedWebinarList = data.tvWebinar.recommendedWebinars;
  const webinarTitle = data.tvWebinar.tvWebinarTitle;
  const webinarProjectionDocument = data.tvWebinar.tvWebinarProjectionDocument;
  const eventId = data.tvWebinar.tvEventPages.length > 0 ? data.tvWebinar.tvEventPages[0].tvEventId : null;

  // それぞれの配信ステータス閾値のmomentを取得
  // 配信開始日時15分前
  const onTimeStartMoment = getJstMoment(data.tvWebinar.tvWebinarStreamingStartDatetime).add(-15, 'minute');
  // 配信終了日時5分後
  const waitTimeStartMoment = getJstMoment(data.tvWebinar.tvWebinarStreamingEndDatetime).add(5, 'minute');
  // 配信終了日時15分後
  const offTimeStartMoment = getJstMoment(data.tvWebinar.tvWebinarStreamingEndDatetime).add(15, 'minute');

  const timeFromOnTimeStartToNowValue = onTimeStartMoment.valueOf() - nowTime.valueOf();
  const timeFromWaitTimeStartToNowValue = waitTimeStartMoment.valueOf() - nowTime.valueOf();
  const timeFromOffTimeStartToNowValue = offTimeStartMoment.valueOf() - nowTime.valueOf();

  // 時間自動制御
  switch (webinarType) {
    case TvWebinarType.StandBy:
      setTimeout(() => {

        setWebinarType(TvWebinarType.OnTime);

      }, timeFromOnTimeStartToNowValue);
      setTimeout(() => {

        setWebinarType(TvWebinarType.WaitTime);

      }, timeFromWaitTimeStartToNowValue);
      setTimeout(() => {

        setWebinarType(TvWebinarType.OffTime);

      }, timeFromOffTimeStartToNowValue);
      break;
    case TvWebinarType.OnTime:
      setTimeout(() => {

        setWebinarType(TvWebinarType.WaitTime);

      }, timeFromWaitTimeStartToNowValue);
      setTimeout(() => {

        setWebinarType(TvWebinarType.OffTime);

      }, timeFromOffTimeStartToNowValue);
      break;
    case TvWebinarType.WaitTime:
      setTimeout(() => {

        setWebinarType(TvWebinarType.OffTime);

      }, timeFromOffTimeStartToNowValue);
      break;
    default:
      break;
  }

  const recommendTexts: Array<string | undefined> = [];

  recommendTexts.push(
    data.tvWebinar.tvWebinarRecommendSentence1,
    data.tvWebinar.tvWebinarRecommendSentence2,
    data.tvWebinar.tvWebinarRecommendSentence3,
  );
  const transcriptions = data.tvWebinar.tvWebinarTranscriptions;

  const title = `${data.tvWebinar.tvWebinarTitle} | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`;
  const description = `${data.tvWebinar.tvWebinarSubtitle.replace(/[　\s\n]/g, '')}｜${(
    data.tvWebinar.tvWebinarDescription ?? ''
  ).replace(/[　\s\n]/g, '')}`;
  const queryStringRemovedMainImagePath = data.tvWebinar.tvWebinarMainImgFilePath.split(/[?]/)[0];
  const defaultVideoCoverImageUrl = `${getStaticFileDomain()}tv/images/video-cover-image.png`;

  const breadcrumbs: Breadcrumb[] = [
    {
      text: 'トップ',
      url:  '/',
    },
    {
      text: `${
        data.tvWebinar.companyContract.companyLang.companyNameFull
          ? data.tvWebinar.companyContract.companyLang.companyNameFull
          : ''
      }`,
      url: data.tvWebinar.companyContract.companyPagePublishFlag
        ? `/company/${data.tvWebinar.companyContract.companyContractDisplayId}`
        : undefined,
    },
    {
      text: `${data.tvWebinar.tvWebinarTitle}`,
    },
  ];

  return (
    <>
      <GptScriptInHeader />
      <TitleAndMeta
        title={title}
        description={description}
        canonicalHref={getTvDomain().slice(0, -1) + removeEndSlash(window.location.pathname)}
        openGraphType="video.other"
        thumbnail={`${
          queryStringRemovedMainImagePath === defaultVideoCoverImageUrl ? '' : queryStringRemovedMainImagePath
        }`}
      />
      {!isMobile && <AtBreadcrumbsJsonLd breadcrumbs={breadcrumbs} baseUrl={getTvDomain().slice(0, -1)} />}
      <div className="at-detail">
        {!isMobile && (
          <div className="at-detail-breadcrumbs">
            <AtBreadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        )}
        <LoginModal
          show={showGuestLoginModal}
          onClose={() => setShowGuestLoginModal(false)}
          title="この動画の続きを視聴するにはログインが必要です。"
          loginTitle="アペルザIDへログインの上、先にお進みください。"
          signupTitle={
            <>
              アペルザIDへのご登録がお済みでない方は
              <br />
              会員登録へお進みください。（登録無料）
            </>
          }
          signupButtonName="無料会員登録をして動画の続きを見る"
          path={`${window.location.pathname}?t=${
            data.tvWebinar?.tvWebinarMaxGuestViewingTimeInSeconds ?? 0
          }s`}
        />

        {initFlag && (
          <AtVideoArea
            webinarId={data.tvWebinar.tvWebinarId}
            webinarTitle={data.tvWebinar.tvWebinarTitle}
            companyName={data.tvWebinar.companyContract.companyLang.companyNameFull}
            webinarMovieUrl={data.tvWebinar.relTvWebinarSystem?.tvWebinarOndemandUrl}
            webinarChatUrl={data.tvWebinar.relTvWebinarSystem?.tvWebinarChatUrl}
            webinarLiveEventUrl={data.tvWebinar.relTvWebinarSystem?.tvWebinarLiveUrl}
            webinarStreamingStartDatetime={data.tvWebinar.tvWebinarStreamingStartDatetime}
            webinarStreamingEndDatetime={data.tvWebinar.tvWebinarStreamingEndDatetime}
            webinarMoviePlayTime={data.tvWebinar.tvWebinarMoviePlayTime}
            webinarOndemandFlag={data.tvWebinar.tvWebinarOndemandFlag}
            webinarMainImgFilePath={data.tvWebinar.tvWebinarMainImgFilePath}
            watchLaterStatus={watchLaterStatus}
            setWatchLaterStatus={setWatchLaterStatus}
            tvWatchLaterId={data.tvWebinar?.loggedInUserWatchStatus?.tvWatchLaterId}
            webinarType={webinarType}
            startVideoTime={startVideoTime}
            tvWebinarMaxGuestViewingTimeInSeconds={data.tvWebinar?.tvWebinarMaxGuestViewingTimeInSeconds}
            onGuestViewLimitReached={() => {

              setShowGuestLoginModal(true);

            }}
            recommendedWebinarList={recommendedWebinarList}
          />
        )}
        <div id="at-main-area">
          <AtDetailCompany
            webinarId={data.tvWebinar.tvWebinarId}
            webinarTitle={data.tvWebinar.tvWebinarTitle}
            webinarSubtitle={data.tvWebinar.tvWebinarSubtitle}
            webinarDescription={data.tvWebinar.tvWebinarDescription}
            webinarPublishStartDatetime={data.tvWebinar.tvWebinarPublishStartDatetime}
            webinarOndemandFirstDatetime={data.tvWebinar.tvWebinarOndemandFirstDatetime}
            webinarStreamingStartDatetime={data.tvWebinar.tvWebinarStreamingStartDatetime}
            webinarStreamingEndDatetime={data.tvWebinar.tvWebinarStreamingEndDatetime}
            webinarSpeakerName={data.tvWebinar.tvWebinarSpeakerName}
            webinarSpeakerImgFilePath={data.tvWebinar.tvWebinarSpeakerImgFilePath}
            webinarSpeakerDepartment={data.tvWebinar.tvWebinarSpeakerDepartment}
            webinarSpeakerBusinessCardDocument={data.tvWebinar.tvWebinarSpeakerBusinessCardDocument}
            webinarMainImgFilePath={data.tvWebinar.tvWebinarMainImgFilePath}
            eventId={eventId}
            watchLaterStatus={watchLaterStatus}
            setWatchLaterStatus={setWatchLaterStatus}
            tvWatchLaterId={data.tvWebinar?.loggedInUserWatchStatus?.tvWatchLaterId}
            userType={currentUser.userType}
            webinarType={webinarType}
            companyContract={data.tvWebinar.companyContract}
            recommendTexts={recommendTexts}
            transcriptions={transcriptions}
          />
          <div className="at-other-area">
            {webinarProjectionDocument && isDocumentAreaShow(webinarType) ? (
              <AtProjectionDocArea
                webinarId={data.tvWebinar.tvWebinarId}
                webinarTitle={webinarTitle}
                projectionImage={webinarProjectionDocument.documentImageUrl}
              />
            ) : (
              ''
            )}
            {catalogs.length !== 0 ? <AtCatalogArea catalogs={catalogs} /> : ''}
            {recommendedWebinarList.length !== 0 && isMobile && (
              <AtRecommendedWebinarArea recommendedWebinars={recommendedWebinarList} />
            )}
            <AtGpt />
          </div>
          {isMobile && eventId ? (
            <AtEventScheduleFrameForDetail displayButton={false} eventId={eventId} />
          ) : null}
        </div>
      </div>
    </>
  );

};
