import { useAuth } from '../../contexts/AuthContext';
import { getStaticFileDomain } from '../../util/envUtil';
import { getMyPageUrlWithTab } from '../../util/urlUtil';
import { AtLogo } from '../atoms/AtLogo';
import AtTab from '../atoms/AtTab';
import AtMypageTop from '../organisms/AtMypageTop';
import AtMypageWatchlist from '../organisms/AtMypageWatchlist';
import './AtMypage.scss';

interface AtMypageProp {
  pageName: string;
}

export type MyPageTab = 'top' | 'watchlist';
export const AtMypage = ({ pageName }: AtMypageProp) => {

  const { currentUser } = useAuth();

  const renderContents = () =>
    (pageName === 'watchlist' ? (
      <AtMypageWatchlist />
    ) : (
      <AtMypageTop userMailAddress={currentUser.mailAddress!} />
    ));

  return (
    <div className="at-mypage">
      <div
        className="at-mypage-title-area"
        style={{ backgroundImage: `url(${getStaticFileDomain()}tv/images/mypage-title-bg.png)` }}
      >
        <AtLogo thumbnail thumbnailColor="white" />
      </div>
      <div className="at-mypage-tab-area">
        <AtTab date={'マイページ'} activeFlag={pageName === 'top'} to={getMyPageUrlWithTab('top')} />
        <AtTab
          date={'視聴リスト'}
          activeFlag={pageName === 'watchlist'}
          to={getMyPageUrlWithTab('watchlist')}
        />
      </div>
      {renderContents()}
    </div>
  );

};

export default AtMypage;
