import { useEffect, useState } from 'react';

type Orientation = 'landscape' | 'portrait' | null;

const createImageElement = (src: string) =>
  new Promise<HTMLImageElement>((resolve) => {

    const imageElement = new Image();
    imageElement.onload = () => resolve(imageElement);
    imageElement.src = src;

  });

export const useImageOrientation = (imageSrc: string) => {

  const [orientation, setOrientation] = useState<Orientation>(null);
  const [canOrientation, setCanOrientation] = useState(false);

  useEffect(() => {

    const handleSetOrientation = async () => {

      const imageElement = await createImageElement(imageSrc);
      setOrientation(imageElement.width >= imageElement.height ? 'landscape' : 'portrait');

    };

    if (canOrientation) {

      handleSetOrientation();

    }

  }, [imageSrc, canOrientation]);

  return { orientation, setCanOrientation };

};
