import { VFC } from 'react';
import { CompanyPageDetail } from '../../generated/graphql';
import { AtCompanyImage } from '../atoms/AtCompanyImage';
import style from './AtCompanyHeader.module.scss';

export interface AtCompanyHeaderProps {
  companyDetail: CompanyPageDetail;
  totalWebinarCount: number;
}

export const AtCompanyHeader: VFC<AtCompanyHeaderProps> = (props) => {

  const { companyDetail, totalWebinarCount } = props;

  return (
    <div className={`${style.headerContainer} d-flex align-items-center`}>
      <div className="mr-1">
        <AtCompanyImage className={`${style.headerCompanyLogoThumbnail}`} src={companyDetail.logoUrl} alt={companyDetail.name} width={90} />
      </div>
      <div>
        <h1 className={style.headerCompanyTitle}>{companyDetail.name}</h1>
        <div>
          <i className={`${style.headerCompanyVideoCountIcon} far fa-video fa-sm`} />
          <span className={style.headerCompanyVideoCount}>
            <span className="font-weight-bold">{totalWebinarCount}件</span>の登録動画
          </span>
        </div>
      </div>
    </div>
  );

};
