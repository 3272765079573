import { VFC } from 'react';
import { Redirect } from 'react-router-dom';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { AtSearchSection } from './AtSearchSection';
import { AtEventSchedule } from '../molecules/AtEventSchedule';
import AtMovieListForWebinars from './AtMovieListForWebinars';
import { SearchTvWebinarsQuery } from '../../generated/graphql';
import style from './AtSearchSections.module.scss';
import { AtSearchNoResults } from '../atoms/AtSearchNoResults';
import { tabsInfo } from '../pages/AtSearch';

import AtMovieAttention from '../atoms/AtMovieAttention';
import { LoadingBox } from '../atoms/LoadingBox';

const UPCOMING_EVENT_DISPLAY_COUNT = 2;
const WEBINAR_DISPLAY_COUNT = 5;
const ARCHIVE_EVENT_DISPLAY_COUNT = 2;
const FEATURE_DISPLAY_COUNT = 2;

export interface AtSearchSectionsProps {
  data?: SearchTvWebinarsQuery;
  loading: boolean;
  error?: ApolloError;
  onClickTab: (tabId: string) => void;
  refetch: () => Promise<ApolloQueryResult<any>>;
}

export const AtSearchSections: VFC<AtSearchSectionsProps> = (props) => {

  const {
    data, loading, error, onClickTab, refetch,
  } = props;

  if (loading) {

    return <LoadingBox />;

  }

  if (error) {

    return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  }

  const upcomingEventTotal = data?.searchTvWebinars.futureTvEventPages?.length ?? 0;
  const webinarTotal = data?.searchTvWebinars.totalElements ?? 0;
  const archiveEventTotal = data?.searchTvWebinars.oldTvEventPages?.length ?? 0;
  const featureTotal = data?.searchTvWebinars.tvFeaturePages?.length ?? 0;
  const total = upcomingEventTotal + webinarTotal + archiveEventTotal + featureTotal;

  if (total === 0) {

    return <AtSearchNoResults />;

  }

  const renderWebinarList = () => {

    const webinarList = data?.searchTvWebinars?.content?.slice(0, WEBINAR_DISPLAY_COUNT);
    if (webinarList && webinarList?.length > 0) {

      return (
        <>
          <AtMovieAttention />
          {webinarList.map(
            (tvWebinar) =>
              tvWebinar && (
                <AtMovieListForWebinars
                  key={tvWebinar.tvWebinarId}
                  webinarId={tvWebinar.tvWebinarId}
                  thumbnailImage={tvWebinar.tvWebinarMainImgFilePath}
                  companyContract={tvWebinar?.companyContract}
                  ondemandFlag={tvWebinar.tvWebinarOndemandFlag}
                  streamingStartDatetime={tvWebinar.tvWebinarStreamingStartDatetime?.toString()}
                  streamingEndDatetime={tvWebinar.tvWebinarStreamingEndDatetime?.toString()}
                  title={tvWebinar.tvWebinarTitle}
                  subtitle={tvWebinar.tvWebinarSubtitle}
                  speakerName={tvWebinar.tvWebinarSpeakerName}
                  speakerImage={tvWebinar.tvWebinarSpeakerImgFilePath}
                  speakerPost={tvWebinar.tvWebinarSpeakerDepartment}
                  watchLaterId={tvWebinar.loggedInUserWatchStatus?.tvWatchLaterId}
                  doesWatchLater={!!tvWebinar.loggedInUserWatchStatus?.tvWatchLaterId}
                  recommendTexts={[
                    tvWebinar.tvWebinarRecommendSentence1,
                    tvWebinar.tvWebinarRecommendSentence2,
                    tvWebinar.tvWebinarRecommendSentence3,
                  ]}
                />
              ),
          )}
        </>
      );

    }
    return undefined;

  };

  return (
    <>
      <div className={`${style.paginationHeader}`}>{total}件の検索結果</div>
      <AtSearchSection
        icon={'fa-user-chart'}
        title={tabsInfo.upcomingEvent.label}
        tabId={tabsInfo.upcomingEvent.id}
        totalCount={upcomingEventTotal}
        displayCount={UPCOMING_EVENT_DISPLAY_COUNT}
        onClickLink={onClickTab}
      >
        {data?.searchTvWebinars?.futureTvEventPages
          ?.slice(0, UPCOMING_EVENT_DISPLAY_COUNT)
          .map((eventPage) => (
            <AtEventSchedule
              refetch={refetch}
              key={eventPage.tvEventId}
              tvEventId={eventPage.tvEventId}
              tvEventTitle={eventPage.tvEventTitle}
              tvEventDescription={eventPage.tvEventDescription}
              tvEventMainImgFilePath={eventPage.tvEventMainImgFilePath}
              tvEventStartDatetime={(eventPage.tvEventStartDatetime as unknown) as string}
              tvEventEndDatetime={(eventPage.tvEventEndDatetime as unknown) as string}
              tvEventType={eventPage.tvEventType}
              otherExhibitors={eventPage.otherExhibitors}
              companyContracts={eventPage.relTvEventCompanyContracts}
              tvExhibitionReports={eventPage?.tvExhibitionReports}
              tvDisplayButton
            />
          ))}
      </AtSearchSection>
      <AtSearchSection
        icon={'fa-door-open'}
        title={tabsInfo.webinar.label}
        tabId={tabsInfo.webinar.id}
        totalCount={webinarTotal}
        displayCount={WEBINAR_DISPLAY_COUNT}
        onClickLink={onClickTab}
      >
        {renderWebinarList()}
      </AtSearchSection>
      <AtSearchSection
        icon={'fa-user-chart'}
        title={tabsInfo.archiveEvent.label}
        tabId={tabsInfo.archiveEvent.id}
        totalCount={archiveEventTotal}
        displayCount={ARCHIVE_EVENT_DISPLAY_COUNT}
        onClickLink={onClickTab}
      >
        {data?.searchTvWebinars?.oldTvEventPages?.slice(0, ARCHIVE_EVENT_DISPLAY_COUNT).map((eventPage) => (
          <AtEventSchedule
            refetch={refetch}
            key={eventPage.tvEventId}
            tvEventId={eventPage.tvEventId}
            tvEventTitle={eventPage.tvEventTitle}
            tvEventDescription={eventPage.tvEventDescription}
            tvEventMainImgFilePath={eventPage.tvEventMainImgFilePath}
            tvEventStartDatetime={(eventPage.tvEventStartDatetime as unknown) as string}
            tvEventEndDatetime={(eventPage.tvEventEndDatetime as unknown) as string}
            tvEventType={eventPage.tvEventType}
            otherExhibitors={eventPage.otherExhibitors}
            companyContracts={eventPage.relTvEventCompanyContracts}
            tvExhibitionReports={eventPage?.tvExhibitionReports}
            tvDisplayButton
          />
        ))}
      </AtSearchSection>
      <AtSearchSection
        icon={'fa-user-chart'}
        title={tabsInfo.feature.label}
        tabId={tabsInfo.feature.id}
        totalCount={featureTotal}
        displayCount={FEATURE_DISPLAY_COUNT}
        onClickLink={onClickTab}
      >
        {data?.searchTvWebinars?.tvFeaturePages?.slice(0, FEATURE_DISPLAY_COUNT)?.map((featurePage) => (
          <AtEventSchedule
            refetch={refetch}
            key={featurePage.tvEventId}
            tvEventId={featurePage.tvEventId}
            tvEventTitle={featurePage.tvEventTitle}
            tvEventDescription={featurePage.tvEventDescription}
            tvEventMainImgFilePath={featurePage.tvEventMainImgFilePath}
            tvEventStartDatetime={featurePage.tvEventStartDatetime}
            tvEventEndDatetime={featurePage.tvEventEndDatetime}
            tvEventType={featurePage.tvEventType}
            otherExhibitors={featurePage.otherExhibitors}
            companyContracts={featurePage.relTvEventCompanyContracts}
            tvExhibitionReports={featurePage?.tvExhibitionReports}
            tvDisplayButton
            eventApplyFlag={featurePage?.eventApplyFlag}
          />
        ))}
      </AtSearchSection>
    </>
  );

};
