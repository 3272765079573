import 'bootstrap/dist/css/bootstrap.min.css';
import {
  forwardRef, useImperativeHandle, useRef, VFC,
} from 'react';
import { useAccordionToggle } from 'react-bootstrap';
import { WebinarType } from '../../constant/tvConstant';
import { isDocumentAreaShow } from '../../util/webinarUtils';
import AtExternalLink from '../atoms/AtExternalLink';
import { useIsMobile } from '../templates/MobileReady';
import AtDocumentDownload from './AtDocumentDownload';
import { AtDocument } from './AtInquiry';
import './AtInquiry.scss';
import { AtRequiredSigninModal } from './AtRequiredSigninModal';

const CustomToggle = forwardRef(({ children, eventKey, optionalFuncOnClick }: any, ref: any) => {

  const toggleComponentRef = useRef({} as HTMLInputElement);

  const decoratedOnClick = useAccordionToggle(eventKey, () => {

    optionalFuncOnClick();

  });

  useImperativeHandle(ref, () => ({
    toggleComponent() {

      decoratedOnClick(eventKey);

    },
  }));

  return (
    <div className="at-button-multiple" ref={toggleComponentRef} onClick={decoratedOnClick}>
      {children}
    </div>
  );

});

export interface AtInquiryBusinessCardProps {
  companyContractDisplayId: number;
  companyPagePublishFlag: boolean;
  speakerImgFilePath: string;
  webinarSpeakerCompanyName: string;
  webinarSpeakerName: string;
  webinarSpeakerDepartment: string;
  webinarSpeakerBusinessCardDocument?: AtDocument;
  webinarType: WebinarType;
  webinarId: number;
  openInquiryToggleFlag: boolean;
  toggleRef: any;
  changeInquiryToggle: () => void;
}

const AtInquiryBusinessCard: VFC<AtInquiryBusinessCardProps> = ({
  companyContractDisplayId,
  companyPagePublishFlag,
  speakerImgFilePath,
  webinarSpeakerCompanyName,
  webinarSpeakerName,
  webinarSpeakerDepartment,
  webinarSpeakerBusinessCardDocument,
  webinarType,
  webinarId,
  openInquiryToggleFlag,
  toggleRef,
  changeInquiryToggle,
}) => {

  const CompanyName = () => {

    if (companyPagePublishFlag) {

      return (
        <AtExternalLink name={webinarSpeakerCompanyName} href={`/company/${companyContractDisplayId}`} bold />
      );

    }

    return <p className="at-company-name">{webinarSpeakerCompanyName}</p>;

  };

  const isMobile = useIsMobile();
  const MobileVersion = () => (
    <div className="at-business-card-area">
      <CompanyName />
      <div className="at-business-card-info-area">
        <img className="at-teacher-icon" src={speakerImgFilePath} alt={webinarSpeakerCompanyName} />
        <div className="at-lecturer-info-area">
          <p className="at-lecturer-department">{webinarSpeakerDepartment}</p>
          <p className="at-lecturer-name">{webinarSpeakerName ? `${webinarSpeakerName} 氏` : ''}</p>
        </div>
      </div>
      <div className="at-mobile-only-lecturer-action-area">
        {webinarSpeakerBusinessCardDocument && isDocumentAreaShow(webinarType) && (
          <AtDocumentDownload
            to={`/watch/${webinarId}/document/bizcard`}
            wrapperClassName="at-button-normal-back"
            text="名刺をダウンロード"
          />
        )}
        {(isDocumentAreaShow(webinarType) || openInquiryToggleFlag) && (
          <AtRequiredSigninModal buttonType="inquiry">
            <CustomToggle eventKey="0" ref={toggleRef} optionalFuncOnClick={changeInquiryToggle}>
              <p>個別に質問 / 相談</p>
              <i className={openInquiryToggleFlag ? 'far fa-times' : 'far fa-chevron-down'} />
            </CustomToggle>
          </AtRequiredSigninModal>
        )}
      </div>
    </div>
  );

  const DesktopVersion = () => (
    <div className="at-business-card-area">
      <img className="at-teacher-icon" src={speakerImgFilePath} alt={webinarSpeakerCompanyName} />
      <div className="at-business-card-info-area">
        <CompanyName />
        <div className="at-lecturer-info-area">
          <p className="at-lecturer-department">{webinarSpeakerDepartment}</p>
          <p className="at-lecturer-name">{webinarSpeakerName ? `${webinarSpeakerName} 氏` : ''}</p>
        </div>
      </div>
      {webinarSpeakerBusinessCardDocument && isDocumentAreaShow(webinarType) && (
        <AtDocumentDownload
          to={`/watch/${webinarId}/document/bizcard`}
          wrapperClassName="at-button-normal-back"
          text="名刺をダウンロード"
        />
      )}
      {(isDocumentAreaShow(webinarType) || openInquiryToggleFlag) && (
        <AtRequiredSigninModal buttonType="inquiry" openLoginInNewTab>
          <CustomToggle eventKey="0" ref={toggleRef} optionalFuncOnClick={changeInquiryToggle}>
            <p>個別に質問 / 相談</p>
            <i className={openInquiryToggleFlag ? 'far fa-times' : 'far fa-chevron-down'} />
          </CustomToggle>
        </AtRequiredSigninModal>
      )}
    </div>
  );

  return isMobile ? <MobileVersion /> : <DesktopVersion />;

};

export default AtInquiryBusinessCard;
