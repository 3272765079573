import { VFC } from 'react';
import style from './AtTimetableEventGroup.module.scss';
import { AtTimetableEvent } from './AtTimetableEvent';
import { TvEventPageQuery } from '../../generated/graphql';

export interface AtTimetableEventGroupProps {
  eventGroup: N<N<TvEventPageQuery['tvEventPage']>['eventTimetable']>[number];
  index: number;
  setShowCTAModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AtTimetableEventGroup: VFC<AtTimetableEventGroupProps> = (props) => {

  const { eventGroup, index, setShowCTAModal } = props;

  return (
    <div id={`eventGroup_${index}`} className={style.container}>
      {eventGroup.webinars.map((webinar, _index) => (
        <AtTimetableEvent setShowCTAModal={setShowCTAModal} webinar={webinar} key={_index} />
      ))}
    </div>
  );

};
