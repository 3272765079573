import { Dispatch, SetStateAction, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  CompanyPageDetail,
  CompanyPageWebinarsQuery,
  useCompanyPageEventsQuery,
  useCompanyPageWebinarsQuery,
} from '../../generated/graphql';
import { timeIsPast } from '../../util/dateUtil';
import AtMovieAttention from '../atoms/AtMovieAttention';
import { LoadingBox } from '../atoms/LoadingBox';
import AtEventSchedule from '../molecules/AtEventSchedule';
import AtShowMoreSection from '../molecules/AtShowMoreSection';
import style from './AtCompanyMainContent.module.scss';
import AtMovieListForWebinars from './AtMovieListForWebinars';

const EVENT_PAGE_SIZE_PER_SHOW_MORE = 2;
const WEBINAR_PAGE_SIZE_PER_SHOW_MORE = 10;

export interface AtCompanyMainContentProps {
  companyContractDisplayId: number;
  companyDetail: CompanyPageDetail;
  onWebinarsLoaded?: (data: CompanyPageWebinarsQuery) => void;
}

export const AtCompanyMainContent = ({
  companyContractDisplayId,
  companyDetail,
  onWebinarsLoaded,
}: AtCompanyMainContentProps) => {

  const [currentEventPage, setCurrentEventPage] = useState(1);
  const [currentWebinarPage, setCurrentWebinarPage] = useState(1);
  const {
    loading: eventLoading,
    error: eventError,
    data: eventData,
    fetchMore: eventFetchMore,
    refetch,
  } = useCompanyPageEventsQuery({
    variables: {
      companyContractDisplayId,
      page: 1,
      size: EVENT_PAGE_SIZE_PER_SHOW_MORE,
    },
  });

  const {
    loading: webinarLoading,
    error: webinarError,
    data: webinarData,
    fetchMore: webinarFetchMore,
  } = useCompanyPageWebinarsQuery({
    variables: {
      companyContractDisplayId,
      page: 1,
      size: WEBINAR_PAGE_SIZE_PER_SHOW_MORE,
    },
    onCompleted: onWebinarsLoaded,
  });
  if (eventLoading || webinarLoading) return <LoadingBox />;
  if (eventError || webinarError) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  const eventPages = eventData?.tvEventPagesByCompanyContractDisplayId.content;
  const webinarPages = webinarData?.tvWebinarsByCompanyContractDisplayId.content;

  const currentNumberOfEventsDisplayed = currentEventPage * EVENT_PAGE_SIZE_PER_SHOW_MORE;
  const currentNumberOfWebinarsDisplayed = currentWebinarPage * WEBINAR_PAGE_SIZE_PER_SHOW_MORE;

  const handleShowMoreEvents = (setLoading: Dispatch<SetStateAction<boolean>>) => {

    setLoading(true);
    eventFetchMore({
      variables: {
        page: currentEventPage + 1,
      },
    }).then((fetchMoreResult) => {

      setCurrentEventPage(currentEventPage + 1);
      setLoading(false);

    });

  };

  const handleShowMoreWebinars = (setLoading: Dispatch<SetStateAction<boolean>>) => {

    setLoading(true);
    webinarFetchMore({
      variables: {
        page: currentWebinarPage + 1,
      },
    }).then((fetchMoreResult) => {

      setCurrentWebinarPage(currentWebinarPage + 1);
      setLoading(false);

    });

  };

  const eventPageExists = eventPages !== undefined && eventPages.length !== 0;
  const webinarPageExists = webinarPages !== undefined && webinarPages.length !== 0;
  const isOnlyCompletedEvents =
    eventPages !== undefined && eventPages.length !== 0 && timeIsPast(eventPages[0]?.tvEventEndDatetime);

  const renderEvents = () =>
    eventPages?.map((eventPage) => (
      <div className={style.eventCard} key={eventPage?.tvEventId}>
        <AtEventSchedule
          refetch={refetch}
          key={eventPage?.tvEventId}
          tvEventId={eventPage?.tvEventId}
          tvEventTitle={eventPage?.tvEventTitle}
          tvEventDescription={eventPage?.tvEventDescription}
          tvEventMainImgFilePath={eventPage?.tvEventMainImgFilePath}
          tvEventStartDatetime={(eventPage?.tvEventStartDatetime as unknown) as string}
          tvEventEndDatetime={(eventPage?.tvEventEndDatetime as unknown) as string}
          tvEventType={eventPage?.tvEventType}
          otherExhibitors={eventPage?.otherExhibitors}
          companyContracts={eventPage?.relTvEventCompanyContracts}
          tvExhibitionReports={eventPage?.tvExhibitionReports}
          tvDisplayButton
          eventApplyFlag={eventPage?.eventApplyFlag}
        />
      </div>
    ));

  const EventSection = () => {

    if (eventPageExists) {

      return (
        <>
          <AtShowMoreSection
            title="この企業が出展するイベント"
            titleIcon="fas fa-user-chart"
            onShowMore={handleShowMoreEvents}
            hideShowMore={
              (eventData?.tvEventPagesByCompanyContractDisplayId.totalElements || 0) <=
              currentNumberOfEventsDisplayed
            }
          >
            {renderEvents()}
          </AtShowMoreSection>
          <div className={style.sectionDivider} />
        </>
      );

    }
    return null;

  };

  const renderWebinars = () =>
    webinarPages?.map((webinarPage) => {

      if (!webinarPage) {

        return undefined;

      }

      return (
        <div className={style.eventCard} key={webinarPage.tvWebinarId}>
          <AtMovieListForWebinars
            webinarId={webinarPage.tvWebinarId}
            thumbnailImage={webinarPage.tvWebinarMainImgFilePath}
            companyContract={webinarPage.companyContract}
            ondemandFlag={webinarPage.tvWebinarOndemandFlag}
            streamingStartDatetime={webinarPage.tvWebinarStreamingStartDatetime?.toString()}
            streamingEndDatetime={webinarPage.tvWebinarStreamingEndDatetime?.toString()}
            title={webinarPage.tvWebinarTitle}
            subtitle={webinarPage.tvWebinarSubtitle}
            speakerName={webinarPage.tvWebinarSpeakerName}
            speakerImage={webinarPage.tvWebinarSpeakerImgFilePath}
            speakerPost={webinarPage.tvWebinarSpeakerDepartment}
            watchLaterId={webinarPage.loggedInUserWatchStatus?.tvWatchLaterId}
            doesWatchLater={!!webinarPage.loggedInUserWatchStatus?.tvWatchLaterId}
            recommendTexts={[
              webinarPage.tvWebinarRecommendSentence1,
              webinarPage.tvWebinarRecommendSentence2,
              webinarPage.tvWebinarRecommendSentence3,
            ]}
          />
        </div>
      );

    });

  const WebinarSection = () => {

    if (webinarPageExists) {

      return (
        <>
          <AtShowMoreSection
            title="この企業の動画"
            titleIcon="fas fa-door-open"
            onShowMore={handleShowMoreWebinars}
            hideShowMore={
              (webinarData?.tvWebinarsByCompanyContractDisplayId.totalElements || 0) <=
              currentNumberOfWebinarsDisplayed
            }
          >
            <div style={{ marginBottom: '20px' }}>
              <AtMovieAttention />
            </div>
            {renderWebinars()}
          </AtShowMoreSection>
          <div className={style.sectionDivider} />
        </>
      );

    }
    return null;

  };

  return (
    <div className={`${style.contentContainer} flex-grow-1`}>
      <div className="wrap">
        {companyDetail.tagline && <h2 className={style.corporateSlogan}>{companyDetail.tagline}</h2>}
        {companyDetail.description && <div className={style.description}>{companyDetail.description}</div>}
      </div>

      {isOnlyCompletedEvents ? (
        <>
          <WebinarSection />
          <EventSection />
        </>
      ) : (
        <>
          <EventSection />
          <WebinarSection />
        </>
      )}

      {!eventPageExists && !webinarPageExists && (
        <p>現在、この企業が出展しているイベントと動画はありません。</p>
      )}
    </div>
  );

};
