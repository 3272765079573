import { VFC } from 'react';
import { Helmet } from 'react-helmet-async';
import { AtOpenGraphMeta, OpenGraphType } from './AtOpenGraphMeta';

export interface TitleAndMetaProps {
  title?: string;
  description?: string;
  canonicalHref?: string;
  noIndex?: boolean;
  openGraphType?: OpenGraphType;
  thumbnail?: string;
}

export const TitleAndMeta: VFC<TitleAndMetaProps> = ({
  title = 'Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト',
  description = 'Apérza TV（アペルザTV、アペルザテレビ）は、ものづくり産業向けに特化した動画サイトです。最新の製品や技術、トレンドやノウハウなど、ものづくりや研究開発に携わる技術者の役に立つ動画コンテンツをいつでも無料で視聴できます。',
  canonicalHref,
  noIndex,
  openGraphType = 'website',
  thumbnail,
}) => (
  <>
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {canonicalHref && <link rel="canonical" href={canonicalHref} />}
      {noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
    <AtOpenGraphMeta title={title} description={description} type={openGraphType} image={thumbnail} />
  </>
);

export default TitleAndMeta;
