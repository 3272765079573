const getOmittionSentence = (description: string = '', maxLength: number) => {

  if (!description) {

    return '';

  }
  if (description.length < maxLength) {

    return description;

  }
  return `${description.substring(0, maxLength)}...`;

};

export { getOmittionSentence };
