import { VFC } from 'react';
import style from './AtEventLpOnDemand.module.scss';
import { AtEventLpTitle } from '../molecules/AtEventLpTitle';
import { AtEventLpOnDemandEvents } from '../molecules/AtEventLpOnDemandEvents';
import { TvEventPageQuery } from '../../generated/graphql';

export interface AtEventLpOnDemandProps {
  onDemandEvents: N<N<TvEventPageQuery['tvEventPage']>['relTvEventLpTvOndemandWebinars']>;
  sectionTitle: string;
}

export const AtEventLpOnDemand: VFC<AtEventLpOnDemandProps> = (props) => {

  const { onDemandEvents, sectionTitle } = props;

  return (
    <div id={sectionTitle.replace(/\r\n/, '')} className={style.container}>
      <AtEventLpTitle title={sectionTitle} />
      <AtEventLpOnDemandEvents onDemandEvents={onDemandEvents} />
    </div>
  );

};
