import { VFC } from 'react';
import style from './AtPaginationHeader.module.scss';

export interface AtPaginationHeaderProps {
  totalElements: number | string;
  contentLength: number | string;
}

export const AtPaginationHeader: VFC<AtPaginationHeaderProps> = (props) => {

  const { totalElements, contentLength } = props;

  return (
    <div>
      <p className={` ${style.atPaginationHeader} `}>
        {totalElements}件中{contentLength}件を表示
      </p>
    </div>
  );

};
