import { VFC } from 'react';
import { RelTvEventCompanyContract, TvEventPageQuery } from '../../../../generated/graphql';
import { AtEventLpTitle } from '../../../molecules/AtEventLpTitle';
import { AtExhibitingCompanyLogo } from '../../../molecules/event-lp/exhibiting-company/AtExhibitingCompanyLogo';
import style from './AtExhibitingCompanyLogos.module.scss';

export interface AtExhibitingCompanyLogosProps {
  exhibitingCompanies?: N<N<TvEventPageQuery['tvEventPage']>['relTvEventCompanyContracts']>;
  sectionTitle?: string;
}

export const AtExhibitingCompanyLogos: VFC<AtExhibitingCompanyLogosProps> = (props) => {

  const { exhibitingCompanies, sectionTitle } = props;

  if (!exhibitingCompanies) {

    return null;

  }

  return (
    <div id={sectionTitle ? sectionTitle.replace(/\r\n/, '') : ''} className={style.container}>
      <AtEventLpTitle title={sectionTitle} displayDots={false} smallHeader />
      <div className={style.logoContainer}>
        {exhibitingCompanies.map((exhibitingCompany, index) => {

          if (!exhibitingCompany?.companyContract?.companyLang?.companyLogoUrl) {

            return null;

          }
          return <AtExhibitingCompanyLogo key={index} company={exhibitingCompany} />;

        })}
      </div>
    </div>
  );

};
