import { VFC } from 'react';
import { SubmitHandler, FieldValues, useFormContext } from 'react-hook-form';
import { notifyMessage } from '@aperza/ac-ui';
import { useApplyTvEventMutation } from '../../../generated/graphql';
import { AtFormSubmit } from '../../molecules/AtFormSubmit';
import { FormSection } from '../../molecules/AtForm';
import style from '../AtBizCardFormContainer.module.scss';
import { useTrackEvent } from '../../../hooks/tracking/useTrackEvent';

interface AtStaticEventApplyConfirmContainerProps {
  formSections: FormSection[];
  tvEventId: number;
}

export const AtStaticEventApplyConfirmContainer: VFC<AtStaticEventApplyConfirmContainerProps> = (props) => {

  const [applyTvEvent, { data, loading, error }] = useApplyTvEventMutation();

  // @ts-ignore
  const { setScreen } = useFormContext();
  const { trackEvent } = useTrackEvent();

  const onSuccessSubmit: SubmitHandler<FieldValues> = async (_data: FieldValues) => {

    window.scrollTo(0, 0);
    const answers: { formItemId: number; formItemAnswerId: number; answerText?: string }[] = [];
    if (Array.isArray(_data.formItems)) {

      (Object.keys(_data.formItems) as Array<string>).forEach((key) => {

        const index = props.formSections.findIndex(
          (checkFormItem) => checkFormItem.id === `formItems.${key}`,
        );
        if (index < 0) {

          return;

        }
        const formItem = props.formSections[index];

        const formItemAnswers = _data.formItems[key];
        if (Array.isArray(formItemAnswers)) {

          if (formItem.type === 'checkbox') {

            formItemAnswers.forEach((formItemAnswer) => {

              let answerText;
              if (+formItemAnswer === 2) {

                answerText = _data.formItems[`${key}-other`];

              }
              answers.push({
                formItemId:       +key,
                formItemAnswerId: +formItemAnswer,
                answerText,
              });

            });

          }

        } else {

          switch (formItem.type) {
            case 'text':
              if (formItemAnswers) {

                answers.push({
                  formItemId:       +key,
                  formItemAnswerId: 3,
                  answerText:       formItemAnswers,
                });

              } else {

                answers.push({
                  formItemId:       +key,
                  formItemAnswerId: 0,
                  answerText:       undefined,
                });

              }
              break;
            case 'textarea':
              if (formItemAnswers) {

                answers.push({
                  formItemId:       +key,
                  formItemAnswerId: 4,
                  answerText:       formItemAnswers,
                });

              } else {

                answers.push({
                  formItemId:       +key,
                  formItemAnswerId: 0,
                  answerText:       undefined,
                });

              }
              break;
            case 'radio':
            case 'select':
              if (formItemAnswers) {

                let answerText;
                if (+formItemAnswers === 2) {

                  answerText = _data.formItems[`${key}-other`];

                }
                answers.push({
                  formItemId:       +key,
                  formItemAnswerId: +formItemAnswers,
                  answerText,
                });

              } else {

                answers.push({
                  formItemId:       +key,
                  formItemAnswerId: 0,
                  answerText:       undefined,
                });

              }
              break;
            default:
          }

        }

      });

    }

    try {

      const result = await applyTvEvent({
        variables: {
          tvEventApplyInput: {
            tvEventId:          props.tvEventId,
            userAuthentication: { mailAddress: _data.mailAddress, password: _data.password },
            lastName:           _data.name.lastName,
            firstName:          _data.name.firstName,
            phoneNumber:        _data.tel,
            companyName:        _data.companyName,
            departmentName:     _data.departmentName,
            subdivisionCode:    _data.subdivisionCode,
          },
        },
      });

      if (result && result.data) {

        // メールアドレスとパスワードが入力されていれば、新規にIDを作成するユーザーである
        trackEvent('Click Register To Event', {
          event_id:   props.tvEventId,
          is_member:  !(_data.mailAddress && _data.password),
          is_success: true,
        });

        setScreen('complete');

      }

    } catch (e) {

      // TODO 必ずやる。エラーメッセージを変更する
      if (e instanceof Error) {

        notifyMessage(e.message, 'ERROR', {
          position:        'bottom-center',
          hideProgressBar: true,
        });

      }

      trackEvent('Click Register To Event', {
        event_id:   props.tvEventId,
        is_member:  !(_data.mailAddress && _data.password),
        is_success: false,
      });

    }

  };

  return (
    <div className={` ${style.bizCardFormContainer} `}>
      <div className={` ${style.mainSection} `}>
        <AtFormSubmit
          formSections={props.formSections}
          onCompleteSubmit={onSuccessSubmit}
          submitButtonValue={'登録'}
          onClickBackButtonCallback={() => {

            trackEvent('Back To Event Input Form', {
              event_id: props.tvEventId,
            });

          }}
        />
      </div>
    </div>
  );

};
