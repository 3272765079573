import { VFC } from 'react';
import { BreadcrumbList } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet-async';
import { Breadcrumb } from '../atoms/AtBreadcrumbs';

type Props = {
  breadcrumbs: Breadcrumb[];
  baseUrl?: string;
};

const AtBreadcrumbsJsonLd: VFC<Props> = ({ breadcrumbs, baseUrl = '' }): JSX.Element => (
  <Helmet
    script={[
      helmetJsonLdProp<BreadcrumbList>({
        '@context':      'https://schema.org',
        '@type':         'BreadcrumbList',
        itemListElement: breadcrumbs.map((item, index) => ({
          '@type':  'ListItem',
          position: index + 1,
          name:     item.text,
          item:     item.url ? baseUrl + item.url : undefined,
        })),
      }),
    ]}
  />
);

export default AtBreadcrumbsJsonLd;
