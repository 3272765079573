import { ErrorMessage } from '@hookform/error-message';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  useEffect, useRef, useState, VFC,
} from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import scrollIntoView from 'scroll-into-view-if-needed';
import { WebinarType } from '../../constant/tvConstant';
import { useAuth } from '../../contexts/AuthContext';
import { useCreateTvWebinarInquiryMutation } from '../../generated/graphql';
import { LoadingBox } from '../atoms/LoadingBox';
import { useIsMobile } from '../templates/MobileReady';
import './AtInquiry.scss';
import AtInquiryBusinessCard from './AtInquiryBusinessCard';
import useClickInquiryTrackEvent from '../../hooks/tracking/useClickInquiryTrackEvent';

interface Handler {
  toggleComponent(): void;
}

export interface AtDocument {
  documentId: string;
  documentName: string;
  documentUrl?: string;
  documentImageUrl?: string;
  displayOrder: number;
}

export interface AtInquiryProps {
  webinarId: number;
  webinarType: WebinarType;
  speakerImgFilePath: string;
  companyContractDisplayId: number;
  companyPagePublishFlag: boolean;
  webinarSpeakerCompanyName: string;
  webinarSpeakerName: string;
  webinarSpeakerDepartment: string;
  webinarSpeakerBusinessCardDocument?: AtDocument;
}

const AtInquiry: VFC<AtInquiryProps> = (props) => {

  const toggleRef = useRef({} as Handler);
  const inquiryBodyRef = useRef({} as HTMLDivElement); // used for scrolling on mobile only

  const [openInquiryToggleFlag, setOpenInquiryToggleFlag] = useState(false);
  const { currentUser } = useAuth();
  const isMobile = useIsMobile();
  const {
    webinarId,
    webinarType,
    speakerImgFilePath,
    companyPagePublishFlag,
    companyContractDisplayId,
    webinarSpeakerCompanyName,
    webinarSpeakerName,
    webinarSpeakerDepartment,
    webinarSpeakerBusinessCardDocument,
  } = props;

  const changeInquiryToggle = () => {

    setOpenInquiryToggleFlag(!openInquiryToggleFlag);

  };

  useEffect(() => {

    if (openInquiryToggleFlag) {

      scrollIntoView(inquiryBodyRef.current!, {
        behavior: 'smooth',
        block:    'start',
      });

    }

  }, [openInquiryToggleFlag]);

  const toggleComponent = () => {

    toggleRef.current.toggleComponent();

  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      companyName:    currentUser.companyName,
      departmentName: currentUser.departmentName,
      lastName:       currentUser.lastName,
      firstName:      currentUser.firstName,
      inquiryText:    '',
    },
  });

  const { clickSendInquiryOnWebinarTrackEvent } = useClickInquiryTrackEvent();

  const [createTvWebinarInquiryMutation, { loading }] = useCreateTvWebinarInquiryMutation({
    onCompleted: () => {

      reset();
      toggleComponent();
      clickSendInquiryOnWebinarTrackEvent(webinarId);

    },
  });

  const cancelOnClick = () => {

    toggleComponent();

  };

  const onSubmitInquiry = (data: {
    companyName?: string;
    departmentName?: string;
    lastName?: string;
    firstName?: string;
    inquiryText?: string;
  }) => {

    createTvWebinarInquiryMutation({
      variables: {
        tvWebinarInquiry: {
          tvWebinarId:    webinarId,
          inquiryText:    data.inquiryText,
          companyName:    data.companyName,
          departmentName: data.departmentName,
          lastName:       data.lastName,
          firstName:      data.firstName,
        },
      },
    });

  };

  return (
    <>
      <Accordion>
        <Card className="at-accordion-toggle border-0">
          <Card.Header className="at-accordion-toggle-header">
            <AtInquiryBusinessCard
              companyContractDisplayId={companyContractDisplayId}
              companyPagePublishFlag={companyPagePublishFlag}
              speakerImgFilePath={speakerImgFilePath}
              webinarSpeakerCompanyName={webinarSpeakerCompanyName}
              webinarSpeakerName={webinarSpeakerName}
              webinarSpeakerDepartment={webinarSpeakerDepartment}
              webinarSpeakerBusinessCardDocument={webinarSpeakerBusinessCardDocument}
              webinarType={webinarType}
              webinarId={webinarId}
              openInquiryToggleFlag={openInquiryToggleFlag}
              toggleRef={toggleRef}
              changeInquiryToggle={changeInquiryToggle}
            />
          </Card.Header>

          {/* ref inside the body doesn't scroll, create custom placeholder to start scrolling */}
          <div ref={inquiryBodyRef} />
          <Accordion.Collapse className="at-accordion-collapse" eventKey="0">
            <Card.Body className="at-toggle-content">
              <div className="at-toggle-content-container">
                <div className="at-toggle-content-row">
                  <div className="at-inquiry-company">
                    {isMobile ? '出展企業に問い合わせ' : '出展企業に個別に質問 / 相談'}
                    <span> ※ 全て必須項目です</span>
                  </div>
                  <div className="at-inquiry-sender">送信元：{currentUser.mailAddress}</div>
                </div>
                <div className="at-inquiry-textarea-row">
                  <div className="at-inquiry-textarea-col">
                    <input
                      type="text"
                      id="companyName"
                      className="at-inquiry-textarea-contents"
                      placeholder="会社名"
                      {...register('companyName', {
                        required: {
                          value:   true,
                          message: '会社名をご入力ください',
                        },
                        maxLength: {
                          value:   255,
                          message: '会社名は255文字以内でご入力ください',
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="companyName"
                      render={({ message }) => (
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                          <div>{message}</div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="at-inquiry-textarea-col">
                    <input
                      type="text"
                      id="departmentName"
                      className="at-inquiry-textarea-contents"
                      placeholder="部署名"
                      {...register('departmentName', {
                        required: {
                          value:   true,
                          message: '部署名をご入力ください',
                        },
                        maxLength: {
                          value:   255,
                          message: '部署名は255文字以内でご入力ください',
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="departmentName"
                      render={({ message }) => (
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                          <div>{message}</div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="at-inquiry-textarea-col-name">
                    <div className="at-inquiry-textarea-col">
                      <input
                        type="text"
                        id="lastName"
                        className="at-inquiry-textarea-contents"
                        placeholder="姓（山田）"
                        {...register('lastName', {
                          required: {
                            value:   true,
                            message: 'お名前（性）をご入力ください',
                          },
                          maxLength: {
                            value:   40,
                            message: 'お名前（性）は40文字以内でご入力ください',
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="lastName"
                        render={({ message }) => (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            <div>{message}</div>
                          </div>
                        )}
                      />
                    </div>
                    <div className="at-inquiry-textarea-col">
                      <input
                        type="text"
                        id="firstName"
                        className="at-inquiry-textarea-contents"
                        placeholder="名（太郎）"
                        {...register('firstName', {
                          required: {
                            value:   true,
                            message: 'お名前（名）をご入力ください',
                          },
                          maxLength: {
                            value:   40,
                            message: 'お名前（名）は40文字以内でご入力ください',
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="firstName"
                        render={({ message }) => (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            <div>{message}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="at-inquiry-textarea-large-row">
                  <div className="at-inquiry-textarea-large">
                    <textarea
                      id="inquiryText"
                      className="at-inquiry-textarea-large-contents"
                      placeholder="質問 / 相談内容をご記入ください。"
                      {...register('inquiryText', {
                        required: {
                          value:   true,
                          message: '質問 / 相談内容をご入力ください',
                        },
                        maxLength: {
                          value:   5000,
                          message: '質問 / 相談内容は5000文字以内でご入力ください',
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="inquiryText"
                      render={({ message }) => (
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                          <div>{message}</div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="at-inquiry-buttonarea">
                    <div className="at-button-normal-go" onClick={handleSubmit(onSubmitInquiry)}>
                      送信
                    </div>
                    {/* <AtButtonMultiple message={" 送信 "} href={"#"} targetBlankFlg={true} /> */}
                    <div className="at-button-normal-back" onClick={cancelOnClick}>
                      キャンセル
                    </div>
                    {/* <AtButtonNormalBack message={"キャンセル"} href={"#"} targetBlankFlg={true} /> */}
                  </div>
                </div>
                <div className="at-inquiry-anotation-row">
                  <div className="at-inquiry-anotation">
                    <p className="at-inquiry-anotation-content">
                      入力された情報は、弊社のプライバシーポリシーに則って取り扱われるほか、質問 /
                      相談への回答のために
                      動画の出演企業にも提供されますので、必ず同意の上送信していただきますようお願いいたします。
                    </p>
                  </div>
                </div>
                {loading && <LoadingBox />}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );

};

export default AtInquiry;
