import { VFC } from 'react';
import { TvEventOverview } from '../../generated/graphql';
import style from './AtEventLpEvent.module.scss';

export interface AtEventLpEventProps {
  event: TvEventOverview;
}

export const AtEventLpEvent: VFC<AtEventLpEventProps> = (props) => {

  const { event } = props;

  return (
    <div className={style.container}>
      <img className={` ${style.img} `} src={`${event.imagePath}`} alt="event" />
      <div className={` ${style.description} `}>{event.description.slice(0, 75)}</div>
    </div>
  );

};
