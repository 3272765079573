import { VFC } from 'react';
import style from './CollapseChevron.module.scss';

export interface CollapseChevronProps {
  onClick?: () => void;
  collapsed?: boolean;
  className?: string;
  floatRight?: boolean;
}

export const CollapseChevron: VFC<CollapseChevronProps> = (props) => {

  const {
    onClick, collapsed, className = '', floatRight,
  } = props;

  return (
    <div onClick={onClick} className={` ${style.collapse} ${className} ${floatRight && style.floatRight}`}>
      <i className={`fas fa-chevron-${collapsed ? 'down' : 'up'}`} />
    </div>
  );

};
