import sampleSize from 'lodash.samplesize';
import { AtRecommendedWebinarAreaWebinar } from '../molecules/AtRecommendedWebinarAreaWebinar';
import { RelatedWebinar } from '../../generated/graphql';
import style from './AtRecommendedWebinarArea.module.scss';

interface AtRecommendedWebinarAreaProps {
  recommendedWebinars: [RelatedWebinar];
}

export const AtRecommendedWebinarArea = ({ recommendedWebinars }: AtRecommendedWebinarAreaProps) => {

  const getRandomList = () => sampleSize(recommendedWebinars.slice(2, recommendedWebinars.length), 18);

  const firstTwoRecommendedWebinars = () =>
    recommendedWebinars.slice(0, 2)?.map((recommendedWebinar) => (
      <div key={recommendedWebinar.id}>
        <AtRecommendedWebinarAreaWebinar lazy webinar={recommendedWebinar} />
      </div>
    ));

  const renderRecommendedWebinars = () => (
    <div className={style.atRecommendedWebinarContentArea}>
      {recommendedWebinars.length > 0 && firstTwoRecommendedWebinars()}
      {getRandomList()?.map((recommendedWebinar, index) => (
        <div key={recommendedWebinar.id}>
          <AtRecommendedWebinarAreaWebinar lazy webinar={recommendedWebinar} />
        </div>
      ))}
    </div>
  );

  return (
    <div className={style.atRecommendedWebinarArea}>
      <div className={style.atRecommendedWebinarTitleArea}>
        <p className={style.atOtherTitle}>
          <i className="far fa-tv fa-fw fs-14" /> こちらの動画もいかがですか？
        </p>
      </div>
      <p className={style.atWebinarSeparation} />
      {renderRecommendedWebinars()}
    </div>
  );

};

export default AtRecommendedWebinarArea;
