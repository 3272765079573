import {
  ApolloClient, ApolloProvider, InMemoryCache, createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getTvDomain } from '../util/envUtil';
import { apolloCacheConfig } from './AtApolloCacheConfig';

const Cookies = require('js-cookie');

const serverHost = `${getTvDomain()}tv`;
const aperzaCloudCookieWhiteList = ['_ctkn', '_scc', '_scf', 'SESSION'];

const createApolloClient = (name?: string) => {

  const appName = name || 'aperza-tv';

  const httpLink = createHttpLink({
    uri: `${serverHost}/graphql`,
    // SSL対応後コメント化解除を予定
    // credentials: 'include',
  });

  const authLink = setContext(async (_, { headers }) => {

    const cookies: Record<string, any> = {};
    cookies.cloudDomain = serverHost.slice(
      serverHost.indexOf('.'),
      serverHost.lastIndexOf(':') > serverHost.indexOf('.') ? serverHost.lastIndexOf(':') : serverHost.length,
    );

    if (Cookies.get()) {

      Object.keys(Cookies.get()).forEach((key) => {

        if (aperzaCloudCookieWhiteList.includes(key)) {

          cookies[key] = Cookies.get(key);

        }

      });

    }

    return {
      headers: {
        ...headers,
        'Aperza-App':          appName,
        'Aperza-Cloud-Cookie': JSON.stringify(cookies),
      },
    };

  });

  const apolloClient = new ApolloClient({
    name:              appName,
    connectToDevTools: true,
    cache:             new InMemoryCache({ ...apolloCacheConfig }),
    link:              authLink.concat(httpLink),
  });
  return apolloClient;

};

export { createApolloClient, ApolloProvider };
