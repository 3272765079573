import { LoadingBox } from '@aperza/ac-contents-ui';
import { ApolloQueryResult } from '@apollo/client';
import {
  useMemo, useState, VFC, useEffect,
} from 'react';
import { Redirect } from 'react-router-dom';
import { notifyMessage } from '@aperza/ac-ui';
import { useAuth } from '../../contexts/AuthContext';
import { getDateAsString } from '../../util/dateUtil';
import {
  useCreateTvEventWatchLaterMutation,
  UserType,
  useEventTimeTableQuery,
  TvEventSchedulesQuery,
  useTvEventSchedulesQuery,
  RelatedWebinar,
} from '../../generated/graphql';
import { AtBasicButton, AtButtonOk } from '../atoms/AtButton';
import { AtCTAModalDay } from '../molecules/AtCTAModalDay';
import { AtEventModalBodyHeader } from '../molecules/AtEventModalBodyHeader';
import style from './AtCTAModal.module.scss';
import { AtModal } from './AtModal';
import useTvWatchLaterTrackEvent from '../../hooks/tracking/useTvWatchLaterTrackEvent';

export type webinarDay = {
  schedule?: string;
  tvEventScheduleDate?: string;
  content?: N<N<N<N<TvEventSchedulesQuery['tvEventSchedules']>['content']>[number]['content']>[number]>[];
  webinars?: N<N<N<N<TvEventSchedulesQuery['tvEventSchedules']>['content']>[number]['content']>[number]>[];
};

export interface AtCTAModalProps {
  webinarDays?: any[];
  upComingEvents?: RelatedWebinar[];
  closeModal: () => void;
  title?: string;
  refetch?: () => Promise<ApolloQueryResult<any>>;
  eventId: string;
  eventType?: string;
}

export const AtCTAModal: VFC<AtCTAModalProps> = (props) => {

  const {
    webinarDays, closeModal, title, refetch, eventId, eventType, upComingEvents,
  } = props;

  const [combinedWbinarDaysList, setCombinedWbinarDaysList] = useState<webinarDay[]>([]);

  const {
    currentUser, currentPath, signin, signup,
  } = useAuth();
  const [selectedTvWebinarIds, setSelectedTvWebinarIds] = useState<number[]>([]);
  const { tvWatchLaterTrackEvent } = useTvWatchLaterTrackEvent();
  const {
    data: internalQueryData,
    error: internalQueryError,
    loading: internalQueryLoading,
    refetch: internalQueryRefetch,
  } = useEventTimeTableQuery({
    variables: {
      tvEventId: +eventId,
    },
    skip:        !!webinarDays || (!!eventType && eventType !== 'EVENT_LP'),
    fetchPolicy: 'cache-and-network',
  });

  // query for old style events
  const {
    data: oldEventQueryData,
    error: oldEventQueryError,
    loading: oldEventQueryLoading,
    refetch: oldEventQueryRefetch,
  } = useTvEventSchedulesQuery({
    variables: {
      tvEventId: +eventId,
    },
    skip:        !!webinarDays || !eventType || eventType === 'EVENT_LP',
    fetchPolicy: 'cache-and-network',
  });

  let _webinarDays: webinarDay[] = (webinarDays ??
    internalQueryData?.eventTimetable ??
    oldEventQueryData?.tvEventSchedules?.content ??
    []) as webinarDay[];

  if (_webinarDays?.[0]?.content) {

    _webinarDays = _webinarDays.map((webinarDay) => ({ ...webinarDay, webinars: webinarDay.content }));

  }

  const [addEventQuery, { loading: addEventLoading }] = useCreateTvEventWatchLaterMutation();
  const registeredTvWebinarIds = useMemo(() => {

    const _registeredTvWebinarIds: number[] = [];
    const _selectedTvWebinarIds: number[] = [];

    // set each event as either 'registered' or 'selected'
    _webinarDays?.forEach((webinarDay) => {

      webinarDay.webinars?.forEach((webinar) => {

        if (webinar.loggedInUserWatchStatus?.tvWatchLaterId) {

          _registeredTvWebinarIds.push(webinar.tvWebinarId);

        } else {

          _selectedTvWebinarIds.push(webinar.tvWebinarId);

        }

      });

    });

    upComingEvents?.forEach((event) => {

      if (
        event.loggedInUserWatchStatus?.tvWatchLaterId &&
        !_registeredTvWebinarIds.includes(Number(event?.id))
      ) {

        _registeredTvWebinarIds.push(Number(event?.id));

      }

      if (
        !event.loggedInUserWatchStatus?.tvWatchLaterId &&
        !_selectedTvWebinarIds.includes(Number(event?.id))
      ) {

        _selectedTvWebinarIds.push(Number(event?.id));

      }

    });

    setSelectedTvWebinarIds(_selectedTvWebinarIds);

    return _registeredTvWebinarIds;

  }, [webinarDays, internalQueryData, oldEventQueryData]);

  const mapWebinarDayField = (item: any) => ({
    schedule: item.schedule,
    webinars: item.webinars,
  });

  const mapWebinarNodeField = (event: any) => ({
    companyContract: {
      __typename:               'CompanyContract',
      companyContractId:        event?.companyContractId,
      companyContractDisplayId: 0,
      companyPagePublishFlag:   true,
      companyLang:              {
        companyLangId:   '',
        companyName:     '',
        companyLogoUrl:  '',
        companyNameFull: event?.companyNameFull,
        __typename:      'CompanyLang',
      },
    },
    loggedInUserWatchStatus:         event?.loggedInUserWatchStatus,
    tvWebinarDescription:            '',
    tvWebinarId:                     Number(event?.id),
    tvWebinarMainImgFilePath:        event?.tvWebinarMainImgFilePath,
    tvWebinarMoviePlayTime:          event?.tvWebinarMoviePlayTime,
    tvWebinarOndemandFlag:           event?.tvWebinarOndemandFlag,
    tvWebinarPublishEndDatetime:     event?.tvWebinarPublishEndDatetime,
    tvWebinarPublishStartDatetime:   event?.tvWebinarPublishStartDatetime,
    tvWebinarRecommendSentence1:     '',
    tvWebinarRecommendSentence2:     '',
    tvWebinarRecommendSentence3:     '',
    tvWebinarSpeakerDepartment:      event?.tvWebinarSpeakerDepartment,
    tvWebinarSpeakerImgFilePath:     event?.tvWebinarSpeakerImgFilePath,
    tvWebinarSpeakerName:            event?.tvWebinarSpeakerName,
    tvWebinarStreamingEndDatetime:   event?.tvWebinarStreamingEndDatetime,
    tvWebinarStreamingStartDatetime: event?.tvWebinarStreamingStartDatetime,
    tvWebinarSubtitle:               event?.tvWebinarSubtitle,
    tvWebinarTitle:                  event?.tvWebinarTitle,
    __typename:                      'TvWebinar',
  });

  const combineToWebinarDays = () => {

    const mappedItems: any[] = _webinarDays.map((item) => mapWebinarDayField(item));

    upComingEvents?.forEach((event) => {

      const index = mappedItems.findIndex(
        (_webinarDay) =>
          _webinarDay.schedule === getDateAsString(event.tvWebinarStreamingStartDatetime, 'YYYY-MM-DD'),
      );

      if (
        index !== -1 &&
        mappedItems[index]?.webinars.findIndex(
          (obj: { tvWebinarId: number }) => obj.tvWebinarId === Number(event.id),
        ) === -1
      ) {

        const webinarNode: any[] = [...mappedItems[index]?.webinars, mapWebinarNodeField(event)];
        mappedItems.splice(index, 1, {
          schedule: getDateAsString(event.tvWebinarStreamingStartDatetime, 'YYYY-MM-DD'),
          webinars: webinarNode,
        });

      }

      if (index === -1) {

        mappedItems.push({
          schedule: getDateAsString(event.tvWebinarStreamingStartDatetime, 'YYYY-MM-DD'),
          webinars: [mapWebinarNodeField(event)],
        });

      }

    });

    return mappedItems?.sort(
      (objA, objB) => new Date(objA.schedule).getTime() - new Date(objB.schedule).getTime(),
    );

  };

  useEffect(() => {

    if (upComingEvents && !internalQueryLoading && !oldEventQueryLoading) {

      setCombinedWbinarDaysList(combineToWebinarDays());

    }

  }, [upComingEvents, internalQueryData, oldEventQueryData]);

  if (internalQueryLoading || oldEventQueryLoading) return <LoadingBox />;

  if (internalQueryError || oldEventQueryError) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  const add = (webinarId: number) => {

    if (!registeredTvWebinarIds.includes(webinarId)) {

      setSelectedTvWebinarIds((previousSelectedTvWebinarIds) => [
        ...previousSelectedTvWebinarIds,
        +webinarId,
      ]);

    }

  };
  const remove = (webinarId: number) => {

    setSelectedTvWebinarIds((previousSelectedTvWebinarIds) =>
      previousSelectedTvWebinarIds.filter((id) => id !== +webinarId));

  };
  const addEvent = async (signinOrSignup?: (path: string) => void) => {

    await addEventQuery({
      variables: {
        tvWebinarIds: selectedTvWebinarIds,
        tvEventId:    +eventId,
      },
    })
      .then((result) => {

        if (result?.data?.createTvEventWatchLater?.tvWatchLaterRegistrationStatus === 'TEMPORARY') {

          signinOrSignup?.(currentPath);
          closeModal();
          return;

        }

        notifyMessage(
          <div>
            <i className="far fa-check-circle" style={{ margin: 'auto 5px' }} />
            イベントの登録が完了しました。
          </div>,
          'SUCCESS',
          { position: 'bottom-center', hideProgressBar: true },
        );
        tvWatchLaterTrackEvent(
          result.data?.createTvEventWatchLater?.tvWatchLaterResults?.map((d) => d?.tvWebinarId),
        );

        closeModal();

      })
      .catch((error) => {

        notifyMessage('登録に失敗しました。もう一度登録をお願いいたします。', 'ERROR', {
          position:        'bottom-center',
          hideProgressBar: true,
        });
        closeModal();

      });

    refetch?.();

  };

  const getCombinedWebinarDays = (_upComingEvents: RelatedWebinar[] | undefined) => {

    if (_upComingEvents && _upComingEvents?.length > 0) {

      return combinedWbinarDaysList;

    }

    return _webinarDays;

  };

  const bodyArea = (
    <div className={` ${style.bodyArea} `}>
      <AtEventModalBodyHeader
        firstDayDate={_webinarDays?.[0]?.schedule}
        // firstDayEventType={_webinarDays.firstDayEventType}  // labels not implemented yet
        lastDayDate={
          (_webinarDays?.length ?? 0) > 1 ? _webinarDays[_webinarDays.length - 1]?.schedule : undefined
        }
        eventTitle={title}
      />
      {getCombinedWebinarDays(upComingEvents)?.map((day, index) => (
        <AtCTAModalDay
          key={index}
          day={day}
          add={add}
          remove={remove}
          registeredTvWebinarIds={registeredTvWebinarIds}
          selectedTvWebinarIds={selectedTvWebinarIds}
        />
      ))}
      <div className={` ${style.informationSharingText} `}>
        {`イベントに参加時に、アペルザIDにご登録のプロフィール情報及び視聴した動画に関する情報（視聴した動画、視聴時間等）が掲載企業（商品・サービスの提供者の他、当該商品・サービスの販売代理店等の商品・サービスの取扱事業者、当該イベントまたは動画の協力・協賛事業者を含みます。）へ提供されます。掲載企業は同情報に基づきお客様へ情報提供やお問合せへのフォローを行う場合があります。上記にご同意の上、アペルザTVをご利用ください。`}
      </div>
    </div>
  );

  const loggedInButtons = (
    <div className={`${style.modalButtonArea}`}>
      <div className={` ${style.buttonRow} `}>
        <AtButtonOk
          className={` ${style.buttonOverride} `}
          onSubmit={addEvent}
          disabled={selectedTvWebinarIds.length === 0}
        >
          イベントに参加する
        </AtButtonOk>
        <AtBasicButton className={` ${style.buttonOverride} `} onSubmit={closeModal}>
          キャンセル
        </AtBasicButton>
      </div>
    </div>
  );

  const guestButtons = (
    <div className={`${style.modalButtonArea}`}>
      <div className={`${style.loginInfo}`}>
        アペルザIDへログイン、または新規登録の上
        <br />
        イベントに参加（選択済みの動画を視聴リストに追加）する
      </div>
      <div className={` ${style.buttonRow} `}>
        <AtButtonOk
          className={` ${style.buttonOverride} `}
          onSubmit={() => addEvent(signin)}
          disabled={selectedTvWebinarIds.length === 0}
        >
          ログインしてイベントに参加
        </AtButtonOk>
        <AtBasicButton
          className={` ${style.buttonOverride} `}
          onSubmit={() => addEvent(signup)}
          disabled={selectedTvWebinarIds.length === 0}
        >
          新規登録してイベントに参加
        </AtBasicButton>
      </div>
    </div>
  );

  const buttonArea = currentUser.userType === UserType.Member ? loggedInButtons : guestButtons;

  return (
    <AtModal title="イベントに参加しますか？" closeEventModal={closeModal}>
      <div className={style.container}>
        {!internalQueryLoading && !oldEventQueryLoading && bodyArea}
        {buttonArea}
      </div>
      {addEventLoading && <LoadingBox />}
    </AtModal>
  );

};
