import { ApolloError } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
  GetTvNotificationSettingQuery,
  NotificationSettingFlag,
  TvNotificationSetting,
  useGetTvNotificationSettingLazyQuery,
  useUpdateTvNotificationSettingMutation,
} from '../../generated/graphql';
import useTvNotificationSettingsTrackEvent from '../../hooks/tracking/useTvNotificationSettingsTrackEvent';
import AtExternalLink from '../atoms/AtExternalLink';
import { AtLinkSubmit } from '../atoms/AtLink';
import { LoadingBox } from '../atoms/LoadingBox';
import TitleAndMeta from '../atoms/TitleAndMeta';
import './AtMypageTop.scss';

interface AtMypageTopProp {
  userMailAddress: string;
}

export const AtMypageTop = ({ userMailAddress }: AtMypageTopProp) => {

  const {
    currentUser, logout, changeEmail, changePassword, accountServiceUrl,
  } = useAuth();
  const [isRedirect, setIsRedirect] = useState(false);
  const [tvNotificationSettings, setTvNotificationSettings] = useState<TvNotificationSetting[]>([]);
  const {
    currentTvNotificationSettingsTrackEvent,
    updateTvNotificationSettingsTrackEvent,
  } = useTvNotificationSettingsTrackEvent();

  const [getTvNotificationSettingLazyQuery] = useGetTvNotificationSettingLazyQuery({
    onCompleted(data: GetTvNotificationSettingQuery) {

      if (!data || !data.tvNotificationSetting || data.tvNotificationSetting.length === 0) {

        setIsRedirect(true);
        return;

      }

      setTvNotificationSettings(
        data.tvNotificationSetting.map(
          (setting) =>
            ({
              notificationSettingName:        setting.notificationSettingName,
              notificationSettingDescription: setting.notificationSettingDescription,
              notificationSettingType:        setting.notificationSettingType,
              notificationSettingFlag:        setting.notificationSettingFlag,
            } as TvNotificationSetting),
        ),
      );

      currentTvNotificationSettingsTrackEvent(data.tvNotificationSetting);

    },
    onError(error: ApolloError) {

      setIsRedirect(true);

    },
    fetchPolicy: 'network-only',
  });
  const [updateTvNotificationSettingMutation] = useUpdateTvNotificationSettingMutation();

  const onChangeNotificationSetting = async (changeSetting: TvNotificationSetting) => {

    const response = await updateTvNotificationSettingMutation({
      variables: {
        notificationSettingType: changeSetting.notificationSettingType,
        notificationSettingFlag:
          NotificationSettingFlag.Notice === changeSetting.notificationSettingFlag
            ? NotificationSettingFlag.NotNotice
            : NotificationSettingFlag.Notice,
      },
    });

    if (!response || !response.data || !response.data.updateTvNotificationSetting) {

      setIsRedirect(true);
      return;

    }
    setTvNotificationSettings(
      response.data.updateTvNotificationSetting.map(
        (setting) =>
          ({
            notificationSettingName:        setting.notificationSettingName,
            notificationSettingDescription: setting.notificationSettingDescription,
            notificationSettingType:        setting.notificationSettingType,
            notificationSettingFlag:        setting.notificationSettingFlag,
          } as TvNotificationSetting),
      ),
    );

    updateTvNotificationSettingsTrackEvent(response.data.updateTvNotificationSetting, changeSetting);

  };

  useEffect(() => {

    const getSettings = async () => {

      await getTvNotificationSettingLazyQuery();

    };
    getSettings();

  }, []);

  if (!tvNotificationSettings) {

    return <LoadingBox />;

  }

  if (isRedirect) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  const settings = tvNotificationSettings.map((setting) => (
    /* eslint-disable jsx-a11y/label-has-associated-control */
    <div className="user-info-contents" key={setting.notificationSettingType}>
      <span className="heading">
        <p className="title">{setting.notificationSettingName}</p>
        <p className="content">{setting.notificationSettingDescription}</p>
      </span>
      <div className="toggle-switch">
        <input
          id="toggle"
          className="toggle-input"
          type="checkbox"
          checked={setting.notificationSettingFlag === NotificationSettingFlag.Notice}
          onChange={() => onChangeNotificationSetting(setting)}
        />
        <label htmlFor="toggle" className="toggle-label" />
        <span />
      </div>
    </div>
    /* eslint-enable */
  ));

  return (
    <>
      <TitleAndMeta title="マイページ | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト" />
      <div className="at-mypage-top">
        <div className="at-mypage-user-info">
          <div className="user-info-title">
            <p>アカウントサービス</p>
            <span className="user-accountservice-link-area">
              <AtExternalLink href={accountServiceUrl} name="アペルザID" bold fontSize="16px" iconSize="1x" />
            </span>
          </div>
          <div className="user-info-contents-area">
            <div className="user-info-contents">
              <p className="title">メールアドレス</p>
              <p className="content">{userMailAddress}</p>
              <AtLinkSubmit onSubmit={changeEmail}>メールアドレスを変更する</AtLinkSubmit>
            </div>
          </div>
          <div className="user-info-contents-area">
            <div className="user-info-contents">
              <p className="title">パスワード</p>
              <p className="content">********</p>
              <AtLinkSubmit onSubmit={changePassword}>パスワードを変更する</AtLinkSubmit>
            </div>
          </div>
        </div>
        <div className="at-mypage-user-info">
          <div className="user-info-title">
            <p>通知設定</p>
          </div>
          <div className="user-info-contents-area">{settings}</div>
        </div>
        <div className="logout d-flex justify-content-end">
          <AtLinkSubmit onSubmit={logout}>ログアウトする</AtLinkSubmit>
        </div>
      </div>
    </>
  );

};

export default AtMypageTop;
