import { ApolloQueryResult } from '@apollo/client';
import {
  useEffect, useLayoutEffect, useState, VFC,
} from 'react';
import { RelatedWebinar, TvEventPageQuery } from '../../generated/graphql';
import { timeIsFuture, timeIsPast } from '../../util/dateUtil';
import { getTvDomain } from '../../util/envUtil';
import { removeEndSlash } from '../../util/pathUtil';
import type { Breadcrumb } from '../atoms/AtBreadcrumbs';
import { AtBreadcrumbs } from '../atoms/AtBreadcrumbs';
import TitleAndMeta from '../atoms/TitleAndMeta';
import AtBreadcrumbsJsonLd from '../molecules/AtBreadcrumbsJsonLd';
import { AtEventLpMainVisual } from '../molecules/AtEventLpMainVisual';
import { AtCTAModal } from '../organisms/AtCTAModal';
import { AtEventLpLinks } from '../organisms/AtEventLpLinks';
import { AtEventLpOnDemand } from '../organisms/AtEventLpOnDemand';
import { AtEventLpOutline } from '../organisms/AtEventLpOutline';
import { AtEventLpTimetable } from '../organisms/AtEventLpTimetable';
import { AtNewEventLpExhibitionReports } from '../organisms/AtNewEventLpExhibitionReports';
import { AtPartnerCompanies } from '../organisms/AtPartnerCompanies';
import { AtExhibitingCompanyLogos } from '../organisms/event-lp/exhibiting-company/AtExhibitingCompanyLogos';
import { CatalogList } from '../organisms/static-event/CatalogList';
import { AtHeaderAndFooter } from '../templates/AtHeaderAndFooter';
import { useIsMobile } from '../templates/MobileReady';
import style from './AtEventLp.module.scss';

const queryString = require('query-string');

export interface AtEventLpProps {
  eventId: string;
  data?: TvEventPageQuery;
  refetch: () => Promise<ApolloQueryResult<any>>;
}

export const AtEventLp: VFC<AtEventLpProps> = ({ eventId, data, refetch }) => {

  const [showCTAModal, setShowCTAModal] = useState<boolean>(false);

  const isMobile = useIsMobile();

  const { reload } = queryString.parse(window.location.search);
  useEffect(() => {

    if (reload === '1') {

      window.location.replace(window.location.href.split('?')[0]);

    }

  }, [reload]);

  useLayoutEffect(() => {

    const anchor = window.location.hash;

    if (anchor) {

      // decodeURI + remove # from first string
      const getHashString = decodeURIComponent(anchor).substring(1);
      const anchorEl = document.getElementById(getHashString);
      if (anchorEl) {

        anchorEl.scrollIntoView();

      }

    }

  }, []);

  const showCompanyLogo =
    data?.tvEventPage?.relTvEventCompanyContracts &&
    data?.tvEventPage?.relTvEventCompanyContracts.filter(
      (relTvEventCompanyContract) => relTvEventCompanyContract?.companyContract.companyLang?.companyLogoUrl,
    ).length > 0;

  const showExhibitionReports =
    data?.tvEventPage?.tvExhibitionReports && data?.tvEventPage?.tvExhibitionReports.length > 0;

  const showOnDemand =
    data?.tvEventPage?.relTvEventLpTvOndemandWebinars &&
    data?.tvEventPage?.relTvEventLpTvOndemandWebinars.length > 0;

  const showPartnerCompany =
    data?.tvEventPage?.tvEventPartnerCompanies && data?.tvEventPage?.tvEventPartnerCompanies.length > 0;

  const showCatalogList = data?.tvEventPage?.catalogs && data?.tvEventPage?.catalogs.length !== 0;

  const generateSectionTitles = (): { [sectionTitle: string]: string } => {

    const sectionTitles: { [sectionTitle: string]: string } = {};

    sectionTitles.イベント概要 = 'イベント概要';
    sectionTitles.タイムテーブル = 'タイムテーブル';

    if (showExhibitionReports) sectionTitles.展示会レポート = 'レポーターによる展示会ブースの現地取材\r\n展示会レポート';
    if (showOnDemand) sectionTitles.関連動画 = 'このイベントに関連する\r\nオンデマンド動画';
    if (showCatalogList) sectionTitles.イベント出展カタログ = 'イベント出展カタログ';
    if (showPartnerCompany) sectionTitles.パートナー企業 = 'パートナー企業';

    return sectionTitles;

  };

  const sectionTitles = generateSectionTitles();

  const upComingEventFilter = (upcomingEvent: RelatedWebinar) =>
    timeIsFuture(upcomingEvent.tvWebinarStreamingStartDatetime) &&
    timeIsPast(upcomingEvent.tvWebinarPublishStartDatetime);

  let upComingExhibitionReports: RelatedWebinar[] = [];

  if (data?.tvEventPage && data?.tvEventPage.tvExhibitionReports.length > 0) {

    upComingExhibitionReports = data?.tvEventPage.tvExhibitionReports.filter(upComingEventFilter) ?? [];

  }

  const breadcrumbs: Breadcrumb[] = [
    {
      text: 'トップ',
      url:  '/',
    },
    {
      text: 'イベント・特集一覧',
      url:  '/event',
    },
    {
      text: `${data?.tvEventPage?.tvEventTitle}`,
    },
  ];

  const backgroundColor = data?.tvEventPage?.tvEventLpBackgroundColor ?? '#4E2517';

  return (
    <>
      <TitleAndMeta
        title={`${
          data?.tvEventPage?.tvEventTitle ?? ''
        } | Apérza TV（アペルザTV、アペルザテレビ） | ものづくり産業向け動画サイト`}
        canonicalHref={removeEndSlash(getTvDomain()) + removeEndSlash(window.location.pathname)}
      />
      <AtBreadcrumbsJsonLd breadcrumbs={breadcrumbs} baseUrl={getTvDomain().slice(0, -1)} />
      <AtHeaderAndFooter modalSidebar>
        <AtEventLpMainVisual
          eventId={eventId}
          imgSrc={data?.tvEventPage?.tvEventMainImgFilePath}
          eventType={data?.tvEventPage?.tvEventType}
          startDate={data?.tvEventPage?.tvEventStartDatetime}
          endDate={data?.tvEventPage?.tvEventEndDatetime}
          venueType={'オンライン開催'}
          buttonBackgroundColor={data?.tvEventPage?.tvEventLpCtaButtonBackgroundColor}
          buttonTextColor={data?.tvEventPage?.tvEventLpCtaButtonTextColor}
          title={data?.tvEventPage?.tvEventTitle}
          setShowCTAModal={setShowCTAModal}
          backgroundColor={backgroundColor}
          eventApplyFlag={data?.tvEventPage?.eventApplyFlag}
        />
        <AtEventLpLinks sectionTitles={sectionTitles} backgroundColor={backgroundColor} />
        <AtEventLpOutline
          sectionTitle={sectionTitles.イベント概要}
          eventTitle={data?.tvEventPage?.tvEventSubtitle}
          description={data?.tvEventPage?.tvEventDescription}
          eventList={data?.tvEventPage?.tvEventOverviews}
          backgroundColor={backgroundColor}
        />
        {showCompanyLogo && (
          <AtExhibitingCompanyLogos exhibitingCompanies={data!.tvEventPage!.relTvEventCompanyContracts!} />
        )}
        <AtEventLpTimetable
          eventGroups={data!.tvEventPage!.eventTimetable!}
          sectionTitle={sectionTitles.タイムテーブル}
          setShowCTAModal={setShowCTAModal}
        />
        {showExhibitionReports && (
          <AtNewEventLpExhibitionReports
            exhibitionReports={data!.tvEventPage!.tvExhibitionReports!}
            sectionTitle={sectionTitles.展示会レポート}
            setShowCTAModal={setShowCTAModal}
          />
        )}
        {showOnDemand && (
          <AtEventLpOnDemand
            onDemandEvents={data!.tvEventPage!.relTvEventLpTvOndemandWebinars!}
            sectionTitle={sectionTitles.関連動画}
          />
        )}
        {showCatalogList && !isMobile && (
          <CatalogList
            catalogs={data!.tvEventPage!.catalogs!}
            sectionTitle={sectionTitles.イベント出展カタログ}
            tvEventId={Number(eventId)}
            tvEventStartDatetime={data?.tvEventPage?.tvEventStartDatetime}
            tvEventEndDatetime={data?.tvEventPage?.tvEventEndDatetime}
            accessPage="top"
          />
        )}
        {showPartnerCompany && (
          <AtPartnerCompanies
            partnerCompanyNames={data?.tvEventPage?.tvEventPartnerCompanies}
            sectionTitle={sectionTitles.パートナー企業}
          />
        )}
        {!isMobile && (
          <div className={`${style.atBreadcrumbs}`}>
            <AtBreadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        )}
        {showCTAModal && (
          <AtCTAModal
            refetch={refetch}
            title={data?.tvEventPage?.tvEventTitle}
            webinarDays={data?.tvEventPage?.eventTimetable}
            upComingEvents={upComingExhibitionReports}
            closeModal={() => setShowCTAModal(false)}
            eventId={eventId}
          />
        )}
      </AtHeaderAndFooter>
    </>
  );

};
