import { FC } from 'react';
import style from './CluezCatalogDownloadButton.module.scss';

type CatalogCluezDownloadButtonProps = {
  onClick: () => void;
};

export const CatalogCluezDownloadButton: FC<CatalogCluezDownloadButtonProps> = (props) => (
  <button type="button" className={`btn btn-sm  mt-auto ${style.button}`} onClick={() => props.onClick()}>
    ダウンロード
    <i className="far fa-arrow-to-bottom fa-fw" />
  </button>
);
