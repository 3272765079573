import { RefObject } from 'react';

/**
 * Support for highlighting text in a card.
 * Otherwise click the card in @param ref
 */
const clickCard = (ref: RefObject<any>) => {

  const isTextSelected = window.getSelection()?.toString();
  if (!isTextSelected) {

    ref.current?.click();

  }

};

export { clickCard };
