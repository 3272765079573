import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { dividePartDate, timeIsFuture, timeIsPast } from '../../util/dateUtil';
import AtLink from '../atoms/AtLink';
import AtTab from '../atoms/AtTab';
import { LoadingBox } from '../atoms/LoadingBox';
import AtMovieListDetail from '../molecules/AtMovieListDetail';
import './AtMovieListDetailFrame.scss';

const webinarsFurturesOpenQuery = gql`
  query {
    tvWebinarsFurturesOpen(page: 1, size: 4) {
      content {
        tvWebinarId
        companyContractId
        tvWebinarTitle
        tvWebinarSubtitle
        tvWebinarDescription
        tvWebinarStreamingStartDatetime
        tvWebinarStreamingEndDatetime
        tvWebinarOndemandFlag
        tvWebinarRecommendSentence1
        tvWebinarRecommendSentence2
        tvWebinarRecommendSentence3
        tvWebinarSpeakerName
        tvWebinarSpeakerImgFilePath
        tvWebinarSpeakerDepartment
        tvWebinarMainImgFilePath
        relTvWebinarSystem {
          tvWebinarId
          tvWebinarVimeoLiveEventId
          tvWebinarVimeoLiveEventStatus
          tvWebinarVimeoVideoId
          tvWebinarVimeoVideoStatus
          tvWebinarMedialiveChannelId
          tvWebinarMedialiveChannelStatus
          tvWebinarSlidoEventId
        }
        loggedInUserWatchStatus {
          tvWebinarId
          tvWatchLaterId
        }
      }
      page
      size
      totalElements
      totalPages
    }
  }
`;

export const AtMovieListDetailFrame = () => {

  const [tabState, setTabState] = useState(0);
  const { loading, error, data } = useQuery(webinarsFurturesOpenQuery, {});

  if (loading) return <LoadingBox />;
  if (error) return <Redirect to={{ pathname: '/500', state: { invalidURL: window.location.pathname } }} />;

  const tabValue: any = [];
  const webinarValue: any = [];
  let date: string;
  let timeTerm: string;

  const tvWebinarsFurturesOpen: [any] = data.tvWebinarsFurturesOpen.content;
  tvWebinarsFurturesOpen.forEach((tvWebinarsFurtureOpen, tvWebinarsFurtureOpenIndex) => {

    const recommendTexts: any = [];
    let webinarType = 'offTime';
    if (
      tvWebinarsFurtureOpen.tvWebinarStreamingStartDatetime &&
      tvWebinarsFurtureOpen.tvWebinarStreamingEndDatetime
    ) {

      const {
        jstMonth: webinarStartMonth,
        jstDay: webinarStartDay,
        jstWeekDay: webinarStartWeekDay,
        jstTime: webinarStartTime,
      } = dividePartDate(tvWebinarsFurtureOpen.tvWebinarStreamingStartDatetime);
      const { jstTime: webinarEndtime } = dividePartDate(tvWebinarsFurtureOpen.tvWebinarStreamingEndDatetime);

      date = `${webinarStartMonth}/${webinarStartDay} (${webinarStartWeekDay})`;
      timeTerm = `${webinarStartTime}〜${webinarEndtime}`;

      if (timeIsFuture(tvWebinarsFurtureOpen.tvWebinarStreamingStartDatetime)) {

        webinarType = 'openTime';

      } else if (
        timeIsPast(tvWebinarsFurtureOpen.tvWebinarStreamingStartDatetime) &&
        timeIsFuture(tvWebinarsFurtureOpen.tvWebinarStreamingEndDatetime)
      ) {

        webinarType = 'onTime';

      } else if (
        timeIsPast(tvWebinarsFurtureOpen.tvWebinarStreamingEndDatetime) &&
        tvWebinarsFurtureOpen.tvWebinarOndemandFlag === 'ONDEMAND'
      ) {

        webinarType = 'ondemand';

      }
      tabValue.push(
        <AtTab
          key={tvWebinarsFurtureOpen.tvWebinarId}
          date={date}
          timeTerm={timeTerm}
          activeFlag={tabState === tvWebinarsFurtureOpenIndex}
          onClick={() => setTabState(tvWebinarsFurtureOpenIndex)}
        />,
      );

    }

    recommendTexts.push(
      tvWebinarsFurtureOpen.tvWebinarRecommendSentence1,
      tvWebinarsFurtureOpen.tvWebinarRecommendSentence2,
      tvWebinarsFurtureOpen.tvWebinarRecommendSentence3,
    );

    if (tabState === tvWebinarsFurtureOpenIndex) {

      webinarValue.push(
        <AtMovieListDetail
          key={`${tvWebinarsFurtureOpen.tvWebinarId}`}
          webinarId={tvWebinarsFurtureOpen.tvWebinarId}
          movieTerm={`${date} ${timeTerm}`}
          movieTitle={tvWebinarsFurtureOpen.tvWebinarTitle}
          movieSubtitle={tvWebinarsFurtureOpen.tvWebinarSubtitle}
          movieDescription={tvWebinarsFurtureOpen.tvWebinarDescription}
          movieRecommendTexts={recommendTexts}
          movieSpeakerName={tvWebinarsFurtureOpen.tvWebinarSpeakerName}
          movieSpeakerImgFilePath={tvWebinarsFurtureOpen.tvWebinarSpeakerImgFilePath}
          movieSpeakerDepartment={tvWebinarsFurtureOpen.tvWebinarSpeakerDepartment}
          companyContractId={tvWebinarsFurtureOpen.companyContractId}
          doesWatchLater={tvWebinarsFurtureOpen?.loggedInUserWatchStatus?.tvWatchLaterId != null}
          tvWatchLaterId={tvWebinarsFurtureOpen?.loggedInUserWatchStatus?.tvWatchLaterId}
          tvWebinarMainImgFilePath={tvWebinarsFurtureOpen?.tvWebinarMainImgFilePath}
          webinarType={webinarType}
        />,
      );

    }

  });

  return (
    <section className="futures-movie-frame">
      <div className="title">
        <i className="fas fa-door-open" />
        <h2>配信予定の動画</h2>
        <AtLink name={'すべて見る'} href="/upcoming" />
      </div>
      <small>
        配信中の時間帯に登壇内容での疑問点・懸念点があれば、チャット経由で担当者に直接質問することが可能です。
      </small>
      <div className="futures">
        <div className="tabs">{tabValue}</div>
        {webinarValue}
      </div>
      <p className="at-attention-mail">
        ご視聴されたお客様の情報（お客様がアペルザIDにご登録された企業名、名前、メールアドレス、住所、電話番号などのプロフィール情報）は、弊社プライバシーポリシーに則り掲載企業へ提供されます。
        <br />
        あらかじめ同意の上、ご視聴してください。
        <br />
        なお、お客様の情報の提供を受けた掲載企業は、お問合せへのご回答や営業活動（メールなどによる広告宣伝を含む）等において、それぞれ掲載企業が定めるプライバシーポリシー等の方針に沿って取り扱われます。
        詳しくは掲載企業のウェブサイトなどをご確認いただくか、掲載企業に直接お問い合わせください。
      </p>
    </section>
  );

};

export default AtMovieListDetailFrame;
