import { VFC } from 'react';
import { Link } from 'react-router-dom';
import { TvWatchLaterStatus } from '../../generated/graphql';
import { getWatchUrlWithTab, WatchUrlPrefix } from '../../util/urlUtil';
import style from './AtMovieTab.module.scss';

type AtMovieTabType = 'ALL' | 'BEFORE_STREAMING' |'STREAMING' | 'ONDEMAND';

export interface AtMovieTabProps {
  showStreamingTab?: boolean;
  selectedTab: AtMovieTabType;
  watchTabPrefix: WatchUrlPrefix;
}

export const AtMovieTab: VFC<AtMovieTabProps> = ({ showStreamingTab, watchTabPrefix, selectedTab }) => (
  <div className={`${style.atFilterArea}`}>
    <Link
      className={`${style.atFilter} ${
        selectedTab === 'ALL' ? `${style.active}` : ''
      }`}
      to={getWatchUrlWithTab(watchTabPrefix, TvWatchLaterStatus.All)}
    >
      すべて
    </Link>
    <Link
      className={`${style.atFilter} ${
        selectedTab === 'BEFORE_STREAMING' ? `${style.active}` : ''
      }`}
      to={getWatchUrlWithTab(watchTabPrefix, TvWatchLaterStatus.BeforeStreaming)}
    >
      配信予定
    </Link>
    {showStreamingTab && (
      <Link
        className={`${style.atFilter} ${selectedTab === 'STREAMING' ? `${style.active}` : ''}`}
        to={getWatchUrlWithTab(watchTabPrefix, TvWatchLaterStatus.Streaming)}
      >
        配信中
      </Link>
    )}
    <Link
      className={`${style.atFilter} ${
        selectedTab === 'ONDEMAND' ? `${style.active}` : ''
      }`}
      to={getWatchUrlWithTab(watchTabPrefix, TvWatchLaterStatus.Ondemand)}
    >
      オンデマンド
    </Link>
  </div>
);

export default AtMovieTab;
