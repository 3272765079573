import { ReactNode, VFC } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useTrackEvent } from '../../hooks/tracking/useTrackEvent';
import style from './LoginModal.module.scss';

export interface EventApplyModalProps {
  eventId: string;
  title: string;
  loginTitle: string | JSX.Element;
  signupTitle: ReactNode;
  signupButtonName: string;
  show: boolean;
  onClose: () => void;
}

const EventApplyModal: VFC<EventApplyModalProps> = ({
  eventId,
  show,
  onClose,
  title,
  loginTitle,
  signupTitle,
}) => {

  const { signin } = useAuth();
  const history = useHistory();
  const { trackEvent } = useTrackEvent();

  return (
    <>
      {show && (
        <div
          id={style.overlay}
          onClick={(e) => {

            e.stopPropagation();
            onClose();

          }}
        >
          <div className={style.atModalRequiredSignin} onClick={(e) => e.stopPropagation()}>
            <div className="d-flex flex-column">
              <div className={`d-flex align-items-center border-bottom ${style.atModalRequiredSigninTop}`}>
                <div className={`${style.atModalDescription}`}>{title}</div>
                <i className="ml-auto fal fa-times" onClick={onClose} />
              </div>
              <div className={style.atModalBody}>
                <p>{loginTitle}</p>

                <a
                  className={style.atButtonNormalGo}
                  onClick={() => {

                    signin(`/event/${eventId}`);

                    /**
                     * FIXME:
                     *  id.aperza.com に移動すると amplitude にイベントが送信されないが、
                     *  ブラウザを閉じずに tv.aperza.com に戻ってこられれば送信される。
                     *  また、signin()よりも先に置いても、非同期処理なので上記の問題は解決しない。
                     */
                    trackEvent('Click Event Apply Modal', {
                      event_id:    Number(eventId),
                      choose_step: 'login',
                    });

                  }}
                  target={'self'}
                >
                  ログインする
                </a>
                <p>{signupTitle}</p>
                <a
                  className={style.atButtonNormalBack}
                  onClick={() => {

                    history.push(`/event/${eventId}/apply`);

                    trackEvent('Click Event Apply Modal', {
                      event_id:    Number(eventId),
                      choose_step: 'register',
                    });

                  }}
                >
                  無料会員登録をして参加登録する
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

};

export default EventApplyModal;
