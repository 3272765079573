import { VFC } from 'react';
import style from './AtHeaderAndFooter.module.scss';

export interface AtHeaderAndFooterProps {
  children: React.ReactNode;
  showSidebar?: boolean;
  hideFooter?: boolean;
  fixedSidebar?: boolean;
  modalSidebar?: boolean; // do not set both 'modalSidebar' and 'fixedSidebar'
}

export const AtHeaderAndFooter: VFC<AtHeaderAndFooterProps> = (props) => {

  const {
    children, showSidebar, hideFooter, fixedSidebar, modalSidebar,
  } = props;

  return <section className={`${style.headerAndFooterTemplate}`}>{children}</section>;

};
