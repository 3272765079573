import { SyntheticEvent, useRef, VFC } from 'react';
import style from './AtRecommendedWebinarRow.module.scss';
import { AtCard } from './AtCard';
import { AtLabel } from '../atoms/AtLabel';
import { RecommendedWebinarProps } from './AtRecommendedWebinarAreaWebinar';
import useClickRecommendedWebinarTrackEvent from '../../hooks/tracking/useClickRecommendedWebinarTrackEvent';

export const AtRecommendedWebinarRow: VFC<RecommendedWebinarProps> = (props) => {

  const { webinar, lazy } = props;

  const menuLinkRef = useRef<HTMLAnchorElement>(null);
  const { clickRecommendedWebinarTrackEvent } = useClickRecommendedWebinarTrackEvent();

  const handleClick = (e: SyntheticEvent) => {

    e.stopPropagation();

    clickRecommendedWebinarTrackEvent(window.location, webinar);

  };

  return (
    <AtCard className={style.card} onClick={() => menuLinkRef.current?.click()}>
      <div className={style.container}>
        <div className={style.thumbnailArea}>
          <img loading={lazy ? 'lazy' : undefined} src={webinar?.tvWebinarMainImgFilePath} className={` ${style.img} `} alt="movieThumbnail" />
          <AtLabel className={style.label} labelKind={webinar?.tvWebinarOndemandFlag} />
        </div>
        <a href={`/watch/${webinar?.id}`} ref={menuLinkRef} onClick={handleClick} target="_blank">
          <div className={style.title}>{webinar?.tvWebinarTitle}</div>
          <div className={style.subtitle}>{webinar?.companyNameFull}</div>
        </a>
      </div>
    </AtCard>
  );

};
