import { LoadingBox } from '@aperza/ac-contents-ui';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { UserType, useEventApplicantQuery } from '../../generated/graphql';
import { AtButtonAddEvent } from '../atoms/AtButton';
import EventApplyModal from './EventApplyModal';

type EventApplyButtonSmallProps = {
  eventId: string;
  setShowCTAModal: React.Dispatch<React.SetStateAction<boolean>>;
  eventApplyFlag: boolean;
  endDate?: string;
};

/**
 * イベント一覧/検索結果/トップでのイベントボタン表示に利用する。
 */
export const EventApplyButtonSmall: FC<EventApplyButtonSmallProps> = ({
  eventId,
  setShowCTAModal,
  eventApplyFlag,
}) => {

  const { currentUser } = useAuth();
  const isMember = currentUser.userType === UserType.Member;

  // TODO ボタンのレンダリングごとにクエリ実行されるので改修したい
  const {
    data,
  } = useEventApplicantQuery({
    variables: {
      tvEventId: +eventId,
    },
    skip: !eventApplyFlag,
  });

  const [open, setOpen] = useState(false);
  const history = useHistory();

  if (eventApplyFlag) {

    if (!data) return null;

    if (isMember) {

      return (
        <AtButtonAddEvent
          openEventModal={(e) => {

            e.stopPropagation();
            history.push(`/event/${eventId}/apply`);

          }}
          disabled={!!data?.eventApplicant}
        />
      );

    }

    return (
      <>
        <AtButtonAddEvent
          openEventModal={(e) => {

            e.stopPropagation();
            setOpen(true);

          }}
        />
        <EventApplyModal
          eventId={eventId}
          show={open}
          onClose={() => {

            setOpen(false);

          }}
          title="参加登録するにはログインが必要です"
          loginTitle={<>アペルザIDへログインの上、先にお進みください。</>}
          signupTitle={
            <>
              アペルザIDへご登録がお済みでない方は
              <br />
              会員登録へお進みください。（登録無料）
            </>
          }
          signupButtonName="無料会員登録をして参加登録する"
        />
      </>
    );

  }

  return (
    <AtButtonAddEvent
      openEventModal={(e) => {

        e.stopPropagation();
        setShowCTAModal(true);

      }}
    />
  );

};
