import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import './AtTab.scss';

interface AtTabProps {
  activeFlag?: boolean;
  date?: string;
  onClick?: () => void;
  timeTerm?: string;
  to?: string;
}

interface AtScheduleTabProps {
  activeFlag?: boolean;
  date: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

interface AtInfoTabProps {
  activeFlag?: boolean;
  onClick?: () => void;
  content: ReactNode;
}

export const AtTab = ({
  date, timeTerm, activeFlag, onClick, to,
}: AtTabProps) => (
  <Link className={`${`at-tab${activeFlag ? ' at-tab-active' : ''}`}`} onClick={onClick} to={to || ''}>
    <p className="date">{date}</p>
    <p className="time-term">{timeTerm}</p>
  </Link>
);

export const AtScheduleTab = ({
  date, activeFlag, onClick, className,
}: AtScheduleTabProps) => (
  <div className={`at-date-tab ${activeFlag ? 'at-date-tab-active' : ''} ${className}`} onClick={onClick}>
    <p className="date">{date}</p>
  </div>
);

export const AtInfoTab = ({ content, activeFlag, onClick }: AtInfoTabProps) => (
  <div className={`${`at-info-tab${activeFlag ? ' at-info-tab-active' : ''}`}`} onClick={onClick}>
    <p className="content">{content}</p>
  </div>
);

export default AtTab;
