import { VFC } from 'react';
import style from './AtCard.module.scss';

export interface AtCardProps {
  children?: React.ReactNode;
  className?: string;
  onClick?: (_arguments?: any) => void;
  disabled?: boolean;
}

export const AtCard: VFC<AtCardProps> = (props) => {

  const {
    children, className = '', onClick, disabled,
  } = props;

  return (
    <div
      onClick={!disabled ? onClick : undefined}
      className={`${className} ${style.container} ${!disabled ? style.clickableCard : style.unClickableCard}`}
    >
      {children}
    </div>
  );

};
