import React, { VFC } from 'react';
import style from './AtButtonMultiple.module.scss';

interface AtButtonMultipleProps {
  clickHandler: () => void;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
}

export const AtButtonMultiple: VFC<AtButtonMultipleProps> = (props) => {

  const {
    clickHandler, disabled, children, className,
  } = props;

  return (
    <div
      className={`${style.atButtonMultiple} ${className}`}
      style={disabled ? { opacity: 0.5 } : {}}
      onClick={() => {

        if (!disabled) {

          clickHandler();

        }

      }}
    >
      {children}
    </div>
  );

};
