/* eslint-disable no-empty */
import { gql, useMutation } from '@apollo/client';
import { SeekedEvent, TimeUpdateEvent } from '@u-wave/react-vimeo';
import { Player } from '@vimeo/player';
import React, { useEffect, useState } from 'react';
import { TvWebinarType, WebinarType } from '../../constant/tvConstant';
import { useAuth } from '../../contexts/AuthContext';
import { RelatedWebinar, UserType } from '../../generated/graphql';
import { dividePartDate } from '../../util/dateUtil';
import { getOmittionSentence } from '../../util/displayUtil';
import { getStaticFileDomain } from '../../util/envUtil';
import { isChatShow, isVideoShow } from '../../util/webinarUtils';
import { AtBasicButton, AtButtonOk, AtButtonWatchLater } from '../atoms/AtButton';
import { AtSupportInfoForVideo } from '../molecules/AtSupportInfoForVideo';
import { Video } from '../molecules/Video';
import { useIsMobile } from '../templates/MobileReady';
import AtRecommendedWebinarVideoArea from './AtRecommendedWebinarVideoArea';
import './AtVideoArea.scss';
import useTvWatchLaterTrackEvent from '../../hooks/tracking/useTvWatchLaterTrackEvent';

const addWatchMutation = gql`
  mutation createTvWebinarWatchLater($tvWebinarIds: [BigInteger!]!) {
    createTvWebinarWatchLater(tvWebinarIds: $tvWebinarIds) {
      tvWatchLaterResults {
        tvWebinarId
        tvWatchLaterId
      }
      tvWatchLaterRegistrationStatus
    }
  }
`;

const cancelWatchMutation = gql`
  mutation updateTvWatchLater($tvWatchLaterIds: [BigInteger!]!) {
    updateTvWatchLater(tvWatchLaterIds: $tvWatchLaterIds) {
      tvWebinarId
      tvWatchLaterId
    }
  }
`;

interface AtVideoAreaProps {
  webinarId: number;
  webinarTitle: string;
  companyName: string;
  webinarMovieUrl?: string;
  webinarChatUrl?: string;
  webinarLiveEventUrl?: string;
  webinarStreamingStartDatetime?: string;
  webinarStreamingEndDatetime?: string;
  webinarMoviePlayTime?: string;
  webinarOndemandFlag: string;
  webinarMainImgFilePath?: string;
  watchLaterStatus?: boolean;
  setWatchLaterStatus?: React.Dispatch<React.SetStateAction<boolean>>;
  tvWatchLaterId?: number;
  webinarType: WebinarType;
  startVideoTime?: number;
  tvWebinarMaxGuestViewingTimeInSeconds?: number;
  ubidForEmbed?: string;
  onGuestViewLimitReached?: () => void;
  recommendedWebinarList: [RelatedWebinar];
}

export const AtVideoArea = ({
  webinarId,
  webinarTitle,
  companyName,
  webinarMovieUrl,
  webinarChatUrl,
  webinarLiveEventUrl,
  webinarStreamingStartDatetime,
  webinarStreamingEndDatetime,
  webinarMoviePlayTime,
  webinarMainImgFilePath,
  watchLaterStatus,
  setWatchLaterStatus,
  tvWatchLaterId,
  webinarType,
  startVideoTime = 0,
  tvWebinarMaxGuestViewingTimeInSeconds,
  ubidForEmbed,
  onGuestViewLimitReached,
  recommendedWebinarList,
}: AtVideoAreaProps) => {

  const {
    currentUser, signup, signin, currentPath,
  } = useAuth();
  const [show, setShow] = useState(true);
  const [currentTvWatchLaterId, setCurrentTvWatchLaterId] = useState(tvWatchLaterId);
  const [chatShow, setChatShow] = useState(isChatShow(webinarType));
  const [videoShow, setVideoShow] = useState(isVideoShow(webinarType));
  const isMobile = useIsMobile();
  const { tvWatchLaterTrackEvent } = useTvWatchLaterTrackEvent();

  const renderError = (videoImageAlt: string, videoImageUrl?: string) => (
    <>
      <div id="at-video-area" className="at-video-area-cover" style={{ display: 'flex' }}>
        <div id="video-container">
          <img
            src={videoImageUrl ?? `${getStaticFileDomain()}tv/images/video-cover_no_image.png`}
            alt={videoImageAlt}
          />
          <div className="video-error-message">
            <p className="video-error-message-title">エラーが発生しました。</p>
            ブラウザのリロード、または再起動を行ってください。
            <br />
            それでも表示されない場合は動画が準備中の可能性があります。
            <br />
            時間をおいてアクセスしてください。
          </div>
        </div>
        {!isMobile && (
          <div className="recommend-webinar-area">
            <AtRecommendedWebinarVideoArea recommendedWebinars={recommendedWebinarList} />
          </div>
        )}
      </div>
      <AtSupportInfoForVideo companyName={companyName} />
    </>
  );

  const renderOffTime = (videoImageAlt: string, videoImageUrl?: string) => (
    <>
      <div id="at-video-area" className="at-video-area-cover" style={{ display: 'flex' }}>
        <div id="video-container">
          <img
            src={videoImageUrl ?? `${getStaticFileDomain()}tv/images/video-cover_no_image.png`}
            alt={videoImageAlt}
          />
          <div className="video-not-available-ondemand">
            <p className="video-not-available-ondemand-title">この動画はオンデマンド配信前です</p>
            <p className="video-not-available-ondemand-body">
              ライブ配信時に視聴をされなかった場合、
              <br />
              「視聴リストに追加」ボタンから登録いただくと
              <br />
              オンデマンド配信が開始した時にメールでお知らせいたします。
            </p>
          </div>
        </div>
        {!isMobile && (
          <div className="recommend-webinar-area">
            <AtRecommendedWebinarVideoArea recommendedWebinars={recommendedWebinarList} />
          </div>
        )}
      </div>
      <AtSupportInfoForVideo companyName={companyName} />
    </>
  );

  const renderInPreparation = (videoImageAlt: string, videoImageUrl?: string) => (
    <>
      <div id="at-video-area" className="at-video-area-cover" style={{ display: 'flex' }}>
        <div id="video-container">
          <img
            src={videoImageUrl ?? `${getStaticFileDomain()}tv/images/video-cover_no_image.png`}
            alt={videoImageAlt}
          />
          <div className="video-in-preparation-ondemand">
            <p className="video-in-preparation-ondemand-title">この動画は公開準備中です</p>
            動画の公開まで、いましばらくお待ちください。
            <br />
            気になる動画は「視聴リストに追加」いただけます。
          </div>
        </div>
        {!isMobile && (
          <div className="recommend-webinar-area">
            <AtRecommendedWebinarVideoArea recommendedWebinars={recommendedWebinarList} />
          </div>
        )}
      </div>
      <AtSupportInfoForVideo companyName={companyName} />
    </>
  );

  const [AddWebinar, { loading: AddWebinarLoading }] = useMutation(addWatchMutation, {
    variables: {
      tvWebinarIds: [webinarId],
    },
    onCompleted: (data) => {

      if (data) {

        setWatchLaterStatus?.(true);
        setCurrentTvWatchLaterId(data.createTvWebinarWatchLater.tvWatchLaterResults[0].tvWatchLaterId);
        tvWatchLaterTrackEvent(
          data.createTvWebinarWatchLater.tvWatchLaterResults.map((result: any) => result?.tvWebinarId),
        );

      }

    },
  });

  const [CancelWebinar, { loading: CancelWebinarLoading }] = useMutation(cancelWatchMutation, {
    variables: {
      tvWatchLaterIds: [currentTvWatchLaterId],
    },
    onCompleted: (data) => {

      if (data) {

        setWatchLaterStatus?.(false);

      }

    },
  });

  const loading = AddWebinarLoading || CancelWebinarLoading;

  const getWatchLaterButton = () => {

    if (watchLaterStatus) {

      return [
        <AtButtonWatchLater
          key={webinarId}
          label="視聴リストから削除"
          className="fas fa-times"
          onClickFunction={CancelWebinar}
          loading={loading}
          addedFlag
        />,
      ];

    }
    if (currentUser.userType === UserType.Member && !watchLaterStatus) {

      return [
        <AtButtonWatchLater
          key={webinarId}
          label="視聴リストに追加"
          className="fas fa-plus"
          onClickFunction={AddWebinar}
          loading={loading}
        />,
      ];

    }
    return [<AtButtonWatchLater key={webinarId} label={'視聴リストに追加'} className="fas fa-plus" />];

  };

  let buttonValue: any = getWatchLaterButton();

  useEffect(() => {

    buttonValue = getWatchLaterButton();

  }, [watchLaterStatus]);

  useEffect(() => {

    setChatShow(isChatShow(webinarType));
    setVideoShow(isVideoShow(webinarType));

  }, [webinarType]);

  let dateValue = <></>;

  const {
    jstMonth: startMonth,
    jstDay: startDay,
    jstWeekDay: startWeekDay,
    jstTime: startTime,
  } = dividePartDate(webinarStreamingStartDatetime, true);
  const { jstTime: endTime } = dividePartDate(webinarStreamingEndDatetime, true);
  const slash = webinarStreamingStartDatetime ? '/' : '';
  const startBracket = webinarStreamingStartDatetime ? ' (' : '';
  const endBracket = webinarStreamingStartDatetime ? ') ' : '';
  const startTilda = webinarStreamingStartDatetime ? '～' : '';
  if (webinarStreamingStartDatetime) {

    dateValue = (
      <p className="video-time">
        {startMonth}
        {slash}
        {startDay}
        {startBracket}
        {startWeekDay}
        {endBracket}
        {startTime}
        {startTilda}
        {endTime}
      </p>
    );

  }

  const title = getOmittionSentence(webinarTitle, 50);
  const videoCoverImageUrl = `${getStaticFileDomain()}tv/images/video-cover-image.png`;

  const renderGuest = (videoImageAlt: string, videoImageUrl: string) => {

    const result = (
      <>
        <div id="at-video-area" className="at-video-area-cover" style={{ display: 'flex' }}>
          <div id="video-container" className="has-video-modal">
            <img src={videoImageUrl} alt={videoImageAlt} />
            <div className="at-video-modal">
              <div className="at-video-modal-header">視聴にはログインが必要です。</div>
              <div className="at-button-normal-go-position">
                <AtButtonOk onSubmit={() => signin(currentPath)}>ログインする</AtButtonOk>
              </div>
              <p className="modal-attention">登録がお済でない方</p>
              <AtBasicButton onSubmit={() => signup(currentPath)}>新規登録する</AtBasicButton>
            </div>
          </div>
          {!isMobile && (
            <div className="recommend-webinar-area">
              <AtRecommendedWebinarVideoArea recommendedWebinars={recommendedWebinarList} />
            </div>
          )}
        </div>
        <AtSupportInfoForVideo companyName={companyName} />
      </>
    );

    return result;

  };

  const handlePlaybackOnDemand = (time: TimeUpdateEvent, player: Player) => {

    if (!tvWebinarMaxGuestViewingTimeInSeconds) return;
    if (currentUser.userType !== UserType.Guest) return;

    if (time.seconds >= tvWebinarMaxGuestViewingTimeInSeconds) {

      player.pause();

      const userAgentLowerCase = navigator.userAgent.toLowerCase();
      // Exclude Safari On iOS
      if (userAgentLowerCase.indexOf('iphone') === -1) {

        player.setCurrentTime(tvWebinarMaxGuestViewingTimeInSeconds);

      }

      onGuestViewLimitReached && onGuestViewLimitReached();

    }

  };

  const handlePlayerSeeked = (time: SeekedEvent, player: Player) => {

    if (!tvWebinarMaxGuestViewingTimeInSeconds) return;
    if (currentUser.userType !== UserType.Guest) return;

    if (time.seconds >= tvWebinarMaxGuestViewingTimeInSeconds) {

      player.setCurrentTime(tvWebinarMaxGuestViewingTimeInSeconds);
      onGuestViewLimitReached && onGuestViewLimitReached();

    } else {

      player.setCurrentTime(time.seconds);

    }

  };

  switch (webinarType) {
    case TvWebinarType.StandBy:
    case TvWebinarType.OnTime:
    case TvWebinarType.WaitTime:
      if (currentUser.userType === UserType.Guest) return renderGuest(webinarTitle, videoCoverImageUrl);
      if (!webinarLiveEventUrl || !webinarChatUrl) return renderError(webinarTitle, videoCoverImageUrl);

      return (
        <>
          <div id="at-video-area" style={{ display: 'flex' }}>
            <Video
              webinarId={webinarId}
              movieUrl={webinarLiveEventUrl}
              chatUrl={webinarChatUrl}
              webinarOndemandFlag={false}
              chatShowFlag={chatShow}
              videoShowFlag={videoShow}
              disableVideoImageUrl={videoCoverImageUrl}
              disableVideoImageAlt={webinarTitle}
              startVideoTime={startVideoTime}
              webinarStreamingStartDatetime={webinarStreamingStartDatetime}
              webinarStreamingEndDatetime={webinarStreamingEndDatetime}
              webinarMoviePlayTime={webinarMoviePlayTime}
            />
            {!isMobile && chatShow && (
              <iframe src={webinarChatUrl} height="100%" width="30%" frameBorder="0" title="Slido" />
            )}
            {!isMobile && !chatShow && (
              <div className="recommend-webinar-area">
                <AtRecommendedWebinarVideoArea recommendedWebinars={recommendedWebinarList} />
              </div>
            )}
          </div>
          <AtSupportInfoForVideo companyName={companyName} />
        </>
      );
    case TvWebinarType.Ondemand:
      if (!webinarMovieUrl) return renderInPreparation(webinarTitle, videoCoverImageUrl);

      return (
        <>
          <div id="at-video-area" style={{ display: 'flex' }}>
            <Video
              webinarId={webinarId}
              movieUrl={webinarMovieUrl}
              chatUrl={webinarChatUrl}
              webinarOndemandFlag
              chatShowFlag={chatShow}
              videoShowFlag={videoShow}
              disableVideoImageUrl={videoCoverImageUrl}
              disableVideoImageAlt={webinarTitle}
              startVideoTime={startVideoTime}
              webinarMoviePlayTime={webinarMoviePlayTime}
              onPlaybackTimeUpdate={handlePlaybackOnDemand}
              onPlayerSeeked={handlePlayerSeeked}
              ubidForEmbed={ubidForEmbed}
            />
            {!isMobile && (
              <div className="recommend-webinar-area">
                <AtRecommendedWebinarVideoArea recommendedWebinars={recommendedWebinarList} />
              </div>
            )}
          </div>
          <AtSupportInfoForVideo companyName={companyName} />
        </>
      );
    case TvWebinarType.OpenTime:
      if (currentUser.userType === UserType.Guest) return renderGuest(webinarTitle, videoCoverImageUrl);
      return (
        <>
          <div id="at-video-area" className="at-video-area-cover" style={{ display: 'flex' }}>
            <div id="video-container" className="has-video-modal">
              <img src={videoCoverImageUrl} alt={webinarTitle} />
              {show ? (
                <div className="at-video-modal">
                  <div className="at-video-modal-header">
                    <p className="at-video-modal-header-title">この動画はまだ配信前です</p>
                  </div>
                  <div className="at-video-modal-movie-area">
                    <div className="at-video-modal-time-area">
                      <i className="fas fa-clock" />
                      {dateValue}
                    </div>
                    <div className="at-video-modal-detail-area">
                      <img className="video-thumbnail" src={webinarMainImgFilePath} alt={webinarTitle} />
                      <p className="video-title">{title}</p>
                    </div>
                  </div>
                  <div className="at-video-modal-button-area">{buttonValue}</div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {!isMobile && (
              <div className="recommend-webinar-area">
                <AtRecommendedWebinarVideoArea recommendedWebinars={recommendedWebinarList} />
              </div>
            )}
          </div>
          <AtSupportInfoForVideo companyName={companyName} />
        </>
      );
    case TvWebinarType.OffTime:
      return renderOffTime(webinarTitle, videoCoverImageUrl);
    default:
      return renderError(webinarTitle, videoCoverImageUrl);
  }

};

export default AtVideoArea;
