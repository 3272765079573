import { VFC } from 'react';
import { useHistory } from 'react-router-dom';
import style from './AtTimetableModal.module.scss';
// import { AtLabel } from '../atoms/AtLabel';
import { AtButtonMultiple } from '../atoms/AtButtonMultiple';
import { AtModal } from './AtModal';
import { useIsMobile } from '../templates/MobileReady';
import { TvEventPageQuery, TvWebinarOndemandFlag } from '../../generated/graphql';
import {
  dividePartDate, timeIsFuture, nowIsBetweenTimes, timeIsPast,
} from '../../util/dateUtil';
import { AtRecommendInfo } from '../molecules/AtRecommendInfo';
import { getTvDomain } from '../../util/envUtil';
import { AtCompanyImage } from '../atoms/AtCompanyImage';

export interface AtTimetableModalProps {
  closeModal: () => void;
  webinar: N<N<TvEventPageQuery['tvEventPage']>['eventTimetable']>[number]['webinars'][number];
  setShowCTAModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AtTimetableModal: VFC<AtTimetableModalProps> = (props) => {

  const { closeModal, webinar, setShowCTAModal } = props;

  const isMobile = useIsMobile();
  const history = useHistory();

  const {
    jstTime: startTime,
    jstDay: startDay,
    jstMonth: startMonth,
    jstWeekDay: startWeekDay,
  } = dividePartDate(webinar.tvWebinarStreamingStartDatetime);
  const { jstTime: endTime } = dividePartDate(webinar.tvWebinarStreamingEndDatetime);

  const buttonAction = async () => {

    closeModal();
    await true; // give modal time to close

    setShowCTAModal(true);

  };

  const speakerSection = (
    <div className={` ${style.speakerSection} `}>
      <img className={` ${style.speakerImg} `} src={webinar.tvWebinarSpeakerImgFilePath} alt="speaker" />
      <div className={` ${style.companyName} `}>{webinar.companyContract.companyLang?.companyNameFull}</div>
      <div className={` ${style.jobTitle} `}>{webinar.tvWebinarSpeakerDepartment}</div>
      <div className={` ${style.speakerName} `}>
        {webinar.tvWebinarSpeakerName ? `${webinar.tvWebinarSpeakerName} 氏` : ''}
      </div>
      {/* ↓の部分はAtExhibitionCompanyModal.tsxの中身と同じなのでコンポーネントに切り出せそう */}
      {webinar.companyContract.companyPagePublishFlag ? (
        <a
          className="at-corporate-logo-area"
          href={`/company/${webinar.companyContract.companyContractDisplayId}`}
          target="_blank"
          rel="noopener"
        >
          <AtCompanyImage
            src={webinar.companyContract.companyLang?.companyLogoUrl}
            className={` ${style.companyLogo} `}
            alt={webinar.companyContract.companyLang?.companyNameFull}
            width={143}
          />
        </a>
      ) : (
        <AtCompanyImage
          src={webinar.companyContract.companyLang?.companyLogoUrl}
          className={` ${style.companyLogo} `}
          alt={webinar.companyContract.companyLang?.companyNameFull}
          width={143}
        />
      )}
    </div>
  );

  const dateSection = (
    <div className={` ${style.dateSection} `}>
      {`${startMonth}/${startDay} (${startWeekDay}) ${isMobile ? '\n' : ''}${startTime}～${endTime}`}
    </div>
  );

  const detailSection = (
    <div className={` ${style.detailsSection} `}>
      {/* <div className={` ${style.categories} `}>
        <AtLabel labelKind={webinar.category1} />
        <AtLabel labelKind={webinar.category2} />
      </div> */}
      <div className={` ${style.title} `}>{webinar.tvWebinarTitle}</div>
      <div className={` ${style.subtitle} `}>{webinar.tvWebinarSubtitle}</div>
      <div className={` ${style.description} `}>{webinar.tvWebinarDescription}</div>
      <div className={` ${style.recommendations} `}>
        <AtRecommendInfo
          recommendTexts={[
            webinar.tvWebinarRecommendSentence1,
            webinar.tvWebinarRecommendSentence2,
            webinar.tvWebinarRecommendSentence3,
          ]}
          verticalList
        />
      </div>
    </div>
  );

  return (
    <AtModal
      classNameModalContainer={` ${style.modalContainer} `}
      title="セッション詳細"
      closeEventModal={() => {

        closeModal();

      }}
    >
      <div className={` ${style.content} `}>
        {isMobile ? (
          <>
            {dateSection}
            {speakerSection}
            {detailSection}
          </>
        ) : (
          <>
            {speakerSection}
            <div className={` ${style.right} `}>
              {dateSection}
              {detailSection}
            </div>
          </>
        )}
      </div>
      <AtButtonMultiple className={` ${style.button} `} clickHandler={buttonAction}>
        {'視聴予約をする'}
      </AtButtonMultiple>
    </AtModal>
  );

};
